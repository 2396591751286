import { getUidToken } from '../helpers/commonFunctions';
import { middleCallback } from "./commonApis";
import { BASE_URL } from '../helpers/Constants';
const superagent = require("superagent");

  export const createNewWorkSpace = (workspace, callback) => {
    const { uid, token } = getUidToken();
    superagent
      .post(BASE_URL + "createWorkspace")
      .send( workspace )
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  };

  export const getAllWorkspace = (orgId, type, page, size, sortField, sortOrder, callback) => {
    console.log("Getall workspace called")
    const { uid, token } = getUidToken();
    let reqBody = {};
    if ( sortField && sortOrder ) {
      reqBody = {sortBy: sortField, sortOrder: (sortOrder === "ascend" ? "ASC" : "DESC")};
    }
    superagent
      .post(BASE_URL + orgId + "/getAllWorkspace?type=" + type + "&page=" + page + "&size=" + size)
      .send(reqBody)
      .set("uid", uid)
      .set("token", token)
      .withCredentials(false) // Explicitly prevent sending cookies
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  };

  export const updateWorkspace = (workspace, callback) => {
    const { uid, token } = getUidToken();
    superagent
      .post(BASE_URL + "updateWorkspace" )
      .send( workspace )
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  };


  export const deleteWorkspace = (workspace, type, callback) => {
    const { uid, token } = getUidToken();
    superagent
      .post(BASE_URL + "deleteWorkspace?deletedFlag=" + type )
      .send( workspace )
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  };

  export const getUsersByOrgId = (orgId, callback, role) => {
    const { uid, token } = getUidToken();
    let url = BASE_URL  + "projectusers/" + orgId + "/" + role;
    superagent
      .get( url )
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  };

  export const getSharedProjects = () =>{
    const sharedProject = window.sessionStorage.getItem("sharedProject");
    return sharedProject;
  };

  export const setSharedProjects = project =>{
    window.sessionStorage.setItem("sharedProject", project);
  };

  export const getactiveName = () =>{
    const activeName = window.sessionStorage.getItem("activeName");
    return activeName;
  };

  export const undoDeleteWorkspace = (recoverworkspace, callback) => {
    const { uid, token } = getUidToken();
    superagent
      .post(BASE_URL + "undoDeleteWorkspace" )
      .send( recoverworkspace )
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  };


  export const getTaskTypesOrgProject = (orgId, callback) =>{
    console.log("getTaskTypesOrgProject")
    const { uid, token } = getUidToken();
    let url = BASE_URL + "getTaskTypes?orgId=" + orgId;
    superagent
      .post(url)
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
      middleCallback(err, res, callback)
      });
  }

// Project View Page 

  export const getHomeDataWithPagination = (callback, cache, param) => {
    let url = "getUserHome";
    if (cache) {
      url = "getUserHome?cache=false";
    }
    const { uid, token } = getUidToken();
    console.log("getting home data with pagination from dthelper");
    superagent
      .post(BASE_URL + url)
      .set("uid", uid)
      .send(param)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  };


  export const archiveProjectList = (archiveProjectList, callback) => {
    const { uid, token } = getUidToken();
    superagent
      .post(BASE_URL + "archiveProjectList" )
      .send( archiveProjectList)
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  };

  export const undoArchiveProjects = (recoverProjects, callback) => {
    const { uid, token } = getUidToken();
    superagent
      .post(BASE_URL + "undoArchiveProjects" )
      .send( recoverProjects)
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  };

  export const deleteArchiveProjects = (projects, callback) => {
    const { uid, token } = getUidToken();
    superagent
      .post(BASE_URL + "deleteArchiveProjects" )
      .send( projects)
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  };



  export const getHomeData = (callback, cache) => {
    let url = "getUserHome";
    if (cache) {
      url = "getUserHome?cache=false";
    }
    const { uid, token } = getUidToken();
    console.log("getting home data from dthelper");
    superagent
      .post(BASE_URL + url)
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback)
        // callback(err, res);
      });
  };


  export const getAllWorkspaceList = (orgId, callback) => {
    const { uid, token } = getUidToken();
    superagent
      .post(BASE_URL + orgId + "/getAllWorkspaceList" )
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  };


  export const getDeleteHistory = (orgId, page, size, callback, searchKeyword, searchFromDate, searchToDate, orderBy, sortOrder) => {
    const { uid, token } = getUidToken();
    console.log("invoked: getDeleteHistory");
    let url = BASE_URL + "getDeleteHistory/" + orgId + "?page=" + page + "&size=" + size + "&orderBy=" + orderBy + "&sortOrder=" + sortOrder; 
    if(searchKeyword){
      url = url + "&searchKeyword=" + searchKeyword;
    }
    if(searchFromDate){
      url = url + "&searchFromDate=" + searchFromDate;
    }
    if(searchToDate){
      url = url + "&searchToDate=" + searchToDate ;
    } 
       superagent
      .get(url)
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback, "body")
        // callback(err, res.body);
      });
    }