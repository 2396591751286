// App.js
import React, { Component, PropTypes } from 'react';
import Modal from "react-bootstrap/lib/Modal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push, replace } from "react-router-redux";
import { Segment,Menu,Icon,Button } from "semantic-ui-react";
import { logout, verifyUser, signIn } from "../../redux/reducer/loginReducer";
import { getUidToken } from '../../helpers/commonFunctions';
import { LINKS, SESSION_VARIABLES, MESSAGES, BOOLEAN_TYPES, ALERT_TYPES, NUMBERS } from '../../helpers/Constants';
import firebase from 'firebase';
import ReactGA from 'react-ga';
import ga from 'react-ga';
import * as cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import { setError } from '../../redux/reducer/projectReducer';
import { selectProject,getUserHomeData,setactiveMenu,resetState } from '../../redux/reducer/projectReducer';
import { logEvent,refreshToken,saveactiveTab,getactiveName,setSharedProjects,showAlert } from '../../helpers/commonFunctions';
import config from '../../helpers/config';
import { extendTimeoutInvoke,DUMMY_UID } from '../../helpers/Utils';
import logo from "../../images/logo1.svg";
import UserImg from "../../images/userImg.jpg";
import imgHolder from "../../images/userImg1.png";
import Organization from "../../images/organization.svg"
import OrganizationHighlights from "../../images/organizationhighlights.svg"
import Archived from "../../images/archived.svg"
import ArchivedHighlight from '../../images/archivedhighlight.svg'
import Share from "../../images/share.svg"
import ShareHighlight from "../../images/sharehighlight.svg"
import Workspace from "../../images/workspace.svg"
import WorkspaceHighlight from "../../images/workspacehighlight.svg"
import Plus from "../../images/plus.svg"
import PlusHighlight from "../../images/plushighlight.svg"
import Workspaceuser from "../../images/workspaceuser.svg"
import WorkspaceuserHighlight from "../../images/workspaceuserhighlight.svg"
import Loader from '../Loader/Loader';

function getBlobUrl(url) {
    const baseUrl = window.URL || window.webkitURL;
    const blob = new Blob([`importScripts('${url}')`], {
      type: "application/javascript"
    });

    return baseUrl.createObjectURL(blob);
  }

  let webWorkerUrl = getBlobUrl(
    "https://unpkg.com/cornerstone-wado-image-loader@2.2.3/dist/cornerstoneWADOImageLoaderWebWorker.min.js"
  );
  let codecsUrl = getBlobUrl(
    "https://unpkg.com/cornerstone-wado-image-loader@2.2.3/dist/cornerstoneWADOImageLoaderCodecs.js"
  );

class Outline extends Component {

    static contextTypes = {
        store: PropTypes.object.isRequired
      };
    
      constructor(props) {
        super(props);
        this.selectMenu = this.selectMenu.bind(this);
        this.navigatetoFirstIcon = this.navigatetoFirstIcon.bind(this)
        this.getProjectNames = this.getProjectNames.bind(this);
        this.selectProjectMenu = this.selectProjectMenu.bind(this);
        if(getUidToken().roleId === "3" || getUidToken().roleId === "5" || getUidToken().roleId === "6")
        {
           saveactiveTab('shared');
           setSharedProjects('shared');
        }
        else if(getUidToken().roleId === "1"){
          saveactiveTab('users');
           setSharedProjects('users');
        }
        else if(getUidToken().roleId === "4"){
          saveactiveTab('organizations');
           setSharedProjects('organizations');
        }
        else if(getUidToken().roleId === "2"){
          saveactiveTab('home');
           setSharedProjects('home');
        }
    
        window.addEventListener("click", (ev) => {
          if (getUidToken().token && window.sessionStorage.getItem(SESSION_VARIABLES.TIME_EXPIRY_POPUP) === BOOLEAN_TYPES.FALSE &&
              (window.sessionStorage.getItem(SESSION_VARIABLES.TOKEN_EXPIRY) &&
                window.sessionStorage.getItem(SESSION_VARIABLES.TOKEN_EXPIRY) === BOOLEAN_TYPES.FALSE)) {
            extendTimeoutInvoke("click");
          }
        });
    
        window.setInterval( () => {extendTimeoutInvoke("idle")}, 3 * 60 * 1000);  // 5 minutes
      }
    
      state = {
        activeMenu: getUidToken().roleId === "1" ? "users" :
                    getUidToken().roleId === "4" ? "organizations" : 
                    getUidToken().roleId === "2" ? "home" : "shared" ,
        projectNameIdMap: {},
        projectNames: [],
        refresh: false,
        isLoginPage: false,
        oldUrl: ''
      };
    
      componentWillMount() {
        const config = {
          webWorkerPath: webWorkerUrl,
          taskConfiguration: {
            decodeTask: {
              codecsPath: codecsUrl
            }
          }
        };
        if (!window.isWebWorkerManagerInitialized) {
          cornerstoneWADOImageLoader.webWorkerManager.initialize(config);
          window.isWebWorkerManagerInitialized = true;
      }
    
        logEvent('buttons', 'User visit started');
        if (this.props.user && config.servingEnv === 'online') {
          this.props.verifyUser();
        }
        if (this.props.location.pathname === '/workspace') {
          saveactiveTab('home');
        }
        this.startErrorLog();
      }
    
      componentDidMount() {
        window['ga-disable-UA-110079535-1'] = true;
        ga.initialize('UA-110079535-1');
        ga.pageview(this.props.location.pathname);
        this.clearAllCookies();
        window.history.pushState(null, document.title, window.location.href);
        const pathSplits = this.props.location.pathname.split('/');
        // set active Main Menu
        this.selectMainMenu(pathSplits);
        // This is to redirect the user to Home (My Projects), on click of back button in browser
        window.addEventListener('popstate', (event) => {
            let isNotification = document.getElementsByClassName('ant-notification-notice');
              if (isNotification.length === 0) {
                showAlert("You are redirected to home page. Please use the options provided by the application for navigation.", 'warning')
          }
            saveactiveTab('home');
            setSharedProjects('home');
            this.props.replaceState('/workspace');
        });
        console.log('app did mount', this.props);
        if (!this.props.projects && this.props.user) {
          if (config.servingEnv === 'online') {
            firebase.auth().onAuthStateChanged(function(user) {
              refreshToken();
              console.log('user refreshed', user);
            });
            if (window.FS) {
              window.FS.identify(this.props.user.uid, { displayName: this.props.user.firstName, email: this.props.user.email, plan: this.props.plan });
            }
            ReactGA.set({ userId: this.props.user.uid });
            if (window.Raven) {
              console.log('setting raven context', this.props.user.email);
              window.Raven.setUserContext({
                email: this.props.user.email
              });
            }
          }
          if (getUidToken().uid && getUidToken().uid !== DUMMY_UID) {
            this.props.getUserHomeData(getUidToken());
          }
        }
        if (this.props.location.pathname === '/projects' && !this.props.user) {
          // logout
          if (config.servingEnv === 'online') {
            const cu = firebase.auth().getCurrentUser;
            console.log('current user is', cu);
            if (cu) {
              this.props.signIn(cu);
            } else {
              this.props.replaceState('/projects/login');
            }
          } else {
            if (!getUidToken().uid || getUidToken().uid === DUMMY_UID) {
              this.props.replaceState('/projects/login');
            }
          }
        } else if (this.props.user && config.servingEnv === 'onpremise') {
          if (!getUidToken().uid) {
            console.log('GOT dummy uid');
            this.props.logout();
          }
        }else if ((this.props.user || this.props.user === null) && config.servingEnv === 'onpremise') {
          this.validateUsersession();
        }
      }

      clearAllCookies = () => {
        document.cookie.split(";").forEach(function(c) {
          document.cookie = c.trim().split("=")[0] + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        });
      }
    
      componentWillReceiveProps(nextProps) {
        console.log('next props in app', nextProps);
        if (this.props.location.pathname !== nextProps.location.pathname) {
          ga.pageview(nextProps.location.pathname);
        }
        if (this.props.globalError && nextProps.globalError) {
          console.log('resetting global error');
          this.props.setError(false);
        }
        if (!this.props.projects && nextProps.projects) {
          this.setState({ refresh: true});
        }
        if (this.props.loggingIn && !nextProps.loggingIn) {
          this.props.getUserHomeData(getUidToken());
        }
        if (!this.props.user && nextProps.user) {
          // login
          if (nextProps.user && nextProps.user.email) {
            ReactGA.set({ userId: nextProps.user.uid });
            if (window.Raven) {
              window.Raven.setUserContext({
                email: nextProps.user.email
              });
            }
          }
          if ( nextProps.user.roleId === 4) {
            this.props.pushState('/projects/organizations');
          }else if (nextProps.user.roleId === 1){
            this.props.pushState('/projects/users');
          } else {
            if (nextProps.user.roleId === 2) {
              this.props.pushState('/workspace');
              this.setState({ activeMenu: LINKS.HOME })
              saveactiveTab(LINKS.HOME)
            }
            if (nextProps.user.roleId === 3 || nextProps.user.roleId === 5 || nextProps.user.roleId === 6 ) {
              this.props.pushState('/projects/' + nextProps.user.roleId + '/list');
              this.setState({ activeMenu: LINKS.SHARED })
              saveactiveTab(LINKS.SHARED)
              setSharedProjects(LINKS.SHARED)
            }
          }
        } else if ((this.props.location.pathname === '/projects' && !this.props.user && !nextProps.user) || (this.props.user && !nextProps.user)) {
          // logout
          this.props.replaceState('/projects/login');
        }
    
        if (nextProps.params.roleId === NUMBERS.NUM_3) {
          this.setState({activeMenu: LINKS.SHARED})
        }
        if (window.sessionStorage.getItem(SESSION_VARIABLES.TOKEN_EXPIRY) && window.sessionStorage.getItem(SESSION_VARIABLES.TOKEN_EXPIRY) === BOOLEAN_TYPES.TRUE) {
          showAlert(MESSAGES.TOKEN_EXPIRY_MESSAGE, ALERT_TYPES.WARNING);
        }
      }
    
      componentWillUnmount() {
        console.log('unmounting Component');
          this.props.logout();
        window.clearInterval();
        this.props.resetState();
      }
    
      getProjectNames(projectArray) {
        const { activeMenu } = this.state;
        const names = [];
        console.log('project array is ', projectArray);
        if (projectArray) {
          for (let index = 0; index < projectArray.length; index ++) {
            const name = projectArray[index].projectDetails.name;
            const orgName = projectArray[index].projectDetails.orgName;
            const id = projectArray[index].projectDetails.id;
            names.push(
            <Menu.Item key={index} name={name} as="a" href={'/projects/' + orgName + '/' + name + '/' + id} active={activeMenu === id} onClick={(event) => { this.selectProjectMenu(id, orgName, name); event.preventDefault();}}>
              {name}
            </Menu.Item>
            );
          }
        }
        return names;
      }
    
      validateUsersession() {
        if (!getUidToken().uid || getUidToken().uid === null) {
          this.setState({oldUrl: this.props.location.pathname})
          if (this.props.location.pathname === '/projects/password/reset') {
            this.props.replaceState(this.props.location.pathname + this.props.location.search);
          } else {
            this.props.replaceState('/projects/login');
          }
        }
      }
      startErrorLog() {
        window.onerror = (message, file, line, column, errorObject) => {
          const column1 = column || (window.event && window.event.errorCharacter);
          const stack = errorObject ? errorObject.stack : null;
          this.props.setError(true);
          const data = {
            message: message,
            file: file,
            line: line,
            column: column1,
            errorStack: stack,
          };
          console.log('error log data is ', data);
        };
      }
    
      selectProjectMenu(id, orgName, projectName) {
        this.props.selectProject(id);
        this.setState({ activeMenu: id });
        this.props.pushState('/projects/' + orgName + '/' + projectName + '/' + id);
      }
    
      navigateToUpdatePassword = () =>{
        this.props.pushState('/projects/updatePassword');
      }
    
      navigateToDeleteHistory = () =>{
        this.props.pushState('projects/deletehistory');
      }
    
      selectMenu(item, {name}) {
        console.log('selecing menu ', item, name);
        this.setState({ activeMenu: name, currentwPage: 1});
        saveactiveTab(name)
        setSharedProjects(name)
        if (name === 'home') {
          this.props.pushState('/workspace?currentwPage=1');
        } else if (name === 'shared') {
          let roleId = 3;
          if( window.localStorage.getItem("roleId") > 3 ){
            roleId = window.localStorage.getItem("roleId");
          }
          this.props.replaceState('/projects/' + roleId + '/list?currentpPage=1');
        } else if (name === 'archived') {
          this.props.pushState('/archived?currentwPage=1');
        }
         else {
          this.props.pushState('/projects/' + name);
        }
        item.preventDefault();
      }

      navigatetoFirstIcon() {
        let roleId = window.localStorage.getItem("roleId")
        if(roleId === "2")
        {
          this.setState({ activeMenu: "home", currentwPage: 1});
          saveactiveTab("home")
          setSharedProjects("home")
          this.props.pushState('/workspace?currentwPage=1');
        }
        else if(roleId === "4")
        {
          this.setState({ activeMenu: "organizations", currentwPage: 1});
          saveactiveTab("organizations")
          setSharedProjects("organizations")
          this.props.pushState('/projects/organizations');
        }
      }
    
      handleLogout = (event) => {
        event.preventDefault();
        window.sessionStorage.removeItem('lastTimeAccessAPI');
        this.props.logout();
      };
    
      loginRedirect = (event) => {
        this.props.replaceState('/projects/login');
      };
    
      apiKeyFetched = (error, response) => {
        console.log('apiKeyFetched ', error, response);
        if (error) {
          let errorM = "Failed";
          if (response && response.body && response.body.message) {
            errorM = response.body.message;
          }
          this.setState({ apiKeyError: errorM, apiKeyResponse: undefined });
        } else {
          this.setState({ apiKeyError: undefined, apiKeyResponse: response.body });
        }
      }

      selectMainMenu(pathSplits) {
        console.log('getactiveName===>', getactiveName())
        console.log('pathsplits===>', pathSplits)
        console.log('pathsplits==length=>', pathSplits.length)
        this.setState({isLoginPage:false,activeMenu:getactiveName()})
      }

  render() {
    const { user, children } = this.props;
    const { activeMenu, isLoginPage, apiKeyModal, apiKeyError, apiKeyResponse } = this.state;
    let lroleId = getUidToken().roleId;

    const viewUser=()=> {
        console.log("Entered in viewUser")
        this.props.replaceState('/projects/' + getUidToken().uid + '/userDetails/');
      }

    return (
      <div>
        <Loader/>
    { user &&
         <header className="pr_nav_header w-100">
         <div onClick={this.navigatetoFirstIcon}>
           <img
               alt='logo'
               src={logo}
               className="logo"
             />            
         </div>
         <div>
           <ul className="pn_mainNav">
          
            { !user &&
            <li className="profile">
              <a href="#">
                <img
                  alt='userimg'
                  src={UserImg}
                  className="userImg" title="Sign Up/Login"
                />Sign Up/Login
              </a>
            </li>
            }
            {user && getUidToken().uid &&
            <li className="profile">
              <div className="dropdown">
                <a>
                { user.profileImage &&
                		<img
                        alt='profile'
                        src={`data:image/jpeg;base64,${user.profileImage}`}
                        className="userImg" title="profile"
                      />
                }
                {!user.profileImage &&
                        	<img
                            alt='imgholder'
                            src={imgHolder}
                            className="userImg" title="profile"
                          />
                 }
                   <span className="statusIndicator" />
                </a>
                <div className="dropdown-content">
                <ul className="hsub_menu">
                  <li 
                   onClick={viewUser}
                  >
                    <Icon name='user circle' size='small' /> <span>{user.firstName}</span>
                  </li>
                  <li 
                  onClick={this.navigateToUpdatePassword}>
                    <Icon name='unlock alternate' size='small' /> <span>Change Password </span>
                  </li>
                 {(parseInt(lroleId) === 1 || parseInt(lroleId) === 2) && <li 
                 onClick={this.navigateToDeleteHistory}
                 >
                    <Icon name='trash alternate' size='small' /> <span>Delete History </span>
                  </li>}
                  <li 
                  onClick={this.handleLogout}
                  >
                    <i className="anticon anticon-logout" style={{ fontWeight : "bold"}}></i> 
                    <span style={{ marginLeft : "3px"}}>Logout </span>
                  </li>
                </ul>
                </div>
              </div>
            </li>
            }
            </ul>
          </div>
          </header>
        }


    <div>
      <Segment.Group basic horizontal style={{ border: 'none', boxShadow: 'none', margin: '0'}}>
      { user && getUidToken().uid &&
      <Segment basic vertical id="pr_side_nav" style={{ minWidth: "5%", maxWidth: "5%"}}>
      <ul class="pn_mainNav" style={{visibility: 'hidden', textIndent: '-3000%'}}><li style={{height: '60px'}}>DOCUMENTATION</li></ul>
         
          <Menu secondary vertical fluid icon="labeled" widths="one" size="large" id="main_menu">
          {/* Create Project workspace for Admin User Alone */}
          { parseInt(lroleId) === 2 &&
            [
              <Menu.Item className='d-flex justify-content-center align-items-center' name= "home"
                  active={activeMenu === 'home'}
                  onClick={this.selectMenu} title="My Workspaces">
                  <img src={activeMenu === 'home' ? WorkspaceHighlight : Workspace} alt='' />
              </Menu.Item>,
              <Menu.Item className='d-flex justify-content-center align-items-center' name="create" active={activeMenu === 'create'} href={'/projects/create'} onClick={this.selectMenu} title="Create Project">
                <img src={activeMenu === 'create' ? PlusHighlight : Plus} alt='create'/>   
              </Menu.Item>
            ]
          }
          { parseInt(lroleId)  > 1 && parseInt(lroleId) !== 4 && 
              <Menu.Item className='d-flex justify-content-center align-items-center' name="shared"
                  active={activeMenu === 'shared'}
                  onClick={this.selectMenu} title="Shared Projects">
                        <img src={activeMenu === 'shared' ? ShareHighlight : Share} alt='share'/>
              </Menu.Item>
          }

          {
            parseInt(lroleId) === 2 &&
              <Menu.Item className='d-flex justify-content-center align-items-center' name="archived" active={activeMenu === 'archived'} href={'/archived'} onClick={this.selectMenu} title="Archived Workspaces">
                <img src={activeMenu === 'archived' ? ArchivedHighlight : Archived} alt='archived' />
             </Menu.Item>
          }
          { parseInt(lroleId) === 4 &&

            <Menu.Item className='d-flex justify-content-center align-items-center' name="organizations" active={activeMenu === 'organizations'} title="Organizations" onClick={this.selectMenu}>
                <img src={activeMenu === 'organizations'? OrganizationHighlights: Organization} alt='organization' />
            </Menu.Item>
            }
            { parseInt(lroleId) !== 3 && parseInt(lroleId) !== 5 && parseInt(lroleId) !== 6 &&
              <Menu.Item className='d-flex justify-content-center align-items-center' name="users" active={activeMenu === 'users'} title="Users" href={'/projects/users'} onClick={this.selectMenu}>
                <img src={activeMenu === 'users' ? WorkspaceuserHighlight :Workspaceuser} alt='users'/>
              </Menu.Item>
            }
            
            { lroleId > 6 &&
             <Menu.Item className='d-flex justify-content-center align-items-center' name="create" active={activeMenu === 'create'} onClick={this.selectMenu}>
              <Icon name="plus" title="Create Dataset"/>
              {!this.props.menuHidden && <h6> Create Dataset </h6>}
            </Menu.Item>
            }
            </Menu>
        </Segment>
      }

        <div className="col-md-0.5" />
          <Segment basic vertical id="work_area" className={"main_container " + (isLoginPage ? 'menu_hide' : 'menu_show')} style={{ marginTop: user ? '3.2%' : '0px', paddingTop: user ? '1em' : '0em', paddingBottom: user ? '0em' : '0em'}}>
          {apiKeyModal &&
          <div>
            <Modal.Dialog>
              <Modal.Header>
                <Modal.Title>API Authentication</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                {  apiKeyError &&
                   <p> {apiKeyError} </p>
                }
                {
                  !apiKeyError && apiKeyResponse &&
                  <p>
                    All APIs take a ‘key’ and ‘secret’ param in the header.
                    These key-secret pairs are associated with a user and allows the same permission to the user as she has on the Dataturks website,
                    ex: if she is an admin of a project, she can upload data to the project etc.
                    <br />
                    <pre>
                      {JSON.stringify(apiKeyResponse, null, 2)}
                    </pre>
                    <br />
                    API Documentation : <a href="#" target="_blank">Docs</a>
                  </p>
                }
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    this.setState({ apiKeyModal: false });
                  }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal.Dialog>
          </div>
            }
            </Segment>
        </Segment.Group>
        </div>
        <main>
          {children}
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
      user: state.loginReducer.user,
      globalError: state.projectReducer.globalError, 
      menuHidden: state.projectReducer.menuHidden,
      orgName: state.projectReducer.orgName, 
      projects: state.projectReducer.projects,
      plan: state.projectReducer.plan,
      loggingIn: state.loginReducer.loggingIn,
      labelsDone: state.projectReducer.labelsDone,
      labelsAllowed: state.projectReducer.labelsAllowed, 
      subscriptionExpiryTimestamp: state.projectReducer.subscriptionExpiryTimestamp,
      getActiveMenu: state.projectReducer.getactiveMenu
      
    };
  };
  
  const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        pushState: push,
        verifyUser,
        logout,
        replaceState: replace,
        signIn, 
        resetState, 
        selectProject,
        getUserHomeData,
        setactiveMenu,
        setError

      },
      dispatch
    );
  
  export default connect(mapStateToProps, mapDispatchToProps)(Outline);
