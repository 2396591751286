import {
    VIDEO_CLASSIFICATION,
    VIDEO_BOUNDING_BOX,
    IMAGE_CLASSIFICATION,
    DOCUMENT_ANNOTATION,
    TEXT_SUMMARIZATION,
    IMAGE_POLYGON_BOUNDING_BOX,
    IMAGE_POLYGON_BOUNDING_BOX_V2,
    IMAGE_SEGMENTATION,
    POS_TAGGING,
    POS_TAGGING_GENERIC,
    TEXT_CLASSIFICATION,
    TEXT_MODERATION,
    IMAGE_BOUNDING_BOX,
    DICOM_SEGMENTATION,
    DICOM_CLASSIFICATION,
    IMAGE_MASKING
  } from '../../helpers/Utils';
  
  import React from 'react';
  import {Tag} from 'antd'
    
  const getPlaceHolder = (type) => {
      let placeholder = 'Tagging guidelines for your team. Ex: Mark all place names as City';
      if (type === TEXT_CLASSIFICATION || type === IMAGE_CLASSIFICATION || type === VIDEO_CLASSIFICATION) {
        placeholder = 'Classification guidelines for your team. Ex: Mark all 1 star review as negative';
      } else if (type === IMAGE_BOUNDING_BOX || type === VIDEO_BOUNDING_BOX || type === IMAGE_POLYGON_BOUNDING_BOX || type === IMAGE_SEGMENTATION || type === IMAGE_POLYGON_BOUNDING_BOX_V2 || type === DICOM_SEGMENTATION) {
        placeholder = 'Bounding guidelines for your team. Ex: Create rectangles around cars';
      } else if (type === DOCUMENT_ANNOTATION) {
        placeholder = 'Document annotation guidelines for your team. e.g Mark javascript as super-human skill ';
      }
      return placeholder
  }
  
  const getNamePlaceHolder = (type) => {
      let namePlaceHolder = "";
      switch (type) {
          case POS_TAGGING:
          case POS_TAGGING_GENERIC:
            namePlaceHolder = 'Resume Skill Identification Project';
            break;
          case IMAGE_CLASSIFICATION:
            namePlaceHolder = 'Cat Dog Image Classification Project';
            break;
          case VIDEO_CLASSIFICATION:
            namePlaceHolder = 'Cat Dog Video Classification Project';
            break;
          case IMAGE_POLYGON_BOUNDING_BOX:
          case VIDEO_BOUNDING_BOX:
          case IMAGE_POLYGON_BOUNDING_BOX_V2:
          case IMAGE_SEGMENTATION:
          case IMAGE_BOUNDING_BOX:
            namePlaceHolder = 'Car/Cat Bounding Box Project';
            break;
          case TEXT_CLASSIFICATION:
            namePlaceHolder = 'Emotion Detection Project using tweets';
            break;
          case TEXT_MODERATION:
            namePlaceHolder = 'Violent Content Moderation Project';
            break;
          case TEXT_SUMMARIZATION:
            namePlaceHolder = 'Resume Summarization Project';
            break;
          default:
            namePlaceHolder = "Project Name"
        }
      return namePlaceHolder
  }
  
  const getButtonText = (type) => {
    let buttonText = ''
    switch (type) {
      case POS_TAGGING:
          buttonText = 'Start Tagging';
          break;
      case DOCUMENT_ANNOTATION:
          buttonText = 'Start Tagging';
        break;
      case POS_TAGGING_GENERIC:
          buttonText = 'Start Tagging';
        break;
      case TEXT_SUMMARIZATION:
        buttonText = 'Summarize Text';
        break;
      case TEXT_MODERATION:
        buttonText = 'Moderate Text';
        break;
      case TEXT_CLASSIFICATION:
          buttonText = 'Classify Text';
          break;
      case IMAGE_CLASSIFICATION:
          buttonText = 'Classify Image';
          break;
      case VIDEO_CLASSIFICATION:
        buttonText = 'Classify Video';
        break;
      case VIDEO_BOUNDING_BOX:
        buttonText = 'Bound Videos';
        break;
      case IMAGE_BOUNDING_BOX:
        buttonText = 'Bound Images';
        break;
      case IMAGE_POLYGON_BOUNDING_BOX:
        buttonText = 'Bound Images';
        break;
      case IMAGE_POLYGON_BOUNDING_BOX_V2:
      case IMAGE_SEGMENTATION:
        buttonText = 'Bound Images';
        break;
      case IMAGE_MASKING:
        buttonText = 'Bound Images';
        break;
      case DICOM_SEGMENTATION:
        buttonText = 'Bound Images';
        break;
      default:
        buttonText = "Unknown Button"
    }
    return buttonText;
  }
  const tagPlaceHolder = "List of Classes comma seperated : Car, Cat, Tree";
  
  const getSubmitDisabled = (type, state) => {
  
      let submitDisabled = true;
      if (type === DOCUMENT_ANNOTATION) {
        if (state.basicEntities.length > 0 || state.advanceEntities.length > 0) {
          submitDisabled = false
        }
      }
  
     if ((type === POS_TAGGING
          || (type === POS_TAGGING_GENERIC && !state.modelEntity) //checks if auto tagging not selected
          || type === TEXT_CLASSIFICATION
          || type === IMAGE_CLASSIFICATION
          || type === IMAGE_SEGMENTATION
          || type === IMAGE_BOUNDING_BOX
          || type === IMAGE_POLYGON_BOUNDING_BOX
          || type === VIDEO_CLASSIFICATION
          || type === VIDEO_BOUNDING_BOX
          || type === IMAGE_POLYGON_BOUNDING_BOX_V2
          || type === IMAGE_MASKING
          || type === DICOM_CLASSIFICATION
          || type === DICOM_SEGMENTATION) &&
        ((Object.keys(state.fields).length > 5 || (state.file)) && !state.loading)) {
           submitDisabled = false;
        }
        else if(type === POS_TAGGING_GENERIC && state.modelEntity){
            if((('instructions' in state.fields && state.fields.instructions !== "") &&
                ('project_name' in state.fields && state.fields.project_name !== "")
                || (state.file)) && !state.loading) {
              submitDisabled = false;
            }
        }
        else if ((type === TEXT_SUMMARIZATION || type === TEXT_MODERATION) && ((Object.keys(state.fields).length > 4 || (state.file)) && !state.loading)) {
          submitDisabled = false;
        }
  
        if (!submitDisabled && ("project_name" in state.fields) && state.fields.project_name.length === 0) {
          submitDisabled = true;
        }
        else if (!submitDisabled && Object.keys(state.fields).length === 6 && state.fields.tags.length === 0) {
            if(type === POS_TAGGING_GENERIC) {
              submitDisabled = !state.modelEntity ? true : false //if autotag is enabled for ner, then tag is not mandratory
            }
            else if(type !== TEXT_SUMMARIZATION && type !== TEXT_MODERATION) {
               submitDisabled = true;
            }
        }
        if (!submitDisabled && ("instructions" in state.fields) && state.fields.instructions.length === 0) {
          submitDisabled = true;
        }
        // if ((state.fields.classification_name.length > 0 && (state.fields.classification_classes.length === 0 || state.fields.classification_disp_name.length === 0)) ||
        //      ((state.fields.classification_name.length === 0 || state.fields.classification_disp_name.length === 0) && state.fields.classification_classes.length > 0) ||
        //      ((state.fields.classification_name.length === 0 || state.fields.classification_classes.length === 0) && state.fields.classification_disp_name.length > 0)) {
        //       submitDisabled = true;
        // }
        return submitDisabled
  }
  
  const checkColoredTagEmpty = (submitDisabled, coloredTags) =>{
    if(coloredTags && coloredTags.length > 0){
      coloredTags.map(coloredTag =>{
        if(coloredTag.label.length === 0){
          submitDisabled = true;
          return true;
        }
      })
    }
    return submitDisabled;
  }
  
  const checkIfColoredArrayIsUnique = (coloredTags) => {
    let tagsArr = [];
    if(coloredTags){
      coloredTags.map(coloredTag =>{
          tagsArr.push(coloredTag.label);
      })
    }
    return tagsArr.length === new Set(tagsArr).size;
  }
  
  const checkForCommaSeparator = (coloredTags) => {
    let submitDisabled = false;
    if(coloredTags){
      coloredTags.map(coloredTag =>{
        if(coloredTag.label.includes(",")){
          submitDisabled = true;
          return true;
        }
      })
    }
    return submitDisabled;
  }
  
  const getIgnoreClass =  (state) => {
      let ignoreClass = 'hidden';
        if (state.projectCreationComplete && state.fileUploadStats.numHitsIgnored > 0) {
          ignoreClass = '';
        }
      return ignoreClass
  }
  
  const validateFirstLastIndex = (basicEntitiy) => {
    let commaIndex = false;
      if (basicEntitiy.entities.endsWith(",") || basicEntitiy.entities.startsWith(","))
        {
          commaIndex = true;
        }
    return commaIndex
  }
  
  const validateBasicEntity = (basicEntities) => {
    let emptyCheck = false
    basicEntities.forEach(entity => {
    if ((
      entity.label === ""
      || entity.entities === "")
       || (typeof entity.entities === 'string'
        && entity.entities.length > 0
        && (entity.entities.startsWith(",")
       || entity.entities.endsWith(",")
       || entity.entities.includes(",,"))))
      {
        emptyCheck = true
      }
    });
    return emptyCheck
  }
  
  const validateBasicEntityDOC = (basicEntities) => {
        let emptyCheck = false;
        for (let i = 0; i < basicEntities.length; i++) {
            if ('entities' in basicEntities[i]) {
                for (let j = 0; j <  basicEntities[i].entities.length; j++) {
                    if (basicEntities[i].entities[j].name === "" || basicEntities[i].entities[j].name.trim().length === 0) {
                        emptyCheck = true;
                        break;
                    }
                }
            }
        }
        return emptyCheck
    }
  
    const advanceOptions = {
      AUTOCLOSE: 'autoClose',
      HIDELABELS: 'hideLabels',
      NOTES: 'notes',
      DEFAULTSHAPE: 'defaultShape',
      SELECTEDFORMAT: 'selectedFormat',
      ISURLS: 'isURLs',
      ADVANCETAGS: 'advancedTags',
      UPLOADMODEL : 'uploadModel',
      RUNMODEL : 'runModel'
    }
    const iconUrl = {
      LEFT_ARROW: 'https://blog.capestart.com/wp-content/uploads/2019/05/Arrow_Simple_left1.svg',
      ADD: 'https://blog.capestart.com/wp-content/uploads/2019/05/Add-2.svg'
    }
  
    const modelOptions = () => {
  
      let modelTypes = []
      let TypeKeyValue = {}
      let keys = ['mobile_net_full','mobile_net_weight', 'nas_net_weight']
      let values = ['Mobile Net Full','Mobile Net Weight', 'Nas Net Weight']
      keys.forEach((model, index) => {
        TypeKeyValue = {}
        TypeKeyValue.key = model
        TypeKeyValue.value = values[index]
        modelTypes.push(TypeKeyValue)
      })
      return modelTypes
    }
  
    const getDefaultColor = '#000000'
  
    const listEntities = (object) => {
  
        let result = ""
        let colors = ["magenta","red","orange","gold","lime","green","cyan","blue"]
        if(object.state.NERTags !== null) {
          let nerTags = JSON.parse(object.state.NERTags)
          let count = 0
          const tagValues = nerTags.map(function(item, index){
                              let tagcolor = colors[count]
                              count ++
                              count == colors.length ? count = 0 : count = count
                              return <Tag color={tagcolor}> {item.slabel} </Tag>;
                            });
          result =  (<div>
                      {tagValues}
                    </div>)
        }
      return result
    }
  
  
  export default {
      getPlaceHolder,
      getNamePlaceHolder,
      tagPlaceHolder,
      getSubmitDisabled,
      getIgnoreClass,
      validateBasicEntity,
      validateBasicEntityDOC,
      advanceOptions,
      getButtonText,
      validateFirstLastIndex,
      modelOptions,
      iconUrl,
      getDefaultColor,
      checkIfColoredArrayIsUnique,
      checkForCommaSeparator,
      checkColoredTagEmpty,
      listEntities
  }
  