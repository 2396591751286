import React from 'react';
import { HIT_STATE_NOT_DONE, IMAGE_MASKING, IMAGE_SEGMENTATION, saveButtonShow } from "../../helpers/Utils";
import { Image, Button, Label, Icon, Input } from 'semantic-ui-react';
import { DRAW_TOOLS, POLY_ANNOTATOR_CONSTANTS, TOOLTIP } from '../../helpers/Constants';
import { convertKeyToString, IMAGE_BOUNDING_BOX_V2, displaySettingsByRole, ROLES_TYPES } from '../../helpers/Utils';
import { Modal as AntdModal, Icon as AntIcon } from 'antd';
import { getUidToken } from '../../helpers/commonFunctions';
import Mousetrap from 'mousetrap';
import ColorPicker from '../ColorPicker/ColorPicker';
import magicTool from "../../images/magicTool.svg" 
class ToolsBar extends React.Component {
  
  showButtons() {
    const {propsData} = this.props;
    let nextButton = 'Next';
    let prevButton = 'Previous';
    let skipButton = 'Skip';
    let saveButton = 'Move to Done';
    let saveAllButton = 'Move all to Done';
    const nextButtonDisabled =
    propsData.currentIndex < 0 ||
      (propsData.hitScrollCompleted &&
        propsData.currentIndex >= propsData.hits.length - 1)
      || (propsData.totalHitsCompleted &&
        propsData.currentIndex >= propsData.hits.length - 1)
      || propsData.buttonDisabled;
    if ('shortcuts' in propsData) {
      const shortcuts = propsData.shortcuts;
      if ('next' in shortcuts) {
        const combo = convertKeyToString(shortcuts.next);
        nextButton = 'Next (' + combo + ')';
        if (!nextButtonDisabled) {
          Mousetrap.bind(combo, propsData.saveTagAndNextRow);
        } else {
          Mousetrap.unbind(combo);
        }
      }
      if ('previous' in shortcuts) {
        const combo = convertKeyToString(shortcuts.previous);
        prevButton = 'Previous (' + combo + ')';
        if (propsData.currentIndex > 0) {
          Mousetrap.bind(combo, propsData.getBackTopreviousRow);
        } else {
          Mousetrap.unbind(combo);
        }
      }
      if (parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN || parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR) {
        if ('skip' in shortcuts) {
          const combo = convertKeyToString(shortcuts.skip);
          skipButton = 'Skip (' + combo + ')';
          if (propsData.currentIndex >= 0) {
            Mousetrap.bind(combo, propsData.skipRow);
          } else {
            Mousetrap.unbind(combo);
          }
        }
        if ('moveToDone' in shortcuts) {
          const combo = convertKeyToString(shortcuts.moveToDone);
          saveButton = 'Move To Done (' + combo + ')';
          Mousetrap.bind(combo, propsData.saveRow.bind(this, 'saveToDone'));
        }
      }
    }
    return (
      <div className="e_buttons">
        <div title={prevButton} className={propsData.currentIndex <= 0 || propsData.buttonDisabled ? 'eventnone' : ''}>
          <Button icon size="mini" color="grey" style={{backgroundColor:"#12c4fc"}}  onClick={propsData.getBackTopreviousRow} disabled={propsData.currentIndex <= 0 || propsData.buttonDisabled}>
            <Icon name="left arrow" />
          </Button>
        </div>
        {(parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN || parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR) &&
          <div title={skipButton} className={propsData.currentIndex < 0 || propsData.buttonDisabled ? 'eventnone' : ''}>
            <Button icon size="mini" color="grey" style={{backgroundColor:"#12c4fc"}}  onClick={propsData.skipRow} disabled={propsData.currentIndex < 0 || propsData.buttonDisabled}>
              <Icon name="mail forward" />
            </Button>
          </div>
        }
        <div title={nextButton} className={nextButtonDisabled ? 'eventnone' : ''}>
          <Button icon size="mini" color="blue" style={{backgroundColor:"#12c4fc"}}  onClick={propsData.saveTagAndNextRow} disabled={nextButtonDisabled}>
            <Icon name="right arrow" />
          </Button>
        </div>
        {(parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN || parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR) &&
          <div title={saveButton} className={"text-center " + (propsData.buttonDisabled ? 'eventnone' : '')}>
            <Button size="mini" color="blue" icon style={{backgroundColor:"#12c4fc"}}
             onClick={()=>propsData.saveRow("saveToDone")}
             disabled={propsData.buttonDisabled}>
              <Icon name="check icon" />
            </Button>
          </div>
        }
        {((parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN || parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR) && (propsData.taskType === IMAGE_MASKING || (propsData.taskType === IMAGE_SEGMENTATION))) &&
          <div title={saveAllButton} className={"text-center " + (propsData.buttonDisabled ? 'eventnone' : '')}>
            <Button size="mini" color="blue" icon style={{backgroundColor:"#12c4fc"}}
            onClick={()=>propsData.saveAllRow("saveAllToDone")}
             disabled={propsData.buttonDisabled}>
              <Icon name="fast forward" />
            </Button>
          </div>
        }
      </div>
    );
  }

  render() {

    const {propsState,propsData,addDynamicHitLabel,getEntitiesWithoutHitLabel,entries,selectCategory,
        toggleEyeStatus,setImageHover,dynamicSort,toggleEyeAllStatus,hits,saveChanged,undoButton,clearButton,
        getHitLabels, removePolygon,removeShapeAnnotation,disableEraserPolygon,
        toggleTool,showLabelPopup,toggleMergeOldAnnotation,changeSelectedAnnotationAsGroup,toggleEntitySelected,
        deletePoint,confirmAlertOkTapped,undoLabelSelection,clearPolygons,undoLast,saveAnnotationMasking,showActionButtons,
        currentIndex, setDeleteConfirm,setDivToggle,setSearchQuery,handleColorChange,handleClose
      } = this.props;

    const getMenuItems = () => {
      const arrs = [];
      let index1 = 0;
      let jindex = 0;
      const cursorType = propsData.isSampleData ? 'default' : 'pointer';
      const entityColorMap = getEntitiesWithoutHitLabel();
      for (const [key, value] of entries(entityColorMap)) {
        if (!propsState.searchQuery || propsState.searchQuery.length === 0 || key.toUpperCase().includes(propsState.searchQuery.toUpperCase())) {
          let bgC = 'white';
          if (propsState.defaultClass === key) {
            bgC = 'grey';
          }
          let combo = undefined;
          if (key in propsData.shortcuts) {
            combo = convertKeyToString(propsData.shortcuts[key]);
            Mousetrap.bind(combo, selectCategory.bind(this, key));
          }
          arrs.push(
            <div className="disable_text_highlighting text-center" onClick={selectCategory.bind(this, key)} 
              style={{ cursor: 'pointer', backgroundColor: `${bgC}`, minHeight: '30px', marginBottom: '3px', padding: '3px', marginTop: '3px', display: 'flex', justifyContent: 'space-around', overflow: 'auto', width: '100%' }} disabled={(parseInt(getUidToken().roleId) === ROLES_TYPES.GUEST || parseInt(getUidToken().roleId) === ROLES_TYPES.QA) ? true : false}>
              {((key in propsState.hideLabelsMap) && (propsState.hideLabelsMap[key] === true)) &&
                <div title={TOOLTIP.SHOW_LABEL} style={{ marginTop: '2px', overflow: 'initial' }}>
                  <Button size="mini" icon style={{ backgroundColor: 'white', cursor: `${cursorType}` }} onClick={(event) => toggleEyeStatus(key, false, event)}>
                    <Icon name="low vision" style={{ color: `${propsData.entityColorMap[key]}`, fontSize: '14px' }} />
                  </Button>
                </div>
              }
              {((!(key in propsState.hideLabelsMap)) || (key in propsState.hideLabelsMap && propsState.hideLabelsMap[key] === false)) &&
                <div title={TOOLTIP.HIDE_LABEL} style={{ marginTop: '2px', overflow: 'initial' }}>
                  <Button size="mini" icon style={{ backgroundColor: 'white', cursor: `${cursorType}` }} onClick={(event) => toggleEyeStatus(key, true, event)}>
                    <Icon name="eye" style={{ color: `${propsData.entityColorMap[key]}`, fontSize: '14px' }} />
                  </Button>
                </div>
              }
              {
                propsData.entitiesObject && key in propsData.entitiesObject &&
                <div style={{ overflow: 'initial' }}>
                  <Image onMouseOver={setImageHover(this, key, true)} onMouseOut={setImageHover.bind(this, key, false)} src={propsData.entitiesObject[key]} avatar />
                  {propsState.imageHoverMap && propsState.imageHoverMap[key] &&
                    <img alt='imageData' src={propsData.entitiesObject[key]} style={{ position: 'absolute', left: '50%', top: '30%' }} />}
                </div>
              }
              <div className="polygon_lable_name">
                <Label id={key} className={(key.length > 10) ? 'slabel_wrap' : ''} title={(key.length > 10) ? key : ''} size="mini" style={{ whiteSpace: 'inherit', boxShadow: '1px 1px 1px', color: 'white', backgroundColor: `${propsData.entityColorMap[key]}`, marginRight: '5px' }}>
                  {key}
                </Label>
              </div>
              <div className="label_right" style={{ cursor: `${cursorType}`, overflow: 'initial', width: '50%' }}>
                <div>
                  {combo && <p style={{ fontSize: '0.6rem' }}> {combo}</p>}
                </div>
              </div>
            </div>);

          jindex = jindex + 1;
        }
        index1 = index1 + 1;
        arrs.sort(dynamicSort("key"))
      }
      return (
        <div
        style={{ display: 'flex', overflow: 'auto', flexDirection: 'column',padding: '5px', gap:"5px" }}
          bsStyle="primary"
        >
            {propsState.toggleEyeStatusAll ? 
              <Button size="mini" icon style={{ backgroundColor: 'white', cursor: `${cursorType}` }} 
              onClick={() => { toggleEyeAllStatus(true); this.setState({ toggleEyeStatusAll: false }) }}>
                <Icon name="eye" style={{ color: `black`, fontSize: '14px' }} />
              </Button> : <Button size="mini" icon style={{ backgroundColor: 'white', cursor: `${cursorType}` }} onClick={() => { toggleEyeAllStatus(false); this.setState({ toggleEyeStatusAll: true }) }}>
                <Icon name="low vision" style={{ color: `black`, fontSize: '14px' }} />
              </Button>
            }
         
          <div style={{ boxShadow: '4px 4px 4px rgba(85,85,85,0.1)' }}>
            <Input value={propsState.searchQuery} className="w-100" 
            onChange={(event) => setSearchQuery(event.target.value)}
             placeholder="Search..." />
          </div>
          <div className="option_scroll" disabled={(parseInt(getUidToken().roleId) === ROLES_TYPES.GUEST || parseInt(getUidToken().roleId) === ROLES_TYPES.QA) ? true : false}>
            {arrs}
          </div>
        </div>
      );
    };

    return (
      <div >
            {!propsState.toolbarHidden &&
              <div className="label_holder">
                <div style={{display:"flex"}}>
                <div className={" " + (propsData.type !== HIT_STATE_NOT_DONE ? 'hview_label' : 'hview_label')}
                 >
                   {(propsData.taskType === IMAGE_SEGMENTATION || propsData.taskType === IMAGE_MASKING)  &&
                      <div className="colorPickerCustom">
                        <ColorPicker 
                        className="ts" 
                        pickerColor={propsState.pickerColor} 
                        handleColorChange={handleColorChange} 
                        pickedTop={true} 
                        handleClose={handleClose} 
                        />
                      </div>
                    }
                    <div title={'Merge Annotation Label'} style={{ display: displaySettingsByRole(getUidToken().roleId) }}>
                      <Button size="mini" color={propsState.mergeOldAnnotation ? 'blue' : 'grey'} onClick={() => toggleMergeOldAnnotation()} className="icon">
                        <Icon id="d_icon" name="object ungroup outline" />
                      </Button>
                    </div>
                    <div title="Group Existing Label" style={{ display: displaySettingsByRole(getUidToken().roleId) }}>
                      <Button size="mini" color={propsState.mergeGroupLabel ? 'blue' : 'grey'} onClick={() => changeSelectedAnnotationAsGroup()} className="icon" >
                        <Icon id="d_icon" name="gg" />
                      </Button>
                    </div>
                    <div title={!propsState.keepEntitySelected ? 'Select Group Label' : 'Unselect Group Label'} style={{ display: displaySettingsByRole(getUidToken().roleId) }}>
                      <Button size="mini" color={propsState.keepEntitySelected ? 'blue' : 'grey'} onClick={() => toggleEntitySelected()} className="icon">
                        <Icon id="d_icon" name="object ungroup outline" />
                      </Button>
                    </div>
                    <div title="Click to Erase Polygon">
                      <Button size="mini" color={(propsState.toolName === DRAW_TOOLS.ERASERPOLYGON.NAME && propsState.annotationToBeErasedId) ? 'blue' : 'grey'} icon 
                      onClick={(event) => disableEraserPolygon(event)} 
                      disabled={!propsState.activeErasePolygon}>
                        <Icon id="d_icon" name="erase" />
                      </Button>
                    </div>
                    {((propsData.taskType === IMAGE_MASKING || propsData.taskType === IMAGE_SEGMENTATION) &&
                    !propsData.imageNiri) && 
                    <div title="Click to draw Auto Annotation">
                      <Button size="mini" color={propsState.toolName === DRAW_TOOLS.MAGICTOOL.NAME ? 'blue' : 'grey'} icon onClick={(event) => { toggleTool(DRAW_TOOLS.MAGICTOOL.NAME, event); showLabelPopup() }}>
                      <img src={magicTool} alt='auto' style={{ height : "13px", width : "13px"}} />                      </Button>
                    </div> }
                    {propsData.defaultShape !== 'rectangle' &&
                      <div title="Click to draw Polygon">
                        <Button size="mini" color={propsState.toolName === DRAW_TOOLS.POLYGON.NAME ? 'blue' : 'grey'} icon onClick={(event) => { toggleTool(DRAW_TOOLS.POLYGON.NAME, event); showLabelPopup() }}>
                          <Icon id="d_icon" name="star outline" />
                        </Button>
                      </div>}
                    <div title="Click to draw a Line">
                      <Button size="mini" color={propsState.toolName === DRAW_TOOLS.LINE.NAME ? 'blue' : 'grey'} icon onClick={(event) => { toggleTool(DRAW_TOOLS.LINE.NAME, event); showLabelPopup() }}>
                        <Icon id="d_icon" name="minus icon" />
                      </Button>
                    </div>
                    <div title="Click to draw Polyline">
                      <Button size="mini" color={propsState.toolName === DRAW_TOOLS.POLYLINE.NAME ? 'blue' : 'grey'} icon onClick={(event) => { toggleTool(DRAW_TOOLS.POLYLINE.NAME, event); showLabelPopup() }}>
                        <Icon id="d_icon" name="moon outline" />
                      </Button>
                    </div>
                    <div title="Click to draw PolylineConnector">
                      <Button size="mini" color={propsState.toolName === DRAW_TOOLS.LINEPOLY.NAME ? 'blue' : 'grey'} icon onClick={(event) => { toggleTool(DRAW_TOOLS.LINEPOLY.NAME, event); showLabelPopup() }}>
                        <Icon id="d_icon" name="vine" />
                      </Button>
                    </div>
                    <div title="Click to draw Rectangle">
                      <Button size="mini" color={propsState.toolName === DRAW_TOOLS.RECTANGLE.NAME ? 'blue' : 'grey'} icon onClick={(event) => { toggleTool(DRAW_TOOLS.RECTANGLE.NAME, event); showLabelPopup() }}>
                        <Icon id="d_icon" name="square" style={{ color : "transparent", border : "2px solid white"}} />
                      </Button>
                    </div>
                    {(propsData.taskType === IMAGE_MASKING || propsData.taskType === IMAGE_SEGMENTATION) &&
                      <div title="Click to draw Circle">
                        <Button size="mini" color={propsState.toolName === 'circle' ? 'blue' : 'grey'} icon onClick={(event) => { toggleTool('circle', event); showLabelPopup() }}>
                          <Icon id="d_icon" name="circle" />
                        </Button>
                      </div>
                    }
                    {propsData.defaultShape !== 'rectangle' &&
                      <div title="Click to draw Points">
                        <Button size="mini" color={propsState.toolName === 'point' ? 'blue' : 'grey'} icon onClick={(event) => { toggleTool('point', event); showLabelPopup() }}>
                          <Icon id="d_icon" name="point" />
                        </Button>
                      </div>
                    }
                </div>
                {((propsData.taskType === IMAGE_SEGMENTATION) ||
                  (propsData.taskType !== IMAGE_SEGMENTATION)) &&
                  <div className="l_entity" 
                  style={{display: 'flex', flexDirection: 'column',gap:"10px"}}
                  disabled={(parseInt(getUidToken().roleId) === ROLES_TYPES.GUEST || parseInt(getUidToken().roleId) === ROLES_TYPES.QA) ? true : false}>
                    <p 
                    style={{ padding: '5px' }}
                    >
                      Select Entity
                    </p>
                    {getMenuItems()}
                  </div>
                }
                </div>

                {propsData.hitLabels && propsData.hitLabels.length > 0 && (
                  <div className="hitLabel">
                    <p>Hit Labels</p>
                    {getHitLabels()}
                  </div>
                )}
                {(propsState.divToggle && propsData.fullScreen) &&
                  <div className="custom_confirm">
                    <a href='data' className="custom_close" 
                    onClick={()=> setDivToggle(false)}
                    ><AntIcon type="close" /></a>
                    <h4>Confirmation</h4>
                    <p>{POLY_ANNOTATOR_CONSTANTS.TAGGED_ITEM_MESSAGE}</p>
                    <div className="btn_end m-t-10">
                      <button className="btn_cancel" 
                      onClick={()=> setDivToggle(false)}
                      >Cancel</button>
                      <button className="btn_ok m-l-5" onClick={() => confirmAlertOkTapped()}>Ok</button>
                    </div>
                  </div>
                }
                {(propsState.deleteConfirm && propsData.fullScreen) &&
                  <div className="custom_confirm">
                    <a href='Data' className="custom_close" 
                    onClick={()=> setDeleteConfirm(false)}
                    ><AntIcon type="close" /></a>
                    <h4>Confirmation</h4>
                    <p>Are you sure you want to delete this label ?</p>
                    <div className="btn_end m-t-10">
                      <button className="btn_cancel" 
                      onClick={()=> setDeleteConfirm(false)}
                      >Cancel</button>
                      <button className="btn_ok m-l-5" onClick={() => addDynamicHitLabel("delete")}>Ok</button>
                    </div>
                  </div>
                }
                {(propsState.deleteConfirmPoint && propsData.fullScreen) &&
                  deletePoint()
                }
                {propsState.removeAnnotation === true &&
                  removePolygon(propsState.removeAnnotationKey)
                }
                {propsState.remAnnotation && propsData.fullScreen &&
                removeShapeAnnotation(propsState.remAnnotationKey)}
              </div>
            }
            <div>
            <div className="l_icon_section d-flex gap-2">

              { propsData.fullScreen && propsData.space && propsData.type !== HIT_STATE_NOT_DONE && showActionButtons()}
              {
                propsData.type === HIT_STATE_NOT_DONE && this.showButtons()
              }

                   {propsData.taskType === IMAGE_MASKING && (saveButtonShow(propsData.changesInSession, hits, currentIndex) || propsState.imagePropsChanged) &&
                     <div title={saveChanged} className={(propsData.changesInSession !== 0 || propsState.imagePropsChanged) ? '' : 'eventnone'}>
                       <Button icon size="blue mini" style={{backgroundColor:"#12c4fc"}} >
                         <Icon name="save" 
                         onClick={() => saveAnnotationMasking()} 
                         disabled={propsData.changesInSession === 0 || propsState.imagePropsChanged} />
                       </Button>
                     </div>
                   }

                   {propsData.taskType !== IMAGE_BOUNDING_BOX_V2 &&
                     <div title={undoButton} className={propsState.currentRect.length === 0 ? 'eventnone' : ''} style={{ display: displaySettingsByRole(getUidToken().roleId) }}>
                       <Button icon size="mini" secondary style={{backgroundColor:"#12c4fc"}}  onClick={undoLast} disabled={propsState.currentRect.length === 0}>
                         <Icon name="undo" />
                       </Button>
                     </div>
                     }

                   <div title={clearButton} className={Object.keys(propsState.rects).length === 0 ? 'eventnone' : ''} style={{ display: displaySettingsByRole(getUidToken().roleId) }}>
                     <Button icon size="mini" className="btn_danger" secondary  onClick={clearPolygons} disabled={Object.keys(propsState.rects).length === 0}>
                       <Icon name="remove" />
                     </Button>
                   </div>

                   {propsState.defaultClass &&
                     <div title="Undo Label Selection" className={propsState.currentRect.length > 0 ? 'eventnone' : ''} style={{ display: displaySettingsByRole(getUidToken().roleId) }}>
                       <Button icon size="mini" secondary style={{backgroundColor:"#12c4fc"}} onClick={undoLabelSelection} disabled={propsState.currentRect.length > 0}>
                         <Icon name="undo" />
                       </Button>
                     </div>
                   }

                </div>
            </div>
      </div>
    );
  }
}

export default ToolsBar
