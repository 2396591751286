import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { bindActionCreators } from 'redux';
import { getAllUsers } from '../../redux/reducer/projectReducer';
import {  Button, Icon, Table, Confirm, Pagination } from 'semantic-ui-react';
import { Icon as AntIcon, Modal as AntModal } from 'antd';
import { getUidToken,getOrgId,showAlert } from '../../helpers/commonFunctions';
import { approveUserByAdmin,recoverUserByAdmin,deleteUserByAdmin,getAdminUsers,getOrgDetailsForAdmin,getOrganizationUsersWithPagination } from '../../Services/usersApis';
import { pageSize } from '../../helpers/Constants';
import { push, replace } from 'react-router-redux';
import { setloaderMenu } from '../../redux/reducer/projectReducer';
import { MESSAGES, CONFIRMATION_BOX  } from '../../helpers/Constants';
import moment from 'moment';
import {ROLES_TYPES } from '../../helpers/Utils';
import BACK from '../../images/Arrow_Simple_left1.svg';

class Users extends Component {

  static propTypes = {
    user: PropTypes.object,
    pushState: PropTypes.func,
    allUserData: PropTypes.object,
    getAllUsers: PropTypes.func,
    replaceState: PropTypes.func
  }
  constructor(props) {
    super(props);
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let currentPage = params.get('currentpage');
    this.state={
      currentpage:currentPage,
      fields: {},
      errors: {},
      inviteModal: false,
      loading: false,
      loaded:false,
      successModal: false,
      status: null,
      userId: null,
      orgId: null,
      open: null,
      userDetails: null,
      orgUserDetails: [],
      activeMenu: 'users',
      annotatorData: [],
      offset: 0,
      activePage:currentPage?currentPage:1,
      activePageForRequest: 0,
      boundaryRange: 1,
      siblingRange: 1,
      showEllipsis: true,
      showFirstNav: false,
      showPreviousNav: false,
      showLastNav: true,
      showNextNav: true,
	  usersTotal:0,
      totalPages:0,
	  recoverUserConfirmModalOpen:false,
      deleteUserConfirmModalOpen: false,
      orderBy:'updated_timestamp',
      sortOrder: 'desc',
      query:'',
      byAll:'byAll'
    };
    this.handlePaginationChange = this.handlePaginationChange.bind(this);
    this.deleteUserConfirmModal = this.deleteUserConfirmModal.bind(this);
	  this.recoverUserConfirmModal = this.recoverUserConfirmModal.bind(this);
  }

  
  componentDidMount() {
    if (!getUidToken().uid) {
      this.props.pushState('/projects/login');
    }
    let orgId = this.props.params.orgId;
    if (!orgId) {
      this.userDetailsFetched();
      this.state.activeMenu = "users";
    } else {
      let roleIdCheck = this.props.params.roleIdCheck;
      getOrganizationUsersWithPagination(getOrgId(), this.state.activePageForRequest, pageSize, this.getOrganizationUsersCallback.bind(this),roleIdCheck);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!getUidToken().uid) {
      this.props.pushState('/projects/login');
    }
    if (nextProps !== null) {
      if (this.props.params.orgId !== nextProps.params.orgId) {
        this.userDetailsFetched(nextProps.params.orgId);
      } else if (getUidToken().roleId === 2) {
        this.userDetailsFetched(nextProps.params.orgId);
      }
    }
  }

  state = {
    fields: {},
    errors: {},
    inviteModal: false,
    loading: false,
    successModal: false,
    userDetails: null,
    status: null,
    userId: null,
    orgId: null,
    open: null
  }

 handleOnSearchUsers = ( event ) => {
    const query = event.target.value;
    this.state.loaded=true;
    this.setState({query});

    if(event.target.value.length==0 && parseInt(getUidToken().roleId) === ROLES_TYPES.SYSTEM_ADMIN){
      this.props.getAllUsers(getUidToken(), { page:this.state.query?0:this.state.activePageForRequest,size: pageSize, orderBy: this.state.orderBy, sortOrder: this.state.sortOrder, searchType:this.state.query?this.state.byAll:null, searchKeyword: "" });
    }else if(event.target.value.length==0 ){
      getOrganizationUsersWithPagination(getOrgId(), this.state.activePageForRequest, pageSize, this.getOrganizationUsersCallback.bind(this), window.localStorage.getItem("roleId"), 'byAll', "");
    }
  };

  searchUsers = (e) => {
    const { activePageForRequest,query,orderBy,sortOrder,byAll } = this.state
    e.preventDefault();
    if(query.length === 0)
    {
      showAlert("Please enter the value to search", 'error')
    }
    else{
      if (this.props.params.orgId !== undefined || window.localStorage.getItem("roleId") === '2') {
        getOrganizationUsersWithPagination(getOrgId(), activePageForRequest, pageSize, this.getOrganizationUsersCallback.bind(this), window.localStorage.getItem("roleId"), 'byAll', query);
      } else{
        this.setState({
        activePage: 1,
       activePageForRequest:0 });
      this.props.getAllUsers(getUidToken(), { page:query?0:activePageForRequest, size: pageSize, orderBy: orderBy, sortOrder: sortOrder, searchType:query?byAll:null, searchKeyword: query });
      }
    }
  }


  getOrganizationUsersCallback(error, response) {
    if (!error) {
      this.setState({
        orgUserDetails: window.localStorage.getItem("roleId") === '2' ? response : JSON.parse(response.text)
      });

      if (window.localStorage.getItem("roleId") === '2') {
        this.setState({ annotatorData: response });
      }
    } else {
      showAlert('error', 'error');
    }
  }

  backPage(event) {
    console.log("user currenntPAge",this.state.currentpage)
    this.props.replaceState('/projects/organizations'+'?currentpage='+this.state.currentpage)
  }

  userDetailsFetched = (nextPropsOrgId) => {
    const {activePageForRequest,query,orderBy,sortOrder,byAll,activePage} = this.state
    if (nextPropsOrgId !== undefined) {
      getOrganizationUsersWithPagination(getOrgId(), activePageForRequest, pageSize, this.getOrganizationUsersCallback.bind(this), this.props.params.roleIdCheck);
    } else {
      if (parseInt(getUidToken().roleId) === 1 || parseInt(getUidToken().roleId) === 4) {
        this.props.getAllUsers(getUidToken(), { page: query?0:(activePage - 1), size: pageSize, orderBy: orderBy, sortOrder: sortOrder, searchType:query?byAll:null, searchKeyword: query });
        this.props.setloaderMenu(false);
        this.setState({ userDetails: this.props.allUserData, loading: false });
      } else {
        getOrgDetailsForAdmin(getUidToken().uid, this.dtgetOrgDetailsForAdmin.bind(this));
      }
    }

  }

  dtgetOrgDetailsForAdmin(error, response) {
    const {orderBy,sortOrder,activePage} = this.state
    if (!error) {
      this.setState({ orgId: String(response.body.id) });
      getAdminUsers({ orgId: response.body.id, page: (activePage - 1), size: pageSize, orderBy: orderBy, sortOrder: sortOrder }, this.dtgetAdminsAndUsersByOrgId.bind(this));
    }
  }
  sortByUsers = (data) =>{
    const {activePageForRequest,query,orderBy,sortOrder,byAll} = this.state
    if (this.props.params.orgId !== undefined) {
      getOrganizationUsersWithPagination(getOrgId(), activePageForRequest, pageSize, this.getOrganizationUsersCallback.bind(this), this.props.params.roleIdCheck);
    } else {
      if (parseInt(getUidToken().roleId) === 1 || parseInt(getUidToken().roleId) === 4) {
        this.setState({ orderBy: data.orderBy, sortOrder: data.sortOrder }, () => {
          this.props.getAllUsers(getUidToken(), { page: query?0:activePageForRequest, size: pageSize, orderBy: orderBy, sortOrder: sortOrder, searchType:query?byAll:null, searchKeyword: query });
         });
      } else {
        this.setState({ orderBy: data.orderBy, sortOrder: data.sortOrder }, () => {
          getOrgDetailsForAdmin(getUidToken().uid, this.dtgetOrgDetailsForAdmin.bind(this));
        });
      }
    }
  };

  getUsersData = (data) => {
    const arrs = [];
    console.log("getUsersData Data",data)
	this.state.usersTotal = data.total;
    if (this.state.totalPages !== data.totalPages) {
        this.setState({ totalPages: data.totalPages });
    }
    if (data.total > 0) {
      data = data.userInfos;
      for (let index = 0; index < data.length; index++) {
        if (getUidToken().roleId === '1' || getUidToken().roleId === '4') {
          if (data[index] !== null && data[index].roleId > 0) {
            arrs.push(
              <Table.Row key={index}>
                <Table.Cell>
                  {data[index].firstName}
                </Table.Cell>
                <Table.Cell>
                  {data[index].secondName}
                </Table.Cell>
                <Table.Cell>
                  {data[index].email}
                </Table.Cell>
                {((parseInt(getUidToken().roleId) === ROLES_TYPES.SYSTEM_ADMIN) ||
                (parseInt(getUidToken().roleId) === ROLES_TYPES.SUPER_ADMIN)) &&
                <Table.Cell>
                  {data[index].organizationName}
                </Table.Cell>}
                {(parseInt(getUidToken().roleId) !== ROLES_TYPES.ADMIN) &&
                <Table.Cell>
                  {data[index].reportingTo}
                </Table.Cell>}
                {data[index].roleId === 1 &&
                  <Table.Cell> Super Admin </Table.Cell>}
                {data[index].roleId === 2 &&
                  <Table.Cell> Admin</Table.Cell>}
                {data[index].roleId === 3 &&
                  <Table.Cell> Annotator </Table.Cell>}
                {data[index].roleId === 4 &&
                  <Table.Cell> System Admin </Table.Cell>}
                  {data[index].roleId === 5 &&
                  <Table.Cell> Guest </Table.Cell>}
                {data[index].roleId === 6 &&
                  <Table.Cell> QA </Table.Cell>}
                { data[index].deleted &&
                <Table.Cell>
                  <span style={{ color: 'red'}}>InActive</span>
                </Table.Cell>
                }

                { !data[index].deleted && data[index].approved &&
                    <Table.Cell>
                      <span style={{ color: 'green'}}>Approved</span>
                    </Table.Cell>
                }

                { !data[index].deleted && !data[index].approved &&
                    <Table.Cell>
                      <span style={{ color: 'yellow'}}>Pending</span>
                    </Table.Cell>
                }
              <Table.Cell>
              { moment(new Date(data[index].updated_timestamp)).format('YYYY-MM-DD HH:mm:ss')}
              </Table.Cell>
             {this.props.params.orgId === undefined &&
                  <Table.Cell>
                    <div className="table_actionbtn m-l-35">
                    <a className="clr_icon" title="View User"><Icon className="anticon anticon-eye" onClick={this.userDetails.bind(this, data[index])} /></a>
                    {data[index].roleId !== 1 && data[index].roleId !== 4 &&
                      <a className="clr_icon" title="Edit User"><Icon className="anticon anticon-edit" onClick={this.editUser.bind(this, data[index])} /></a>
                    }
                    {data[index].roleId !== 4 && !data[index].deleted &&
                      <a className="clr_icon" title="Delete User"><Icon className="anticon anticon-delete" onClick={this.deleteUser.bind(this, data[index])} /></a>
                    }

                    {data[index].roleId !== 4 && data[index].deleted &&
                   <a className="clr_icon" title="Recover User"><Icon className="anticon anticon-save" onClick={this.recoverUser.bind(this, data[index])} /></a>
                    }
                    </div>
                  </Table.Cell>
                }
              </Table.Row>);
          }
        } else {
          if (data[index] !== null && data[index].roleId > 2 && data[index].roleId !== 4) {
            arrs.push(
              <Table.Row key={index}>
                <Table.Cell>
                  {data[index].firstName}
                </Table.Cell>
                <Table.Cell>
                  {data[index].secondName}
                </Table.Cell>
                <Table.Cell>
                  {data[index].email}
                </Table.Cell>
                {(parseInt(getUidToken().roleId) === ROLES_TYPES.SYSTEM_ADMIN) &&
                <Table.Cell>
                  {data[index].organizationName}
                </Table.Cell>}
                {data[index].roleId === 3 &&
                  <Table.Cell> Annotator </Table.Cell>}
                {data[index].roleId === 5 &&
                  <Table.Cell> Guest </Table.Cell>}
                {data[index].roleId === 6 &&
                  <Table.Cell> QA </Table.Cell>}
                { data[index].deleted &&
                <Table.Cell>
                  <span style={{ color: 'red'}}>InActive</span>
                </Table.Cell>
                }

                { !data[index].deleted && data[index].approved &&
                    <Table.Cell>
                      <span style={{ color: 'green'}}>Approved</span>
                    </Table.Cell>
                }

                { !data[index].deleted && !data[index].approved &&
                    <Table.Cell>
                      <span style={{ color: 'yellow'}}>Pending</span>
                    </Table.Cell>
                }

                <Table.Cell>
                { moment(new Date(data[index].updated_timestamp)).format('YYYY-MM-DD HH:mm:ss')}
                </Table.Cell>
                {this.props.params.orgId === undefined &&
                  <Table.Cell>
                    <div className="table_actionbtn m-l-35">
                    <a className="clr_icon" title="View User"><Icon className="anticon anticon-eye" onClick={this.userDetails.bind(this, data[index])} /></a>
                    <a className="clr_icon" title="Edit User"><Icon className="anticon anticon-edit" onClick={this.editUser.bind(this, data[index])} /></a>
                    {(data[index].roleId === 3 || data[index].roleId === 5 || data[index].roleId === 6) && !data[index].deleted ?
                      <a className="clr_icon" title="Delete User"><Icon className="anticon anticon-delete" onClick={this.deleteUser.bind(this, data[index])} /></a>
                      : <a className="clr_icon" title="Recover User"><Icon className="anticon anticon-save" onClick={this.recoverUser.bind(this, data[index])} /></a>
                    }
                    </div>
                  </Table.Cell>
                }
              </Table.Row>);
          }
        }
      }
      return (<Table.Body className="ant-table-tbody">{arrs}</Table.Body>);
    }
  }

  approvedUser(data) {
    const {userId,orgId} = this.state
    this.setState({ userId: data.id });
    approveUserByAdmin(userId, orgId, this.dtApproveUserByAdminCallback.bind(this))
  }

  dtApproveUserByAdminCallback(error, response) {
    const { activePageForRequest,orderBy,sortOrder,query } = this.state

    if (!error) {
      this.props.setloaderMenu(false);
      this.setState({ loading: false, firstName: '', secondName: '', email: '', role: '0', orgId: '0', success: 'User created successfully' });
      this.props.getAllUsers(getUidToken(), {page:activePageForRequest, size:pageSize, orderBy:orderBy, sortOrder:sortOrder, searchType:null, searchKeyword:query});
    } else {
      showAlert(error, 'error');
      this.props.setloaderMenu(false);
      this.setState({ loading: false });
    }
  }

  deleteUser(data) {
    this.setState({ userId: data.id, open: false, deleteUserConfirmModalOpen: true },
      this.deleteUserConfirmModal(data))
  }

    recoverUser(data) {
    this.setState({ userId: data.id, open: false, recoverUserConfirmModalOpen: true },
      this.recoverUserConfirmModal(data))
  }

  recoverUserConfirmModal = (data) => {
    const that = this
    const confirm = AntModal.confirm;
    confirm({
      title: CONFIRMATION_BOX.CONFIRMATION_TITLE,
      content: MESSAGES.RECOVER_USER_CONFIRM_MESSAGE[0] + "'" + data.firstName + "'" + MESSAGES.RECOVER_USER_CONFIRM_MESSAGE[1],
      okText: CONFIRMATION_BOX.CONFIRMATION_MODAL_OK,
      cancelText: CONFIRMATION_BOX.CONFIRMATION_MODAL_CANCEL,
      onOk() { recoverUserByAdmin(data.id, that.dtRecoverUserByAdminCallback.bind(that)); },
      onCancel() { that.setState({ recoverUserConfirmModalOpen: false }); },
    });
  }

  dtRecoverUserByAdminCallback(error, response) {
    const { activePageForRequest,orderBy,sortOrder } = this.state
    if (!error) {
      showAlert("User recovered successfully.");
      if (this.props.params.orgId !== undefined || parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN) {
        getOrganizationUsersWithPagination(getOrgId(), activePageForRequest, pageSize, this.getOrganizationUsersCallback.bind(this), this.props.params.roleIdCheck);
      } else {
        this.props.getAllUsers(getUidToken(), {page:activePageForRequest, size:pageSize, orderBy:orderBy, sortOrder:sortOrder});
      }
    } else {
      showAlert(error, 'error');
      let errorMessage = response && response.body ? response.body.message : "";
      showAlert(errorMessage, 'error');
      this.props.setloaderMenu(false);
      this.setState({ loading: false });
    }
    this.setState({ recoverUserConfirmModalOpen: false })
  }


  deleteUserConfirmModal = (data) => {
    const that = this
    const confirm = AntModal.confirm;
    confirm({
      title: CONFIRMATION_BOX.CONFIRMATION_TITLE,
      content: MESSAGES.DELETE_USER_CONFIRM_MESSAGE[0] + "'" + data.firstName + "'" + MESSAGES.DELETE_USER_CONFIRM_MESSAGE[1],
      okText: CONFIRMATION_BOX.CONFIRMATION_MODAL_OK,
      cancelText: CONFIRMATION_BOX.CONFIRMATION_MODAL_CANCEL,
      onOk() { deleteUserByAdmin(data.id, that.dtDeleteUserByAdminCallback.bind(that)); },
      onCancel() { that.setState({ deleteUserConfirmModalOpen: false }); },
    });
  }

  handleConfirm = () => {
    this.setState({ open: false })
    deleteUserByAdmin(this.state.userId, this.dtDeleteUserByAdminCallback.bind(this));
    this.setState({ delId: null });
  }
  handleCancel = () => {
    this.setState({ open: false })
    this.setState({ userId: null });
  }

  dtDeleteUserByAdminCallback(error, response) {
    const {activePageForRequest,orderBy,sortOrder,query} = this.state
    if (!error) {
      showAlert("User deleted successfully.");
      if (this.props.params.orgId !== undefined || parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN) {
        getOrganizationUsersWithPagination(getOrgId(), activePageForRequest, pageSize, this.getOrganizationUsersCallback.bind(this), this.props.params.roleIdCheck);
      } else {
        this.props.getAllUsers(getUidToken(), {page:activePageForRequest, size:pageSize, orderBy:orderBy, sortOrder:sortOrder, searchType:null, searchKeyword:query});
      }
    } else {
      showAlert(error, 'error');
      let errorMessage = response && response.body ? response.body.message : "";
      showAlert(errorMessage, 'error');
      this.props.setloaderMenu(false);
      this.setState({ loading: false });
    }
    this.setState({ deleteUserConfirmModalOpen: false })
  }

  dtgetAdminsAndUsersByOrgId(error, response) {
    if (!error) {
      this.setState({ annotatorData: JSON.parse(response.text) });
    } else {
      showAlert(error, 'error');
      this.props.setloaderMenu(false);
      this.setState({ loading: false });
    }
  }

  editUser(data) {
    const {activePage} = this.state
    this.props.replaceState('/projects/' + data.id + '/editUsers'+'?currentpage='+activePage);
  }
  userDetails(data) {
    const {activePage} = this.state
    this.props.replaceState('/projects/' + data.id + '/userDetails'+'?currentpage='+activePage);
  }
  handlePageClick = data => {
    if (this.props.params.roleIdCheck) {
      this.props.replaceState('/projects/addUsers/' + this.props.params.roleIdCheck);
    } else {
      this.props.replaceState('/projects/addUsers/');
    }
  };
  handlePaginationChange = (e, { activePage }) => {
    this.setState({
      activePage: activePage,
      activePageForRequest: activePage - 1
    })

    if (activePage === this.state.totalPages) {
      this.setState({ showLastNav: false, showNextNav: false })
    } else {
      this.setState({ showLastNav: true, showNextNav: true })
    }

    if (activePage === 1) {
      this.setState({ showFirstNav: false, showPreviousNav: false })
    } else {
      this.setState({ showFirstNav: true, showPreviousNav: true })
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    const {activePageForRequest,query,orderBy,sortOrder,byAll} = this.state
    if (nextState.activePageForRequest !== activePageForRequest) {
        if (nextProps.params.orgId !== undefined) {
          getOrganizationUsersWithPagination(getOrgId(), nextState.activePageForRequest, pageSize, this.getOrganizationUsersCallback.bind(this), this.props.params.roleIdCheck);
        } else {
          if (parseInt(getUidToken().roleId) === 1 || parseInt(getUidToken().roleId) === 4) {
            this.props.getAllUsers(getUidToken(), { page: nextState.activePageForRequest, size: pageSize, orderBy: orderBy, sortOrder: sortOrder, searchType:query?byAll:null, searchKeyword: query });
            this.props.setloaderMenu(false);
            this.setState({ userDetails: this.props.allUserData, loading: false });
          } else {
            getOrgDetailsForAdmin(getUidToken().uid, this.dtgetOrgDetailsForAdmin.bind(this));
          }
        }
    }
    return true
  }

  getTableData() {
    const {orgUserDetails,annotatorData} = this.state
    if (this.props.allUserData && this.props.params.orgId === undefined) {
      return this.getUsersData(this.props.allUserData)
    } else if (this.props.params.orgId !== undefined) {
      return this.getUsersData(orgUserDetails)
    }
    else if (window.localStorage.getItem("roleId") === '2') {
      return this.getUsersData(annotatorData)
    }
    else if (getUidToken().roleId === 2 && annotatorData) {
      return "";
    }
    return "";
  }

  getSortIcon(fieldName) {
    return (<div className="ant-table-column-sorter float-left pad-6-em"><span className="ant-table-column-sorter-up off" title="↑" onClick={()=>this.sortByUsers({orderBy: fieldName, sortOrder: "asc"})}><i className="anticon anticon-caret-up"></i></span><span className="ant-table-column-sorter-down off" title="↓" onClick={()=>this.sortByUsers({orderBy: fieldName, sortOrder: "desc"})}><i className="anticon anticon-caret-down"></i></span></div>);
  }

  render() {
    const {query,loaded,showNextNav,showPreviousNav,showLastNav,showFirstNav,showEllipsis,
      totalPages,siblingRange,boundaryRange,activePage,usersTotal,
      recoverUserConfirmModalOpen,deleteUserConfirmModalOpen,open} = this.state;
    if (this.props.allUserData) {
    }
    return (
      <div style={{ paddingLeft: '3%', paddingRight: '3%' }} className="m-t-20">
        <Confirm
          open={open}
          cancelButton='Never mind'
          confirmButton="Let's do it"
          onCancel={this.handleCancel}
          onConfirm={this.handleConfirm}
        />
        <Helmet title="Create User" />
        {
          <div className="title_section flex-between m-b-15 m-t-20">
            <div className="pr_labelset">
              <div className="project_sec m-t-20">
                <div className="project_title f-column singletxt">
                {this.props.params.orgId !== undefined && <div className="f-center">
                <a><span className="btn_back m-r-5" onClick={this.backPage.bind(this)}>
                    <img alt='back' src={BACK} /></span></a>
                </div>}
                <div className="breadcrum-sec">
                  <div className="sec_holder">
                    <h2 className="tilte_display fw-600" >Manage Users </h2>
                  </div>
                </div>
                </div>
              </div>
            </div>
            
            <div className="table_btn flexCenter">
            <div className="m-r-15">{(getUidToken().roleId === '1' || getUidToken().roleId === '4') ? `Total Count: ${usersTotal}` : ''}</div>
            <div className="m-r-15">
                  <div className="search_h">
                    <div className="search_wrap">
                      <div className="search_sec">
                        <div className="search_select">
                         <form className="form_search">
                           <input type="text" className="Search Users searchTerm bdrUnset"
                                placeholder="Search for Users"
                                onChange={this.handleOnSearchUsers}
                                value={query}
                            />
                          <button type="submit" onClick={(e) => this.searchUsers(e)} className="searchButton">
                                  <AntIcon type="search" />
                          </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
             </div>
                 <Button className="btn_base btn_success m-l-15" title="Add User" onClick={this.handlePageClick.bind(this)}>
                    <AntIcon type="plus" /> Add User
                 </Button>

             </div>
          </div>
        }
        <div style={{marginLeft:"3%"}} className="content_section ant-table-wrapper table_workspace wordBreakFix">
          <div style={{ width: '100%' }}>
              <Table compact="very" celled size="small" striped>
                <Table.Header className="ant-table-thead">
                  <Table.Row>
                    <Table.HeaderCell>
                    <div className="width-100"><span className="float-left" >First Name</span>{this.getSortIcon("firstName")}</div></Table.HeaderCell>
                    <Table.HeaderCell><div className="width-100"><span className="float-left">Last Name</span>{this.getSortIcon("secondName")}</div></Table.HeaderCell>
                    <Table.HeaderCell><div className="width-100"><span className="float-left">Email Address</span>{this.getSortIcon("email")}</div></Table.HeaderCell>
                    {((parseInt(getUidToken().roleId) === ROLES_TYPES.SYSTEM_ADMIN) || (parseInt(getUidToken().roleId) === ROLES_TYPES.SUPER_ADMIN)) &&	<Table.HeaderCell><div className="width-100"><span className="float-left">Organization Name</span>{this.getSortIcon("organizationName")}</div></Table.HeaderCell> }
                    {(parseInt(getUidToken().roleId) !== ROLES_TYPES.ADMIN) &&	<Table.HeaderCell><div className="width-100"><span className="float-left">Reporting To</span></div></Table.HeaderCell> }
                    <Table.HeaderCell><div className="width-100"><span className="float-left">Role</span>{this.getSortIcon("roleId")}</div></Table.HeaderCell>
                    <Table.HeaderCell><div className="width-100"><span className="float-left">Status</span>{this.getSortIcon("status")}</div></Table.HeaderCell>
                    <Table.HeaderCell><div className="width1-00"><span className="float-left">Updated On</span>{this.getSortIcon("updated_timestamp")}</div></Table.HeaderCell>
                    {this.props.params.orgId === undefined &&
                      <Table.HeaderCell style={{'textAlign':'center'}}>Action</Table.HeaderCell>
                    }
                  </Table.Row>
                </Table.Header>
                {
                  this.getTableData()
                }
              {deleteUserConfirmModalOpen &&
                this.deleteUserConfirmModal()
              }
              {
                recoverUserConfirmModalOpen &&
                this.recoverUserConfirmModal()
              }
                {
                  <Table.Footer fullWidth>
                    <Table.Row>
                      <Table.HeaderCell colSpan='8' className="noBg">
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <div style={{ width: '90%', textAlign: 'center' }}>
                            {usersTotal > 0 ?
                              <Pagination style={{ position: 'unset' }}
                                activePage={activePage}
                                boundaryRange={boundaryRange}
                                onPageChange={this.handlePaginationChange}
                                size='mini'
                                siblingRange={siblingRange}
                                totalPages={totalPages}
                                ellipsisItem={showEllipsis ? undefined : null}
                                firstItem={showFirstNav ? undefined : null}
                                lastItem={showLastNav ? undefined : null}
                                prevItem={showPreviousNav ? undefined : null}
                                nextItem={showNextNav ? undefined : null}
                              />:(loaded === true)?<div>
                              <h4>
                                No matching records found
                              </h4>
                            </div>:''
                            }
                          </div>
                        </div>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                }
              </Table>
          </div>
        </div>
      </div>)
  }
}


const mapStateToProps = (state) => {
    return {
      user: state.loginReducer.user,
      allUserData: state.projectReducer.allUserData
    };
  };
  
  const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        pushState: push, 
        setloaderMenu, 
        getAllUsers, 
        replaceState: replace
      },
      dispatch
    );

  export default connect(mapStateToProps, mapDispatchToProps)(Users);