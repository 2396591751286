import React, { Component, PropTypes } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push, goBack } from "react-router-redux";
import { Select, DatePicker } from "antd";
import Popover from "react-bootstrap/lib/Popover";
import OverlayTrigger from "react-bootstrap/lib/OverlayTrigger";
import cloneDeep from "lodash/cloneDeep";

import differenceWith from "lodash/differenceWith";
import _clone from "lodash/clone";
import _escapeRegExp from "lodash/escapeRegExp";
import _uniqBy from "lodash/uniqBy";
import { Label, Table, Icon, Button, Segment, Input, Dropdown, Form, TextArea, Dimmer, Checkbox } from "semantic-ui-react";
import "antd/dist/antd.css";
import Modal from "react-awesome-modal";
import FileSaver from "file-saver";
import Fullscreen from "react-fullscreen-crossbrowser";
import { Player, ControlBar, ForwardControl, PlaybackRateMenuButton } from "video-react";
import queryString from "query-string";
import './mentions.css'
import {
        Modal as AntModal, LocaleProvider,Badge,message, Icon as AntIcon, Input as AntInput, Tooltip
      } from 'antd'
import { setloaderMenu } from "../../redux/reducer/projectReducer.js";
import domtoimage from "dom-to-image-improved";
import LEFT from "../../images/left.svg";
import RIGHT from "../../images/right.svg";
import MAIL from "../../images/mail.svg";
import FUNEL from "../../images/filter.svg";
import BACKICON from "../../images/Arrow_Simple_left1.svg";
import IMGDOWNLOAD from "../../images/img-download.svg";
import SEARCHSMALL from "../../images/Search-small.svg";

import en_US from "antd/lib/locale-provider/en_US";

import {
    updateProjectDetails,setCurrentHit,setCurrentProject,getProjectDetails,updatePrevPageDeatails,
  } from "../../redux/reducer/projectReducer.js";

import {
    fetchHits,
    fetchNotDoneHits,
    addHits,
    skipHits,
    editProject,
    updateHitStatus,
    addHitEvaluation,
    updateNewLabelName,
    uploadAnnotatedImagesToS3,
    uploadAnnotatedImagesToS3Mask,
    deleteAnnotatedImagesFromS3,
    imageMaskCreation,
    addHitLabel,
    uploadGrayScaleImage,
  } from "../../Services/TaggerApis.js";


import {
  downloadImageUrl,
  createIndex,
  downloadfile,
  downloadfilecsv,
  clearMachinePrediction,
  saveComments,
  getCommentsDetails,
  updateComments,
  deleteComments,
} from "../../Services/commonApis.js";

import {
  getUidToken,
  logEvent,
  refreshUidToken,
  showAlert,
  saveactiveTab,
  getactiveName,
} from "../../helpers/commonFunctions.js";

import {
  fromDateTimestamp,
  toDateTimestamp,
  blob2file,
  getGroupingLabels,
  getByValue,
  displaySettingsByRole,
  isJSON,
  getAnnotatedLabelsCount,
} from "../../helpers/Utils";
import {
  createDocEntityColorMap,
  VIDEO_BOUNDING_BOX,
  DOCUMENT_ANNOTATION,
  POS_TAGGING_GENERIC,
  IMAGE_POLYGON_BOUNDING_BOX,
  IMAGE_POLYGON_BOUNDING_BOX_V2,
  IMAGE_SEGMENTATION,
  IMAGE_MASKING,
  IMAGE_CLASSIFICATION,
  VIDEO_CLASSIFICATION,
  ENTITY_COLORS,
  createEntities,
  createEntitiesJson,
  getDetaultShortcuts,
  convertKeyToString,
  POS_TAGGING,
  TEXT_SUMMARIZATION,
  IMAGE_BOUNDING_BOX,
  TEXT_CLASSIFICATION,
  HIT_STATE_SKIPPED,
  HIT_STATE_DONE,
  HIT_STATE_NOT_DONE,
  HIT_STATE_DELETED,
  HIT_STATE_PRE_TAGGED,
  HIT_STATE_REQUEUED,
  HIT_STATE_ALL,
  TEXT_MODERATION,
  checkVideoURL,
  getClassificationResponse,
  dateTimeConverter,
  hitStateNameMap,
  captureException,
  breadcrumbSeperate,
  getClassificationResult,
  HIT_EVALUATION_CORRECT,
  HIT_EVALUATION_INCORRECT,
  HIT_EVALUATION_NE,
  HIT_COMMENTED,
  HIT_UN_COMMENTED,
  HIT_COMMENTS_All,
  NEW_LABEL_UPDATE,
  NEW_LABEL_DELETE,
  DOC_ENTITY_COLORS,
  hitsBreadCrumbTitle,
  getImageMaskEntityColorMap,
  createHitJson,
  STATIC_HIT_LABEL_COLOR,
  ROLES_TYPES,
} from "../../helpers/Utils";

import {
  SUCCESS,
  COMMENTS_SAVED_SUCCESS,
  COMMENTS_UPDATED_SUCCESS,
  COMMENTS_DELETED_SUCCESS,
  VIDEO_ANNOTATOR_CONSTANTS,
} from "../../helpers/Constants";

import {
  DRAW_TOOLS,
  TAGS,
  LABELS,
  MESSAGES,
  ALERT_TYPES,
  CONFIRMATION_BOX,
  ACTION_BUTTONS,
} from "../../helpers/Constants";
import PanZoomElement from "../../helpers/PinchZoomPan/PanZoomElement.js";
import ImageAnnotator from "../../Components/PolygonAnnotator/ImageAnnotator.js";
import helper from "../../Pages/TaggerPage/helper.js";
import AnnotationLableModel from "../../Components/AnnotationLableModel/AnnotationLableModel.js";
import HitsOverviewBox from "../../Components/HitsOverviewBox/HitsOverviewBox.js";
import CommentComponent from "../../Components/CommentComponent/CommentComponent.js";
import DocumentAnnotator from "../../Components/DocumentAnnotator2/DocumentAnnotator.js";
import VideoAnnotator from "../VideoAnnotation/VideoAnnotation.js";
import FilterEntityTree from "../../Components/FilterEntityTree/FilterEntityTree.js";
import BoxAnnotator from "../../Components/BoxAnnotator/BoxAnnotator.js";
const styles = require("./TaggerPage.css");
const Mousetrap = require("mousetrap");
const { Option } = Select;

class TaggerSpace extends Component {
  static propTypes = {
    projectDetails: PropTypes.object,
    tagData: PropTypes.func,
    nextRow: PropTypes.func,
    // menuHidden: PropTypes.boolean,
    previousRow: PropTypes.func,
    currentProject: PropTypes.string,
    updateProjectDetails: PropTypes.func,
    getProjectDetails: PropTypes.func,
    pushState: PropTypes.func,
    currentHit: PropTypes.object,
    setCurrentHit: PropTypes.func,
    goBack: PropTypes.func,
    params: PropTypes.object,
    orgName: PropTypes.string,
    projectName: PropTypes.string,
    setCurrentProject: PropTypes.func,
    location: PropTypes.object,
    query: PropTypes.object,
    type: PropTypes.string,
    label: PropTypes.string,
    contributorId: PropTypes.string,
    from: PropTypes.string,
    to: PropTypes.string,
    user: PropTypes.object,
  };

  constructor(props) {
    super(props);
    let type = "notDone";
    let label = undefined;
    let evaluationType = undefined;
    let contributorId = undefined;
    let commentFilter = undefined;
    let commentsId = undefined;
    let selecteddate = undefined;
    let stopAutoSave = false;
    let fromLeaderBoard = false;
    let fromHitList = false;
    let labelCountMap = {};
    let selectedLabel = "";
    let annotateModalOpen = false;
    let labelBoundingBoxDoc = [];
    let contributorName = undefined;
    let action = "";
    let hitLabelsArr = [];
    let fromhitAddCallback = false;
    if (props.location && props.location.query) {
      if (props.location.query.type) type = props.location.query.type;
      if (props.prevPageDetails === "leaderBoard") {
        fromLeaderBoard = true;
        props.updatePrevPageDeatails("");
      } else if (props.prevPageDetails === "HitList") {
        fromHitList = true;
        fromLeaderBoard = false;
        props.updatePrevPageDeatails("");
      }
      if (props.location.query.label) label = props.location.query.label;

      if (props.location.query.evaluationType)
        evaluationType = props.location.query.evaluationType;

      if (props.location.query.commentFilter)
        commentFilter = props.location.query.commentFilter;

      if (props.location.query.contributorId)
        contributorId = props.location.query.contributorId;

      if (props.location.query.date) selecteddate = props.location.query.date;
    }

    this.showTagLine = this.showTagLine.bind(this);
    this.tagAreaClick = this.tagAreaClick.bind(this);
    this.setTagClick = this.setTagClick.bind(this);
    this.saveTagAndNextRow = this.saveTagAndNextRow.bind(this);
    this.saveTagAndNextRowForDoc = this.saveTagAndNextRowForDoc.bind(this);
    this.skipRow = this.skipRow.bind(this);
    this.projectDetailsFetched = this.projectDetailsFetched.bind(this);
    this.hitAddCallback = this.hitAddCallback.bind(this);
    this.hitAddCallbackNiri = this.hitAddCallbackNiri.bind(this);
    this.imageCreationCallback = this.imageCreationCallback.bind(this);
    this.addHitinState = this.addHitinState.bind(this);
    this.openProjectStats = this.openProjectStats.bind(this);
    this.removeTag = this.removeTag.bind(this);
    this.showTags = this.showTags.bind(this);
    this.showImages = this.showImages.bind(this);
    this.getBackTopreviousRow = this.getBackTopreviousRow.bind(this);
    this.getBackTopreviousRowForDoc = this.getBackTopreviousRowForDoc.bind(
      this
    );
    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.showClassifyTags = this.showClassifyTags.bind(this);
    this.showButtons = this.showButtons.bind(this);
    this.getCurrentResult = this.getCurrentResult.bind(this);
    this.moveToDone = this.moveToDone.bind(this);
    this.setSelectedDate = this.setSelectedDate.bind(this);
    this.redirectBackImage = this.redirectBackImage.bind(this);
    this.moveToDoneAction = this.moveToDoneAction.bind(this);
    this.clearPosLabels = this.clearPosLabels.bind(this);
    this.clearPosLabelsNew = this.clearPosLabelsNew.bind(this);
    this.getAnnotatorInfo = this.getAnnotatorInfo.bind(this);
    this.getCurrentProjectDetails = this.getCurrentProjectDetails.bind(this);
    this.receivedProjectDetails = this.receivedProjectDetails.bind(this);
    this.changeAutoClose = this.changeAutoClose.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.downloadFilterResult = this.downloadFilterResult.bind(this);
    this.downloadCallback = this.downloadCallback.bind(this);
    this.downloadCsvCallback = this.downloadCsvCallback.bind(this);
    this.handleClassificationCheckBoxChange = this.handleClassificationCheckBoxChange.bind(
      this
    );
    this.callsaveTagAndNextRow = this.callsaveTagAndNextRow.bind(this);
    this.callgetBackTopreviousRow = this.callgetBackTopreviousRow.bind(this);
    this.callMovetoDone = this.callMovetoDone.bind(this);
    this.callBackFun = this.callBackFun.bind(this);
    this.callBackButton = this.callBackButton.bind(this);
    this.segRedirect = this.segRedirect.bind(this);
    this.moveToSkippedAfterUpdateFromS3 = this.moveToSkippedAfterUpdateFromS3.bind(
      this
    );
    this.deleteItemAfterUpdateFromS3 = this.deleteItemAfterUpdateFromS3.bind(
      this
    );
    this.requeueAfterUpdateFromS3 = this.requeueAfterUpdateFromS3.bind(this);
    this.moveToDoneCallback = this.moveToDoneCallback.bind(this);
    this.moveToDoneCallbackNiri = this.moveToDoneCallbackNiri.bind(this);
    this.domToImagemoveToDoneAction = this.domToImagemoveToDoneAction.bind(
      this
    );
    this.saveAnnotation = this.saveAnnotation.bind(this);
    this.setDynamicLabelling = this.setDynamicLabelling.bind(this);
    this.moveToSkip = this.moveToSkip.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.retagHit = this.retagHit.bind(this);
    this.saveCommentCallBack = this.saveCommentCallBack.bind(this);
    this.addHitLabelCallback = this.addHitLabelCallback.bind(this);
    this.classificationLabelUpdatedCallback = this.classificationLabelUpdatedCallback.bind(
      this
    );
    this.handleJsonChange = this.handleJsonChange.bind(this);
    this.swapTags = this.swapTags.bind(this);
    this.getUsersFromTags = this.getUsersFromTags.bind(this);
    if (
      this.props.projectDetails &&
      this.props.currentHit &&
      this.props.projectDetails.task_type === POS_TAGGING
    ) {
      const rules = JSON.parse(this.props.projectDetails.taskRules);
      const entities = createEntitiesJson(this.props.projectDetails.taskRules)
        .entities;
      const entityColorMap = createDocEntityColorMap(entities, ENTITY_COLORS);

      let shortcuts = getDetaultShortcuts(
        this.props.projectDetails.task_type,
        entities
      );
      if ("shortcuts" in rules) {
        shortcuts = rules.shortcuts;
      }
      const hits = [];
      downloadImageUrl((url, uid, token) => {
        if (
          JSON.stringify(this.props.currentHit).includes("hits/images") &&
          !JSON.stringify(this.props.currentHit).includes("http") &&
          !JSON.stringify(this.props.currentHit).includes("https")
        ) {
          this.props.currentHit.data = `${url}${this.props.currentHit.data}?token=${token}&uid=${uid}`;
        }
      });
      hits.push(this.props.currentHit);
      let tagLine = this.props.currentHit.data;
      if (
        this.props.currentHit.hitResults &&
        this.props.currentHit.hitResults.length > 0
      ) {
        tagLine = this.props.currentHit.hitResults[0].result.trim();
      }
      let clickedColor = {};
      let taggedEntity = {};
      let words = [];

      try {
        const resultJson = JSON.parse(tagLine);
        const labelMap = {};
        if (this.props.currentHit.data) {
          words = this.props.currentHit.data.split(" ");
        }
        for (let index = 0; index < resultJson.length; index++) {
          const points = resultJson[index].points;
          const text = points[0].text;
          const entity = resultJson[index].label[0];
          labelMap[text] = entity;
        }
        for (let index = 0; index < words.length; index++) {
          if (words[index] in labelMap) {
            clickedColor[index] = entityColorMap[labelMap[words[index]]];
            taggedEntity[index] = labelMap[words[index]];
          } else {
            clickedColor[index] = "";
            taggedEntity[index] = "__";
          }
        }
      } catch (exception) {
        // statements
        const splits = tagLine.split(" ");
        for (let index = 0; index < splits.length; index++) {
          const word = splits[index];
          const wordSplits = word.split("____");
          if (wordSplits.length > 1) {
            clickedColor[index] = entityColorMap[wordSplits[1]];
            taggedEntity[index] = wordSplits[1];
            words[index] = wordSplits[0];
          } else {
            clickedColor[index] = "";
            taggedEntity[index] = "__";
            words[index] = splits[index];
          }
        }
      }
      const ch = this.props.currentHit;
      console.log("check ch 389", ch);
      this.state = {
        type,
        contributorId,
        commentsId,
        label,
        tagLine,
        clickedColor,
        evaluationType,
        commentFilter,
        taggedEntity,
        words,
        selectIds: [],
        shortcuts,
        rules,
        entityColorMap,
        currentIndex: 0,
        isSaveAction: false,
        hits,
        currentHit: ch,
        entities,
        changesInSession: 0,
        projectDetails: this.props.projectDetails,
        loading: false,
        currentStart: 0,
        visible: false,
        currentCount: 1,
        startTime: new Date().getTime(),
        isAutosave: false,
        newLabelUpdate: false,
        labelValue: "",
        value: "",
        suggestions: [],
        showLabelError: false,
        isEmptyLabel: false,
        dateFrom: props.selectedHitsDetails
          ? props.selectedHitsDetails.from
            ? props.selectedHitsDetails.from
            : null
          : null,
        dateTo: props.selectedHitsDetails
          ? props.selectedHitsDetails.to
            ? props.selectedHitsDetails.to
            : null
          : null,
        selecteddate: props.selectedHitsDetails
          ? props.selectedHitsDetails.selectedDate
            ? props.selectedHitsDetails.selectedDate
            : null
          : null,
        pageSortBy: "NEW_FIRST",
        contributer: props.selectedHitsDetails
          ? props.selectedHitsDetails.contributorId
            ? props.selectedHitsDetails.contributorId[0]
            : null
          : null,
        confirmModalVisible: false,
        searchHitUrl: null,
        redirectContributionDetails: null,
        hitUserName: "",
        hitEvaluatedBy: "",
        hitUserEmail: "",
        hitFileName: "",
        hitStatus: "",
        hitEvaluation: "",
        hitConvId: "",
        hitUpdatedTime: "",
        updateAnnotatorInfo: true,
        autocloseDisable: false,
        selectedLabel,
        labelBoundingBoxDoc,
        annotateModalOpen,
        downloadFormat: "json",
        itemOption: "ALL",
        confirmDownloadModalVisible: false,
        contributorName,
        taggingInfoModelVisible: false,
        buttonDisabled: false,
        mergeGroupLabel: false,
        clearButton: true,
        redoButton: true,
        undoButton: true,
        posTaggingPredChangeLabels: [],
        hasmaskLable: false,
        maskingLables: [],
        mllabelArray: [],
        downloadOption: "ALL",
        newLabelEntity: [],
        mpredClearModal: false,
        comments: "",
        editCommentsButtonEnable: false,
        commentsType: false,
        isCompletedRequest: true,
        hitLabels: [],
        hitKey: "",
        hitLabelsLast: "",
        autoSaveAction: false,
        hitLabelAction: "",
        selectedHitLabel: "",
        selectedHitLabelIndex: [],
        labelGroupMap: {},
        classificationModalVisible: false,
        dynamicClassificationLabel: "",
        grayscalenotif: false,
        tagimageNaturalheight: undefined,
        tagimageNaturalWidth: undefined,
        currentProject: null,
        lastHitsDetails: false,
      };
    } else if (
      this.props.projectDetails &&
      this.props.currentHit &&
      (this.props.projectDetails.task_type === TEXT_SUMMARIZATION ||
        this.props.projectDetails.task_type === TEXT_MODERATION)
    ) {
      const rules = JSON.parse(this.props.projectDetails.taskRules);
      let shortcuts = getDetaultShortcuts(this.props.projectDetails.task_type);
      if ("shortcuts" in rules) {
        shortcuts = rules.shortcuts;
      }
      const hits = [];
      hits.push(this.props.currentHit);
      const ch = this.props.currentHit;
      console.log("check ch 496", ch);
      let result = "";
      if (ch.hitResults) {
        result = ch.hitResults[0].result;
      }
      this.state = {
        type,
        contributorId,
        commentsId,
        label,
        selectIds: [],
        evaluationType,
        commentFilter,
        rules,
        currentIndex: 0,
        isSaveAction: false,
        hits,
        shortcuts,
        currentHit: ch,
        changesInSession: 0,
        textSummary: result.note,
        projectDetails: this.props.projectDetails,
        loading: false,
        currentStart: 0,
        currentCount: 1,
        startTime: new Date().getTime(),
        newLabelUpdate: false,
        labelValue: "",
        value: "",
        suggestions: [],
        showLabelError: false,
        isEmptyLabel: false,
        selecteddate: props.selectedHitsDetails
          ? props.selectedHitsDetails.selectedDate
            ? props.selectedHitsDetails.selectedDate
            : null
          : null,
        dateFrom: props.selectedHitsDetails
          ? props.selectedHitsDetails.from
            ? props.selectedHitsDetails.from
            : null
          : null,
        dateTo: props.selectedHitsDetails
          ? props.selectedHitsDetails.to
            ? props.selectedHitsDetails.to
            : null
          : null,
        contributer: props.selectedHitsDetails
          ? props.selectedHitsDetails.contributorId
            ? props.selectedHitsDetails.contributorId[0]
            : null
          : null,
        pageSortBy: "NEW_FIRST",
        confirmModalVisible: false,
        searchHitUrl: null,
        redirectContributionDetails: null,
        hitUserName: "",
        hitEvaluatedBy: "",
        hitUserEmail: "",
        hitFileName: "",
        hitStatus: "",
        hitEvaluation: "",
        hitConvId: "",
        hitUpdatedTime: "",
        updateAnnotatorInfo: true,
        autocloseDisable: false,
        selectedLabel,
        labelBoundingBoxDoc,
        annotateModalOpen,
        downloadFormat: "json",
        itemOption: "ALL",
        confirmDownloadModalVisible: false,
        contributorName,
        taggingInfoModelVisible: false,
        buttonDisabled: false,
        mergeGroupLabel: false,
        clearButton: true,
        undoButton: true,
        redoButton: true,
        hasmaskLable: false,
        maskingLables: [],
        mllabelArray: [],
        downloadOption: "ALL",
        newLabelEntity: [],
        mpredClearModal: false,
        comments: "",
        editCommentsButtonEnable: false,
        commentsType: false,
        isCompletedRequest: true,
        hitLabels: [],
        hitKey: "",
        hitLabelsLast: "",
        autoSaveAction: false,
        hitLabelAction: "",
        selectedHitLabel: "",
        selectedHitLabelIndex: [],
        labelGroupMap: {},
        classificationModalVisible: false,
        dynamicClassificationLabel: "",
        grayscalenotif: false,
        tagimageNaturalheight: undefined,
        tagimageNaturalWidth: undefined,
        currentProject: null,
      };
    } else if (
      this.props.projectDetails &&
      this.props.currentHit &&
      (this.props.projectDetails.task_type === TEXT_CLASSIFICATION ||
        this.props.projectDetails.task_type === VIDEO_CLASSIFICATION ||
        this.props.projectDetails.task_type === IMAGE_CLASSIFICATION)
    ) {
      const rules = JSON.parse(this.props.projectDetails.taskRules);
      const entities = createEntitiesJson(this.props.projectDetails.taskRules)
        .entities;
      let shortcuts = getDetaultShortcuts(
        this.props.projectDetails.task_type,
        entities
      );
      if ("shortcuts" in rules) {
        shortcuts = rules.shortcuts;
      }
      const entityColorMap = createDocEntityColorMap(entities, ENTITY_COLORS);
      const hits = [];
      hits.push(this.props.currentHit);
      const ch = this.props.currentHit;
      console.log("check ch 604", ch);
      this.setCurrentTags(ch.predHitResults, "machine");
      this.setCurrentTags(ch.hitResults, "human");
      this.state = {
        type,
        contributorId,
        commentsId,
        label,
        selectIds: [],
        entities,
        evaluationType,
        commentFilter,
        entityColorMap,
        currentTags:new Set(),
        rules,
        currentIndex: 0,
        isSaveAction: false,
        hits,
        shortcuts,
        currentHit: ch,
        changesInSession: 0,
        currentNote:"",
        projectDetails: this.props.projectDetails,
        loading: false,
        currentStart: 0,
        currentCount: 1,
        startTime: new Date().getTime(),
        value: "",
        suggestions: [],
        selecteddate: props.selectedHitsDetails
          ? props.selectedHitsDetails.selectedDate
            ? props.selectedHitsDetails.selectedDate
            : null
          : null,
        dateFrom: props.selectedHitsDetails
          ? props.selectedHitsDetails.from
            ? props.selectedHitsDetails.from
            : null
          : null,
        dateTo: props.selectedHitsDetails
          ? props.selectedHitsDetails.to
            ? props.selectedHitsDetails.to
            : null
          : null,
        contributer: props.selectedHitsDetails
          ? props.selectedHitsDetails.contributorId
            ? props.selectedHitsDetails.contributorId[0]
            : null
          : null,
        pageSortBy: "NEW_FIRST",
        confirmModalVisible: false,
        searchHitUrl: null,
        redirectContributionDetails: null,
        hitUserName: "",
        hitEvaluatedBy: "",
        hitUserEmail: "",
        hitFileName: "",
        hitStatus: "",
        hitEvaluation: "",
        hitConvId: "",
        hitUpdatedTime: "",
        updateAnnotatorInfo: true,
        fromLeaderBoard: fromLeaderBoard,
        fromHitList: fromHitList,
        autocloseDisable: false,
        selectedLabel,
        labelBoundingBoxDoc,
        annotateModalOpen,
        downloadFormat: "json",
        itemOption: "ALL",
        confirmDownloadModalVisible: false,
        contributorName,
        taggingInfoModelVisible: false,
        buttonDisabled: false,
        mergeGroupLabel: false,
        clearButton: true,
        undoButton: true,
        redoButton: true,
        currentPredTags:[],
        currentPredNote:"",
        hasmaskLable: false,
        maskingLables: [],
        mllabelArray: [],
        downloadOption: "ALL",
        newLabelEntity: [],
        mpredClearModal: false,
        comments: "",
        editCommentsButtonEnable: false,
        commentsType: false,
        isCompletedRequest: true,
        hitLabels: [],
        hitKey: "",
        hitLabelsLast: "",
        autoSaveAction: false,
        hitLabelAction: "",
        selectedHitLabel: "",
        selectedHitLabelIndex: [],
        labelGroupMap: {},
        classificationModalVisible: false,
        dynamicClassificationLabel: "",
        currentProject: null,
      };
    } else if (
      this.props.projectDetails &&
      this.props.currentHit &&
      (this.props.projectDetails.task_type === IMAGE_BOUNDING_BOX ||
        this.props.projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX ||
        this.props.projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX_V2 ||
        this.props.projectDetails.task_type === IMAGE_SEGMENTATION ||
        this.props.projectDetails.task_type === IMAGE_MASKING ||
        this.props.projectDetails.task_type === VIDEO_BOUNDING_BOX ||
        this.props.projectDetails.task_type === DOCUMENT_ANNOTATION ||
        this.props.projectDetails.task_type === POS_TAGGING_GENERIC)
    ) {
      // for documentAnnotation bounding box update
      const rules = JSON.parse(this.props.projectDetails.taskRules);
      const entities = createEntities(this.props.projectDetails.taskRules)
        .entities;
      const entityColorMap = createDocEntityColorMap(entities, ENTITY_COLORS);
      this.setState({ entityColorMap: entityColorMap });

      const hits = [];
      let shortcuts = getDetaultShortcuts(
        this.props.projectDetails.task_type,
        entities
      );

      let autoClose = true;
      let notes = false;
      let classification = undefined;
      let attributes = undefined;
      let defaultShape = "polygon";
      if ("shortcuts" in rules) {
        shortcuts = rules.shortcuts;
      }
      if ("autoClose" in rules) {
        autoClose = rules.autoClose;
      }
      if ("notes" in rules) {
        notes = rules.notes;
      }
      if ("defaultShape" in rules) {
        defaultShape = rules.defaultShape;
      }

      if ("attributes" in rules) {
        attributes = rules.attributes;
      }

      hits.push(this.props.currentHit);
      const ch = this.props.currentHit;
      console.log("check ch 743", ch);
      let boundingBoxMap = {};
      let classificationResponse = [];
      let hitLabels = [];

      if (ch.hitResults[0].result.length > 0) {
        const resultObject = JSON.parse(ch.hitResults[0].result);
        if ("classification" in rules) {
          classification = rules.classification;
          if (ch.hitResults) {
            if ("annotationResult" in resultObject) {
              boundingBoxMap = resultObject.annotationResult;
              if ("classificationResult" in resultObject) {
                classificationResponse = getClassificationResponse(
                  resultObject.classificationResult,
                  classification,
                  this.props.projectDetails.task_type
                );
              }
            } else {
              boundingBoxMap = JSON.parse(ch.hitResults[0].result);
            }
          }
        } else {
          if (ch.hitResults) {
            const resultObject = JSON.parse(ch.hitResults[0].result);
            if ("annotationResult" in resultObject) {
              boundingBoxMap = resultObject.annotationResult;
            } else {
              boundingBoxMap = JSON.parse(ch.hitResults[0].result);
            }
          }
        }
      }
      if (ch.labels && ch.labels.length > 0) {
        ch.labels.split(",").map((elem) => {
          hitLabels.push(elem);
          if (!entityColorMap[elem]) {
            entityColorMap[elem] = STATIC_HIT_LABEL_COLOR;
          }
        });
      }
      if (
        this.props.projectDetails.task_type === IMAGE_BOUNDING_BOX ||
        this.props.projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX ||
        this.props.projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX_V2 ||
        this.props.projectDetails.task_type === IMAGE_SEGMENTATION ||
        this.props.projectDetails.task_type === IMAGE_MASKING
      ) {
        ch.data = ch.data + "?ts=" + new Date();
      }
      this.state = {
        type,
        defaultShape,
        classification,
        classificationResponse,
        attributes,
        contributorId,
        commentsId,
        evaluationType,
        commentFilter,
        label,
        selectIds: [],
        entities,
        entityColorMap,
        boundingBoxMap,
        shortcuts,
        autoLabel: false,
        autoClose,
        notes,
        rules,
        currentIndex: 0,
        isSaveAction: false,
        hits,
        newEntities: [],
        currentHit: ch,
        changesInSession: 0,
        projectDetails: this.props.projectDetails,
        loading: false,
        currentStart: 0,
        currentCount: 1,
        startTime: new Date().getTime(),
        value: "",
        suggestions: [],
        showLabelError: false,
        isEmptyLabel: false,
        selecteddate: props.selectedHitsDetails
          ? props.selectedHitsDetails.selectedDate
            ? props.selectedHitsDetails.selectedDate
            : null
          : null,
        dateFrom: props.selectedHitsDetails
          ? props.selectedHitsDetails.from
            ? props.selectedHitsDetails.from
            : null
          : null,
        dateTo: props.selectedHitsDetails
          ? props.selectedHitsDetails.to
            ? props.selectedHitsDetails.to
            : null
          : null,
        contributer: props.selectedHitsDetails
          ? props.selectedHitsDetails.contributorId
            ? props.selectedHitsDetails.contributorId[0]
            : null
          : null,
        pageSortBy: "NEW_FIRST",
        confirmModalVisible: false,
        searchHitUrl: null,
        redirectContributionDetails: null,
        hitUserName: "",
        hitEvaluatedBy: "",
        hitUserEmail: "",
        hitFileName: "",
        hitStatus: "",
        hitEvaluation: "",
        hitConvId: "",
        hitUpdatedTime: "",
        updateAnnotatorInfo: true,
        fromLeaderBoard: fromLeaderBoard,
        fromHitList: fromHitList,
        autocloseDisable: false,
        selectedLabel,
        currentProject: null,
        classificationLabel: null,
        labelBoundingBoxDoc,
        annotateModalOpen,
        downloadFormat: "json",
        itemOption: "ALL",
        confirmDownloadModalVisible: false,
        contributorName,
        taggingInfoModelVisible: false,
        buttonDisabled: false,
        mergeGroupLabel: false,
        clearButton: true,
        undoButton: true,
        redoButton: true,
        hasmaskLable: false,
        maskingLables: [],
        mllabelArray: [],
        downloadOption: "ALL",
        newLabelEntity: [],
        mpredClearModal: false,
        comments: "",
        editCommentsButtonEnable: false,
        commentsType: false,
        isCompletedRequest: true,
        hitLabels,
        hitKey: "",
        hitLabelsLast: "",
        autoSaveAction: false,
        hitLabelAction: "",
        selectedHitLabel: "",
        selectedHitLabelIndex: [],
        labelGroupMap: {},
        classificationModalVisible: false,
        dynamicClassificationLabel: "",
        grayscalenotif: false,
        tagimageNaturalheight: undefined,
        tagimageNaturalWidth: undefined,
        retainAnnotations: false,
        retainIndex: 0,
        slicedHits: [],
        opacityValue: false,
      };
    } else {
      this.state = {
        tagLine: "",
        clickedColor: {},
        taggedEntity: {},
        entities: [],
        words: [],
        hits: [],
        hitsNiri: [],
        checkHits: [],
        niriFilteredHits: null,
        type,
        searchQuery: "",
        evaluationType,
        commentFilter,
        label,
        contributorId,
        commentsId,
        rules: {},
        autoLabel: false,
        autoClose: true,
        defaultShape: "polygon",
        currentProject: null,
        notes: false,
        hideLabels: false,
        tagSelected: false,
        selectIds: [],
        loading: false,
        hitsCompleted: false,
        currentIndex: -1,
        isSaveAction: false,
        currentTags: new Set(),
        entityColorMap: {},
        currentStart: 0,
        currentCount: this.getDefaultCount(),
        DEFAULT_COUNT: this.getDefaultCount(),
        activeIndex: -1,
        action: "",
        startTime: new Date().getTime(),
        changesInSession: 0,
        shortcuts: {},
        isFullscreenEnabled: false,
        value: "",
        suggestions: [],
        showLabelError: false,
        isEmptyLabel: false,
        pageSortBy: "NEW_FIRST",
        selecteddate,
        contributer: props.selectedHitsDetails
          ? props.selectedHitsDetails.contributorId
            ? props.selectedHitsDetails.contributorId[0]
            : null
          : null,
        confirmModalVisible: false,
        searchHitUrl: null,
        redirectContributionDetails: null,
        hitUserName: "",
        hitEvaluatedBy: "",
        hitUserEmail: "",
        hitFileName: "",
        hitStatus: "",
        hitEvaluation: "",
        hitConvId: "",
        hitUpdatedTime: "",
        updateAnnotatorInfo: true,
        fromLeaderBoard: fromLeaderBoard,
        fromHitList: fromHitList,
        autocloseDisable: false,
        selectedLabel,
        labelBoundingBoxDoc,
        classificationLabel: null,
        annotateModalOpen,
        downloadFormat: "json",
        itemOption: "ALL",
        confirmDownloadModalVisible: false,
        contributorName,
        taggingInfoModelVisible: false,
        buttonDisabled: false,
        mergeGroupLabel: false,
        clearButton: true,
        undoButton: true,
        redoButton: true,
        hasmaskLable: false,
        maskingLables: [],
        mllabelArray: [],
        downloadOption: "ALL",
        newLabelEntity: [],
        mpredClearModal: false,
        comments: "",
        editCommentsButtonEnable: false,
        commentsType: false,
        hitEntities: {},
        isCompletedRequest: true,
        hitLabels: [],
        hitKey: "",
        hitLabelsLast: "",
        autoSaveAction: false,
        hitLabelAction: "",
        selectedHitLabel: "",
        selectedHitLabelIndex: [],
        labelGroupMap: {},
        classificationModalVisible: false,
        dynamicClassificationLabel: "",
        grayscalenotif: false,
        tagimageNaturalheight: undefined,
        tagimageNaturalWidth: undefined,
        stroke_width: 2,
        imagesLoad: false,
        totalHitsVal: null,
        currentIndexValue: 9,
        imageLoad: false,
        autoSaveVal: 0,
        loadFilterImages: false,
        imgName: false,
        selectShape: null,
        boundingValues : []
      };
    }

    this.props.setCurrentHit(undefined);
    this.setState({ autocloseDisable: false });
  }

  handleIsSaveAction = () => this.setState({ isSaveAction: true });

  toggleMenu() {
    this.setState({ visible: !this.state.visible });
  }

  componentWillMount() {
    this.setState({ loading: true });
    // document.addEventListener('keydown', this.handleKeyDown.bind(this));
  }
  hasNetwork(online) {
    // Update the DOM to reflect the current status
    if (online) {
    } else {
      this.setState({ fullScreen: false });
      showAlert(
        "Looks like your internet connection is not stable. Please save your work and refresh the application",
        "warning"
      );
    }
  }
  componentDidMount() {
    this.hasNetwork(navigator.onLine);
    window.addEventListener("online", () => {
      // Set hasNetwork to online when they change to online.
      this.hasNetwork(true);
    });
    window.addEventListener("offline", () => {
      // Set hasNetwork to offline when they change to offline.
      this.hasNetwork(false);
    });
    this.setState({ currentProject: this.props.currentProject });
    let totalHits;
    this.setState({ currentProject: this.props.params.projectId });

    if (this.props.selectedHitsDetails) {
      this.setState({ currentProject: this.props.params.projectId });
      if (this.state.fromLeaderBoard !== true) {
        this.state.redirectContributionDetails = this.props.selectedHitsDetails.redirectContributionDetails;
        this.state.searchHitUrl = this.props.selectedHitsDetails.searchHitUrl;
        this.state.selecteddate = this.props.selectedHitsDetails.selectedDate;
        this.state.dateFrom = this.props.selectedHitsDetails.from;
        this.state.currentProject = this.props.params.projectId;
        this.state.dateTo = this.props.selectedHitsDetails.to;
        this.state.label = this.props.selectedHitsDetails.label;
        this.state.evaluationType = this.props.selectedHitsDetails.evaluationType;
        this.state.commentFilter = this.props.selectedHitsDetails.commentFilter;
        this.state.classificationLabel = this.props.selectedHitsDetails.classificationLabel;
        if (this.props.selectedHitsDetails.contributorId)
          this.state.contributorId = this.props.selectedHitsDetails.contributorId[0];
        if (this.props.selectedHitsDetails.status.length > 0) {
          if(this.props.selectedHitsDetails.status[0] == "") {
            this.state.type = "all";
          } else {
            this.state.type = this.props.selectedHitsDetails.status[0]
          }
        }
        if (this.props.selectedHitsDetails.contributorId)
          this.setState({
            contributorId: this.props.selectedHitsDetails.contributorId,
          });
        totalHits = this.props.selectedHitsDetails.totalHits;
        if (this.props.projectDetails.niriFilteredHits > 0) {
          totalHits = this.props.selectedHitsDetails.totalHits * 2;
        }
      }
      
      this.setState({ loadFilterImages: true, imageLoad: true });
      const { type, label, dateFrom, dateTo, searchHitUrl } = this.state;
      if (
        (type == "done" || type === "all") &&
        !label &&
        !dateFrom &&
        !dateTo &&
        !searchHitUrl.searchHitUrl
      ) {
        this.loadProjectDetails(
          this.props.currentProject,
          totalHits - 10,
          totalHits
        );
        this.setState({ totalHitsVal: totalHits - 10 });
      } else {
        if (this.state.type.includes("correct")) {
          this.state.evaluationType = this.state.type;
        }
        this.loadProjectDetails(
          this.props.currentProject,
          this.props.selectedHitsDetails.pageStart,
          totalHits
        );
      }
    } else {
      const { projectDetails } = this.props;
      if (
        (this.props.params.orgName &&
          this.props.params.projectName &&
          (!this.props.projectDetails ||
            this.props.projectDetails.name !== this.props.params.projectName ||
              this.props.projectDetails.orgName !==
                this.props.params.orgName)) ||
        !this.props.currentProject
      ) {
        this.setState({ currentProject: this.props.params.projectId });
      }
      if (!this.props.currentHit && !this.props.projectDetails) {
        this.props
          .getProjectDetails(this.props.currentProject, getUidToken())
          .then(() => {
            let projectDetails = this.props.projectDetails;
            let totalNotDoneHits =
              projectDetails.totalHits -
              (projectDetails.totalHitsDeleted +
                projectDetails.totalHitsDone +
                projectDetails.totalHitsSkipped);

            if (this.props.projectDetails.niriFilteredHits > 0) {
              totalNotDoneHits = totalNotDoneHits * 2;
            }

            this.loadProjectDetails(
              this.props.currentProject,
              totalNotDoneHits - 10,
              totalNotDoneHits
            );
            this.setState({
              totalHitsVal: totalNotDoneHits - 10,
            });
          });
      }
      if (!this.props.currentHit && this.props.projectDetails) {
        this.setState({ imageLoad: true });
        let projectDetails = this.props.projectDetails;
        let totalNotDoneHits;
        if (this.props.projectDetails.task_type === VIDEO_BOUNDING_BOX) {
          totalNotDoneHits = projectDetails.totalHits;
        } else {
          totalNotDoneHits = projectDetails.totalHits -
          (projectDetails.totalHitsDeleted +
            projectDetails.totalHitsDone +
            projectDetails.totalHitsSkipped);
        }
        if (this.props.projectDetails.niriFilteredHits > 0) {
          totalNotDoneHits = totalNotDoneHits * 2;
        }
        if (!projectDetails.task_type.includes("IMAGE")) {
          this.state.currentCount = projectDetails.totalHits;
        }
        this.loadProjectDetails(
          this.props.currentProject,
          totalNotDoneHits - 10,
          totalNotDoneHits
        );
        this.setState({
          totalHitsVal: totalNotDoneHits - 10,
          // currentIndexValue: currentIndexValue + 10,
        });
      }
      if (this.state.type !== "notDone" && this.props.currentProject) {
        this.props.getProjectDetails(this.props.currentProject, getUidToken());
      }
      const editor = document.getElementById("write_text");
      if (editor !== null) {
        editor.setAttribute("data-gramm", "false");
      }
    }
  }

  componentWillReceiveProps(props) {
    let { type } = this.state;
    if (Array.isArray(type)) {
      type = type[0];
      this.setState({ type: type });
    }

    if (
      (!this.state.hits || this.state.hits.length === 0) &&
      !this.state.currentHit &&
      props.currentProject &&
      this.props.currentProject !== props.currentProject
    ) {
      if (!props.selectedHitsDetails)
        this.loadProjectDetails(props.currentProject);
      this.props.getProjectDetails(props.currentProject, getUidToken());
    } else if (this.props.menuHidden !== props.menuHidden) {
      this.setState({ menuHidden: props.menuHidden });
      this.loadProjectDetails(props.currentProject);
    }
    if (this.props.location !== props.location) {
      if (props.location && props.location.query) {
        if (props.location.query.type)
          this.state.type = props.location.query.type;
        if (props.location.query.label)
          this.state.label = props.location.query.label;
        if (props.location.query.evaluationType)
          this.state.evaluationType = props.location.query.evaluationType;
        if (props.location.query.commentFilter)
          this.state.commentFilter = props.location.query.commentFilter;
        if (props.location.query.contributorId)
          this.state.contributorId = props.location.query.contributorId;
        if (props.location.query.date)
          this.state.selecteddate = props.location.query.date;
      }
      if (props.selectedHitsDetails) {
        this.setState({
          contributer: props.selectedHitsDetails.contributorId
            ? props.selectedHitsDetails.contributorId[0]
            : "",
          type: props.selectedHitsDetails.status,
          selecteddate: props.selectedHitsDetails.selectedDate
            ? props.selectedHitsDetails.selectedDate
            : null,
        });
      }
      this.state.hitsCompleted = false;
      this.state.currentStart = 0;
      this.loadProjectDetails(
        props.currentProject,
        this.state.currentStart,
        this.state.count
      );
    }
    if (!this.props.projectDetails && props.projectDetails) {
      this.setState({ projectDetails: props.projectDetails });
    }
    if (props.projectDetails && props.projectDetails.contributorDetails) {
      this.setState({
        contributorDetails: props.projectDetails.contributorDetails,
        contributorDetailsMap: this.getContributorDetailsMap(
          props.projectDetails.contributorDetails
        ),
      });
    }

    if (
      this.state.type === HIT_STATE_NOT_DONE &&
      this.state.currentHit &&
      this.state.projectDetails !== props.projectDetails
    ) {
      this.setState({ projectDetails: props.projectDetails });
    }
  }

  // auto save have bben conditionally used
  // no auto save in requeied page
  // save only through move to done


  componentDidUpdate() {
    const editor = document.getElementById("write_text");
    if (editor !== null) {
      editor.setAttribute("data-gramm", "false");
    }
  
    const {
      type,
      confirmModalVisible,
      changesInSession,
      currentHit,
      hasmaskLable,
      updateAnnotatorInfo,
      currentIndex,
    } = this.state;
  
    console.log("taggerpage ", this.props.projectDetails);
  
    // Check if this.props.projectDetails is not null before accessing its properties
    if (this.props.projectDetails) {
      const { task_type } = this.props.projectDetails;
  
      if (
        changesInSession &&
        changesInSession > 0 &&
        currentHit &&
        currentHit.status !== "reQueued"
      ) {
        if (task_type) {
          if (
            task_type === IMAGE_POLYGON_BOUNDING_BOX_V2 ||
            task_type === IMAGE_SEGMENTATION ||
            task_type === IMAGE_MASKING ||
            (task_type === IMAGE_CLASSIFICATION && !this.stopAutoSave)
          ) {
            if (!confirmModalVisible) {
              this.setAutoSaveProp(true);
              this.setAutoSaveAction(false);
              console.log("check moveToDone only 1164");
              this.moveToDone("autoSaveToDone");
            }
          }
          if (task_type === IMAGE_MASKING && hasmaskLable) {
            this.setDynamicLabelling();
            task_type === IMAGE_MASKING && this.saveMaskingNewLabels();
          }
        }
      }
    }
  
    if (currentHit && updateAnnotatorInfo) {
      let hit = this.state.hits[currentIndex];
      let hitLabels = this.state.hitLabels;
      if (hit && hit.labels && hit.labels.length > 0) {
        hit.labels.split(",").map((elem) => {
          if (!hitLabels.includes(elem)) {
            hitLabels.push(elem);
          }
          console.log("check componentDidUpdate", currentHit, this.state.hits);
          this.addHitColorToEntityColorMap(elem);
        });
      }
      if (hit === undefined) {
        this.setState(
          { updateAnnotatorInfo: false },
          this.getAnnotatorInfo(hit)
        );
      } else {
        this.setState(
          {
            updateAnnotatorInfo: false,
            hitLabels: hitLabels,
            editCommentsButtonEnable:
              hit.comments && hit.comments.length > 1 ? true : false,
          },
          this.getAnnotatorInfo(hit)
        );
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
    this.setState({ hits: undefined, selecteddate: null });
  }

  getDefaultCount() {
    if (this.props.projectDetails && this.props.projectDetails.task_type) {
      switch (this.props.projectDetails.task_type) {
        case POS_TAGGING:
        case TEXT_MODERATION:
        case TEXT_SUMMARIZATION:
        case TEXT_CLASSIFICATION:
        case IMAGE_CLASSIFICATION:
        case VIDEO_CLASSIFICATION:
          return 10;
        case POS_TAGGING_GENERIC:
        case IMAGE_POLYGON_BOUNDING_BOX:
        case VIDEO_BOUNDING_BOX:
        case DOCUMENT_ANNOTATION:
        case IMAGE_POLYGON_BOUNDING_BOX_V2:
        case IMAGE_SEGMENTATION:
        case IMAGE_BOUNDING_BOX:
          return 10;
        default:
          console.log("default")
      }
    }
    return 10;
  }

  getContributorDetailsMap(contributorDetails) {
    let contributorDetailsMap = {};
    for (let index = 0; index < contributorDetails.length; index++) {
      contributorDetailsMap[contributorDetails[index].userDetails.uid] =
        contributorDetails[index].userDetails;
    }
    return contributorDetailsMap;
  }
  setretainAnnotations = (checked) => {
    this.setState({ retainAnnotations: checked });
  };

  setretainIndex = (data) => {
    this.setState({ retainIndex: data });
  };

  setSlicedHits = (data) => {
    this.setState({ slicedHits: data });
  };

  setTagClick(data, event) {
    if (
      parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN ||
      parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR
    ) {
      event.preventDefault();
      let predChangeLabels = [];
      if (this.state.tagSelected && this.state.selectIds.length > 0) {
        const currentClickState = this.state.clickedColor;
        const selectIds = this.state.selectIds;
        let changesInSession = this.state.changesInSession;
        changesInSession = changesInSession + 1;
        for (let index = 0; index < selectIds.length; index++) {
          currentClickState[selectIds[index]] = this.state.entityColorMap[
            data // data.name
          ];
          const currententities = this.state.taggedEntity;
          currententities[selectIds[index]] = data; // data.name;
          predChangeLabels = this.state.posTaggingPredChangeLabels;
          predChangeLabels[selectIds[index]] = "H";
        }
        this.setState({
          clickedColor: currentClickState,
          tagSelected: false,
          selectIds: [],
          changesInSession,
          posTaggingPredChangeLabels: predChangeLabels,
        });
      }
    }
  }



  deleteAnnotationsFromS3 = (event, type) => {
    const hitId = this.state.currentHit.id;
    const proId = this.props.projectDetails.id;
    if (type === "Skipped") {
      deleteAnnotatedImagesFromS3(
        proId,
        hitId,
        event.moveToSkippedAfterUpdateFromS3
      );
    } else if (type === "Deleted") {
      deleteAnnotatedImagesFromS3(
        proId,
        hitId,
        event.deleteItemAfterUpdateFromS3
      );
    } else if (type === "Requeue") {
      deleteAnnotatedImagesFromS3(proId, hitId, event.requeueAfterUpdateFromS3);
    }
  };

  setDynamicLabelling() {
    if (this.state.newLabelUpdate) {
      let maskLables = this.state.maskingLables;
      maskLables.push(this.state.value);
      this.setState({ hasmaskLable: false, maskingLables: maskLables });

      let tagsEntitiy = this.state.entities;
      tagsEntitiy.push(this.state.value);
      this.setState({ entities: tagsEntitiy });

      console.log("setDynamicLabelling entityColorMap", this.state);
      let entityColorMap = this.state.entityColorMap;
      let hitEntities = this.state.hitEntities;
      console.log(
        "setDynamicLabelling",
        this.state.hitEntities,
        this.state.value,
        entityColorMap[this.state.value]
      );
      if (!entityColorMap[this.state.value]) {
        var colorCode =
          "#" +
          Math.random()
            .toString(16)
            .substr(-6);
        // entityColorMap[this.state.value] = colorCode;
        hitEntities[this.state.value] = colorCode;
      }
      console.log("setDynamicLabelling", hitEntities);
      let shortcuts = getDetaultShortcuts(IMAGE_MASKING, tagsEntitiy);
      this.setState(
        {
          entityColorMap: entityColorMap,
          shortcuts: shortcuts,
          hitEntities: hitEntities,
        },
        () => {
          this.polyAnnotator.updateNewLabel("masking");
        }
      );
    }
  }


  callMovetoDone = () => {
    this.setState({
      buttonDisabled: false,
      opacityValue: false,
      isAutosave: false,
    });
    this.removeShapeDots();
    console.log("check domToImagemoveToDoneAction 1449");
    this.domToImagemoveToDoneAction(this.action);
  };

  callBackFun = () => {
    this.setState({
      buttonDisabled: false,
      opacityValue: false,
      isAutosave: true,
      loading: false,
    });
  };

  getBackTopreviousRow(event, data) {
    if (
      this.props.projectDetails.task_type === IMAGE_MASKING ||
      this.props.projectDetails.task_type === IMAGE_SEGMENTATION
    ) {
      this.mergingGroupLabelButtonSwitch(false);
    }
    this.callgetBackTopreviousRow();
  }
  
  callgetBackTopreviousRow = (event, data) => {
    this.removeShapeDots();
    this.setState({ opacityValue: false });
    let tagString = "";
    const latency = (
      (new Date().getTime() - this.state.startTime) /
      1000
    ).toPrecision(3);

    logEvent("buttons", "Prev hit");
    if (this.state.changesInSession > 0) {
      logEvent("buttons", "Labeled Data");
    }
    this.setState({
      comments: "",
      hitsCompleted: false,
      buttonDisabled: false,
    });
    if (this.state.newEntities && this.state.newEntities.length > 0) {
      logEvent("buttons", "New Entities");
      const { taskRules } = this.props.projectDetails;
      const rulesJson = JSON.parse(taskRules);
      rulesJson.tags = [...this.state.entities, ...this.state.newEntities].join(
        ","
      );
      editProject(
        this.props.currentProject,
        { rules: JSON.stringify(rulesJson) },
        this.projectEditedCallback.bind(this)
      );
      this.props.setloaderMenu(true);
      this.setState({ loading: true, newEntities: [] });
    }

    const resultString = this.getCurrentResult();
    if (this.state.changesInSession > 0 && this.state.type !== "done") {
      this.saveMaskingNewLabels();
      tagString = tagString.trim();
      this.state.currentHit.result = resultString;
      this.props.setloaderMenu(true);
      this.setState({
        loading: true,
        action: "previous",
        changesInSession: 0,
      });
      let hitData = "";
      if (this.state.type === "skipped") {
        hitData = {
          result: resultString,
          timeTakenToLabelInSec: latency,
          status: this.state.currentHit.status,
        };
      } else {
        if (this.state.projectDetails.task_type === VIDEO_BOUNDING_BOX)
          hitData = {
            result: resultString,
            timeTakenToLabelInSec: latency,
            status: this.state.type,
          };
        else {
          hitData = { result: resultString, timeTakenToLabelInSec: latency };
        }
      }

      if (
        this.state.type === "notDone" &&
        this.state.projectDetails.task_type === POS_TAGGING
      ) {
        this.savePosTaggingPredictionLabels();
      }

      if (this.state.projectDetails.task_type === TEXT_CLASSIFICATION) {
        this.setState({
          action: "previous",
          changesInSession: 0,
          loading: true,
        });
        this.hitAddCallback(undefined, "Ok", "previous");
      } else {
        addHits(
          this.state.currentHit.id,
          hitData,
          this.props.currentProject,
          this.hitAddCallback
        );
      }
    } else {
      this.props.setloaderMenu(true);
      this.setState({
        action: "previous",
        changesInSession: 0,
        loading: true,
      });
      this.hitAddCallback(undefined, "Ok", "previous");
    }
    return false;
  };

  getBackTopreviousRowForDoc(event, data) {
    let tagString = "";
    const latency = (
      (new Date().getTime() - this.state.startTime) /
      1000
    ).toPrecision(3);
    logEvent("buttons", "Prev hit");
    if (this.state.changesInSession > 0) {
      logEvent("buttons", "Labeled Data");
    }
    this.setState({ hitsCompleted: false });
    this.hitAddCallback(undefined, "Ok", "previous");
    return false;
  }

  setClassification(entity, event) {
    if (
      parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN ||
      parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR
    ) {
      event.preventDefault();
      const {
        currentTags,
        changesInSession,
        projectDetails,
        type,
        isAutosave,
      } = this.state;
      if (currentTags.has(entity)) {
        if (
          (type === "skipped" || type === "done" || type === "notDone") &&
          projectDetails.task_type === IMAGE_CLASSIFICATION &&
          currentTags &&
          currentTags.size === 1
        ) {
          this.setState({
            confirmModalVisible: true,
            fullScreen: false,
          });

          if (type === "notDone") {
            let isNotification = document.getElementsByClassName("ant-modal");
            if (isNotification.length === 0) {
              if (this.state.fullScreen) {
                this.state.fullScreen = false;
              }
              const that = this;
              const confirm = AntModal.confirm;
              confirm({
                title: "Confirmation",
                content:
                  "Unfortunately, there are no annotations. Please add annotation",
                okText: "OK",
                cancelText: "CANCEL",
                onOk() {
                  that.setState({ confirmModalVisible: false });
                },
                onCancel() {
                  that.setState({ confirmModalVisible: false });
                },
              });
            }
          }
        } else {
          currentTags.delete(entity);
        }
      } else {
        currentTags.add(entity);
      }
      this.stopAutoSave = false;
      this.setState({
        isAutosave: true,
        currentTags,
        changesInSession: changesInSession + 1,
      });
    }
  }

  getKeyboardShortcuts(shortcuts) {
    const shorts = Object.keys(shortcuts);
    let completeContent = "";
    if (shorts) {
      for (let index = 0; index < shorts.length; index++) {
        const content = convertKeyToString(shortcuts[shorts[index]]);
        completeContent =
          completeContent + shorts[index] + ":" + content + "\n";
      }
    }
    return completeContent;
  }

  getClassificationQuestions() {
    let renderArrs = [];
    let name = "";
    let displayName = "";
    let classification = this.state.classification;
    let displayNames = [];
    let renderArrsFromResponse = [];

    let response = [];
    for (let index = 0; index < classification.length; index++) {
      displayName = classification[index].displayName
        ? classification[index].displayName
        : classification[index].name;
      name = classification[index].name;
      let classes = classification[index].classes;
      renderArrs = [];
      for (let jindex = 0; jindex < classes.length; jindex++) {
        let checked = false;
        let selected = [];
        if (name in this.state.classificationResponse) {
          selected = this.state.classificationResponse[name];
        }
        if (selected.indexOf(classes[jindex]) > -1) {
          checked = true;
        }
        renderArrs.push(
          <Checkbox
            className="m-r-10 m-t-10"
            checked={checked}
            onChange={this.handleClassificationCheckBoxChange.bind(
              this,
              name,
              classes[jindex]
            )}
            label={classes[jindex]}
          />
        );
      }

      displayNames.push(displayName);
      response[displayName] = renderArrs;
    }

    if (displayNames && response) {
      displayNames.map((element) => {
        renderArrsFromResponse.push(
          <Form size="mini" style={styles.formStyle}>
            <label className="m-b-10" style={styles.fSize}>
              {element}
            </label>
            <div className="ant_box" style={styles.fWrap}>
              {response[element]}
            </div>
          </Form>
        );
      });

      if (renderArrsFromResponse.length > 0) {
        return <div style={styles.flexC}>{renderArrsFromResponse}</div>;
      }
    }
  }


  getUrl(type, contributorId, entity, evaluationType, commentFilter, from, to) {
    let url =
      "/projects/" +
      this.props.params.orgName +
      "/" +
      this.props.params.projectName +
      "/" +
      this.props.params.projectId +
      "/space?";
    if (type) {
      url = url + "type=" + type;
    }
    if (contributorId) {
      url = url + "&contributorId=" + contributorId;
    }
    if (entity) {
      url = url + "&label=" + entity;
    }
    if (evaluationType) {
      url = url + "&evaluationType=" + evaluationType;
    }
    if (commentFilter) {
      url = url + "&commentFilter =" + commentFilter;
    }
    if (from) {
      url = url + "&from=" + from;
    }
    if (to) {
      url = url + "&to=" + to;
    }
    return url;
  }

  setSelectedDate(cDate, dateType) {
    this.resetFilterState();
    let startDate = this.state.dateFrom;
    let endDate = this.state.dateTo;
    if (dateType === "start") {
      startDate = cDate ? fromDateTimestamp(cDate) : null;
    } else if (dateType === "end") {
      endDate = cDate ? toDateTimestamp(cDate) : null;
    }

    this.setState({
      dateFrom: startDate,
      dateTo: endDate,
    }, () => {
      this.loadProjectDetails(this.props.currentProject);
    });
  }

  onDateChange = (dateType, value) => {
    if (value !== null) {
      let cDate = value.toDate();
      this.setSelectedDate(cDate, dateType);
    } else {
      this.setSelectedDate(null, dateType);
    }
  };

  onStartChange = (value) => {
    this.setState({ startValue: value });
    this.onDateChange("start", value);
  };

  onEndChange = (value) => {
    this.setState({ endValue: value });
    this.onDateChange("end", value);
  };

  handleStartOK = (value) => {
    this.onDateChange("startValue", value);
  };

  handleEndOK = (value) => {
    this.onDateChange("endValue", value);
  };

  disabledStartDate = (startValue) => {
    const endValue = this.state.endValue;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  disabledEndDate = (endValue) => {
    const startValue = this.state.startValue;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  getHitStates() {
    const options = [];
    let selected = this.state.type;
    if (selected == "") {
      selected = "all";
    }
    options.push({
      text: "Done HITs",
      value: HIT_STATE_DONE,
      onClick: () => {
        this.resetFilterState(
          this.state.label,
          this.state.contributorId,
          this.filterCallBack.bind(this)
        );
        logEvent("buttons", "Select Done");
        this.state.type = HIT_STATE_DONE;
      },
    });
    options.push({
      text: "Not Done HITs",
      value: HIT_STATE_NOT_DONE,
      // callback
      onClick: () => {
        this.resetFilterState(
          this.state.label,
          this.state.contributorId,
          this.filterCallBack.bind(this)
        );
        logEvent("buttons", "Select Not Done");
        this.state.type = HIT_STATE_NOT_DONE;
      },
    });
    options.push({
      text: "Skipped HITs",
      value: HIT_STATE_SKIPPED,
      onClick: () => {
        logEvent("buttons", "Select Skipped");
        this.resetFilterState(
          this.state.label,
          this.state.contributorId,
          this.filterCallBack.bind(this)
        );
        this.state.type = HIT_STATE_SKIPPED;
      },
    });
    options.push({
      text: "Deleted HITs",
      value: HIT_STATE_DELETED,
      onClick: () => {
        logEvent("buttons", "Select Deleted");
        this.resetFilterState(
          this.state.label,
          this.state.contributorId,
          this.filterCallBack.bind(this)
        );
        this.state.type = HIT_STATE_DELETED;
 
      },
    });
    options.push({
      text: "Pre-Tagged HITs",
      value: HIT_STATE_PRE_TAGGED,
      onClick: () => {
        logEvent("buttons", "Select Pre-Tagged");
        this.resetFilterState(
          this.state.label,
          this.state.contributorId,
          this.filterCallBack.bind(this)
        );
        this.state.type = HIT_STATE_PRE_TAGGED;

      },
    });
    options.push({
      text: "Re-Tagging Queue",
      value: HIT_STATE_REQUEUED,
      onClick: () => {
        logEvent("buttons", "Select Re-queued");
        this.resetFilterState(
          this.state.label,
          this.state.contributorId,
          this.filterCallBack.bind(this)
        );
        this.state.type = HIT_STATE_REQUEUED;

      },
    });
    options.push({
      text: "Total Hits",
      value: HIT_STATE_ALL,
      onClick: () => {
        logEvent("buttons", "Select All");
        this.resetFilterState(
          this.state.label,
          this.state.contributorId,
          this.filterCallBack.bind(this)
        );
        this.state.type = HIT_STATE_ALL;
      },
    });
    return (
      <Dropdown
        value={selected}
        placeholder="Select State"
        selection
        options={options}
      />
    );
  }

  evaluationOnClick(evaluationType) {
    this.resetFilterState(this.state.label, this.state.contributorId);
    this.state.label = undefined;
    this.state.contributorId = undefined;
    this.state.currentStart = 0;
    if (evaluationType === "none") {
      this.state.evaluationType = HIT_EVALUATION_NE;
      if (this.state.currentHitNiri) {
        this.state.currentCount = this.props.projectDetails.totalHits / 2;
      } else {
        this.state.currentCount = this.props.projectDetails.totalHits;
      }
      this.loadProjectDetails(this.props.currentProject);
    } else if (evaluationType === "correct" || evaluationType === "incorrect") {
      this.state.evaluationType = evaluationType;
      if (this.state.currentHitNiri) {
        this.state.currentCount = this.props.projectDetails.totalHits / 2;
      } else {
        this.state.currentCount = this.props.projectDetails.totalHits;
      }
      this.loadProjectDetails(this.props.currentProject);
    } else if (evaluationType === "All") {
      this.state.evaluationType = undefined;
      this.loadProjectDetails(this.props.currentProject);
    } else {
      this.props.pushState(
        this.getUrl(
          this.state.type,
          undefined,
          undefined,
          evaluationType,
          undefined
        )
      );
    }
  }

  commentsOnClick(commentFilter) {
    this.resetFilterState(this.state.label, this.state.contributorId);
    this.state.label = undefined;
    this.state.currentStart = 0;
    if (commentFilter === "COMMENTED") {
      this.state.commentFilter = HIT_COMMENTED;
      this.loadProjectDetails(this.props.currentProject);
    } else if (commentFilter === "UN_COMMENTED") {
      this.state.commentFilter = HIT_UN_COMMENTED;
      this.loadProjectDetails(this.props.currentProject);
    } else if (commentFilter === "all") {
      this.state.commentFilter = undefined;
      this.loadProjectDetails(this.props.currentProject);
    } else {
      this.props.pushState(
        this.getUrl(
          this.state.type,
          undefined,
          undefined,
          undefined,
          commentFilter
        )
      );
    }
  }

  getEvaluations() {
    const options = [];
    let selected = this.state.evaluationType;
    if (!this.state.evaluationType) {
      selected = "All";
    }
    options.push({
      text: "Correct",
      value: "correct",
      onClick: () => {
        logEvent("buttons", "Select Correct");
        this.evaluationOnClick(HIT_EVALUATION_CORRECT);
      },
    });
    options.push({
      text: "Incorrect",
      value: "incorrect",
      onClick: () => {
        logEvent("buttons", "Select InCorrect");
        this.evaluationOnClick(HIT_EVALUATION_INCORRECT);
      },
    });
    options.push({
      text: "Not Evaluated",
      value: "none",
      onClick: () => {
        logEvent("buttons", "Select None");
        this.evaluationOnClick(HIT_EVALUATION_NE);
      },
    });
    options.push({
      text: "All",
      value: "All",
      onClick: () => {
        logEvent("buttons", "Select All Evaluation");
        this.evaluationOnClick("All");
      },
    });
    return (
      <div className="w-100">
        <label>EVALUATIONs STATUS</label>
        <Dropdown
          className="f_evaluation"
          compact
          value={selected}
          placeholder="Filter by Evaluation Status"
          selection
          options={options}
        />
      </div>
    );
  }

  getCommentsByFilter() {
    const options = [];
    let selected = this.state.commentFilter;
    if (!this.state.commentFilter) {
      selected = "all";
    }
    options.push({
      text: "Commented",
      value: "COMMENTED",
      onClick: () => {
        logEvent("buttons", "Select Commented");
        this.commentsOnClick(HIT_COMMENTED);
      },
    });
    options.push({
      text: "Uncommented",
      value: "UN_COMMENTED",
      onClick: () => {
        logEvent("buttons", "Select Un-Commented");
        this.commentsOnClick(HIT_UN_COMMENTED);
      },
    });
    options.push({
      text: "All",
      value: "all",
      onClick: () => {
        logEvent("buttons", "Selected All in Comments Section");
        this.commentsOnClick(HIT_COMMENTS_All);
      },
    });
    return (
      <div className="w-100">
        <label>COMMENTS</label>
        <Dropdown
          className="f_evaluation"
          compact
          value={selected}
          placeholder="Filter by Comments"
          selection
          options={options}
        />
      </div>
    );
  }

  filterCallBack() {
    console.log("filtering all", this.state, this.props);
    if (this.state.currentHitNiri) {
      this.state.currentCount = this.props.projectDetails.totalHits / 2;
    } else {
      this.state.currentCount = this.props.projectDetails.totalHits;
    }
    this.loadProjectDetails(this.props.currentProject);
  }
  getContributors(contributorDetails, selectedHits) {
    const options = [];
    let selected = this.state.contributer ? this.state.contributer : "all";
    if (contributorDetails) {
      for (
        let index = 0;
        index < contributorDetails.length && index < 50;
        index++
      ) {
        if (
          this.state.contributorId === contributorDetails[index].userDetails.uid
        ) {
          selected = contributorDetails[index].userDetails.uid;
        }
        options.push({
          text: contributorDetails[index].userDetails.firstName
            ? contributorDetails[index].userDetails.firstName
            : "",
          value: contributorDetails[index].userDetails.uid
            ? contributorDetails[index].userDetails.uid
            : "",
          image: {
            avatar: true,
            src: contributorDetails[index].userDetails.profilePic
              ? contributorDetails[index].userDetails.profilePic
              : "",
          },
          onClick: () => {
            logEvent("buttons", "Select Contributor");
            this.setState({
              contributorName: contributorDetails[index].userDetails.firstName,
            });
            this.resetFilterState(
              this.state.label,
              contributorDetails[index].userDetails.uid,
              this.filterCallBack.bind(this)
            );
          },
        });
      }
      options.push({
        text: "All",
        value: "all",
        onClick: () => {
          this.setState({ contributorName: undefined });
          this.resetFilterState(
            this.state.label,
            undefined,
            this.filterCallBack.bind(this)
          );
        },
      });
      return (
        <div className="w-100">
          <label>CONTRIBUTOR</label>
          <Dropdown
            value={selected}
            placeholder="Filter by Contributor"
            selection
            options={options}
          />
        </div>
      );
    }
  }

  // updated for status
  getHitInfo(hit) {
    if (hit) {
      const { task_type } = this.props.projectDetails;
      const fileName = hit.fileName;
      const { styles } = helper;
      let status = hit.status;
      let convId = hit.convId;
      let isjson = isJSON(hit.result);
      if (hit.status === "reQueued") {
        status = hit.status;
      } else if (status === "skipped") {
        status = "skipped";
      } else if (hit.result && isjson && JSON.parse(hit.result).length === 0) {
        status = "notDone";
      } else if (
        hit.result &&
        isjson &&
        JSON.parse(hit.result).labels &&
        JSON.parse(hit.result).labels.length === 0
      ) {
        status = "notDone";
      } else {
        status = hit.status;
      }
      return (
        <div style={styles.fCenter} className="y-txt">
          <div className="project_info">
            {fileName &&
              task_type != POS_TAGGING_GENERIC &&
              task_type != TEXT_MODERATION &&
              task_type != POS_TAGGING && (
                <div>
                  <label>File Name</label>
                  <p
                    title={fileName}
                    className={fileName.length > 15 ? "info-project-guide" : ""}
                  >
                    {fileName.replaceAll("__-__-", " ")}
                  </p>
                </div>
              )}
          </div>
          <div className="project_info">
            {status && (
              <div>
                <label>HIT status</label>
                <p title="HIT status" style={styles.tCapse}>
                  {hitStateNameMap[status]}
                </p>
              </div>
            )}
          </div>

          <div className="project_info">
            {
              <div>
                <label>Page</label>
                <p title="Page">
                  {this.state.currentIndex +
                    1 +
                    " of " +
                    this.state.hits.length}
                </p>
              </div>
            }
          </div>

          <div className="project_info">
            {hit.convId && (
              <div>
                <label>Conversation Id</label>
                <div className="link_copy">
                  <p title="Conversation Id">{convId}</p>
                  <Icon
                    name="copy"
                    title="Copy Conversation Id to clipboard"
                    onClick={() => this.copyConvId(convId)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }
  }
  copyConvId(copyText) {
    var textArea = document.createElement("textarea");
    textArea.value = copyText;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();
    message.info("Conversation Id copied to clipboard.", 2);
  }

  getAnnotatorInfo(hit) {
    if (hit && hit.hitResults) {
      const updatedtime =
        hit.hitResults[0].updatedTimestamp ||
        hit.hitResults[0].updated_timestamp;
      let userName = hit.hitUserName;
      let userEmail = hit.hitUserEmail;
      let hitEvaluatedBy = "";
      if (this.state.contributorDetailsMap) {
        const hr = hit.hitResults[0];
        userName =
          this.state.contributorDetailsMap[hr.userId] &&
          this.state.contributorDetailsMap[hr.userId].firstName
            ? this.state.contributorDetailsMap[hr.userId].firstName
            : "";
        userEmail =
          this.state.contributorDetailsMap[hr.userId] &&
          this.state.contributorDetailsMap[hr.userId].email
            ? this.state.contributorDetailsMap[hr.userId].email
            : "";
      }
      if (hit.hitEvaluatedBy) {
        hitEvaluatedBy = hit.hitEvaluatedBy;
      }
      this.setState({
        hitUserName: userName,
        hitUserEmail: userEmail,
        hitFileName: hit.fileName,
        hitStatus: hit.status,
        hitEvaluation: hit.evaluation,
        hitConvId: hit.convId,
        hitUpdatedTime: dateTimeConverter(updatedtime),
        hitEvaluatedBy,
      });
    }
  }

  selectCategory = (value, label, event) => {
    logEvent("buttons", "Select Entity");
    if (value === "all") {
      this.state.label = undefined;
    } else {
      this.state.label = label[0];
    }
    this.resetFilterState(
      value === "all" ? undefined : label[0],
      this.state.contributorId,
      this.filterCallBack.bind(this)
    );
  };

  getEntities = (entities) => {
    const options = [];
    let selected = "All";
    if (
      this.state.projectDetails.task_type === DOCUMENT_ANNOTATION &&
      entities
    ) {
      return (
        <FilterEntityTree
          onSelect={(value, label, event) => this.selectCategory(value, label, event)}
          entities={entities}
          label={this.state.label}
        />
      );
    } else {
      if (entities) {
        for (let index = 0; index < entities.length; index++) {
          if (this.state.label === entities[index]) {
            selected = entities[index];
          }
          options.push({
            text: entities[index],
            value: entities[index],
            onClick: () => {
              this.state.loadFilterImages = true;
              this.state.imageLoad = true;
              this.resetFilterState(
                entities[index],
                this.state.contributorId,
                this.filterCallBack.bind(this)
              );
              logEvent("buttons", "Select Entity");

            },
          });
        }
        options.push({
          text: "All",
          value: "All",
          onClick: () => {
            this.resetFilterState(
              undefined,
              this.state.contributorId,
              this.filterCallBack.bind(this)
            );
          },
        });
        return (
          <div className="w-100">
            <label>TAGGED ENTITY</label>
            <Dropdown
              value={selected}
              placeholder="Filter by Entity"
              selection
              options={options}
            />
          </div>
        );
      }
    }
  };

  getClassificationLabels = (entities) => {
    const options = [];
    let selected = this.state.classificationLabel;
    let classificationLabels = [];
    if (entities) {
      classificationLabels = entities[0].classes;

      for (let index = 0; index < classificationLabels.length; index++) {
        if (this.state.classificationLabel === classificationLabels[index]) {
          selected = classificationLabels[index];
        }
        options.push({
          text: classificationLabels[index],
          value: classificationLabels[index],
          onClick: () => {
            this.resetFilterState(
              undefined,
              this.state.contributorId,
              this.filterCallBack.bind(this)
            );
            this.setState(
              { classificationLabel: classificationLabels[index] },
              () => {
                this.loadProjectDetails(this.props.currentProject);
              }
            );
          },
        });
      }
      options.push({
        text: "None",
        value: "None",
        onClick: () => {
          this.resetFilterState(
            undefined,
            this.state.contributorId,
            this.filterCallBack.bind(this)
          );
          this.setState(
            { searchHitUrl: null, classificationLabel: "None" },
            () => {
              this.loadProjectDetails(this.props.currentProject);
            }
          );
        },
      });
      options.push({
        text: "All",
        value: "All",
        onClick: () => {
          this.state.classificationLabel = undefined;
          this.resetFilterState(
            undefined,
            this.state.contributorId,
            this.filterCallBack.bind(this)
          );
        },
      });
      return (
        <div className="w-100">
          <label>CLASSIFICATION LABEL</label>
          <Dropdown
            value={selected}
            placeholder="Filter by Entity"
            selection
            options={options}
          />
        </div>
      );
    }
  };

  getCurrentResult() {
    if (this.state.projectDetails.task_type === POS_TAGGING) {
      let tagString = "";
      for (let index = 0; index < this.state.words.length; index++) {
        if (
          this.state.taggedEntity[index] &&
          this.state.taggedEntity[index] !== "__"
        ) {
          tagString =
            tagString +
            " " +
            this.state.words[index] +
            "____" +
            this.state.taggedEntity[index];
        } else {
          tagString = tagString + " " + this.state.words[index];
        }
      }
      return tagString.trim();
    } else if (
      this.state.projectDetails.task_type === TEXT_SUMMARIZATION ||
      this.state.projectDetails.task_type === TEXT_MODERATION
    ) {
      const result = {};
      console.log(result.note);
      console.log(result.note);
      result.note = this.state.textSummary;
      console.log("check stringify 2503", result);
      return JSON.stringify(result);
    } else if (
      this.state.projectDetails.task_type === TEXT_CLASSIFICATION ||
      this.state.projectDetails.task_type === IMAGE_CLASSIFICATION ||
      this.state.projectDetails.task_type === VIDEO_CLASSIFICATION ||
      this.state.projectDetails.task_type === POS_TAGGING_GENERIC 
    ) {
      console.log("Entered in getCurrentResult doc anno")
      const preTags = this.state.currentPredTags || [];
      let tags = [];
      if (this.state.currentHit.status === "notDone") {
        tags = [...this.state.currentTags, ...preTags];
      } else {
        tags = [...this.state.currentTags];
      }
      const result = {};
      result.labels = tags.filter(this.onlyUnique);
      result.note = this.state.currentNote;
      console.log("check stringify 2520", result);
      return JSON.stringify(result);
    } else if (
      this.state.projectDetails.task_type === IMAGE_BOUNDING_BOX ||
      this.state.projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX ||
      this.state.projectDetails.task_type === VIDEO_BOUNDING_BOX ||
      this.state.projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX_V2 ||
      this.state.projectDetails.task_type === IMAGE_SEGMENTATION ||
      this.state.projectDetails.task_type === IMAGE_MASKING ||
      this.state.projectDetails.task_type === DOCUMENT_ANNOTATION
    ) {
      var boundingBoxMap = this.state.boundingBoxMap;
      boundingBoxMap = boundingBoxMap.map((data) => {
        data.entities = this.state.entities;
        return data;
      });
      if (
        this.state.projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX ||
        this.state.projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX_V2 ||
        this.state.projectDetails.task_type === IMAGE_SEGMENTATION ||
        this.state.projectDetails.task_type === IMAGE_MASKING ||
        this.state.projectDetails.task_type === IMAGE_BOUNDING_BOX
      ) {
        if (
          boundingBoxMap.imageWidth &&
          boundingBoxMap.imageHeight &&
          (boundingBoxMap.imageWidth === 0 || boundingBoxMap.imageHeight === 0)
        ) {
          if (window.Raven) {
            window.Raven.captureException(
              "Error while annotating image, hit is :  " +
                JSON.stringify(this.state.currentHit)
            );
          }
        }
        if (boundingBoxMap.color) {
          if (window.Raven) {
            window.Raven.captureException(
              "Error while annotating image, color miss:  " +
                JSON.stringify(this.state.currentHit)
            );
          }
        }
      }
      let resultString = "";
      if (this.state.classification && this.state.classification.length > 0) {
        const classificationResult = getClassificationResult(
          this.state.classificationResponse
        );
        const resultObject = {
          classificationResult,
          annotationResult: this.getAnnotationResult(boundingBoxMap),
        };
        resultString = JSON.stringify(resultObject);
      } else {
        const resultObject = {
          annotationResult: this.getAnnotationResult(boundingBoxMap),
        };

        resultString =
          this.state.projectDetails.task_type === DOCUMENT_ANNOTATION ||
          this.state.projectDetails.task_type === POS_TAGGING_GENERIC
            ? JSON.stringify(resultObject)
            : JSON.stringify(boundingBoxMap);
      }
      return resultString;
    }
  }

  getAnnotationResult(boundingBoxMap) {
    let resultObject = boundingBoxMap;
    if ("annotationResult" in resultObject) {
      resultObject = resultObject.annotationResult;
      resultObject = this.getAnnotationResult(resultObject);
    }
    return resultObject;
  }

  continuousTaggingSelected = () => {
    logEvent("buttons", "Hide Labels Toggle", !this.state.continuousTagging);
    if (this.state.continuousTagging) {
      this.setState({ continuousTagging: false });
    } else {
      this.setState({ continuousTagging: true });
    }
  };

  mergingGroupLabelButtonSwitch = (value) => {
    this.setState({ mergeGroupLabel: value });
    console.log("Value of the parrameter is", this.state.mergeGroupLabel);
  };

  resetFilterState(label, contributor, callback) {
    this.setState(
      {
        hits: [],
        hitsNiri: [],
        checkHits: [],
        niriFilteredHits: null,
        currentIndex: -1,
        currentStart: 0,
        start: 0,
        currentHit: undefined,
        currentCount: this.state.DEFAULT_COUNT,
        hitsCompleted: false,
        changesInSession: 0,
        selecteddate: undefined,
        searchHitUrl: null,
        label: label,
        contributorId: contributor,
        fromHitList: false,
        classificationLabel: this.state.classificationLabel,
      },
      callback ? callback : null
    );
  }

  saveElementCallback(err, response) {
    if (!err) {
      this.setState({
        currentIndex: this.state.currentIndex - 1,
        currentHit: undefined,
        hitsCompleted: false,
        changesInSession: 0,
        undoButton: true,
        redoButton: true,
        clearButton: true,
      });
      this.loadProjectDetails(this.props.currentProject);
    } else {
      this.props.setloaderMenu(false);
      this.setState({ loading: false });
      captureException(err);
    }
  }

  saveAnnotationCallback(err, response) {
    this.props.setloaderMenu(false);
    this.setState({ loading: false, undoButton: true, redoButton: true });
    if (err) {
      captureException(err);
      showAlert("Document save is failed.", "error");
    } else {
      if (response && response.body.hits && response.body.hits.length >= 1) {
        let existingHits = this.state.hits;
        let index = existingHits.findIndex(
          (hit) => hit.id === response.body.hits[0].id
        );
        existingHits[index] = response.body.hits[0];
        existingHits[index].annotationSaved = true;
        this.setState({
          hits: existingHits,
        });

        const { currentHit } = this.state;
        currentHit.annotationSaved = true;
        this.setState({
          currentHit,
        });
        if (
          this.state.projectDetails.task_type === DOCUMENT_ANNOTATION ||
          this.state.projectDetails.task_type === POS_TAGGING_GENERIC
        )
          showAlert("Document is saved.");
      }
    }
  }

  evaluationCallbackNiri(value, err, response) {
    console.log("evaluationCallbackNiri", response);
  }

  evaluationCallback(value, err, response) {
    if (!err) {
      this.props.setloaderMenu(false);
      const { currentHit, hits, currentIndex } = this.state;
      currentHit.evaluation = value;
      hits[currentIndex].evaluation = value;

      if (response && response.body) {
        const evaluationResult = response.body;
        currentHit.hitEvaluatedBy = evaluationResult.evaluatedBy;
        hits[currentIndex].hitEvaluatedBy = evaluationResult.evaluatedBy;
      }

      this.setState(
        {
          loading: false,
          currentHit,
          hits,
          hitEvaluation: value.toUpperCase(),
        },
        () => {
          this.getAnnotatorInfo(currentHit);
        }
      );
    } else {
      this.setState({ loading: false });
      captureException(err);
    }
  }

  moveToDoneCallbackNiri = (err, response) => {
    console.log("moveToDoneCallbackNiri", response);
  };

  moveToDoneCallback = (err, response) => {
    if (!navigator.onLine || response.statusCode != 200) {
      this.setState({ fullScreen: false });
      showAlert(
        "Looks like your internet connection is not stable. Please save your work and refresh the application",
        "warning"
      );
    } else if (err) {
      this.setState({ fullScreen: false });
      showAlert("Failed to save changes. Please try again", "warning");
    } else {
      this.stopAutoSave = false;
      if (!err) {
        if (
          this.state.projectDetails.task_type === IMAGE_MASKING &&
          this.state.currentHit.status !== HIT_STATE_NOT_DONE &&
          this.action !== undefined
        ) {
          uploadGrayScaleImage(
            this.props.currentProject,
            this.state.currentHit.id
          );
          imageMaskCreation(
            this.props.projectDetails["id"],
            this.props.projectDetails["task_type"],
            this.state.currentHit["id"],
            this.imageCreationCallback
          );
        }
        if (
          this.state.projectDetails.task_type === IMAGE_SEGMENTATION &&
          this.state.currentHit.status === HIT_STATE_DONE
        ) {
          imageMaskCreation(
            this.props.projectDetails["id"],
            this.props.projectDetails["task_type"],
            this.state.currentHit["id"],
            this.imageCreationCallback
          );
        }
        if (response && response.body && response.body.hits) {
          console.log("status code text", response);
          let hit = response.body.hits;
          let allHits = this.state.hits;
          if (allHits.length > 0) {
            var matchIndex = allHits.findIndex(
              (hitItem) => hitItem.id === hit[0].id
            );
            allHits[matchIndex] = hit[0];
            this.setState(
              { hits: allHits, projectDetails: response.body.projectDetails },
              () => {
                this.getAnnotatorInfo(hit[0]);
              }
            );
          }
        }
        this.props.setloaderMenu(false);
        this.setState({ loading: false });
        if (this.state.newEntities && this.state.newEntities.length > 0) {
          logEvent("buttons", "New Entities");
          const { taskRules } = this.props.projectDetails;
          const rulesJson = JSON.parse(taskRules);
          rulesJson.tags = [
            ...this.state.entities,
            ...this.state.newEntities,
          ].join(",");
          editProject(
            this.props.currentProject,
            { rules: JSON.stringify(rulesJson) },
            this.projectEditedCallback.bind(this)
          );
          this.props.setloaderMenu(true);
          this.setState({ loading: true, newEntities: [] });
        }
        this.setState({ isCompletedRequest: true });
        console.log("this.autosave", this.state.isAutosave);
        this.setState({ buttonDisabled: false, opacityValue: false });
        if (!this.state.isAutosave && this.action !== undefined) {
          const chits = this.state.hits;
          this.processHitCallBackResponse(
            this.state.currentHit,
            chits,
            this.state.currentIndex,
            true
          );
          console.log("next page1");
        } else {
          const chits = this.state.hits;
          this.processHitCallBackResponse(
            this.state.currentHit,
            chits,
            this.state.currentIndex,
            true
          );
          console.log("nextest page");
        }
        const {
          totalHitsVal,
          currentIndexValue,
          type,
          currentIndex,
          changesInSession,
          currentHit,
          hits,
          totalHits,
          totalFilteredHits,
        } = this.state;
        const isLastPage = hits[currentIndex] === undefined;
        const isLastPage1 =
          this.props.projectDetails.totalHitsDone - totalFilteredHits;
        if (
          this.action === "moveToDone" &&
          (type === "done" || type === "all") &&
          isLastPage &&
          isLastPage1 === 10 &&
          this.state.niriFilteredHits === 0
        ) {
          const nextHitsVal = totalHitsVal - 10;
          this.setState({ imageLoad: true });
          this.loadProjectDetails(
            this.props.currentProject,
            nextHitsVal,
            totalHitsVal
          );
          this.setState({
            totalHitsVal: nextHitsVal,
            currentIndexValue: currentIndexValue + 10,
          });
        }
        let indexval = hits[currentIndex] === undefined;
        const isLastPage2 = this.props.projectDetails.totalHitsDone - totalHits;
        const isLastPage3 = isLastPage2 + 10;
        if (
          this.action === "moveToDone" &&
          (type === "done" || type === "all") &&
          this.state.niriFilteredHits !== 0 &&
          indexval &&
          isLastPage3 < this.props.projectDetails.totalHitsDone
        ) {
          const nextHitsVal1 = totalHitsVal - 10;
          this.setState({ imageLoad: true });
          this.loadProjectDetails(
            this.props.currentProject,
            nextHitsVal1,
            totalHitsVal
          );
          this.setState({
            totalHitsVal: nextHitsVal1,
            currentIndexValue: currentIndexValue + 10,
          });
        }
      } else {
        this.props.setloaderMenu(false);
        this.setState({ loading: false });
        if (
          response &&
          response.body &&
          response.body.code &&
          response.body.code === 401
        ) {
          this.props.setloaderMenu(false);
          refreshUidToken(() => {
            this.setState({ loading: false });
          });
        } else {
          captureException(err);
        }
      }
    }
  };

  // labelUpdatedCallback
  // Callback method for update and delete label

  labelUpdatedCallback(callback, err, response) {
    this.stopAutoSave = false;
    if (!err) {
      this.props.setloaderMenu(false);
      this.setState({ loading: false, maskingLables: [] });
      const responseData = response.body;
      const taskLabels = responseData
        ? JSON.parse(responseData.taskRules)
        : { tags: "" };
      // For displaying entity with color in right side list
      const enitity = taskLabels.tags;
      let entityColorMap = {};
      let hitEntities = {};
      let hitsDatas = {};
      let currentHitResult = this.state.currentHit;
      let tagsEntitiy = enitity.toString();
      if (enitity !== "") {
        tagsEntitiy = enitity.toString().split(",");
      }

      this.setState(
        { entities: tagsEntitiy, newLabelEntity: tagsEntitiy },
        callback(true)
      );

      if (responseData.createUserDefinedColor) {
        entityColorMap = getImageMaskEntityColorMap(taskLabels.coloredTags);
      } else {
        entityColorMap = createDocEntityColorMap(tagsEntitiy, ENTITY_COLORS);
        this.setState({ entityColorMap: entityColorMap });
        if (
          this.state.currentHit.result &&
          isJSON(this.state.currentHit.result)
        ) {
          hitsDatas = createHitJson(
            this.state.currentHit.result,
            entityColorMap
          );
          hitEntities = hitsDatas.hitEntit;
          currentHitResult.result = JSON.stringify(hitsDatas.hits);
          let newModifyDatapack = JSON.parse(this.state.currentHit.result);
        } else if (
          this.state.hits &&
          this.state.hits.length > 0 &&
          this.state.hits.hitResults && this.state.hits.hitResults.length > 0 &&
          this.state.hits[0].hitResults[0].result &&
            isJSON(this.state.hits[0].hitResults[0].result)
        ) {
          hitEntities = createHitJson(
            this.state.hits[0].hitResults[0].result,
            entityColorMap
          );
        }
      }

      // For displaying keyboardshortcuts below the label
      if (responseData.task_type !== "null") {
        let shortcuts = getDetaultShortcuts(
          responseData.task_type,
          tagsEntitiy
        );
        let projectDetails = response.body;
        const rules = JSON.parse(projectDetails.taskRules);
        if ("shortcuts" in rules) {
          shortcuts = rules.shortcuts;
        }
        this.setState({
          entityColorMap: entityColorMap,
          newLabelUpdate: false,
          labelValue: "",
          shortcuts: shortcuts,
          entities: tagsEntitiy,
          hitEntities: hitEntities,
          currentHit: currentHitResult,
        });
      }
    } else {
      if (
        response &&
        response.body &&
        response.body.code &&
        response.body.code === 401
      ) {
        this.props.setloaderMenu(false);
        refreshUidToken(() => {
          this.setState({ loading: false });
        });
      } else {
        captureException(err);
      }
    }
  }

  setAutoSaveProp(isAutosave) {
    this.state.isAutosave = isAutosave;
    this.setState({ isAutosave: isAutosave });
  }

  setAutoSaveAction(action) {
    if (!navigator.onLine) {
      this.setState({ fullScreen: false });
      showAlert(
        "Looks like your internet connection is not stable. Please save your work and refresh the application",
        "warning"
      );
    }
    this.state.autoSaveAction = action;
    this.setState({ autoSaveAction: action });
  }

  callBackButton(action, data) {
    console.log("check moveToDone only 2880");
    this.moveToDone();
  }

  // updated today_abi
  // check current tag and type of project before saving it
  moveAll(action) {
    this.setState({ fullScreen: false });
    if (action == "saveAllToDone") {
      const confirm = AntModal.confirm;
      confirm({
        title: "Confirmation",
        content: "Check whether all are preAnnotated images before proceeding?",
        okText: "OK",
        cancelText: "CANCEL",
        onOk: () => {
          this.polyAnnotator.moveAllToDoneAction();
        },
        onCancel() {
          return false;
        },
      });
    }
  }

  moveToDone(action, e) {
    if (undefined !== e) e.preventDefault();
    this.stopAutoSave = true;
    this.setState({ updateAnnotatorInfo: true });
    this.saveMaskingNewLabels();
    const {
      currentHit,
      changesInSession,
      currentTags,
      type,
      projectDetails,
      boundingBoxMap,
      currentPredTags,
    } = this.state;
    if (
      action !== "autoSaveToDone" &&
      !this.state.confirmModalVisible &&
      (((projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX_V2 ||
        projectDetails.task_type === IMAGE_SEGMENTATION ||
        projectDetails.task_type === IMAGE_MASKING) &&
        boundingBoxMap &&
        boundingBoxMap.length === 0) ||
        (projectDetails.task_type === IMAGE_CLASSIFICATION &&
          currentTags &&
          currentTags.size === 0))
    ) {
      if (this.state.fullScreen) {
        this.state.fullScreen = false;
      }
      const that = this;
      const confirm = AntModal.confirm;
      confirm({
        title: "Confirmation",
        content:
          "Unfortunately, there are no annotations. Please add annotation",
        okText: "OK",
        cancelText: "CANCEL",
        onOk() {
          that.setState({ confirmModalVisible: false });
        },
        onCancel() {
          that.setState({ confirmModalVisible: false });
        },
      });
    } else if (projectDetails.task_type === VIDEO_BOUNDING_BOX) {
      for (let index = 0; index < boundingBoxMap.length; index++) {
        if (boundingBoxMap[index] !== undefined) {
          if (
            boundingBoxMap[index].endTime !== undefined &&
            boundingBoxMap[index].positions !== undefined
          )
            boundingBoxMap[index].endTime =
              boundingBoxMap[index].positions[
                boundingBoxMap[index].positions.length - 1
              ].time;
        }
      }
      this.moveToDoneAction(action);
    } else if (
      this.state.classification &&
      this.state.classification.length > 0
    ) {
      if (
        Object.keys(this.state.classificationResponse).length === 0 &&
        !this.state.isAutosave
      ) {
        if (this.state.fullScreen) {
          this.state.fullScreen = false;
        }
        const that = this;
        const confirm = AntModal.confirm;
        confirm({
          title: "Confirmation",
          content:
            "There are no classifications selected. Do you want to proceed?",
          okText: "OK",
          cancelText: "CANCEL",
          onOk() {
            that.moveToDoneAction(action);
          },
          onCancel() {},
        });
      } else {
        console.log("check moveToDoneAction 2956");
        this.moveToDoneAction(action);
      }
    } else if (projectDetails.task_type === VIDEO_CLASSIFICATION) {
      if (this.state.currentTags && this.state.currentTags.size > 0) {
        console.log("check moveToDoneAction 2962");
        this.moveToDoneAction(action);
      } else {
        const that = this;
        const confirm = AntModal.confirm;
        confirm({
          title: "Confirmation",
          content:
            VIDEO_ANNOTATOR_CONSTANTS.MOVE_TO_DONE_CLASSIFICATION_CONFIRM_MESSAGE,
          okText: "OK",
          cancelText: "CANCEL",
          onOk() {
            that.moveToDoneAction(action);
          },
          onCancel() {},
        });
      }
    } else {
      console.log("check moveToDoneAction 2977");
      this.moveToDoneAction(action);
    }
  }

  drawPolyline(result) {
    const labelList = JSON.parse(result);
    const annotation = labelList["label"] || labelList;
    const classificationResult =
      labelList["classificationResult"] || labelList["annotationResult"];
    if (!classificationResult) {
      annotation.forEach((anno, j) => {
        if (anno.points.length == 0) {
          delete annotation[j];
        }
      });
      var annotationResult = annotation.filter((elm) => elm);
      let labelResult = JSON.stringify(annotationResult);
      return labelResult;
    } else {
      return result;
    }
  }

  checkResults = (changesInSession, currentHit) => {
    const { selectShape } = this.state;
    console.log("changesInser", this.state);
    console.log("check checkResults rrr", changesInSession, currentHit);
    let result = "";
    if (
      changesInSession > 0 ||
      this.state.projectDetails.task_type === IMAGE_MASKING ||
      this.state.projectDetails.task_type === IMAGE_SEGMENTATION ||
      (this.state.currentPredTags && this.state.currentPredTags.size > 0)
    ) {
      console.log("Inside getCuerrent");
      result = this.getCurrentResult();
      console.log("Inside getCuerrent", result);
    } else if (currentHit.result && currentHit.result.length > 0) {
      result = currentHit.result;
    } else if (currentHit.hitResults && currentHit.hitResults.length > 0) {
      result = currentHit.hitResults[0].result;
    }
    console.log("result", result);
    if (selectShape === "polyline" || selectShape === "polygon") {
      result = this.drawPolyline(result);
    }
    return result;
  };

  domToImagemoveToDoneAction(action) {
    const { currentHit, changesInSession, projectDetails } = this.state;
    console.log("currentHit 2993", currentHit);
    if (this.state.confirmModalVisible) {
      this.removeLastAnnotation();
    }
    let autoFlag = false;
    let clearAllFlag = false;
    const result = this.checkResults(changesInSession, currentHit);
    this.state.currentHit.result = result;
    if (action === "autoSaveToDone") {
      action = "saveToDone";
      autoFlag = true;
    }
    console.log("check moveToDone only 3011");
    this.state.action = action ? action : "moveToDone";
    this.state.changesInSession = 0;
    this.props.setloaderMenu(false);
    this.setState({ loading: false, action: action ? action : "moveToDone" });
    if (
      projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX ||
      projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX_V2 ||
      projectDetails.task_type === IMAGE_SEGMENTATION ||
      projectDetails.task_type === IMAGE_MASKING ||
      projectDetails.task_type === IMAGE_BOUNDING_BOX ||
      projectDetails.task_type === IMAGE_CLASSIFICATION ||
      projectDetails.task_type === DOCUMENT_ANNOTATION
    ) {
      if (action === "clearAllImageAnnotations") {
        console.log("check moveToDone only 3026");
        this.state.action = "moveToDone";
        this.setState({ action: "moveToDone" });
        this.clearAllImageAnnotatios();
      } else if (action === "clearAllDoneImageAnnotations") {
        console.log("check moveToDone only 3032");
        this.setState({ action: "moveToDone" });
        clearAllFlag = true;
        this.clearAllImageAnnotatios();
      } else {
        if (this.state.isCompletedRequest) {
          this.setState({ isCompletedRequest: false }, () => {
            this.drawImageAutoSaveAction(autoFlag, result);
          });
        }
      }
    } else {
      if (
        this.state.type === "notDone" &&
        this.state.projectDetails.task_type === TEXT_CLASSIFICATION
      )
        this.saveTextClassificationPredictionLabels();
      else if (
        this.state.type === "notDone" &&
        this.state.projectDetails.task_type === POS_TAGGING
      )
        this.savePosTaggingPredictionLabels();
      this.state.currentHit.status = "done";
      console.log("my result" + result);
      console.log("check hitstatus 3044");
      updateHitStatus(
        currentHit.id,
        this.props.currentProject,
        HIT_STATE_DONE,
        result,
        this.moveToDoneCallback
      );
      this.checkforNERMLLabels(this.state.projectDetails, currentHit);
      if (this.state.newLabelUpdate) {
        let labelvalue = { labelName: this.state.labelValue };
        updateNewLabelName(
          currentHit.id,
          this.props.currentProject,
          labelvalue,
          NEW_LABEL_UPDATE,
          this.labelUpdatedCallback.bind(this, () => {})
        ); // Saving new Label Name
      }
    }
    if (clearAllFlag) {
      deleteAnnotatedImagesFromS3(
        this.props.currentProject,
        currentHit.id,
        () => {}
      );
    }
  }

  moveToDoneAction(action) {
    console.log("movetodone called ");
    this.action = action;
    this.stopAutoSave = true;
    logEvent("buttons", "Done");
    logEvent("Mark As", "Done");
    if (
      (this.props.projectDetails.task_type === IMAGE_MASKING &&
        action !== "autoSaveToDone" &&
        action !== "clearAllDoneImageAnnotations") ||
      (this.props.projectDetails.task_type === IMAGE_SEGMENTATION &&
        action !== "autoSaveToDone" &&
        action !== "clearAllDoneImageAnnotations")
    ) {
      console.log(
        "this.statu",
        this.state.currentHit.status,
        this.state.retainAnnotations
      );
      this.mergingGroupLabelButtonSwitch(false);
      console.log("movedone called7");
      this.callMovetoDone();
    } else if (this.props.projectDetails.task_type === VIDEO_CLASSIFICATION) {
      this.setState(
        { buttonDisabled: false, opacityValue: false, isAutosave: false },
        () => {
          console.log("check domToImagemoveToDoneAction 3071");
          this.domToImagemoveToDoneAction(this.action);
        }
      );
    } else {
      console.log("check domToImagemoveToDoneAction 3076");
      this.domToImagemoveToDoneAction(this.action);
    }
  }

  drawImageAutoSaveAction(autoFlag, result) {
    console.log("auto", autoFlag, result);
    const {
      currentHit,
      currentHitNiri,
      changesInSession,
      currentTags,
      type,
      projectDetails,
      boundingBoxMap,
      currentPredTags,
    } = this.state;
    const { task_type } = this.props.projectDetails;
    // for popup to remove lat annotation in skipped

    if (this.state.retainAnnotations && this.state.changesInSession === 0) {
      result = this.getCurrentResult();
      autoFlag = true;
    }

    if (
      autoFlag &&
      (this.state.currentHit.status === "re-Queued" ||
        this.state.currentHit.status === "re-Deleted" ||
        this.state.currentHit.status === "re-Skipped") &&
      (this.state.type === "skipped" || this.state.type === "done")
    ) {
      autoFlag = false;
      if (this.state.currentHit.status === "re-Queued") {
        this.state.currentHit.status = "reQueued";
        updateHitStatus(
          currentHit.id,
          this.props.currentProject,
          HIT_STATE_REQUEUED,
          result,
          this.moveToDoneCallback.bind(this)
        );
      } else if (this.state.currentHit.status === "re-Skipped") {
        this.state.currentHit.status = "skipped";
        updateHitStatus(
          currentHit.id,
          this.props.currentProject,
          HIT_STATE_SKIPPED,
          result,
          this.moveToDoneCallback.bind(this)
        );
      } else {
        this.state.currentHit.status = "deleted";
        updateHitStatus(
          currentHit.id,
          this.props.currentProject,
          HIT_STATE_DELETED,
          result,
          this.moveToDoneCallback.bind(this)
        );
      }
    } else if (autoFlag && this.state.type === "skipped") {
      autoFlag = false;
      this.state.currentHit.status = "skipped";
      updateHitStatus(
        currentHit.id,
        this.props.currentProject,
        HIT_STATE_SKIPPED,
        result,
        this.moveToDoneCallback.bind(this)
      );
    } else if (autoFlag && this.state.type === "deleted") {
      autoFlag = false;
      this.state.currentHit.status = "deleted";
      updateHitStatus(
        currentHit.id,
        this.props.currentProject,
        HIT_STATE_DELETED,
        result,
        this.moveToDoneCallback.bind(this)
      );
    } else {
      let validCurrentPredTags = currentPredTags || [];
      const tagsArr = [...currentTags, ...validCurrentPredTags];
      if (
        projectDetails.task_type === IMAGE_CLASSIFICATION &&
        tagsArr &&
        tagsArr.length === 0
      ) {
        const resultJson = {};
        resultJson.labels = [];
        resultJson.note = "";
        console.log("check stringify 3144", resultJson);
        result = JSON.stringify(resultJson);
      }

      if (
        this.state.isAutosave &&
        projectDetails.task_type === DOCUMENT_ANNOTATION
      ) {
        if (
          this.state.currentHit.status === HIT_STATE_REQUEUED ||
          this.state.currentHit.status === HIT_STATE_PRE_TAGGED ||
          this.state.currentHit.status === HIT_STATE_NOT_DONE
        ) {
          console.log("check hitstatus 3120");
          updateHitStatus(
            currentHit.id,
            this.props.currentProject,
            this.state.currentHit.status,
            result,
            this.moveToDoneCallback.bind(this)
          );
        } else {
          this.state.currentHit.status = "done";
          console.log("check hitstatus 3124");
          updateHitStatus(
            currentHit.id,
            this.props.currentProject,
            HIT_STATE_DONE,
            result,
            this.moveToDoneCallback.bind(this)
          );
        }
      } else {
        this.state.currentHit.status = "done";
        console.log("check hitstatus 3129");
        let labelResult;
        labelResult = ( task_type === IMAGE_MASKING || task_type === IMAGE_SEGMENTATION) ?
        this.correctLabelId(result) : result;
        this.state.buttonDisabled = true;
        this.state.opacityValue = true;
        updateHitStatus(
          currentHit.id,
          this.props.currentProject,
          HIT_STATE_DONE,
          labelResult,
          this.moveToDoneCallback.bind(this)
        );
        if (currentHitNiri !== undefined) {
            updateHitStatus(
              currentHitNiri.id,
              this.props.currentProject,
              HIT_STATE_DONE,
              labelResult,
              this.moveToDoneCallbackNiri.bind(this)
            );
          }

        if (this.state.currentPredTags && this.state.currentPredTags.size > 0) {
          let newLabels = "";
          this.state.currentPredTags.forEach((machinelabel) => {
            let isPresent = false;
            this.state.entities.forEach((userName) => {
              if (machinelabel === userName) {
                isPresent = true;
              }
            });
            if (!isPresent) {
              if (newLabels !== "") {
                newLabels = newLabels + ",";
              }
              newLabels = newLabels + "" + machinelabel;
            }
          });
          if (newLabels !== "") {
            let labelvalue = { labelName: newLabels };
            updateNewLabelName(
              currentHit.id,
              this.props.currentProject,
              labelvalue,
              NEW_LABEL_UPDATE,
              this.labelUpdatedCallback.bind(this, () => {})
            );
          }
        }
      }
    }

    if (projectDetails.task_type !== IMAGE_CLASSIFICATION) {
      if (this.state.newLabelUpdate) {
        let labelvalue = { labelName: this.state.labelValue };
        updateNewLabelName(
          currentHit.id,
          this.props.currentProject,
          labelvalue,
          NEW_LABEL_UPDATE,
          this.labelUpdatedCallback.bind(this, () => {})
        );
      }
    }
    this.setState({ confirmModalVisible: false });
  }

  correctLabelId(result) {
    const labelList = JSON.parse(result);
    const newEntities = this.state.entities;
    const annotation = labelList["annotationResult"] || labelList;
    let wholeResult = [];

    annotation.forEach((anno, j) => {
      anno["entities"] = newEntities;
      let labelgroup = anno["labelGroupMap"];
      let newlabel = [{}];
      Object.keys(labelgroup).forEach((idx) => {
        const index = newEntities.indexOf(labelgroup[idx]);
        const labelIndex = String(index);
        if (index !== idx) {
          newlabel[0][labelIndex] = labelgroup[idx];
        } else {
          newlabel[0][idx] = labelgroup[idx];
        }
      });
      const res = newlabel[0];
      wholeResult.push(res);
      if (!labelList["annotationResult"]) {
        labelList[j]["labelGroupMap"] = res;
      } else {
        labelList["annotationResult"][j]["labelGroupMap"] = res;
      }
    });

    let labelArr = [];
    labelArr = labelArr.concat(labelList);
    let labelResult = JSON.stringify(labelArr);
    if (labelList["annotationResult"]) {
      labelResult = labelResult.substring(1, labelResult.length - 1);
    }
    return labelResult;
  }

  checkforNERMLLabels = (projectDetails, currentHit) => {
    if (
      projectDetails.task_type === POS_TAGGING_GENERIC &&
      this.state.mllabelArray.length > 0
    ) {
      let labels = "";
      this.state.mllabelArray.map((label) => {
        labels = labels !== "" ? (labels = labels + "," + label) : label;
      });
      let labelvalue = { labelName: labels };
      updateNewLabelName(
        currentHit.id,
        this.props.currentProject,
        labelvalue,
        NEW_LABEL_UPDATE,
        this.labelUpdatedCallback.bind(this, () => {})
      ); // Saving new Label Name
    }
  };

  saveAnnotations() {
    const { projectDetails, currentHit } = this.state;
    let taskArrList = [
      DOCUMENT_ANNOTATION,
      POS_TAGGING_GENERIC,
      VIDEO_BOUNDING_BOX,
      VIDEO_CLASSIFICATION,
    ];
    //Add new Lqbels for ner tagging
    this.checkforNERMLLabels(projectDetails, currentHit);

    if (
      taskArrList.includes(projectDetails.task_type) &&
      this.props.location.pathname.includes("/space")
    ) {
      console.log("check hitstatus 3181 ");
      updateHitStatus(
        this.state.currentHit.id,
        this.props.currentProject,
        this.state.currentHit.status,
        this.getCurrentResult(),
        this.saveAnnotationCallback.bind(this)
      );
    }
  }

  clearAllImageAnnotatios() {
    this.setAutoSaveProp(true);
    const { currentHit, type, projectDetails } = this.state;
    if (type === "done") {
      this.setAutoSaveProp(false);
    } else {
      this.setAutoSaveProp(true);
    }
    this.state.boundingBoxMap = [];
    if (currentHit.result) {
      currentHit.result = null;
    }
    if (currentHit.hitResults) {
      currentHit.hitResults[0].result = null;
    }
    this.setState({ currentHit, hitLabelAction: "" });
    let status = HIT_STATE_NOT_DONE;
    switch (type) {
      case "skipped":
        status = HIT_STATE_SKIPPED;
        break;
      case "deleted":
        status = HIT_STATE_DELETED;
        break;
      case "reQueued":
        status = HIT_STATE_REQUEUED;
        break;
      default:
        console.log("default")
    }

    if (projectDetails.task_type !== VIDEO_BOUNDING_BOX) {
      console.log("check hitstatus 3217 ");
      updateHitStatus(
        currentHit.id,
        this.props.currentProject,
        status,
        "",
        this.moveToDoneCallback.bind(this)
      );
    } else {
      this.saveAnnotations();
    }
    if (status === HIT_STATE_NOT_DONE) {
      this.state.currentHit.status = "notDone";
    }
  }

  clearAllImageAnnotatiosCallback(err, response) {
    this.setState({ boundingBoxMap: [], hitLabelAction: "" });
  }

  routeToHome = () => {
    if (
      parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN ||
      parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR
    ) {
      this.props.pushState("/workspace");
    } else {
      let roleId = getUidToken().roleId;
      this.props.pushState("/projects/" + roleId + "/list");
    }
  };

  cancelAnnotations = () => {
    this.setState({ changesInSession: 0 }, () => {
      this.redirectBackImage();
    });
  };

  redirectBackImage(e) {
    var params = queryString.parse(this.props.location.search);
    var currentHitPage = params.currentHitPage;
    var currentHitSize = params.currentHitSize;
    var node = document.getElementById("myImage");
    const { currentHit, changesInSession } = this.state;
    let autoFlag = false;
    const result = this.checkResults(changesInSession, currentHit);

    if (undefined !== e) e.preventDefault();
    const { type, projectDetails, retainAnnotations } = this.state;
    this.setState({ selectedLabel: "" });

    if (
      (this.props.projectDetails.task_type === IMAGE_SEGMENTATION ||
        this.props.projectDetails.task_type === IMAGE_MASKING) &&
      node
    ) {
      this.segRedirect();
      if (changesInSession > 0) {
        this.drawImageAutoSaveAction(autoFlag, result);
        imageMaskCreation(
          projectDetails.id,
          projectDetails.task_type,
          currentHit["id"],
          this.imageCreationCallback
        );
        if (projectDetails.task_type === IMAGE_MASKING) {
          uploadGrayScaleImage(this.props.currentProject, currentHit.id);
        }
      }
      console.log("movedone called8");
    } else if (
      projectDetails.task_type === IMAGE_MASKING &&
      this.state.changesInSession > 0 &&
      currentHit !== undefined
    ) {
      const that = this;
      const confirm = AntModal.confirm;
      confirm({
        title: "Confirmation",
        content: "Do you want to save the Annotation(s)?",
        okText: "OK",
        cancelText: "CANCEL",
        onOk() {
          that.saveAnnotation();
        },
        onCancel() {
          that.cancelAnnotations();
        },
      });
    } else {
      if (
        type !== "notDone" &&
        this.state.redirectContributionDetails &&
        (projectDetails.task_type === IMAGE_CLASSIFICATION ||
          projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX ||
          projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX_V2 ||
          projectDetails.task_type === IMAGE_SEGMENTATION ||
          projectDetails.task_type === IMAGE_MASKING ||
          projectDetails.task_type === IMAGE_BOUNDING_BOX)
      ) {
        let contributorDetails = this.state.redirectContributionDetails;
        let params = queryString.parse(this.props.location.search);
        let currentHitPage = params.currentHitPage;
        let currentHitSize = params.currentHitSize;
        if (type === "done" && projectDetails.task_type !== IMAGE_CLASSIFICATION) {
          this.drawImageAutoSaveAction(autoFlag, result);
        }
        this.props.pushState({
          pathname:
            "/projects/hitslist/" +
            this.props.params.orgName +
            "/" +
            this.props.params.projectName +
            "/" +
            this.props.params.projectId +
            "/" +
            type,
          query: { contributorDetails, currentHitPage, currentHitSize },
        });
      } else {
        if (getactiveName() === "create") {
          saveactiveTab("home");
        }
        this.props.pushState({
          pathname:
            "/projects/" +
            this.props.params.orgName +
            "/" +
            this.props.params.projectName +
            "/" +
            this.props.params.projectId,
        });
      }
    }
  }

  segRedirect() {
    var params = queryString.parse(this.props.location.search);
    var currentHitPage = params.currentHitPage;
    var currentHitSize = params.currentHitSize;
    const { type } = this.state;

    if (type !== "notDone" && this.state.redirectContributionDetails) {
      let contributorDetails = this.state.redirectContributionDetails;
      let params = queryString.parse(this.props.location.search);
      let currentHitPage = params.currentHitPage;
      let currentHitSize = params.currentHitSize;
      this.props.pushState({
        pathname:
          "/projects/hitslist/" +
          this.props.params.orgName +
          "/" +
          this.props.params.projectName +
          "/" +
          this.props.params.projectId +
          "/" +
          type,
        query: { contributorDetails, currentHitPage, currentHitSize },
      });
    } else {
      if (getactiveName() === "create") {
        saveactiveTab("home");
      }
      this.props.pushState({
        pathname:
          "/projects/" +
          this.props.params.orgName +
          "/" +
          this.props.params.projectName +
          "/" +
          this.props.params.projectId,
      });
    }
  }

  moveToSkip(e) {
    this.moveToSkippedAfterUpdateFromS3(e);
    if (
      this.props.projectDetails.task_type === IMAGE_MASKING ||
      this.props.projectDetails.task_type === IMAGE_SEGMENTATION
    ) {
      this.mergingGroupLabelButtonSwitch(false);
      this.setState({ isAutosave: false });
    }
  }

  moveToSkippedAfterUpdateFromS3 = (e) => {
    if (undefined !== e) e.preventDefault();
    logEvent("buttons", "Skip");
    logEvent("Mark As", "Skip");
    this.setState({ updateAnnotatorInfo: true });
    const {
      currentHit,
      changesInSession,
      projectDetails,
      currentTags,
      currentHitNiri,
    } = this.state;
    if (this.state.confirmModalVisible) {
      this.removeLastAnnotation();
    }
    let result = "";
    if (changesInSession > 0) {
      result = this.getCurrentResult();
    } else if (currentHit.result && currentHit.result.length > 0) {
      result = currentHit.result;
    } else if (currentHit.hitResults && currentHit.hitResults.length > 0) {
      result = currentHit.hitResults[0].result;
    }

    console.log("check hitstatus 3399");
    this.setState({ buttonDisabled: true });
    if (this.state.autoSaveVal === 1) {
      setTimeout(() => {
        updateHitStatus(
          currentHit.id,
          this.props.currentProject,
          HIT_STATE_SKIPPED,
          result,
          this.moveToDoneCallback.bind(this)
        );
        if(currentHitNiri !== undefined)
        {
            updateHitStatus(
                      currentHitNiri.id,
                      this.props.currentProject,
                      HIT_STATE_SKIPPED,
                      result,
                      this.moveToDoneCallbackNiri.bind(this)
                    )
        }
        this.deleteAnnotationsFromS3(this, "Skipped");
      }, 5000);
    } else {
      updateHitStatus(
        currentHit.id,
        this.props.currentProject,
        HIT_STATE_SKIPPED,
        result,
        this.moveToDoneCallback.bind(this)
      );
      if(currentHitNiri !== undefined)
      {
        updateHitStatus(
                    currentHitNiri.id,
                    this.props.currentProject,
                    HIT_STATE_SKIPPED,
                    result,
                    this.moveToDoneCallbackNiri.bind(this)
                  )
      }
      this.deleteAnnotationsFromS3(this, "Skipped");
    }
    this.setState({ confirmModalVisible: false });
    this.state.currentHit.status = "re-Skipped";
  };

  saveElement(e) {
    if (undefined !== e) e.preventDefault();
    if (
      this.state.projectDetails.task_type === IMAGE_MASKING ||
      this.state.projectDetails.task_type === IMAGE_SEGMENTATION
    ) {
      this.saveAnnotation();
    } else {
      if (this.state.classification && this.state.classification.length > 0) {
        if (Object.keys(this.state.classificationResponse).length === 0) {
          const that = this;
          const confirm = AntModal.confirm;
          confirm({
            title: "Confirmation",
            content:
              "There are no classifications selected. Do you want to proceed?",
            okText: "OK",
            cancelText: "CANCEL",
            onOk() {
              that.updateSkipStatus();
            },
            onCancel() {},
          });
        } else {
          this.updateSkipStatus();
        }
      } else {
        this.updateSkipStatus();
      }
    }
  }

  updateSkipStatus() {
    const { currentHit, changesInSession } = this.state;
    let result = "";
    if (changesInSession > 0) {
      if (this.docAnnotator) {
        this.docAnnotator.saveCategory(true);
      }
      result = this.getCurrentResult();
    } else if (currentHit.hitResults && currentHit.hitResults.length > 0) {
      result = currentHit.hitResults[0].result;
    }
    this.setState({ loding: true });
    console.log("check hitstatus 3453");
    updateHitStatus(
      currentHit.id,
      this.props.currentProject,
      currentHit.status,
      result,
      this.saveElementCallback.bind(this)
    );
  }

  handleClassificationCheckBoxChange(name, e1, e2) {
    let classificationResponseObj = [];
    const classificationResponse = this.state.classificationResponse;
    if (name in this.state.classificationResponse) {
      classificationResponseObj = classificationResponse[name];
      delete classificationResponse[name];
    }

    let delIndex = -1;
    if (classificationResponseObj.length > 0) {
      classificationResponseObj.map((value, index) => {
        if (value === e1) {
          delIndex = index;
        }
      });
    }

    if (delIndex !== -1) {
      classificationResponseObj.splice(delIndex, 1);
    }

    if (delIndex === -1) {
      classificationResponseObj.push(e1);
    }
    if (classificationResponseObj.length > 0) {
      classificationResponse[name] = classificationResponseObj;
    }
    this.setState({
      classificationResponse,
      changesInSession: this.state.changesInSession + 1,
    });
    //Auto save on classification selection
    //updateHitStatus(this.state.currentHit.id, this.props.currentProject, this.state.currentHit.status, this.getCurrentResult(), this.saveElementCallback.bind(this));
  }

  resizeWindow(event) {
    this.loadProjectDetails(this.props.currentProject);
  }

  addHitinState(tagLine, data) {
    let clickedColor = {};
    let taggedEntity = {};
    let words = [];

    try {
      const resultJson = JSON.parse(tagLine);
      const labelMap = {};
      if (data) {
        words = data.split(" ");
      }
      for (let index = 0; index < resultJson.length; index++) {
        const points = resultJson[index].points;
        const text = points[0].text;
        const entity = resultJson[index].label[0];
        labelMap[text] = entity;
      }
      for (let index = 0; index < words.length; index++) {
        if (words[index] in labelMap) {
          clickedColor[index] = this.state.entityColorMap[
            labelMap[words[index]]
          ];
          taggedEntity[index] = labelMap[words[index]];
        } else {
          clickedColor[index] = "";
          taggedEntity[index] = "__";
        }
      }
    } catch (exception) {
      // statements
      const splits = tagLine.split(" ");
      for (let index = 0; index < splits.length; index++) {
        const word = splits[index];
        const wordSplits = word.split("____");
        if (wordSplits.length > 1) {
          clickedColor[index] = this.state.entityColorMap[wordSplits[1]];
          taggedEntity[index] = wordSplits[1];
          words[index] = wordSplits[0];
        } else {
          clickedColor[index] = "";
          taggedEntity[index] = "__";
          words[index] = splits[index];
        }
      }
    }

    const { type, projectDetails, currentHit } = this.state;
    let changeLabelArr = [];
    if (
      type === "notDone" &&
      this.props.projectDetails.task_type === POS_TAGGING &&
      words.length > 0
    ) {
      for (let i = 0; i < words.length; i++) {
        changeLabelArr[i] = "M";
      }
    }

    this.setState({
      tagLine: data ? data : tagLine,
      clickedColor,
      taggedEntity,
      words,
      selectIds: [],
      changesInSession: 0,
      startTime: new Date().getTime(),
      posTaggingPredChangeLabels: changeLabelArr,
    });
  }
  receivedProjectDetails(error, response) {
    if (!error) {
      let projectDetails = this.props.projectDetails;
      if (response.body.projectDetails) {
        this.props.updateProjectDetails(response.body.projectDetails);
        projectDetails = response.body.projectDetails;
      }

      let totalNotDoneHits =
        projectDetails.totalHits -
        (projectDetails.totalHitsDeleted +
          projectDetails.totalHitsDone +
          projectDetails.totalHitsSkipped);
      if (this.props.projectDetails.niriFilteredHits > 0) {
        totalNotDoneHits = totalNotDoneHits * 2;
      }
      if (
        this.state.unProcessedCount <= this.state.hits.length ||
        this.state.unProcessedCount !== 0
      ) {
        this.state.currentStart = this.state.unProcessedCount;
      }
      const { currentStart, currentCount } = this.state;
      let startCount = totalNotDoneHits > 10 ? totalNotDoneHits - 10 : 0;

      this.state.imagesLoad = true;
      this.loadProjectDetails(
        this.props.currentProject,
        this.state.totalHitsVal - 10,
        this.state.totalHitsVal
      );
      this.setState({
        totalHitsVal: this.state.totalHitsVal - 10,
      });
    }
    this.props.setloaderMenu(false);
    this.setState({ loading: false });
  }
  projectDetailsFetched(error, response) {
    if (!error) {
      this.state.niriFilteredHits = response.body.niriFilteredHits;
      if (this.props.selectedHitsDetails) {
        let index = response.body.hits.findIndex(
          (x) => x.id === this.props.selectedHitsDetails.selectedImageId
        );
        response.body.hits.unshift(response.body.hits.splice(index, 1)[0]);
      }
      if (response.body.hits.length > 0) {
        response.body.hits = response.body.hits.sort(function(a, b) {
          return parseInt(b.id) - parseInt(a.id);
        });
      }
      let notDoneHits =
        response.body.projectDetails.totalHits -
        (response.body.projectDetails.totalHitsDeleted +
          response.body.projectDetails.totalHitsDone +
          response.body.projectDetails.totalHitsSkipped +
          response.body.projectDetails.totalHitsReQueued +
          response.body.projectDetails.totalHitsPreTagged);

      let totalFilteredHits = response.body.totalFilteredHits;
      let hitsNotAnnotatedCount = response.body.hitsNotAnnotatedCount;

      this.setState({
        hitsNotAnnotatedCount: hitsNotAnnotatedCount,
        totalFilteredHits: totalFilteredHits,
        notDoneHits: notDoneHits,
      });
      if (response.body.hits.length < this.state.currentCount) {
        this.setState({ hitScrollCompleted: true });
      } else {
        this.setState({ hitScrollCompleted: false });
      }
      if (
        response.body.hits.length <= this.state.totalHits &&
        this.state.fromHitList === true
      ) {
        this.setState({ totalHitsCompleted: true });
      } else {
        this.setState({ totalHitsCompleted: false });
      }
      let projectDetails = this.props.projectDetails;
      let entityColorMap = [];
      if (response.body.projectDetails) {
        this.props.updateProjectDetails(response.body.projectDetails);
        projectDetails = response.body.projectDetails;
      }
      const rules = JSON.parse(projectDetails.taskRules);
      if (
        projectDetails.task_type === IMAGE_MASKING &&
        projectDetails.createUserDefinedColor
      ) {
        entityColorMap = getImageMaskEntityColorMap(rules.coloredTags);
      }
      const newTags = rules.tags;
      const entitiesObject =
        projectDetails.task_type === DOCUMENT_ANNOTATION
          ? createEntitiesJson(JSON.stringify(newTags))
          : createEntitiesJson(projectDetails.taskRules);

      let entities = entitiesObject.entities;

      const entityJson = entitiesObject.entityJson;
      let shortcuts = getDetaultShortcuts(projectDetails.task_type, entities);
      if (!projectDetails.createUserDefinedColor) {
        entityColorMap =
          projectDetails.task_type === DOCUMENT_ANNOTATION
            ? []
            : createDocEntityColorMap(entities, ENTITY_COLORS);
      }
      this.state.entityColorMap = entityColorMap;

      let hitEntities = {};
      if (
        response.body.hits.length > 0 &&
        response.body.hits[0] &&
        response.body.hits[0].hitResults !== null
      ) {
        console.log(
          "check hitEntities 3684",
          response.body.hits[0].hitResults[0].result
        );
        if (isJSON(response.body.hits[0].hitResults[0].result))
          hitEntities = !this.state.retainAnnotations
            ? createHitJson(
                response.body.hits[0].hitResults[0].result,
                entityColorMap
              )
            : this.state.hitEntities;
      }
      console.log("check hitEntities 3684", hitEntities);
      if ("shortcuts" in rules) {
        shortcuts = rules.shortcuts;
      }
      let currentHits = this.state.hits;
      let currentHitsNiri = this.state.hitsNiri;

      let currentHitsImage = [];

      let currentIndex =
        this.state.currentIndex < 0 && this.props.currentHitsUrl
          ? this.state.hits.findIndex(
              (it) => it.data === this.props.currentHitsUrl
            )
          : this.state.currentIndex;
    !this.props.currentHitsUrl && (currentIndex = currentIndex + 1);

      if (this.state.type === "done" && this.state.currentIndex === 0) {
        currentIndex = 0;
      }
      let uniqueArray = differenceWith(
        this.state.hits,
        response.body.hits,
        function(o1, o2) {
          if (o1 !== undefined && o2 !== undefined) return o1.id === o2.id;
        }
      );

      this.setState({ hits: uniqueArray });
      if (response.body.hits !== null) {
        let responseHits = response.body.hits.filter(
          (item) => item !== undefined
        );
        if (
          this.state.hitUrl !== undefined &&
          this.state.niriFilteredHits !== 0
        ) {
          currentHitsNiri = [];
        }
        currentHits = this.state.hitUrl
          ? responseHits
          : [...this.state.hits, ...responseHits];
        currentHits.map((hits, index) => {
          if (hits !== undefined) {
            let imageData = hits.data;
            let result = imageData.includes("color");
            if (result === true) {
              currentHitsNiri.push(hits);
            } else {
              currentHitsImage.push(hits);
            }
          }
        });
      } else {
        currentHits = this.state.hits;
      }
      let currentHit;
      let currentHitNiri;
      if (this.state.niriFilteredHits !== 0) {
        currentHits = currentHitsImage;
        currentHit = currentHits[currentIndex];
        currentHitNiri = currentHitsNiri[currentIndex];
      } else {
        currentHit = currentHits[currentIndex];
      }
      downloadImageUrl((url, uid, token) => {
        /**to send the image request url to cornerstone library */
        if (
          currentHit &&
          currentHit.data &&
          currentHit.data.includes("hits/images") &&
          !currentHit.data.includes("http") &&
          !currentHit.data.includes("https")
        ) {
          currentHit.data = `${url}${currentHit.data}?token=${token}&uid=${uid}`;
        }
        if (
          currentHitNiri &&
          currentHitNiri.data &&
          currentHitNiri.data.includes("hits/images") &&
          !currentHitNiri.data.includes("http") &&
          !currentHitNiri.data.includes("https")
        ) {
          currentHitNiri.data = `${url}${currentHitNiri.data}?token=${token}&uid=${uid}`;
        }
      });
      this.state.imagesLoad = false;
      let unProcessedCount =
        (this.state.unProcessedCount ? this.state.unProcessedCount : 0) +
        response.body.hits.length;
      if (this.state.hitUrl !== "") {
        this.getAnnotatorInfo(currentHit);
      }
      if (response.body.hits.length === 0 || !currentHit) {
        this.props.setloaderMenu(false);
        this.setState({
          hits: currentHits,
          currentIndex: currentHits.length - 1,
          entityColorMap,
          entities,
          entityJson,
          startTime: new Date().getTime(),
          projectDetails,
          loading: false,
          action: "",
        });
        if (!this.state.type || this.state.type === "notDone") {
          this.setState({ hitsCompleted: true });
        }
        showAlert("No Items to display under the selected category", "info");
        this.setState({
          lastHitsDetails: true,
          loadFilterImages: false,
          imageLoad: false,
        });
      } else {
        if (projectDetails.task_type === POS_TAGGING) {
          if (response.body.hits.length > 0) {
            this.addHitinState(
              currentHit.hitResults
                ? currentHit.hitResults[0].result
                : currentHit.data,
              currentHit.data
            );
            this.props.setloaderMenu(false);
            this.setState({
              startTime: new Date().getTime(),
              rules,
              shortcuts,
              entityColorMap,
              currentIndex,
              hits: currentHits,
              currentHit,
              entities,
              projectDetails,
              loading: false,
              action: "",
              imageLoad: false,
            });
          }
        } else if (
          projectDetails.task_type === TEXT_SUMMARIZATION ||
          projectDetails.task_type === TEXT_MODERATION
        ) {
          if (response.body.hits.length > 0) {
            let textSummary = undefined;
            if (currentHit.hitResults !== null) {
              const resultJson =
                currentHit.hitResults[0].result.length > 0
                  ? JSON.parse(currentHit.hitResults[0].result)
                  : currentHit.hitResults[0].result;
              textSummary = resultJson.note;
            }
            this.props.setloaderMenu(false);
            this.setState({
              rules,
              shortcuts,
              currentIndex,
              hits: currentHits,
              currentHit,
              textSummary,
              projectDetails,
              loading: false,
              action: "",
              imageLoad: false,
            });
          }
        } else if (projectDetails.task_type === TEXT_CLASSIFICATION) {
          if (response.body.hits.length > 0) {
            let currentTags = new Set();
            let currentNote = "";
            if (currentHit.hitResults !== null) {
              const result = currentHit.hitResults[0].result;
              try {
                const resultJson = JSON.parse(result);
                currentTags = new Set(resultJson.labels);
                currentNote = resultJson.note;
              } catch (exception) {
                // statements
                currentTags = new Set(result.split("____"));
              }
            }
            this.props.setloaderMenu(false);
            this.setState({
              currentTags,
              shortcuts,
              currentNote,
              rules,
              entities,
              entityColorMap,
              currentIndex,
              hits: currentHits,
              currentHit,
              projectDetails,
              loading: false,
              action: "",
              imageLoad: false,
            });
          }
        } else if (
          projectDetails.task_type === IMAGE_CLASSIFICATION ||
          projectDetails.task_type === VIDEO_CLASSIFICATION
        ) {
          if (response.body.hits.length > 0) {
            this.setCurrentTags(currentHit.hitResults, "human");
            if (currentHit.status === "notDone")
              this.setCurrentTags(currentHit.predHitResults, "machine");
            const image1 = new Image(); // eslint-disable-line no-undef
            image1.src = currentHit.data;
            //setTimeout(this.loadImages.bind(this), 10000);
            this.props.setloaderMenu(false);
            this.setState({
              shortcuts,
              rules,
              entities,
              entityColorMap,
              currentIndex,
              hits: currentHits,
              currentHit,
              projectDetails,
              loading: false,
              action: "",
              unProcessedCount: unProcessedCount,
              lastHitsDetails: false,
              imageLoad: false,
            });
          }
        } else if (
          projectDetails.task_type === IMAGE_BOUNDING_BOX ||
          projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX ||
          projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX_V2 ||
          projectDetails.task_type === IMAGE_SEGMENTATION ||
          projectDetails.task_type === IMAGE_MASKING ||
          projectDetails.task_type === VIDEO_BOUNDING_BOX
        ) {
          let hideLabels = false;
          let autoClose = true;
          let notes = false;
          let defaultShape = "polygon";
          let keepEntitySelected = false;
          let continuousTagging = false;
          let classification = undefined;
          let editClassAttribute = undefined;
          let attributes = undefined;
          let classificationResponse = [];
          if ("notes" in rules) {
            notes = rules.notes;
          }
          if ("autoClose" in rules) {
            autoClose = rules.autoClose;
          }
          if ("hideLabels" in rules) {
            hideLabels = rules.hideLabels;
          }
          if ("defaultShape" in rules) {
            defaultShape = rules.defaultShape;
          }
          if ("keepEntitySelected" in rules) {
            keepEntitySelected = rules.keepEntitySelected;
          }

          if ("attributes" in rules) {
            attributes = rules.attributes;
          }

          if ("classification" in rules) {
            classification = rules.classification;
          }

          if ("editClassAttribute" in rules) {
            editClassAttribute = rules.editClassAttribute;
          }

          if (response.body.hits.length > 0) {
            const image1 = new Image(); // eslint-disable-line no-undef
            image1.src = currentHit.data;
            // setTimeout(this.loadImages.bind(this), 10000);

            let boundingBoxMap = [];

            if (classification) {
              if (
                currentHit.hitResults &&
                currentHit.hitResults[0].result.length > 0
              ) {
                console.log("check currentHit 3855", currentHit);
                let resultObject = JSON.parse(currentHit.hitResults[0].result);
                if ("annotationResult" in resultObject) {
                  boundingBoxMap = resultObject.annotationResult;
                  if ("classificationResult" in resultObject) {
                    classificationResponse = getClassificationResponse(
                      resultObject.classificationResult,
                      classification,
                      projectDetails.task_type
                    );
                  }
                } else {
                  boundingBoxMap = resultObject;
                }
              }
            } else {
              console.log("check currentHit 3869", currentHit);
              if (
                currentHit.hitResults &&
                currentHit.hitResults[0].result.length > 0
              ) {
                let resultObject = JSON.parse(currentHit.hitResults[0].result);
                if ("annotationResult" in resultObject) {
                  boundingBoxMap = resultObject.annotationResult;
                } else {
                  boundingBoxMap = JSON.parse(currentHit.hitResults[0].result);
                }
              }
            }
            boundingBoxMap = !this.state.retainAnnotations
              ? boundingBoxMap
              : this.state.boundingBoxMap;
            this.setretainAnnotations(
              this.state.retainAnnotations ? true : false
            );
            this.props.setloaderMenu(false);
            this.setState({
              boundingBoxMap,
              classification,
              classificationResponse,
              editClassAttribute,
              attributes,
              defaultShape,
              autoClose,
              notes,
              hideLabels,
              entityJson,
              newEntities: [],
              shortcuts,
              rules,
              entities,
              entityColorMap,
              keepEntitySelected,
              currentIndex,
              hits: currentHits,
              hitsNiri: currentHitsNiri,
              currentHitNiri,
              currentHit,
              projectDetails,
              loading: false,
              action: "",
              unProcessedCount: unProcessedCount,
              hitEntities,
              continuousTagging,
              lastHitsDetails: false,
              loadFilterImages: false,
              imageLoad: false,
            });
            this.setState({ loading: false });
          }
        } else if (
          projectDetails.task_type === DOCUMENT_ANNOTATION ||
          projectDetails.task_type === POS_TAGGING_GENERIC
        ) {
          let autoClose = true;
          let classification = undefined;
          let classificationResponse = [];
          if ("autoClose" in rules) {
            autoClose = rules.autoClose;
          }
          if ("classification" in rules) {
            classification = rules.classification;
          }
          if (response.body.hits.length > 0) {
            const image1 = new Image(); // eslint-disable-line no-undef
            image1.src = currentHit.data;
            // setTimeout(this.loadImages.bind(this), 10000);
            // let boundingBoxMap = [];
            let boundingBoxMap = {};
            //starts            //

            if (classification) {
              if (
                currentHit.hitResults &&
                currentHit.hitResults[0].result.length > 0
              ) {
                console.log("check currentHit 3935", currentHit);
                let resultObject = JSON.parse(currentHit.hitResults[0].result);
                if ("annotationResult" in resultObject) {
                  boundingBoxMap = resultObject.annotationResult;
                  if ("classificationResult" in resultObject) {
                    classificationResponse = getClassificationResponse(
                      resultObject.classificationResult,
                      classification,
                      projectDetails.task_type
                    );
                  }
                } else {
                  boundingBoxMap = resultObject;
                }
              }
            } else {
              console.log("check currentHit 3949", currentHit);
              if (
                currentHit.hitResults &&
                currentHit.hitResults[0].result.length > 0
              ) {
                boundingBoxMap = JSON.parse(currentHit.hitResults[0].result);
              }
            }
            //get the medicine prediction labels for ner tagging
            this.setState({
              newLabelEntity:
                cloneDeep(entities) === undefined ? [] : cloneDeep(entities),
            });

            entities = this.getMachinePredEnts(
              entities,
              currentHits,
              currentIndex,
              projectDetails
            );
            //end
            this.props.setloaderMenu(false);
            this.setState({
              clearButton:
                boundingBoxMap &&
                boundingBoxMap.annotationResult &&
                boundingBoxMap.annotationResult.length > 0
                  ? false
                  : true,
              boundingBoxMap,
              classification,
              classificationResponse,
              shortcuts,
              autoClose,
              rules,
              entities,
              newEntities: [],
              entityColorMap,
              currentIndex,
              hits: currentHits,
              currentHit,
              projectDetails,
              loading: false,
              action: "",
              unProcessedCount: unProcessedCount,
              imageLoad: false,
            });
          }
        }
      }
    } else {
      showAlert(
        "Error in fetching project details, please try again.",
        "error"
      );
      this.props.pushState("/projects/login");
      if (error && error.message) {
        logEvent("project_detail_fetch_error", error.message);
      } else {
        logEvent("project_detail_fetch_error", "Error");
      }
      this.props.setloaderMenu(false);
      this.setState({ loading: false });
    }
  }

  getMLEntities = (currentNER, entities) => {
    let ents = [];
    let predHitResults = JSON.parse(currentNER.hitResults[0].result);
    let annotationResult = predHitResults.annotationResult;
    let mllabelArray = [];
    annotationResult !== undefined &&
      annotationResult.map((label) => {
        let labelName = label.label;
        // find dupliacte
        const checkDuplicate = entities.findIndex((it) => it === labelName[0]);
        if (checkDuplicate === -1) {
          entities.push(labelName[0]);
          mllabelArray.push(labelName[0]);
        }
      });
    this.setState({ mllabelArray: mllabelArray });

    return entities;
  };

  getMachinePredEnts = (
    entities,
    currentHits,
    currentIndex,
    projectDetails
  ) => {
    // entities = (entities === undefined)  ? [] : entities
    let ents =
      this.state.newLabelEntity === undefined
        ? []
        : cloneDeep(this.state.newLabelEntity);
    let enty = [];
    if (
      this.state.type === "notDone" &&
      projectDetails.task_type === POS_TAGGING_GENERIC
    ) {
      let currentNER = currentHits[currentIndex];
      if (
        currentNER !== undefined &&
        currentNER.hitResults !== null &&
        currentNER.hitResults !== ""
      ) {
        enty = this.getMLEntities(currentNER, ents);
      }
    }
    return enty.length === 0 ? ents : enty;
  };

  setCurrentTags(hitResults, tagType) {
    let currentTags = new Set();
    let currentNote = "";
    if (
      hitResults !== null &&
      hitResults[0].result &&
      hitResults[0].result.length > 0
    ) {
      const result = hitResults[0].result;
      try {
        const resultJson = JSON.parse(result);
        currentTags = new Set(resultJson.labels);
        currentNote = resultJson.note;
      } catch (exception) {
        currentTags = new Set(result.split("____"));
      }
    }
    if (tagType === "machine") {
      this.setState({
        currentPredTags: currentTags,
        currentPredNote: currentNote,
      });
    } else {
      this.setState({ currentTags, currentNote });
    }
  }

  loadImages() {
    const currentHits = this.state.hits;
    for (let index = 1; index < currentHits.length; index++) {
      const image1 = new Image(); // eslint-disable-line no-undef

      downloadImageUrl((url, uid, token) => {
        if (
          currentHits[index].data.includes("hits/images") &&
          !currentHits[index].data.includes("http") &&
          !currentHits[index].data.includes("https")
        ) {
          image1.src = `${url}${currentHits[index].data}?token=${token}&uid=${uid}`;
        }
      });
    }
  }

  loadProjectDetails(pid, start, count) {
    // TODO_REPLACE
    const { projectDetails } = this.props;
    this.setState({ updateAnnotatorInfo: true });
    let startP = start;
    if (startP === undefined) {
      startP = this.state.currentStart;
    }
    this.props.setloaderMenu(true);
    this.setState({ loading: true });
    this.setState({ totalHits: count, imgName: false });
    console.log("count param" + count);
    console.log("count" + this.state.currentCount);
    fetchHits(
      pid ? pid : this.props.currentProject,
      startP,
      count ? count : this.state.currentCount,
      this.projectDetailsFetched,
      this.state.type,
      this.state.label,
      this.state.contributorId,
      this.state.evaluationType,
      this.state.commentFilter,
      this.state.dateFrom,
      this.state.pageSortBy,
      this.state.dateTo,
      this.state.searchHitUrl,
      this.state.classificationLabel,
      projectDetails.niriFilteredHits
    );
    // this.state.searchHitUrl);
    this.props.setloaderMenu(false);
    this.setState({ loading: false });
  }

  loadCreateIndex(pid) {
    console.log("loadCreateIndex called", pid);
    createIndex(pid ? pid : this.props.currentProject);
  }

  tagAreaClick(index, keyFlag) {
    if (
      parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN ||
      parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR
    ) {
      const currentClickState = this.state.clickedColor;
      let select = this.state.selectIds;
      if (keyFlag) {
        for (
          let jindex = 0;
          jindex < Object.keys(currentClickState).length;
          jindex++
        ) {
          if (currentClickState[jindex] === "grey") {
            currentClickState[jindex] = "";
          }
        }
        if (currentClickState[index] === "") {
          currentClickState[index] = "grey";
        }
        select = [index];
      } else {
        if (currentClickState[index] === "grey") {
          currentClickState[index] = "";
          const indexDel = select.indexOf(index);
          if (indexDel > -1) {
            select.splice(indexDel, 1);
          }
        } else {
          currentClickState[index] = "grey";
          select.push(index);
        }
      }
      this.setState({
        clickedColor: currentClickState,
        tagSelected: true,
        selectIds: select,
        cursorIndex: index,
      });
      return false;
    }
  }

  changeAutoClose(type) {
    this.setState({ autocloseDisable: type });
  }

  getCurrentProjectDetails(pid) {
    fetchNotDoneHits(
      pid ? pid : this.props.currentProject,
      this.receivedProjectDetails,
      this.state.type,
      this.state.contributorId,
      this.state.pageSortBy
    );
    this.props.setloaderMenu(false);
    this.setState({ loading: false });
  }

  imageCreationCallback(error, response) {
    this.props.setloaderMenu(false);
    this.setState({ loading: false });
  }

  hitAddCallbackNiri(error, response, action) {
    console.log("hitAddCallbackNiri", response);
  }

  hitAddCallback(error, response, action) {
    console.log("res10", response, error, action);
    if (!error) {
      const { status } = this.state.currentHit;
      const { projectDetails } = this.state;
      const chits = this.state.hits;
      this.state.action = action;
      let nextIndex = -1;
      let imgLoaded = false;
      let unProcessedCount = chits.length;
      this.state.unProcessedCount = this.state.unProcessedCount
        ? this.state.unProcessedCount
        : unProcessedCount;
      if (
        (action && action === "moveToDone") ||
        this.state.action === "moveToDone"
      ) {
        // action === "moveToDone" would be move to done click form HITs Overview section
        nextIndex = this.state.currentIndex;
        this.state.currentStart = this.state.currentStart - 1;
        chits.splice(this.state.currentIndex, 1);
        this.state.totalFilteredHits = this.state.totalFilteredHits - 1;
        if (nextIndex >= chits.length) {
          // items areover in list new item are fetched and navigation start from enxt element so decreasing trhe count
          this.state.currentIndex = this.state.currentIndex - 1;
          nextIndex = nextIndex - 1;
        }
      } else if (
        (action && action === "saveToDone") ||
        this.state.action === "saveToDone"
      ) {
        // action === "saveToDone" would be move to done click from start tagging  location
        if (this.state.currentIndex + 1 <= chits.length) {
          //nextIndex = this.state.currentIndex + 1;
          //if (this.state.currentIndex < chits.length) {
          if (this.state.type !== HIT_STATE_ALL)
            chits.splice(this.state.currentIndex, 1);
          if (chits.length === 0) {
            this.state.currentIndex = this.state.currentIndex - 1;
          } else if (
            this.state.currentIndex === chits.length &&
            this.state.unProcessedCount > 1
          ) {
            const filteredData = chits.filter((element) => {
              if (element.status !== "done") return element.id;
            });
            if (filteredData.length > 0)
              nextIndex = this.state.currentIndex - 1;
            else nextIndex = this.state.currentIndex + 1;
            this.state.notDoneHits = this.state.notDoneHits - 1;
          } else if (
            this.state.currentIndex + 1 < chits.length &&
            this.state.type === HIT_STATE_ALL
          ) {
            nextIndex = this.state.currentIndex + 1;
          } else nextIndex = this.state.currentIndex;
          this.state.unProcessedCount = this.state.unProcessedCount - 1;
          if (this.state.type === HIT_STATE_SKIPPED)
            this.state.totalFilteredHits = this.state.totalFilteredHits - 1;
          //this.incrementCurrentIndex();
        }
        this.state.totalFilteredHits = this.state.totalFilteredHits - 1;
        imgLoaded = true;
      } else if (
        (action && action === "next") ||
        this.state.action === "next"
      ) {
        if (
          status === "done" &&
          (projectDetails.task_type === IMAGE_MASKING ||
            projectDetails.task_type === IMAGE_SEGMENTATION)
        ) {
          if (projectDetails.task_type === IMAGE_MASKING) {
            uploadGrayScaleImage(
              this.props.currentProject,
              this.state.currentHit.id
            );
          }
          imageMaskCreation(
            projectDetails.id,
            projectDetails.task_type,
            this.state.currentHit["id"],
            this.imageCreationCallback
          );
        }
        if (this.state.isSaveAction) {
          nextIndex = this.state.currentIndex;
          this.setState({ isSaveAction: false });
        } else {
          if (this.state.currentIndex + 1 < chits.length) {
            nextIndex = this.state.currentIndex + 1;
          }
        }
      } else if (
        (action && action === "skip") ||
        this.state.action === "skip"
      ) {
        this.setState({ projectDetails: response.body.projectDetails });
        if (this.state.currentIndex + 1 <= chits.length) {
          if (this.state.type !== HIT_STATE_ALL)
            if (chits.length === 0) {
              this.state.currentIndex = this.state.currentIndex - 1;
            } else if (
              this.state.currentIndex === chits.length &&
              this.state.unProcessedCount > 1
            ) {
              const filteredData = chits.filter((element) => {
                if (element.status !== "done") return element.id;
              });
              if (filteredData.length > 0)
                nextIndex = this.state.currentIndex - 1;
              else nextIndex = this.state.currentIndex + 1;
              this.state.notDoneHits = this.state.notDoneHits - 1;
            } else if (
              this.state.currentIndex + 1 < chits.length &&
              this.state.type === HIT_STATE_ALL
            ) {
              nextIndex = this.state.currentIndex + 1;
            } else {
              nextIndex = this.state.currentIndex;
            }
          this.state.unProcessedCount = this.state.unProcessedCount - 1;
          if (this.state.type === HIT_STATE_DONE)
            this.state.totalFilteredHits = this.state.totalFilteredHits - 1;
          //this.incrementCurrentIndex();
        }
      } else if (
        (action && action === "previous") ||
        this.state.action === "previous"
      ) {
        if (
          status === "done" &&
          (projectDetails.task_type === IMAGE_MASKING ||
            projectDetails.task_type === IMAGE_SEGMENTATION)
        ) {
          if (projectDetails.task_type === IMAGE_MASKING) {
            uploadGrayScaleImage(
              this.props.currentProject,
              this.state.currentHit.id
            );
          }
          imageMaskCreation(
            projectDetails.id,
            projectDetails.task_type,
            this.state.currentHit["id"],
            this.imageCreationCallback
          );
        }
        if (this.state.currentIndex > 0) {
          nextIndex = this.state.currentIndex - 1;
        }
      }

      // chits.splice(0, 1);
      let currentHit = this.state.currentHit;
      if (
        nextIndex > -1 &&
        nextIndex < chits.length &&
        chits[0] !== undefined
      ) {
        this.fromhitAddCallback = true;
        currentHit = this.processHitCallBackResponse(
          currentHit,
          chits,
          nextIndex,
          imgLoaded,
          action,
        );
        this.getAnnotatorInfo(currentHit);
      } else {
        const { currentStart, currentCount } = this.state;
        this.setState({ currentStart: currentStart + currentCount });
        if (
          (!this.state.type || this.state.type === "notDone") &&
          this.state.fromLeaderBoard === true
        ) {
          //if (this.state.projectDetails.task_type === DOCUMENT_ANNOTATION)
          this.getCurrentProjectDetails(this.props.currentProject);
          //else
          //  this.loadProjectDetails(this.props.currentProject, 0, currentCount);
        } else {
          this.setState({
            currentStart: currentStart + currentCount,
            currentCount: this.state.DEFAULT_COUNT,
            currentHit: undefined,
          });
        }
      }
      let hitLabels = this.state.hitLabels;
      if (
        this.state.hits &&
        this.state.hits[nextIndex] &&
        this.state.hits[nextIndex].labels &&
        this.state.hits[nextIndex].labels.length > 0
      ) {
        this.state.hits[nextIndex].labels.split(",").map((elem) => {
          if (!hitLabels.includes(elem)) {
            hitLabels.push(elem);
          }
          this.addHitColorToEntityColorMap(elem);
        });
      }
      this.setState({ hitLabels: hitLabels });
    } else {
      if (
        response &&
        response.body &&
        response.body.code &&
        response.body.code === 401
      ) {
        this.props.setloaderMenu(false);
        refreshUidToken(() => {
          this.setState({ loading: false });
        });
      } else {
        showAlert(error.message, "error");
        this.props.setloaderMenu(false);
        this.setState({ loading: false, error: error.message });
      }
    }
    this.setState({ loading: false });
    this.props.setloaderMenu(false);
  }

  getPreTagsInPos = (result) => {
    let entitiesArr = [];
    if (result) {
      let tagLine = result;
      const splits = tagLine.split(" ");
      for (let index = 0; index < splits.length; index++) {
        const word = splits[index];
        const wordSplits = word.split("____");
        if (wordSplits.length > 1) {
          entitiesArr.push(wordSplits[1]);
        }
      }
    }

    return entitiesArr;
  };

  clearMachinePredictionConfirm = () => {
    const { currentHit } = this.state;
    const type = "clear";
    this.setState({ mpredClearModal: false });
    clearMachinePrediction(
      this.props.currentProject,
      currentHit.id,
      type,
      this.clearMachinePredictionCallback.bind(this)
    );
  };

  clearMachinePrediction = () => {
    const { currentHit } = this.state;
    if (currentHit.annotationSaved) {
      showAlert(
        "Document Already Saved. Not able to clear the Machine Prediction",
        "warning"
      );
    } else {
      const that = this;
      const confirm = AntModal.confirm;
      confirm({
        title: "Clear Machine Prediction",
        content: "Do you want to clear machine prediction for current Hit?",
        okText: "OK",
        cancelText: "CANCEL",
        onOk() {
          that.clearMachinePredictionConfirm();
        },
        onCancel() {
          that.setState({ mpredClearModal: false });
        },
      });
    }
  };

  clearMachinePredictionCallback = (err, response) => {
    if (err) {
      captureException(err);
      showAlert(
        "Some Error Occurs while clearing machine prediction .",
        "error"
      );
    } else {
      if (response && response.body.hits && response.body.hits.length >= 1) {
        let existingHits = this.state.hits;
        let index = existingHits.findIndex(
          (hit) => hit.id === response.body.hits[0].id
        );
        existingHits[index] = response.body.hits[0];
        const entitiesObject = createEntitiesJson(
          response.body.projectDetails.taskRules
        );
        let entities = entitiesObject.entities;
        this.setState({
          boundingBoxMap: [],
          hits: existingHits,
          currentHit: response.body.hits[0],
          entities: entities,
          mllabelArray: [],
        });

        showAlert("Machine Prediction Cleared.", "success");
      }
    }
  };

  saveTextClassificationPredictionLabels = () => {
    const { currentHit } = this.state;
    let entitiesArr = [];
    const resultJson = JSON.parse(currentHit.result);
    entitiesArr = resultJson.labels;
    if (entitiesArr.length > 0) {
      let entityString = entitiesArr.join();
      let labelvalue = { labelName: entityString };
      updateNewLabelName(
        currentHit.id,
        this.props.currentProject,
        labelvalue,
        NEW_LABEL_UPDATE,
        this.labelUpdatedCallback.bind(this, () => {})
      ); // Saving new Label Name
    }
  };

  savePosTaggingPredictionLabels = () => {
    const { currentHit } = this.state;
    let entitiesArr = [];
    if (currentHit.result) {
      let tagLine = currentHit.result;
      const splits = tagLine.split(" ");
      for (let index = 0; index < splits.length; index++) {
        const word = splits[index];
        const wordSplits = word.split("____");
        if (wordSplits.length > 1) {
          entitiesArr.push(wordSplits[1]);
        }
      }
    }

    if (entitiesArr.length > 0) {
      let entityString = entitiesArr.join();
      let labelvalue = { labelName: entityString };
      updateNewLabelName(
        currentHit.id,
        this.props.currentProject,
        labelvalue,
        NEW_LABEL_UPDATE,
        this.labelUpdatedCallback.bind(this, () => {})
      ); // Saving new Label Name
    }
  };

  // action, currentHit,chits,nextIndex,imgLoaded
  // this.state.currentHit,chits,this.state.currentIndex,true
  processHitCallBackResponse( currentHit, chits, nextIndex, imgLoaded,action) {
    console.log("new current hit",currentHit);
    let currentHitNiri = this.state.currentHitNiri;
    const chitsNiri = this.state.hitsNiri;
    if (this.fromhitAddCallback) {
      currentHit = chits[nextIndex];
      if (currentHit === undefined) {
        currentHit = chits[0];
      }
      this.fromhitAddCallback = false;
    }

    if (currentHit !== undefined) {
      chitsNiri.map((hits, index) => {
        if (hits !== undefined) {
          let str = currentHit.fileName;
          let hitStr = str.split("-").pop();
          hitStr = hitStr.substring(0, hitStr.lastIndexOf("_"));
          let result = hits.fileName.includes(hitStr);
          if (result === true) {
            currentHitNiri = hits;
          }
        }
      });
    }
    let currentTags = new Set();
    let currentNote = "";
    let textSummary = "";
    let boundingBoxMap = [];
    let classificationResponse = {};
    let entities = this.state.entities;
    let hitLabels = this.state.hitLabels;
    if (this.state.projectDetails.task_type === POS_TAGGING) {
      if (currentHit.result) {
        this.addHitinState(currentHit.result, currentHit.data);
      } else if (currentHit.hitResults && currentHit.hitResults[0].result) {
        console.log("check currentHit 4435", currentHit);
        this.addHitinState(currentHit.hitResults[0].result, currentHit.data);
      } else {
        console.log("check currentHit 4439", currentHit);
        this.addHitinState(currentHit.data);
      }
      // this.addHitinState(currentHit.hitResults ? currentHit.hitResults[0].result : currentHit.data);
    }

    if (currentHit.status === "notDone") {
      this.setCurrentTags(currentHit.predHitResults, "machine");
    }

    if (currentHit.result && currentHit.result !== null) {
      if (
        this.state.projectDetails.task_type === TEXT_CLASSIFICATION ||
        this.state.projectDetails.task_type === IMAGE_CLASSIFICATION ||
        this.state.projectDetails.task_type === VIDEO_CLASSIFICATION
      ) {
        const result = currentHit.result;
        try {
          if (result !== "") {
            const resultJson = JSON.parse(result);
            currentTags = new Set(resultJson.labels);
            currentNote = resultJson.note;
          }
        } catch (exception) {
          // statements
          currentTags = new Set(result.split("____"));
        }
      } else if (
        this.state.projectDetails.task_type === TEXT_SUMMARIZATION ||
        this.state.projectDetails.task_type === TEXT_MODERATION
      ) {
        textSummary = currentHit.result.note;
      } else if (
        this.state.projectDetails.task_type === IMAGE_BOUNDING_BOX ||
        this.state.projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX ||
        this.state.projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX_V2 ||
        this.state.projectDetails.task_type === IMAGE_SEGMENTATION ||
        this.state.projectDetails.task_type === IMAGE_MASKING ||
        this.state.projectDetails.task_type === VIDEO_BOUNDING_BOX ||
        this.state.projectDetails.task_type === DOCUMENT_ANNOTATION ||
        this.state.projectDetails.task_type === POS_TAGGING_GENERIC
      ) {
        if (this.state.classification) {
          let resultObject = JSON.parse(currentHit.result);
          if ("annotationResult" in resultObject) {
            boundingBoxMap = resultObject.annotationResult;
            if ("classificationResult" in resultObject) {
              classificationResponse = getClassificationResponse(
                resultObject.classificationResult,
                this.state.classification,
                this.state.projectDetails.task_type
              );
            }
          } else {
            boundingBoxMap = resultObject;
          }
        } else {
          if (this.state.projectDetails.task_type === DOCUMENT_ANNOTATION) {
            let currentHits = JSON.parse(currentHit.result);
            Object.keys(currentHits).forEach(function(key) {
              boundingBoxMap = currentHits[key];
            });
          } else {
            boundingBoxMap = JSON.parse(currentHit.result);
          }
        }
      }
    } else if (currentHit.hitResults !== null) {
      if (
        this.state.projectDetails.task_type === TEXT_SUMMARIZATION ||
        this.state.projectDetails.task_type === TEXT_MODERATION
      ) {
        textSummary = currentHit.hitResults[0].result.note;
      } else if (
        this.state.projectDetails.task_type === TEXT_CLASSIFICATION ||
        this.state.projectDetails.task_type === IMAGE_CLASSIFICATION ||
        this.state.projectDetails.task_type === VIDEO_CLASSIFICATION
      ) {
        const result = currentHit.hitResults[0].result;
        try {
          if (result !== "") {
            const resultJson = JSON.parse(result);
            currentTags = new Set(resultJson.labels);
            currentNote = resultJson.note;
          }
        } catch (exception) {
          // statements
          currentTags = new Set(result.split("____"));
        }
      } else if (
        this.state.projectDetails.task_type === IMAGE_BOUNDING_BOX ||
        this.state.projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX ||
        this.state.projectDetails.task_type === VIDEO_BOUNDING_BOX ||
        this.state.projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX_V2 ||
        this.state.projectDetails.task_type === IMAGE_SEGMENTATION ||
        this.state.projectDetails.task_type === IMAGE_MASKING ||
        this.state.projectDetails.task_type === DOCUMENT_ANNOTATION ||
        this.state.projectDetails.task_type === POS_TAGGING_GENERIC
      ) {
        if (
          currentHit.hitResults[0].result !== null &&
          currentHit.hitResults[0].result.length > 0
        ) {
          console.log("check currentHit 4531", currentHit);
          if (this.state.classification) {
            console.log("check currentHit 4533", currentHit);
            let resultObject = JSON.parse(currentHit.hitResults[0].result);

            if (this.state.projectDetails.task_type === POS_TAGGING_GENERIC) {
              //save the entity
              let ents =
                this.state.newLabelEntity === undefined
                  ? []
                  : cloneDeep(this.state.newLabelEntity);
              entities = this.getMLEntities(currentHit, ents);
            }

            if ("annotationResult" in resultObject) {
              boundingBoxMap = resultObject.annotationResult;
              if ("classificationResult" in resultObject) {
                classificationResponse = getClassificationResponse(
                  resultObject.classificationResult,
                  this.state.classification,
                  this.state.projectDetails.task_type
                );
              }
            } else {
              boundingBoxMap = resultObject;
            }
          } else {
            console.log("check currentHit", currentHit);
            boundingBoxMap = JSON.parse(currentHit.hitResults[0].result);
            if (currentHit.labels) {
              let labels = currentHit.labels.split(",");
              labels.map((elem) => {
                if (!hitLabels.includes(elem)) hitLabels.push(elem);
                this.addHitColorToEntityColorMap(elem);
              });
            }

            if (this.state.projectDetails.task_type === POS_TAGGING_GENERIC) {
              //save the entity
              let ents =
                this.state.newLabelEntity === undefined
                  ? []
                  : cloneDeep(this.state.newLabelEntity);
              entities = this.getMLEntities(currentHit, ents);
            }
          }
        }
      }
    }
    if (
      this.state.projectDetails.task_type === DOCUMENT_ANNOTATION ||
      this.state.projectDetails.task_type === POS_TAGGING_GENERIC
    ) {
      this.docAnnotator.resetValuesForHitsOverview(boundingBoxMap);
    }
    this.props.setloaderMenu(false);
    if (this.state.retainAnnotations) {
      if (action === "previous") {
        if (this.state.currentIndex > this.state.retainIndex) {
          boundingBoxMap = this.state.boundingBoxMap;
        }
      } else if (action === "next") {
        if (this.state.currentIndex >= this.state.retainIndex) {
          boundingBoxMap = this.state.boundingBoxMap;
        }
      }

      this.setState({
        boundingBoxMap,
      });
    } else {
      this.setState({
        clearButton:
          boundingBoxMap &&
          boundingBoxMap.annotationResult &&
          boundingBoxMap.annotationResult.length > 0
            ? false
            : true,
        boundingBoxMap,
      });
    }
    this.setState({
      startTime: new Date().getTime(),
      currentNote,
      classificationResponse,
      imgLoaded,
      currentTags,
      hits: chits,
      action: "",
      textSummary,
      currentIndex: nextIndex,
      currentHit,
      currentHitNiri,
      selectIds: [],
      tagSelected: false,
      loading: false,
      isAutosave: false,
      changesInSession: 0,
      entities,
      hitLabels: hitLabels,
    });
    return currentHit;
  }

  openProjectStats() {
    this.props.pushState("/projects/stats");
  }

  /*
    updated by Abinesh Rajapandiyan @332capestart
    skip was called after saving the annotation changes
    plase refer moveToDoneCallback function
  */

  skipRow(event, data) {
    if (event !== undefined) event.preventDefault();
    // const latency = ((new Date().getTime() - this.state.startTime) / 1000).toPrecision(3);
    logEvent("buttons", "Skip hit");
    logEvent("Mark As", "Skipped");
    this.props.setloaderMenu(false);
    this.setState({ loading: true, action: "skip", changesInSession: 0 });
    this.state.currentHit.status = "skipped";
    if (this.state.autoSaveVal === 1) {
      setTimeout(() => {
        this.deleteAnnotationsFromS3(this, "Skipped");
        skipHits(
          this.state.currentHit.id,
          this.props.currentProject,
          this.hitAddCallbackNiri
        );
        // this.state.currentHitNiri !== undefined
        //   ? skipHits(
        //       this.state.currentHitNiri.id,
        //       this.props.currentProject,
        //       this.hitAddCallbackNiri
        //     )
        //   : undefined;
        this.state.currentHitNiri !== undefined && skipHits(
            this.state.currentHitNiri.id,
            this.props.currentProject,
            this.hitAddCallbackNiri
          );
        this.setState({ loading: false, autoSaveVal: 0 });
      }, 5000);
    } else {
      this.deleteAnnotationsFromS3(this, "Skipped");
      skipHits(
        this.state.currentHit.id,
        this.props.currentProject,
        this.hitAddCallbackNiri
      );
    //   this.state.currentHitNiri !== undefined
    //     ? skipHits(
    //         this.state.currentHitNiri.id,
    //         this.props.currentProject,
    //         this.hitAddCallbackNiri
    //       )
    //     : undefined;
    this.state.currentHitNiri !== undefined && skipHits(
        this.state.currentHitNiri.id,
        this.props.currentProject,
        this.hitAddCallbackNiri
      );
    }
    this.setState({ loading: false });
    return false;
  }

  removeShapeDots = () => {
    if (this.props.projectDetails.task_type === IMAGE_MASKING) {
      const node = document.getElementById("myImage");
      if (
        !node &&
        node != null &&
        node.getElementsByTagName("svg") &&
        node.getElementsByTagName("svg")[0]
      ) {
        node.getElementsByTagName("svg")[0].classList.remove("hidecircle");
      }
    }
  };

  saveAnnotation() {
    this.setAutoSaveProp(true);
    this.removeShapeDots();
    if (this.props.projectDetails.task_type === IMAGE_SEGMENTATION) {
      console.log("check moveToDoneAction 4630");
      this.moveToDoneAction("moveToDone");
    } else if (this.props.projectDetails.task_type === IMAGE_MASKING) {
      console.log("check moveToDone only 4638");
      this.moveToDone("autoSaveToDone");
    }
  }

  projectEditedCallback(response, error) {
    this.props.setloaderMenu(false);
    this.setState({ loading: false });
  }

  saveTagAndNextRowForDoc(action, data) {
    const latency = (
      (new Date().getTime() - this.state.startTime) /
      1000
    ).toPrecision(3);
    logEvent("buttons", "Next hit");
    if (this.state.changesInSession > 0) {
      logEvent("buttons", "Labeled Data");
    }
    this.props.setloaderMenu(false);
    this.setState({
      changesInSession: 0,
      loading: false,
      action: ACTION_BUTTONS.NEXT,
    });
    this.hitAddCallback(undefined, MESSAGES.NO_TAG_FOUND, ACTION_BUTTONS.NEXT);
    return false;
  }

  saveMaskingNewLabels = () => {
    console.log("check saveMaskingNewLabels 4658");
    if (this.props.projectDetails.task_type === IMAGE_MASKING) {
      if (this.state.maskingLables.length > 0) {
        let labels = "";
        let maskingLable = this.state.maskingLables;
        let maskingLables = maskingLable.filter(
          (item, index) => maskingLable.indexOf(item) === index
        );

        maskingLables.map((label) => {
          if (labels !== "") {
            labels = labels + ",";
          }
          labels = labels + label;
        });
        let labelvalue = { labelName: labels };
        console.log("check before updateNewLabelName");
        updateNewLabelName(
          this.state.currentHit.id,
          this.props.currentProject,
          labelvalue,
          NEW_LABEL_UPDATE,
          this.labelUpdatedCallback.bind(this, () => {})
        ); // Saving new Label Name
      }
    }
  };

  callsaveTagAndNextRow = (action, data) => {
    this.props.setloaderMenu(false);
    this.removeShapeDots();
    this.setState({ buttonDisabled: false, comments: "", opacityValue: false });
    let hitData = "";
    const latency = (
      (new Date().getTime() - this.state.startTime) /
      1000
    ).toPrecision(3);
    logEvent("buttons", "Next hit");
    if (this.state.changesInSession > 0) {
      logEvent("buttons", "Labeled Data");
    }

    if (this.state.newEntities && this.state.newEntities.length > 0) {
      logEvent("buttons", "New Entities");
      const { taskRules } = this.props.projectDetails;
      const rulesJson = JSON.parse(taskRules);
      rulesJson.tags = [...this.state.entities, ...this.state.newEntities].join(
        ","
      );
      editProject(
        this.props.currentProject,
        { rules: JSON.stringify(rulesJson) },
        this.projectEditedCallback.bind(this)
      );
      this.props.setloaderMenu(false);
      this.setState({ loading: false, newEntities: [] });
    }
    const resultString = this.getCurrentResult();
    this.state.currentHit.result = resultString;
    if (
      (this.state.type !== "done" && this.state.changesInSession > 0) ||
      this.state.currentHit.predHitResults ||
      (this.state.projectDetails.task_type === VIDEO_BOUNDING_BOX &&
        this.getCurrentResult() === "[]")
    ) {
      this.props.setloaderMenu(false);
      this.saveMaskingNewLabels(); // to save the dynamic labels for image masking
      this.setState({ loading: false, action: "next", changesInSession: 0 });
      if (this.state.type === "skipped") {
        hitData = {
          result: resultString,
          timeTakenToLabelInSec: latency,
          status: this.state.currentHit.status,
        };
      } else {
        if (this.state.projectDetails.task_type === VIDEO_BOUNDING_BOX)
          hitData = {
            result: resultString,
            timeTakenToLabelInSec: latency,
            status: this.state.type,
          };
        else {
          hitData = { result: resultString, timeTakenToLabelInSec: latency };
        }
      }

      if (
        this.state.type === "notDone" &&
        this.state.projectDetails.task_type === POS_TAGGING
      ) {
        this.savePosTaggingPredictionLabels();
      }
      /* else if(this.state.type === "notDone" && this.state.projectDetails.task_type === TEXT_CLASSIFICATION)
    	this.saveTextClassificationPredictionLabels(); */
        if (this.state.projectDetails.task_type === TEXT_CLASSIFICATION || 
        this.state.projectDetails.task_type === VIDEO_BOUNDING_BOX) {
          this.setState({ changesInSession: 0, loading: true, action: "next" });
          this.hitAddCallback(undefined, "No Tag Found, moving to next", "next");
      }
    } else {
      this.props.setloaderMenu(true);
      this.setState({ changesInSession: 0, loading: true, action: "next" });
      this.hitAddCallback(undefined, "No Tag Found, moving to next", "next");
    }
    return false;
  };

  saveTagAndNextRow(action, data) {
    const {
      type,
      totalHitsVal,
      currentIndexValue,
      currentIndex,
      label,
      evaluationType,
      dateFrom,
      dateTo,
      isFullscreenEnabled,
      hits,
      totalFilteredHits,
      currentHitNiri,
    } = this.state;
    if (
      this.props.projectDetails.task_type === IMAGE_MASKING ||
      this.props.projectDetails.task_type === IMAGE_SEGMENTATION
    ) {
      if (
        isFullscreenEnabled &&
        !label &&
        !dateFrom &&
        !dateTo &&
        !evaluationType
      ) {
        const isLastPage =
          currentIndex === 9 || currentIndex === currentIndexValue;
        if (
          (type === "done" || type === "all") &&
          isLastPage &&
          !currentHitNiri &&
          hits.length !== totalFilteredHits
        ) {
          const nextHitsVal = totalHitsVal - 10;
          this.setState({ imageLoad: true });
          this.loadProjectDetails(
            this.props.currentProject,
            nextHitsVal,
            totalHitsVal
          );
          this.setState({
            totalHitsVal: nextHitsVal,
            currentIndexValue: currentIndexValue + 10,
          });
        }
        let indexval = currentIndexValue - currentIndex;
        const isLastPage1 = currentIndex === 4 || indexval % 5 === 0;
        if (
          (type === "done" || type === "all") &&
          currentHitNiri &&
          isLastPage1 &&
          hits.length !== totalFilteredHits / 2
        ) {
          const nextHitsVal1 = totalHitsVal - 10;
          this.setState({ imageLoad: true });
          this.loadProjectDetails(
            this.props.currentProject,
            nextHitsVal1,
            totalHitsVal
          );
          this.setState({
            totalHitsVal: nextHitsVal1,
            currentIndexValue: currentIndexValue + 10,
          });
        }
      }
      this.mergingGroupLabelButtonSwitch(false);
      this.callsaveTagAndNextRow();
      console.log("movedone called9");
    } else {
      this.callsaveTagAndNextRow();
    }
  }

  removeTag(index) {
    if (
      parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN ||
      parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR
    ) {
      const { clickedColor, taggedEntity, changesInSession } = this.state;
      clickedColor[index] = "";
      taggedEntity[index] = "__";
      this.setState({
        clickedColor,
        taggedEntity,
        tagSelected: false,
        selectIds: [],
        changesInSession: changesInSession + 1,
      });
    }
  }

  async copyToClipboard() {
    try {
      logEvent("buttons", "copyToClipboard");
      await navigator.clipboard.writeText(
        this.state.currentHit ? this.state.currentHit.data : this.state.tagLine
      );
      this.setState({ copySuccess: "Copied!" });
      message.info("Document is copied to clipboard.", 2);
    } catch (err) {
      message.info("Document is not copied to clipboard.", 2);
    }
  }

  showTagLine() {
    //const currentHit = this.state.currentHit;
    const { type, currentHit } = this.state;
    const splits = currentHit.data.split(" ");
    const shortcuts = this.state.shortcuts;
    const renderArrs = [];

    if ("left" in shortcuts) {
      const lcombo = convertKeyToString(shortcuts.left);
      Mousetrap.bind(
        lcombo,
        this.tagAreaClick.bind(
          this,
          this.state.cursorIndex - 1 >= 0 ? this.state.cursorIndex - 1 : 0,
          true
        )
      );
    }
    if ("right" in shortcuts) {
      const rcombo = convertKeyToString(shortcuts.right);
      Mousetrap.bind(
        rcombo,
        this.tagAreaClick.bind(
          this,
          this.state.cursorIndex + 1 < splits.length
            ? this.state.cursorIndex + 1
            : splits.length - 1,
          true
        )
      );
    }

    for (let index = 0; index < splits.length; index++) {
      let iconC = "hidden";
      if (
        this.state.clickedColor[index] &&
        this.state.clickedColor[index] !== ""
      ) {
        iconC = "";
      }

      renderArrs.push(
        <Label
          disabled={
            parseInt(getUidToken().roleId) === ROLES_TYPES.GUEST ||
            parseInt(getUidToken().roleId) === ROLES_TYPES.QA
              ? true
              : false
          }
          size="large"
          className={styles.clickableLabel}
          name={index}
          style={{
            padding: "5px",
            color: "white",
            backgroundColor: this.state.clickedColor[index]
              ? this.state.clickedColor[index]
              : "silver",
          }}
          key={index}
        >
          {this.state.clickedColor[index] !== "grey" &&
            this.state.taggedEntity[index] !== "__" &&
            type === "notDone" &&
            !currentHit.result &&
            currentHit.predHitResults && (
              <span
                className={
                  "legendTag " + this.state.posTaggingPredChangeLabels[index]
                }
              ></span>
            )}

          <span
            onClick={this.tagAreaClick.bind(this, index, false)}
            name={index}
            key={index}
          >
            {splits[index]}
          </span>
          {this.state.taggedEntity[index] !== "__" && (
            <p style={styles.tagedE}>
              {this.state.taggedEntity[index]}
              <Icon
                name="delete"
                onClick={this.removeTag.bind(this, index)}
                className={iconC}
              />
            </p>
          )}
        </Label>
      );
    }

    return (
      <div className={"view_wrap " + styles.tagArea}>
        {renderArrs}
        <div className="w-100">
          <Button
            style={{ display: displaySettingsByRole(getUidToken().roleId) }}
            className={styles.copyButton}
            size="small"
            onClick={this.copyToClipboard}
          >
            <Icon name="copy" color="teal" />
            Copy
          </Button>
        </div>
      </div>
    );
  }

  showModal = () => {
    this.setState({
      confirmModalVisible: true,
      fullScreen: false,
    });

    if (this.state.type === "notDone") {
      const that = this;
      const confirm = AntModal.confirm;
      confirm({
        title: "Confirmation",
        content:
          "Unfortunately, there are no annotations. Please add annotation",
        okText: "OK",
        cancelText: "CANCEL",
        onOk() {
          that.setState({ confirmModalVisible: false });
        },
        onCancel() {
          that.setState({ confirmModalVisible: false });
        },
      });
    }
  };

  handleOk = (e) => {
    this.setState({
      confirmModalVisible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      confirmModalVisible: false,
    });
  };

  removeCurrentTag(ent, tagType) {
    const { projectDetails, type, currentTags, currentHit } = this.state;
    this.stopAutoSave = false;
    let currentPredTags = this.state.currentPredTags || [];
    let tagsArray = [];
    if (currentHit.status === "notDone") {
      tagsArray = [...currentPredTags, ...this.state.currentTags];
    } else {
      tagsArray = [...this.state.currentTags];
    }

    tagsArray = tagsArray.filter(this.onlyUnique);
    if (
      (type === "skipped" || type === "done" || type === "notDone") &&
      projectDetails.task_type === IMAGE_CLASSIFICATION &&
      tagsArray &&
      tagsArray.length === 1
    ) {
      if (type === "notDone") {
        this.setState({
          confirmModalVisible: true,
          fullScreen: false,
        });
        const that = this;
        const confirm = AntModal.confirm;
        confirm({
          title: "Confirmation",
          content:
            "Are you sure you want to delete this annotation ?",
          okText: "OK",
          cancelText: "CANCEL",
          onOk() {
            that.setState({ confirmModalVisible: false });
          },
          onCancel() {
            that.setState({ confirmModalVisible: false });
          },
        });
      }
      this.removeCurrentTagAction(ent, tagType);
    } else {
      this.removeCurrentTagAction(ent, tagType);
    }
  }

  removeCurrentTagAction(ent, tagType) {
    const { currentTags, currentPredTags, changesInSession } = this.state;
    if (tagType === "machine") {
      currentPredTags.delete(ent);
      if (currentTags.has(ent)) currentTags.delete(ent);
    } else {
      currentTags.delete(ent);
      if (currentPredTags && currentPredTags.has(ent))
        currentPredTags.delete(ent);
    }
    this.setState({
      isAutosave: true,
      currentTags,
      currentPredTags,
      changesInSession: changesInSession + 1,
    });
  }

  actionModalForSkipped() {
    return (
      <div className="flx_btn action_model">
        <div className="intro-sec">
          <h1 className="m-b-10">HIT Actions</h1>
          <p>
            You are about to remove the last annotation. Please select the
            action to proceed
          </p>
          <br />
        </div>

        <div className="box_secn m-b-10" id="btn_wrapper">
          {this.state.type === "skipped" && (
            <Button
              className="m_btn b_blue"
              title="Move HIT to Done HITs"
              compact
              size="mini"
              color="blue"
              icon
              onClick={this.moveToDone.bind(this, "moveToDone")}
            >
              <Icon name="check" />
              Move to Done
            </Button>
          )}

          {this.state.type === "done" && (
            <Button
              className="m_btn b_blue"
              title="Move HIT to Skipped HITs"
              compact
              size="mini"
              color="blue"
              title="Move HIT to Skipped HITs"
              icon
              onClick={this.moveToSkip.bind(this)}
            >
              <Icon name="mail forward" />
              Mark as Skipped
            </Button>
          )}

          <Button
            className="m_btn b_red"
            title="Move HIT to Deleted HITs"
            compact
            size="mini"
            color="red"
            icon
            onClick={this.deleteItem.bind(this)}
          >
            <Icon name="trash alternate icon" />
            Delete
          </Button>

          <Button
            className="m_btn b_blue"
            title="Move HIT to Re-tagging Queue"
            compact
            size="mini"
            color="blue"
            icon
            onClick={this.retagHit.bind(this)}
          >
            <Icon name="redo" />
            Requeue
          </Button>
        </div>
      </div>
    );
  }

  showCurrentTags() {
    const renderArrs = [];
    if (
      this.state.currentHit.status === "notDone" &&
      this.state.currentPredTags &&
      this.state.currentPredTags.size > 0
    ) {
      this.addCurrentTagsUI(renderArrs, "machine");
    }
    this.addCurrentTagsUI(renderArrs, "human");
    return <div className="f_labels"> {renderArrs} </div>;
  }

  getPreStatus = (ent) => {
    const { currentHit } = this.state;
    let predArr = [];
    if (currentHit.predHitResults !== null) {
      const result = currentHit.predHitResults[0].result;
      const resultJson = JSON.parse(result);
      predArr = resultJson.labels;
      if (predArr.includes(ent)) return true;
      else return false;
    } else return false;
  };

  addCurrentTagsUI(renderArrs, tagType) {
    let tagsArr =
      tagType === "machine"
        ? this.state.currentPredTags
        : this.state.currentTags;
    for (const ent of tagsArr) {
      renderArrs.push(
        <Label
          key={ent}
          style={{
            padding: "5px 10px 5px 5px",
            color:
              this.state.entityColorMap[ent] === undefined ? "black" : "white",
            backgroundColor: this.state.entityColorMap[ent],
          }}
        >
          {this.state.projectDetails.task_type !== VIDEO_CLASSIFICATION && (
            <span
              className={
                TAGS.LEGEND_TAG +
                (tagType === TAGS.MACHINE || this.getPreStatus(ent)
                  ? TAGS.M_TAG
                  : TAGS.H_TAG)
              }
            ></span>
          )}{" "}
          {ent}
          <Icon
            name="delete"
            onClick={this.removeCurrentTag.bind(this, ent, tagType)}
            style={{ display: displaySettingsByRole(getUidToken().roleId) }}
          />
        </Label>
      );
    }
  }

  showText() {
    const { data } = this.state.currentHit;
    return (
      <div className="textClasify tbx-wrap">
        <div className="words addBgGrey">
          <p className="m-b-10">{data}</p>
          {this.state.projectDetails.task_type === TEXT_CLASSIFICATION &&
            this.state.currentTags &&
            this.state.currentTags.size > 0 &&
            this.showCurrentTags()}
          <Button
            style={{ display: displaySettingsByRole(getUidToken().roleId) }}
            className={styles.copyButton}
            size="small"
            onClick={this.copyToClipboard}
          >
            <Icon name="copy" color="teal" />
            Copy
          </Button>
        </div>
      </div>
    );
  }

  showTextAnnotation() {
    const { data } = this.state.currentHit;
    return (
      <div className={styles.tagArea}>
        <p className={styles.textStyle}>{data}</p>
        {this.state.projectDetails.task_type === TEXT_CLASSIFICATION &&
          this.state.currentTags &&
          this.state.currentTags.size > 0 &&
          this.showCurrentTags()}
        <Button
          style={{ display: displaySettingsByRole(getUidToken().roleId) }}
          className={styles.copyButton}
          size="small"
          onClick={this.copyToClipboard}
        >
          <Icon name="copy" color="teal" />
          Copy
        </Button>
      </div>
    );
  }

  handleChange(field, element) {
    const value = element.target.value;
    const changesInSession = this.state.changesInSession + 1;
    this.setState({ changesInSession, textSummary: value });
  }

  downloadModeChange = (event, { value }) => {
    this.setState({ downloadOption: value });
  };

  showWriteText(type) {
    if (type === TEXT_SUMMARIZATION) {
      return (
        <div
          className="moderatedCon"
          style={{ display: displaySettingsByRole(getUidToken().roleId) }}
        >
          <Form.Field
            id="write_text"
            control={TextArea}
            onChange={this.handleChange.bind(this, "summary")}
            label="Summary"
            value={this.state.textSummary}
            placeholder="Write text summary here..."
          />
        </div>
      );
    } else if (type === TEXT_MODERATION) {
      return (
        <div
          className="moderatedCon"
          style={{ display: displaySettingsByRole(getUidToken().roleId) }}
        >
          <Form.Field
            id="write_text"
            control={TextArea}
            onChange={this.handleChange.bind(this, "summary")}
            label="Moderated Text"
            value={this.state.textSummary}
            placeholder="Write moderated text here..."
          />
        </div>
      );
    }
  }

  showTags(entiti) {
    const renderArrs = [];
    const shortcuts = this.state.shortcuts;
    for (let index = 0; index < entiti.length; index++) {
      const entity = entiti[index];
      const color = this.state.entityColorMap[entity];
      let combo = "";
      if (entity in shortcuts) {
        combo = convertKeyToString(shortcuts[entity]);
        if (this.state.projectDetails.task_type === POS_TAGGING) {
          Mousetrap.bind(combo, this.setTagClick.bind(this, entity));
        } else {
          if (this.state.currentIndex >= 0) {
            Mousetrap.bind(combo, this.setClassification.bind(this, entity));
          }
        }
      }
      renderArrs.push(
        <Label
          size="large"
          name={entity}
          className={styles.clickableLabel}
          style={{
            padding: "5px",
            margin: "5px",
            color: "white",
            backgroundColor: this.state.entityColorMap[entity],
          }}
          onClick={this.setTagClick.bind(this, entity)}
          key={color}
        >
          <span>{combo}</span> {entity}
        </Label>
      );
    }

    return <div>{renderArrs}</div>;
  }

  showImages() {
    let { data } = this.state.currentHit;
    downloadImageUrl((url, uid, token) => {
      if (
        data.includes("hits/images") &&
        !data.includes("http") &&
        !data.includes("https")
      ) {
        data = `${url}${data}?token=${token}&uid=${uid}`;
      }
    });
    let windowHeight = (window.innerHeight * 70) / 100;
    let windowWidth = (window.innerWidth * 65) / 100;
    if (this.state.fullScreen) {
      windowHeight = (window.innerHeight * 75) / 100;
    }
    if (this.state.type !== "notDone") {
      windowHeight = (window.innerHeight * 65) / 100;
      windowWidth = (window.innerWidth * 60) / 100;
    }
    if (
      this.state.projectDetails.task_type === VIDEO_CLASSIFICATION ||
      this.state.projectDetails.task_type === VIDEO_BOUNDING_BOX
    ) {
      return (
        <div className={styles.tagArea} style={styles.lineHeight}>
          <Player
            width={windowWidth}
            height={windowHeight}
            preload="auto"
            fluid={false}
            poster="/assets/poster.png"
            src={data}
          >
            <ControlBar autoHide={false}>
              <ForwardControl seconds={5} order={3.1} />
              <ForwardControl seconds={10} order={3.2} />
              <ForwardControl seconds={30} order={3.3} />
              <PlaybackRateMenuButton
                rates={[5, 3, 1.5, 1, 0.5, 0.1]}
                order={7.1}
              />
            </ControlBar>
          </Player>
          {this.state.projectDetails.task_type === VIDEO_CLASSIFICATION &&
            this.state.currentTags &&
            this.state.currentTags.size > 0 &&
            this.showCurrentTags()}
        </div>
      );
    } else if (!checkVideoURL(data)) {
      return (
        <div className={styles.tagArea} style={styles.lineHeight}>
          {!this.state.imgLoaded && (
            <Dimmer active>
              <div className="overlay_dom">
                <div className="cssload-wraper">
                  <div className="cssload-dots"></div>
                </div>
              </div>
            </Dimmer>
          )}
          <PanZoomElement
            image={data}
            drawButton={false}
            zoomable
            width={windowWidth}
            height={windowHeight}
          >
          <img
            alt="img"
            draggable="false"
            onLoad={() => {
              this.setState({ imgLoaded: true });
            }}
            className="no-flickr"
            src={data}
            style={{
              maxHeight: `${windowHeight}`,
              maxWidth: `${windowHeight}`,
              width: "auto",
              height: "auto",
              display: "block",
              margin: "auto",
              marginTop: "20px",
              cursor: "move",
            }}
          />
          </PanZoomElement>
          {this.state.projectDetails.task_type === IMAGE_CLASSIFICATION &&
            ((this.state.currentTags && this.state.currentTags.size > 0) ||
              (this.state.currentPredTags &&
                this.state.currentPredTags.size > 0)) &&
            this.showCurrentTags()}
        </div>
      );
    }
  }

  /**
   * updates in the function,to create boundingboxMap points
   * adds color to the point object befor saving it in backend
   */
  containsNonLatinCodepoints(s) {
    return /[^\u0000-\u00ff]/.test(s);
  }

  replaceString(text) {
    return text;
  }

  annotateCallback = (obj) => {
    const { data } = this.state.currentHit;
    if (obj.showModal) {
      this.setState({ annotateModalOpen: true });
    } else {
      this.setState({ annotateModalOpen: false });
    }
    const boundingBoxMap = [];
    let changesInSession = this.state.changesInSession;
    let clearButton = this.state.clearButton;
    let undoButton = this.state.undoButton;
    let redoButton = this.state.redoButton;
    //let changesInSession = 0;
    let newEntities = obj.newEntities;
    for (let index = 0; index < obj.annotations.length; index++) {
      const annotation = obj.annotations[index];
      const points = [];
      if (!this.state.currentHit.isURL) {
        const selectedString = data.substring(
          annotation.start,
          annotation.end + 1
        );
        if (annotation.category.length > 0 && annotation.text.length > 0) {
          const isArray = Array.isArray(annotation.color[0]);
          const annotationColor = isArray
            ? annotation.color[0]
            : annotation.color;
          const labelName = "";
          points.push({
            start: annotation.start,
            end: annotation.end,
            text: annotation.text,
            color: annotation.color[0] ? annotationColor : "#ffffff",
          });
          if ("annotationResult" in boundingBoxMap) {
            boundingBoxMap.annotationResult.push({
              labelName,
              label: annotation.category,
              points,
            });
          } else {
            boundingBoxMap.push({
              labelName,
              label: annotation.category,
              points,
            });
          }
          if (obj.categorySelected) changesInSession = changesInSession + 1;
        }
      } else if (this.state.currentHit.isURL) {
        if (
          annotation.category.length > 0 &&
          annotation.end > annotation.start
        ) {
          points.push({
            start: annotation.start,
            end: annotation.end,
          });
          boundingBoxMap.push({ label: annotation.category, points });
          if (obj.categorySelected) {
            changesInSession = changesInSession + 1;
          }
        }
      }
    }
    if (
      this.state.boundingBoxMap &&
      ((Array.isArray(this.state.boundingBoxMap) &&
        this.state.boundingBoxMap.length > 0) ||
        (!Array.isArray(this.state.boundingBoxMap) &&
          Object.keys(this.state.boundingBoxMap).length)) &&
      obj.annotations.length !== 0
    ) {
      changesInSession = changesInSession + 1;
    }
    if (newEntities.length > 0) {
      newEntities = [...this.state.newEntities, ...newEntities];
    } else {
      newEntities = this.state.newEntities;
    }

    if (obj.undoAnnotations.length > 0 && boundingBoxMap.length > 0) {
      undoButton = false;
      clearButton = false;
    }

    if (obj.redoAnnotations.length > 0 && boundingBoxMap.length > 0) {
      redoButton = false;
    }
    //To enable save button for NER tagging - for last annotation removal (PN-1684)
    if (
      changesInSession === 0 &&
      obj.annotations.length === 0 &&
      obj.taskType === POS_TAGGING_GENERIC &&
      obj.undoAnnotations.length > 0
    ) {
      changesInSession = changesInSession + 1;
    }

    this.state.boundingBoxMap = boundingBoxMap;
    this.state.newEntities = newEntities;
    this.state.changesInSession = changesInSession;

    this.setState({
      changesInSession,
      boundingBoxMap,
      newEntities,
      undoButton,
      clearButton,
      redoButton,
    });

    if (!obj.selecting) {
      this.setAutoSaveProp(false);
      //this.moveToDone("autoSaveToDone");
    }
  };

  annotateCallbackForCancellingEntity = (obj) => {
    const { data } = this.state.currentHit;
    if (obj.showModal) {
      this.setState({ annotateModalOpen: true });
    } else {
      this.setState({ annotateModalOpen: false });
    }
    let undoButton = this.state.undoButton;
    let redoButton = this.state.redoButton;
    this.setState({ undoButton: true });
    this.setState({ redoButton: true });
  };

  refreshSpace = (taskRules) => {
    const rules = JSON.parse(taskRules);
    const newTags = rules.tags;
    const entitiesObject = createEntitiesJson(JSON.stringify(newTags));
    const entities = entitiesObject.entities;
    this.setState({ entities: entities });
    // this.getEntities(this.state.entities)}
  };

  disableFilterOnClickSpan = (modal) => {
    if (modal) {
      this.setState({ annotateModalOpen: modal });
    }
  };

  getReplaceLetter(size) {
    let result = "";
    for (let i = 0; i < size; i++) {
      result = result + "Ⓔ";
    }
    return result;
  }

  replaceAt(str, index, chr) {
    if (index > str.length - 1) return str;
    return str.substr(0, index) + chr + str.substr(index + 1);
  }

  replaceRange(s, start, end, substitute) {
    var arr = s.split("");
    var removed = arr.splice(start, end, substitute); // arr is modified
    s = arr.join("");
    return s;
  }

  showDocumentAnnotation() {
    // using a generator function
    /**
     * conditionally fetching color from backend only for document annotation
     * updates by Abinesh Rajapandiyan @332capestart
     */

    const { data } = this.state.currentHit;
    const { dataText } = this.state.currentHit;
    //const { emojiIndexMap } = this.state.currentHit;
    // const { emojiEnabled } = this.state.currentHit.emojiEnabled;
    const boundingBoxMap = this.state.boundingBoxMap;
    const labelBoundingBoxDoc = this.state.labelBoundingBoxDoc;
    let projectUser = [];
    projectUser.role = "";
    if (this.state.contributorDetails !== undefined) {
      projectUser = this.state.contributorDetails.filter(
        (contributor) => contributor.userDetails.uid === getUidToken().uid
      )[0];
    }
    const annotations = [];
    // const emojies = extractEmoji(data);
    // const emojies = onlyEmoji(data)
    let textData = data;
    // let emojiIndexMap = {};
    // emojies.forEach(emoji => {
    //   var emojiString = emoji.toString();
    //   let emojiArray = [...emojiString.trim()];
    //   var key = parseInt(textData.indexOf(emojiString));
    //   emojiIndexMap[key] = emojiString;
    //   let letter = this.getReplaceLetter(emojiArray.length);
    //   textData = this.replaceRange(textData, textData.indexOf(emojiString), emojiString.length, letter);
    // })
    if (this.state.boundingBoxMap || this.state.labelBoundingBoxDoc) {
      let bbMap = boundingBoxMap.annotationResult
        ? boundingBoxMap.annotationResult
        : boundingBoxMap;
      if (
        this.state.selectedLabel !== "" &&
        this.state.selectedLabel.length > 0
      ) {
        bbMap = labelBoundingBoxDoc.annotationResult
          ? labelBoundingBoxDoc.annotationResult
          : labelBoundingBoxDoc;
      }
      for (let index = 0; index < bbMap.length; index++) {
        const bb = bbMap[index];
        const colors = [];
        for (let jindex = 0; jindex < bb.label.length; jindex++) {
          if (bb.label[jindex] in this.state.entityColorMap) {
            if (this.props.projectDetails.task_type === DOCUMENT_ANNOTATION) {
              colors.push(bb.points[0].color[jindex]);
            } else {
              colors.push(this.state.entityColorMap[bb.label[jindex]]);
            }
          } else {
            if (this.state.selectedLabel !== "") {
              colors.push(bb.points[0].color[jindex]);
            } else {
              colors.push("#A52A2A");
            }
          }
        }

        // id: bb.points[0].start + "-" + bb.points[0].end,

        if (this.containsNonLatinCodepoints(bb.points[0].text)) {
          let selectedText = this.replaceString(bb.points[0].text);
          let strArr = [...bb.points[0].text];
          if (strArr.length === selectedText.length) {
            annotations.push({
              category: bb.label,
              start: bb.points[0].start,
              end: bb.points[0].start + selectedText.length - 1,
              text: bb.points[0].text,
              id:
                bb.points[0].start +
                "-" +
                (bb.points[0].start + selectedText.length - 1),
              color: colors,
            });
          } else {
            annotations.push({
              category: bb.label,
              start: bb.points[0].start,
              end: bb.points[0].end, //(bb.points[0].start + selectedText.length ),
              text: bb.points[0].text,
              id:
                bb.points[0].start +
                "-" +
                (bb.points[0].start + selectedText.length),
              color: colors,
            });
          }
        } else {
          annotations.push({
            category: bb.label,
            start: bb.points[0].start,
            end: bb.points[0].end,
            text: bb.points[0].text,
            id: bb.points[0].start + "-" + bb.points[0].end,
            color: colors,
          });
        }
      }
    }

    if (this.docAnnotator) {
      if (this.docAnnotator.state) {
      }
    }
    return (
      <div className={styles.tagArea}>
        <DocumentAnnotator
          ref={node => (this.docAnnotator = node)}
          shortcuts={this.state.shortcuts}
          hits={this.state.hits}
          currentIndex={this.state.currentIndex}
          saveTagAndNextRow={this.saveTagAndNextRow}
          saveTagAndNextRowForDoc={this.saveTagAndNextRowForDoc}
          saveRow={this.moveToDone} 
          entities={this.state.entities}
          skipRow={this.skipRow}
          urlData={this.state.currentHit.isURL}
          getBackTopreviousRow={this.getBackTopreviousRow}
          getBackTopreviousRowForDoc={this.getBackTopreviousRowForDoc}
          saveAnnotations={this.saveAnnotations.bind(this)}
          autoClose={this.state.autoClose}
          autoLabel={this.state.autoLabel}
          annotations={annotations}
          space={this.state.type === HIT_STATE_NOT_DONE}
          documentText={data}
          documentDataText={dataText}
          // emojiEnabled={emojiEnabled}
          // emojiIndexMap={emojiIndexMap}
          annotateCallback={this.annotateCallback}
          annotateCallbackForCancellingEntity={this.annotateCallbackForCancellingEntity}
          entityColorMap={this.state.entityColorMap}
          taskRules={this.props.projectDetails.taskRules}
          taskType={this.props.projectDetails.task_type}
          currentProject={this.props.currentProject}
          projectName={this.props.params.projectName}
          hitScrollCompleted={this.state.hitScrollCompleted}
          allowDynamiclabelling={this.props.projectDetails.allowDynamiclabelling}
          notDoneHits={this.state.notDoneHits}
          changeAutoClose={this.changeAutoClose}
          autocloseDisable={this.state.autocloseDisable}
          userRole={projectUser.role}
          totalFilteredHits={this.state.totalFilteredHits}
          refreshSpace={this.refreshSpace}
          selectedLabel={this.state.selectedLabel}
          disableFilterOnClickSpan={this.disableFilterOnClickSpan}
          showClearButton={this.showClearButton}
          showUndoButton={this.showUndoButton}
          showRedoButton={this.showRedoButton}
        />
      </div>
    );
  }

  checkvalidPoints = (shape, points) => {
    let isValidPoints = false;
    let countPoints = this.checkPointsGroupOrNot(points, 0);
    if (countPoints > 2) {
      for (let index = 0; index < points.length; index++) {
        isValidPoints = this.validPoints(shape, points[index]);
        if (!isValidPoints) break;
      }
    } else {
      isValidPoints = this.validPoints(shape, points);
    }
    return isValidPoints;
  };

  // validating points when the draw shape is not line or circle
  validPoints = (shape, points) => {
    if (
      shape !== DRAW_TOOLS.CIRCLE.NAME &&
      shape !== DRAW_TOOLS.LINE.NAME &&
      shape !== DRAW_TOOLS.POLYLINE.NAME
    ) {
      for (let index = 0; index < points.length; index++) {
        if (
          points[index][0] > 1.0 ||
          points[index][1] > 1.0 ||
          points[index][0] < 0.0 || points[index][1] < 0.0
        )
          return false;
      }
    }
    return true;
  };

  combineOrGroupPoints = (key, rects, labelGroupMap) => {
    if (labelGroupMap) {
      if (labelGroupMap[key] && labelGroupMap[key].startsWith("#")) {
        let pointsArr = [];
        let selectArr = getByValue(labelGroupMap, labelGroupMap[key]);
        selectArr.map((element) => {
          pointsArr.push(rects[element]);
        });
        return pointsArr;
      } else {
        return rects[key];
      }
    }
  };

  drawPolygon = (obj) => {
    if (!navigator.onLine) {
      this.setState({ fullScreen: false });
      showAlert(
        "Looks like your internet connection is not stable. Please save your work and refresh the application",
        "warning"
      );
    }
    const { currentHit, type, selectedHitLabelIndex } = this.state;
    const boundingBoxMap = [];
    let changesInSession = 0;
    let labelGroupMap = obj.labelGroupMap;
    let groupLabels = getGroupingLabels(obj.labelGroupMap);

    for (const key of Object.keys(groupLabels)) {
      let note = "";
      let attributes = undefined;
      if (obj.notes && key in obj.notes) {
        note = obj.notes[key];
      }
      if (obj.attributesOption && key in obj.attributesOption) {
        attributes =
          Object.keys(obj.attributesOption[key]).length > 0
            ? obj.attributesOption[key]
            : undefined;
      }
      const points = this.combineOrGroupPoints(
        key,
        obj.rects,
        obj.labelGroupMap
      );
      //const points = obj.rects[key];
      const shape = obj.rectShapeMap[key];
      this.state.selectShape = shape;
      const isValidPoints = this.checkvalidPoints(shape, points);
      this.setState({
        tagimageNaturalWidth: obj.imageNaturalWidth,
        tagimageNaturalheight: obj.imageNaturalHeight,
      });
      if (isValidPoints) {
        if (obj.rectShapeMap) {
          console.log(
            "test 5711",
            obj,
            obj.currentColor,
            this.state,
            this.props
          );
          boundingBoxMap.push({
            label: obj.rectCatMap[key],
            shape: obj.rectShapeMap[key],
            points,
            notes: note,
            attributes: attributes,
            imageWidth: obj.imageNaturalWidth,
            imageHeight: obj.imageNaturalHeight,
            color: this.state.entityColorMap ?  this.state.entityColorMap[obj.rectCatMap[key]] : obj.currentColor,
            labelGroupMap: obj.labelGroupMap
              ? obj.labelGroupMap
              : this.state.labelGroupMap,
          });
          console.log("test 5725", obj);
        } else {
          console.log("test 5724", obj.currentColor);
          boundingBoxMap.push({
            label: obj.rectCatMap[key],
            points,
            notes: note,
            attributes: attributes,
            imageWidth: obj.imageNaturalWidth,
            imageHeight: obj.imageNaturalHeight,
            color: this.state.entityColorMap ?  this.state.entityColorMap[obj.rectCatMap[key]] : obj.currentColor,
            labelGroupMap: obj.labelGroupMap
              ? obj.labelGroupMap
              : this.state.labelGroupMap,
          });
        }
      } else {
        captureException("Wrong co-ordinates spotted", currentHit);
        logEvent("Error", "Image Points");
      }

      if (
        (type === "skipped" || type === "done" || type === "notDone") &&
        this.state.boundingBoxMap &&
        this.state.boundingBoxMap.size === 1
      ) {
        this.setState({
          confirmModalVisible: true,
          fullScreen: false,
        });
      }
      changesInSession = changesInSession + 1;
    }

    if (
      this.state.boundingBoxMap &&
      this.state.boundingBoxMap.length > 0 &&
      Object.keys(obj.rects).length === 0
    ) {
      changesInSession = changesInSession + 1;
    }
    this.setState({
      changesInSession,
      boundingBoxMap,
      labelGroupMap,
      hasmaskLable: true,
      selectedHitLabelIndex: [...obj.selectedHitLabelIndex],
    });
  };

  addHitColorToEntityColorMap = (element) => {
    console.log("check addHitColorToEntityColorMap", element);
    if (!this.state.entityColorMap[element]) {
      this.state.entityColorMap[element] = STATIC_HIT_LABEL_COLOR;
    }
  };

  drawHandle = (obj) => {
    const boundingBoxMap = [];
    let changesInSession = 0;
    let newEntities = obj.newEntities;
    this.setState({
      tagimageNaturalWidth: obj.imageNaturalWidth,
      tagimageNaturalheight: obj.imageNaturalHeight,
    });
    for (let index = 0; index < obj.rects.length; index++) {
      const rect = obj.rects[index];
      const points = [];
      points.push({ x: rect.x1, y: rect.y1 });
      points.push({ x: rect.x2, y: rect.y2 });
      console.log("check test draw handle called", obj);
      boundingBoxMap.push({
        label: obj.rectCatMap[index],
        notes: obj.notes[index],
        points,
        imageWidth: obj.imageNaturalWidth,
        imageHeight: obj.imageNaturalHeight,
      });
      changesInSession = changesInSession + 1;
    }
    if (
      (this.state.type === "skipped" ||
        this.state.type === "done" ||
        this.state.type === "notDone") &&
      this.state.boundingBoxMap &&
      this.state.boundingBoxMap.size === 1
    ) {
      this.setState({
        confirmModalVisible: true,
        fullScreen: false,
      });
    }

    if (
      this.state.boundingBoxMap &&
      this.state.boundingBoxMap.length > 0 &&
      obj.rects.length === 0
    ) {
      changesInSession = changesInSession + 1;
    }
    if (newEntities.length > 0) {
      newEntities = [...this.state.newEntities, ...newEntities];
    } else {
      newEntities = this.state.newEntities;
    }
    this.setState({ changesInSession, boundingBoxMap, newEntities });
  };

  saveHitLabel = (value, changedTo, key, action) => {
    this.props.setloaderMenu(true);
    const { currentHit } = this.state;
    if (value !== "" && value.length > 0) {
      this.setState({ hitKey: key, hitLabelAction: action });
      let labelvalue = "";
      if (action === "add") {
        labelvalue = { labelName: value, action: action };
        this.setState({ selectedHitLabel: value });
      } else if (action === "update") {
        labelvalue = { labelName: value, changedTo: changedTo, action: action };
        this.setState({ selectedHitLabel: changedTo });
      } else {
        labelvalue = { labelName: value, action: action };
      }
      this.props.setloaderMenu(false);
      addHitLabel(
        currentHit.id,
        this.props.currentProject,
        labelvalue,
        this.addHitLabelCallback.bind(this)
      ); // Saving new Label Name
    }
    this.props.setloaderMenu(false);
  };

  addHitLabelCallback = (err, response) => {
    if (!err) {
      // const {hitLabelAction} = this.state;
      if (response && response.body) {
        showAlert(response.body.message, response.body.messageType);
      }

      let hitLabels = this.state.hitLabels;
      if (response && response.body.labels) {
        let labels = response.body.labels.split(",");
        labels.map((elem) => {
          if (!hitLabels.includes(elem)) hitLabels.push(elem);
          this.addHitColorToEntityColorMap(elem);
        });
        this.setState({
          hitLabels: hitLabels,
          hitLabelsLast: hitLabels[hitLabels.length - 1],
          autoSaveAction: true,
        });
      }
    } else {
      this.props.setloaderMenu(false);
      showAlert("Something wrong..", "error");
    }
  };

  clearHitLabelState = () => {
    this.setState({ hitKey: "", hitLabelsLast: "" });
  };

  resetHitLabelAction = () => {
    this.setState({ hitLabelAction: "" });
  };

  rebuildGroupArray = () => {
    const { labelGroupMap } = this.state;
    let arrayItems = {};
    const keys = Object.keys(labelGroupMap);
    keys.map((element, index) => {
      arrayItems[index] = labelGroupMap[element];
    });
    return arrayItems;
  };

  checkPointsGroupOrNot = (points, count) => {
    if (Array.isArray(points)) {
      count = count + 1;
      count = this.checkPointsGroupOrNot(points[0], count);
    }
    return count;
  };

  getIndexOfGroup = (labelGroupMap, labelName) => {
    let keys = Object.keys(labelGroupMap);
    if (keys && keys.length > 0) {
    } else {
      return labelName.split("_")[0] + "_" + 0;
    }
  };

  addEmptyAtttributes = () => {
    const { attributes } = this.state;
    let data = {};
    if (attributes) {
      let response = attributes.split(",");
      response.map((element) => {
        data[element] = undefined;
      });
    }
    return data;
  };

  showPolygonV2BoundingImages() {
    // using a generator function
    console.log("showPolygonV2BoundingImages")
    let { data } = this.state.currentHit;

    if (
      this.state.hitUrl !== null &&
      data !== null &&
      this.state.hitUrl !== undefined
    ) {
      this.state.checkHits.map((hits) => {
        let str = this.state.currentHit.fileName;
        let hitStr = str.split("-").pop();
        hitStr = hitStr.substring(0, hitStr.lastIndexOf("_"));
        let result = hits.fileName.includes(hitStr);
        if (result === true) {
          this.state.currentHitNiri = hits;
        }
      });
    }
    let niri_data = this.state.currentHitNiri;
    let imageData = data;
    downloadImageUrl((url, uid, token) => {
      if (
        imageData.includes("hits/images") &&
        !imageData.includes("http") &&
        !imageData.includes("https")
      ) {
        data = `${url}${imageData}?token=${token}&uid=${uid}`;
      }
    });
    if (niri_data !== undefined) {
      let imageDataNiri = niri_data.data;
      if (imageDataNiri !== null) {
        downloadImageUrl((url, uid, token) => {
          if (
            imageDataNiri.includes("hits/images") &&
            !imageDataNiri.includes("http") &&
            !imageDataNiri.includes("https")
          ) {
            niri_data.data = `${url}${imageDataNiri}?token=${token}&uid=${uid}`;
          }
        });
      }
    }
    let boundingBoxMap = this.state.boundingBoxMap;
    const rects = [];
    const rectCatMap = {};
    const rectColorMap = {};
    const rectShapeMap = {};
    const notes = {};
    const attributesOption = {};
    let labelGroupMap = {};
    let labelGroupMapSize;
    const { styles } = helper;
    if (boundingBoxMap) {
      let pointIndex = 0;
      for (let index = 0; index < boundingBoxMap.length; index++) {
        let countIndex = Object.keys(labelGroupMap).length;
        let points = boundingBoxMap[index].points;
        let countPoints = this.checkPointsGroupOrNot(points, 0);
        if (countPoints > 2) {
          points.map((element, keyIndex) => {
            rects[pointIndex] = element;
            let labels = [];
            if (typeof boundingBoxMap[index].label === "string") {
              labels.push(boundingBoxMap[index].label);
            } else {
              labels = boundingBoxMap[index].label;
            }
            rectCatMap[pointIndex] = labels;
            labelGroupMap[pointIndex] = "#" + labels[0] + "_" + countIndex;
            if (boundingBoxMap[index].shape) {
              rectShapeMap[pointIndex] = boundingBoxMap[index].shape;
            }

            try {
              labelGroupMapSize = Object.keys(boundingBoxMap[index].labelGroupMap).length
              if(boundingBoxMap.length == 1 && labelGroupMapSize > 1) {
                for(let labelIndex = 0; labelIndex < labelGroupMapSize ; labelIndex++){
                  rectShapeMap[labelIndex] = 'polygon';
                }
                console.log("updated rectShapeMap ", rectShapeMap)
              }
            } catch (exception) {
              console.log("Can't find the labelGroupMap value from the boundingBoxMap", exception)
            }

            if (boundingBoxMap[index].notes) {
              notes[pointIndex] = boundingBoxMap[index].notes;
            }

            if (
              boundingBoxMap[index].attributes &&
              Object.keys(boundingBoxMap[index].attributes).length > 0
            ) {
              attributesOption[pointIndex] = boundingBoxMap[index].attributes;
            } else {
              attributesOption[pointIndex] = this.addEmptyAtttributes();
            }
            if (boundingBoxMap[index].color) {
              rectColorMap[pointIndex] = boundingBoxMap[index].color;
              this.state.hitEntities[pointIndex] = boundingBoxMap[index].color;
            } else {
              if (this.state.boundingBoxMap[pointIndex]) {
                let label = this.state.boundingBoxMap[pointIndex].label[0];
                let colourCode = this.state.entityColorMap[label];
                rectColorMap[pointIndex] = colourCode;
                this.state.hitEntities[pointIndex] = colourCode;
                boundingBoxMap[pointIndex].color = colourCode;
              }
            }
            pointIndex = pointIndex + 1;
            console.log("color pointIndex", pointIndex);
            console.log("color hg", boundingBoxMap[pointIndex - 1]);
            console.log("expects rectColorMap", boundingBoxMap);
            if (boundingBoxMap[pointIndex - 1]) {
              console.log("expects rectColorMap", this.state);
              let label = this.state.boundingBoxMap[pointIndex - 1].label[0];
              console.log("color label", label);
              let colourCode = this.state.entityColorMap[label];
              console.log("color colorcode", colourCode);
              boundingBoxMap[pointIndex - 1].color = colourCode;
              rectColorMap[pointIndex] = colourCode;
              console.log("color boundingBoxMap", boundingBoxMap);
              console.log("color bgsd", this.state);
              console.log(
                "expects more",
                rectColorMap,
                rectColorMap[pointIndex]
              );
            }
          });
        } else {
          console.log("color else", this.state);
          rects[pointIndex] = boundingBoxMap[index].points;
          let labels = [];
          if (typeof boundingBoxMap[index].label === "string") {
            labels.push(boundingBoxMap[index].label);
          } else {
            labels = boundingBoxMap[index].label;
          }
          rectCatMap[pointIndex] = labels;
          labelGroupMap[pointIndex] = labels[0];
          if (boundingBoxMap[index].shape) {
            rectShapeMap[pointIndex] = boundingBoxMap[index].shape;
          }

          try {
            labelGroupMapSize = Object.keys(boundingBoxMap[index].labelGroupMap).length;
            if(boundingBoxMap.length && labelGroupMapSize > boundingBoxMap.length) {
              for(let labelIndex = 0; labelIndex < labelGroupMapSize; labelIndex++){
                rectShapeMap[labelIndex] = 'polygon';
              }
              console.log("updated rectShapeMap ", rectShapeMap)
            }
          } catch (exception) {
            console.log("Can't find the labelGroupMap value from the boundingBoxMap", exception)
          }

          if (boundingBoxMap[index].notes) {
            notes[pointIndex] = boundingBoxMap[index].notes;
          }

          if (
            boundingBoxMap[index].attributes &&
            Object.keys(boundingBoxMap[index].attributes).length > 0
          ) {
            attributesOption[pointIndex] = boundingBoxMap[index].attributes;
          } else {
            attributesOption[pointIndex] = this.addEmptyAtttributes();
          }

          if (boundingBoxMap[index].color) {
            console.log("6003 If", boundingBoxMap[index].color);
            rectColorMap[pointIndex] = boundingBoxMap[index].color;
            this.state.hitEntities[pointIndex] = boundingBoxMap[index].color;
            console.log("6009", this.state, rectColorMap[pointIndex]);
            // this.setState
          } else {
            if (
              this.state.boundingBoxMap[pointIndex] &&
              !boundingBoxMap[index].color
            ) {
              console.log("expects else", rectColorMap[pointIndex]);
              let label = this.state.boundingBoxMap[pointIndex].label[0];
              let colourCode = this.state.entityColorMap[label];
              boundingBoxMap[pointIndex].color = colourCode;
              rectColorMap[pointIndex] = colourCode;
            }
          }
          pointIndex = pointIndex + 1;
        }
      }

      const {
        hitKey,
        hitLabelsLast,
        selectedHitLabel,
        hitLabelAction,
      } = this.state;
      if (hitLabelAction === "add" && hitKey !== "") {
        const currentData = rectCatMap[parseInt(hitKey)];
        if (
          currentData &&
          selectedHitLabel !== "" &&
          !currentData.includes(selectedHitLabel)
        ) {
          currentData.push(selectedHitLabel);
          rectCatMap[hitKey] = currentData;
        }
      } else if (hitLabelAction === "update") {
        var keys = [...this.state.selectedHitLabelIndex];
        keys.map((value) => {
          let currentData = rectCatMap[value];
          if (
            currentData &&
            selectedHitLabel !== "" &&
            !currentData.includes(selectedHitLabel)
          ) {
            currentData.push(selectedHitLabel);
            rectCatMap[value] = currentData;
          }
        });
      }
    }

    const annotatedLabelCount = getAnnotatedLabelsCount(labelGroupMap);

    // Semantic Auto Suggest start
    let entitySuggestList = [];
    if (this.state.entities !== undefined && this.state.entities !== "") {
      let uniqueLabels = this.state.entities.filter(
        (val, id, array) => array.indexOf(val) === id
      );

      uniqueLabels.map((value, index) => {
        let entitySuggestMap = {};
        entitySuggestMap.key = index;
        entitySuggestMap.value = index;
        entitySuggestMap.text = value;
        entitySuggestList.push(entitySuggestMap.text);
      });
      console.log("entitySuggestList", entitySuggestList);
    }
    if (!this.state.open) {
      entitySuggestList = [];
    }

    if (niri_data !== undefined) {
      let res = niri_data.fileName.includes("color");
      if (res === false) {
        niri_data.data = null;
      }
    }
    console.log("rectsrectsrects", rects)

    return (
      <div>
        <Modal
          visible={this.state.open}
          width="400"
          effect="fadeInUp"
          onClickAway={() => this.handleDialogClose}
          style={styles.modalHeight}
        >
          <AnnotationLableModel
            entitySuggestList={entitySuggestList}
            handleDialogClickEvent={this.handleDialogClickEvent}
            handleDialogClose={this.handleDialogClose}
            isEmptyLabel={this.state.isEmptyLabel}
            setLabelValue={this.setLabelValue}
            value={this.state.value}
            showLabelError={this.state.showLabelError}
          />
        </Modal>
        <ImageAnnotator
          ref={node => (this.polyAnnotator = node)}
          space
          shortcuts={this.state.shortcuts}
          continuousTagging={this.state.continuousTagging}
          rectShapeMap={rectShapeMap}
          notes={notes}
          defaultShape={this.state.defaultShape}
          fullScreen={this.state.isFullscreenEnabled}
          loading={this.state.loading}
          hits={this.state.hits}
          hitsNiri={this.state.hitsNiri}
          currentIndex={this.state.currentIndex}
          saveTagAndNextRow={this.saveTagAndNextRow}
          saveRow={this.moveToDone}
          saveAllRow = {(action)=>{this.moveAll(action)}}
          skipRow={this.skipRow}
          getBackTopreviousRow={this.getBackTopreviousRow}
          rects={rects}
          rectCatMap={rectCatMap}
          image={data}
          totalHitsDone={this.props.projectDetails.totalHitsDone}
          niriFilteredHits={this.state.niriFilteredHits}
          imageNiri={niri_data !== undefined ? niri_data.data : null}
          checkHits={this.state.checkHits}
          drawHandle={this.drawPolygon}
          entityColorMap={this.state.entityColorMap}
          entitiesObject={this.state.entityJson}
          labelValue={this.state.labelValue}
          showLabelPopup={this.showLabelPopup}
          checkNewLabel={this.checkNewLabel}
          deleteLabel={this.deleteLabel}
          taskType={this.state.projectDetails.task_type}
          entities={this.state.entities}
          type={this.state.type}
          showModal={this.showModal}
          clearAllImageAnnotatios={this.clearAllImageAnnotatios}
          totalHitsCompleted={this.state.totalHitsCompleted}
          hitScrollCompleted={this.state.hitScrollCompleted}
          totalFilteredHits={this.state.totalFilteredHits}
          notDoneHits={this.state.notDoneHits}
          buttonDisabled={this.state.buttonDisabled}
          changesInSession={this.state.changesInSession}
          saveAnnotation={this.saveAnnotation}
          moveToSkip={this.moveToSkip}
          deleteItem={this.deleteItem}
          retagHit={this.retagHit}
          isDynamicModelOpen={this.state.open}
          isCommentTyping={this.state.commentsType}
          hitEntities={this.state.hitEntities}
          saveHitLabel={this.saveHitLabel}
          hitLabels={this.state.hitLabels}
          autoSave={this.state.isAutosave}
          autoSaveAction={this.state.autoSaveAction}
          hitLabelsArr={this.hitLabelsArr}
          resetHitLabelAction={this.resetHitLabelAction}
          labelGroupMap={labelGroupMap}
          continuousTaggingSelected = {this.continuousTaggingSelected}
          attributes = {this.state.attributes}
          editClassAttribute = {this.state.editClassAttribute}
          attributesOption = {attributesOption}
          annotatedLabelCount = {annotatedLabelCount}
          stroke_width = {this.state.stroke_width}
          imagesLoad = {this.state.imagesLoad}
          mergingGroupLabelButtonSwitch={this.mergingGroupLabelButtonSwitch}
          mergeGroupLabel={this.state.mergeGroupLabel}
          setretainAnnotations={this.setretainAnnotations}
          setretainIndex={this.setretainIndex}
          opacityValue={this.state.opacityValue}
          retainAnnotations={this.state.retainAnnotations}
          setSlicedHits={this.setSlicedHits}
          pushState={this.props.pushState}
          params={this.props.params}
        />
      </div>
    );
  }

  showVideoAnnotation() {
    // using a generator function
    const { data } = this.state.currentHit;
    const boundingBoxMap = this.state.boundingBoxMap;
    const rects = [];
    const rectCatMap = {};
    const rectShapeMap = {};
    const rectTimeMap = {};
    const endTimeMap = {};
    const rectColorMap = {};
    const notes = {};
    const attributesOption = {};
    let labelGroupMap = {};
    let pointIndex = 0;
    const slidervalues = []
    const { boundingValues } = this.state;
    if (this.state.boundingBoxMap) {
      for (let index = 0; index < boundingBoxMap.length; index++) {
        rects[index] = [];
        let timeMap = [];
        for (let jindex = 0; jindex < boundingBoxMap[index].positions.length; jindex++) {
          rects[index].push(boundingBoxMap[index].positions[jindex].points);
          timeMap.push(boundingBoxMap[index].positions[jindex].time)
        }
        if(this.state.boundingBoxMap[index].boundingSlideValues.length > 0){
          for (let jindex = 0; jindex < this.state.boundingBoxMap[index].boundingSlideValues.length; jindex++) {
            slidervalues.push(this.state.boundingBoxMap[index].boundingSlideValues[jindex]);
        }}
        if (timeMap.length === 1) {
          timeMap.push(undefined);
        }
        // rects[index] = boundingBoxMap[index].positions[0].points;
        let labels = [];
          if (typeof boundingBoxMap[index].label === 'string') {
            labels.push(boundingBoxMap[index].label);
          } else {
            labels = boundingBoxMap[index].label;
          }
        rectCatMap[pointIndex] = labels;
        // rectCatMap[index] = boundingBoxMap[index].label;
        if (boundingBoxMap[index].shape) {
          rectShapeMap[index] = boundingBoxMap[index].shape;
        }
        if (boundingBoxMap[index].notes) {
          notes[pointIndex] = boundingBoxMap[index].notes;
        }
        rectTimeMap[index] = timeMap;
        endTimeMap[index] = boundingBoxMap[index].endTime;
        labelGroupMap[pointIndex] = labels[0];
        if (boundingBoxMap[index].color) {
          rectColorMap[pointIndex] = boundingBoxMap[index].color;
          this.state.hitEntities[pointIndex] = boundingBoxMap[index].color;
        }
        if (boundingBoxMap[index].attributes && Object.keys(boundingBoxMap[index].attributes).length > 0) {
            attributesOption[pointIndex] = boundingBoxMap[index].attributes;
          }else {
            attributesOption[pointIndex] =  this.addEmptyAtttributes();
          }
          pointIndex = pointIndex + 1;
      }
    }
    if(boundingValues && this.state.boundingBoxMap && this.state.boundingBoxMap.length == 0){
      for (let jindex = 0; jindex < boundingValues.length; jindex++) {
        slidervalues.push(boundingValues[jindex]);
    }
    }
    return (
      <div>
        <VideoAnnotator
          space={this.state.type === HIT_STATE_NOT_DONE}
          hits={this.state.hits}
          shortcuts={this.state.shortcuts}
          rectShapeMap={rectShapeMap}
          endTimeMap={endTimeMap}
          rectTimeMap={rectTimeMap}
          defaultShape="rectangle"
          fullScreen={this.state.isFullscreenEnabled}
          currentIndex={this.state.currentIndex}
          loading={this.state.loading}
          saveTagAndNextRow={this.saveTagAndNextRow}
          moveToDone={this.moveToDone}
          skipRow={this.skipRow}
          getBackTopreviousRow={this.getBackTopreviousRow}
          rects={rects}
          slidevalues = {slidervalues}
          rectCatMap={rectCatMap}
          video={data}
          drawHandle={this.drawVideo}
          entityColorMap={this.state.entityColorMap}
          hitScrollCompleted={this.state.hitScrollCompleted}
          handleIsSaveAction={this.handleIsSaveAction}
          attributes = {this.state.attributes}
          editClassAttribute = {this.state.editClassAttribute}
          notes={notes}
          attributesOption = {attributesOption}
          hitEntities={this.state.hitEntities}
          entities={this.state.entities}
          hitLabels={this.state.hitLabels}
          labelGroupMap={labelGroupMap}
          saveHitLabel={this.saveHitLabel}
          taskType={this.state.projectDetails.task_type}
          type={this.state.type}
        />
      </div>
    );
  }

  showPolygonBoundingImages() {
    // using a generator function
    const { data } = this.state.currentHit;
    let imageData = data;
    downloadImageUrl((url, uid, token) => {
      if (
        imageData.includes("hits/images") &&
        !imageData.includes("http") &&
        !imageData.includes("https")
      ) {
        imageData = `${url}${imageData}?token=${token}&uid=${uid}`;
      }
    });
    const boundingBoxMap = this.state.boundingBoxMap;

    const rects = [];
    const rectCatMap = {};
    if (this.state.boundingBoxMap) {
      for (let index = 0; index < boundingBoxMap.length; index++) {
        rects[index] = boundingBoxMap[index].points;
        rectCatMap[index] = boundingBoxMap[index].label;
      }
    }
    return (
      <div className={styles.tagArea}>
        <ImageAnnotator
          space={this.state.type === HIT_STATE_NOT_DONE}
          hits={this.state.hits}
          shortcuts={this.state.shortcuts}
          fullScreen={this.state.isFullscreenEnabled}
          currentIndex={this.state.currentIndex}
          loading={this.state.loading}
          saveTagAndNextRow={this.saveTagAndNextRow}
          skipRow={this.skipRow}
          getBackTopreviousRow={this.getBackTopreviousRow}
          rects={rects}
          rectCatMap={rectCatMap}
          image={data}
          drawHandle={(this.state.type === HIT_STATE_DONE || this.state.type === HIT_STATE_SKIPPED || this.state.type === HIT_STATE_DELETED || this.state.type === HIT_STATE_NOT_DONE) ? this.drawPolygon : undefined}
          entityColorMap={this.state.entityColorMap}
          type={this.state.type}
          showModal={this.showModal}
          clearAllImageAnnotatios={this.clearAllImageAnnotatios}
        />
      </div>
    );
  }

  showBoundingImages() {
    // using a generator function
    const { data } = this.state.currentHit;
    let imageData = data;
    downloadImageUrl((url, uid, token) => {
      if (
        imageData.includes("hits/images") &&
        !imageData.includes("http") &&
        !imageData.includes("https")
      ) {
        imageData = `${url}${imageData}?token=${token}&uid=${uid}`;
      }
    });
    const boundingBoxMap = this.state.boundingBoxMap;
    const rects = [];
    const rectCatMap = {};
    const notes = {};
    if (this.state.boundingBoxMap) {
      for (let index = 0; index < boundingBoxMap.length; index++) {
        const points = {};
        points.x1 = boundingBoxMap[index].points[0].x;
        points.x2 = boundingBoxMap[index].points[1].x;

        points.y1 = boundingBoxMap[index].points[0].y;
        points.y2 = boundingBoxMap[index].points[1].y;

        rects.push(points);
        rectCatMap[index] = boundingBoxMap[index].label;
        notes[index] = boundingBoxMap[index].notes;
      }
    }
    return (
      <div className={styles.tagArea} style={styles.lineHeight}>
        <BoxAnnotator
          loading={this.state.loading}
          space={this.state.type === HIT_STATE_NOT_DONE}
          hits={this.state.hits}
          currentIndex={this.state.currentIndex}
          fullScreen={this.state.isFullscreenEnabled}
          saveTagAndNextRow={this.saveTagAndNextRow}
          skipRow={this.skipRow}
          shortcuts={this.state.shortcuts}
          getBackTopreviousRow={this.getBackTopreviousRow}
          autoClose={this.state.autoClose}
          hideLabels={this.state.hideLabels}
          noteSettings={this.state.notes}
          menuHidden={this.props.menuHidden}
          notes={notes}
          rects={rects}
          rectCatMap={rectCatMap}
          image={data}
          drawHandle={(this.state.type === HIT_STATE_DONE || this.state.type === HIT_STATE_SKIPPED || this.state.type === HIT_STATE_DELETED || this.state.type === HIT_STATE_NOT_DONE) ? this.drawHandle : undefined}
          entityColorMap={this.state.entityColorMap}
          type={this.state.type}
          showModal={this.showModal}
          clearAllImageAnnotatios={this.clearAllImageAnnotatios}
        />
      </div>
    );
  }

  showModalForClassification = () => {
    this.setState({
      classificationModalVisible: true,
      dynamicClassificationLabel: "",
    });
  };

  handleOkForClassification = (e) => {
    console.log(e);
    if (
      this.state.dynamicClassificationLabel &&
      this.state.dynamicClassificationLabel.length > 0
    ) {
      this.setState({ classificationModalVisible: false });
      this.props.setloaderMenu(true);
      let labelvalue = { labelName: this.state.dynamicClassificationLabel };
      updateNewLabelName(
        this.state.currentHit.id,
        this.props.currentProject,
        labelvalue,
        NEW_LABEL_UPDATE,
        this.classificationLabelUpdatedCallback.bind(this)
      ); // Saving new Label Name
    } else {
      showAlert(MESSAGES.LABEL_NOT_EMPTY, ALERT_TYPES.ERROR);
    }
  };

  classificationLabelUpdatedCallback = (err, response) => {
    if (!err) {
      const responseData = response.body;
      const taskLabels = responseData
        ? JSON.parse(responseData.taskRules)
        : { tags: "" };
      const enitity = taskLabels.tags;
      let entityColorMap = null;
      let tagsEntitiy = enitity.toString();
      if (enitity !== "") {
        tagsEntitiy = enitity.toString().split(",");
      }

      this.setState({ entities: tagsEntitiy });
      entityColorMap = createDocEntityColorMap(tagsEntitiy, ENTITY_COLORS);

      if (responseData.task_type !== "null") {
        let shortcuts = getDetaultShortcuts(
          responseData.task_type,
          tagsEntitiy
        );
        let projectDetails = response.body;
        const rules = JSON.parse(projectDetails.taskRules);
        if ("shortcuts" in rules) {
          shortcuts = rules.shortcuts;
        }
        this.setState({
          entityColorMap: entityColorMap,
          shortcuts: shortcuts,
          entities: tagsEntitiy,
        });
      }
      showAlert(MESSAGES.LABEL_CREATE_MESSAGE, ALERT_TYPES.SUCCESS);
      this.props.setloaderMenu(false);
    } else {
      if (
        response &&
        response.body &&
        response.body.code &&
        response.body.code === 401
      ) {
        this.props.setloaderMenu(false);
        refreshUidToken(() => {
          this.setState({ loading: false });
        });
      } else {
        captureException(err);
      }
    }
  };

  handleCancelForClassification = (e) => {
    console.log(e);
    this.setState({
      classificationModalVisible: false,
      dynamicClassificationLabel: "",
    });
  };

  handleClassificationOnChange = (e) => {
    this.setState({ dynamicClassificationLabel: e.target.value });
  };

  handleKeyDownpress = (event) => {
    if (
      parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN ||
      parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR
    ) {
      if (event.keyCode === 13) {
        if (
          this.state.dynamicClassificationLabel &&
          this.state.dynamicClassificationLabel.length > 0
        ) {
          this.setState({ classificationModalVisible: false });
          this.props.setloaderMenu(true);
          let labelvalue = { labelName: this.state.dynamicClassificationLabel };
          updateNewLabelName(
            this.state.currentHit.id,
            this.props.currentProject,
            labelvalue,
            NEW_LABEL_UPDATE,
            this.classificationLabelUpdatedCallback.bind(this)
          ); // Saving new Label Name
        } else {
          showAlert(MESSAGES.LABEL_NOT_EMPTY, ALERT_TYPES.ERROR);
        }
      }
    }
  };

  addClassificationDynamicLabels = () => {
    if (this.state.classificationModalVisible) {
      return (
        <div>
          <Modal
            visible={this.state.classificationModalVisible}
            width="400"
            effect="fadeInUp"
            onClickAway={() => this.handleCancelForClassification}
            style={styles.modalHeight}
          >
            <div className="model_popup_con">
              <h1>{LABELS.ADD_DYNAMIC_LABEL}</h1>
              <AntInput
                className="input_popup"
                placeholder={LABELS.ENTER_LABEL}
                maxLength={50}
                onChange={(e) => this.handleClassificationOnChange(e)}
                onKeyDown={this.handleKeyDownpress}
              />
              <div className="model_submit m-b-10">
                <Button
                  className="btn_cancel"
                  onClick={this.handleCancelForClassification}
                >
                  {CONFIRMATION_BOX.CONFIRMATION_MODAL_CANCEL}
                </Button>
                <Button
                  className="btn_apply"
                  onClick={this.handleOkForClassification}
                >
                  {" "}
                  {CONFIRMATION_BOX.CONFIRMATION_MODAL_OK}
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      );
    }
  };
  showClassifyTags(flag, direct) {
    const arrs = [];
    const entiti = this.state.entities;
    const shortcuts = this.state.shortcuts;
    const renderArrs = [];
    const renderColorArrs = [];
    //fun for document annotation colormap  by monica
    let colorMap = cloneDeep(this.state.entityColorMap);
    let bgColor = [];
    for (let i = 0; i < entiti.length; i++) {
      let entity = colorMap[entiti[i]];
      const isArray = Array.isArray(entity);
      if (isArray) {
        if (entity.length > 0) {
          renderColorArrs.push(entity[0]);
          colorMap[entiti[i]] = colorMap[entiti[i]].slice(1);
        } else {
          renderColorArrs.push(entity[0]);
        }
      }
    }
    //
    for (let index = 0; index < entiti.length; index++) {
      if (
        !this.state.searchQuery ||
        this.state.searchQuery.length === 0 ||
        entiti[index]
          .toUpperCase()
          .includes(this.state.searchQuery.toUpperCase())
      ) {
        const entity = entiti[index];
        let combo = "";
        if (entity in shortcuts && ((flag && flag !== "false") || !flag)) {
          combo = convertKeyToString(shortcuts[entity]);
          if (this.state.currentIndex >= 0) {
            Mousetrap.bind(combo, this.setClassification.bind(this, entity));
          }
        }
        let margin = "1% 5% 3% 5%";
        if (!direct) {
          margin = "0.2%";
        }
        renderArrs.push(
          <Label
            disabled={
              parseInt(getUidToken().roleId) === ROLES_TYPES.GUEST ||
              parseInt(getUidToken().roleId) === ROLES_TYPES.QA
                ? true
                : false
            }
            style={{
              color: "white",
              margin: `${margin}`,
              cursor: "pointer",
              backgroundColor:
                renderColorArrs && renderColorArrs.length > 1
                  ? renderColorArrs[index]
                  : this.state.entityColorMap[entity], // Domcument annotation color map by monica
            }}
            compact
            size="mini"
            name={entity}
            className="t_flabel"
            onClick={this.setClassification.bind(this, entity)}
            key={index}
          >
            <span
              className={
                "txtLenghtTrim " + (entity.length > 15 ? "pro_width" : "")
              }
              title={entity.length > 15 ? entity : ""}
              disabled={
                parseInt(getUidToken().roleId) === ROLES_TYPES.GUEST ||
                parseInt(getUidToken().roleId) === ROLES_TYPES.QA
                  ? true
                  : false
              }
            >
              {entity}
            </span>
            {combo && <p style={{ fontSize: "xx-small" }}>({combo})</p>}
          </Label>
        );
      }
    }
    if (direct && direct === "vertical") {
      let height = "60%";
      let minSearchEntities = 9;
      if (this.state.type !== "notDone") {
        height = "80%";
        minSearchEntities = 15;
      }
      return (
        <div
          className="txt-labels"
          disabled={
            parseInt(getUidToken().roleId) === ROLES_TYPES.GUEST ||
            parseInt(getUidToken().roleId) === ROLES_TYPES.QA
              ? true
              : false
          }
        >
          <div className="l_sidebox m-t-5">
            <div className="flexLayout m-t-10 m-b-10">
              <Label className="t_label p-0"> Select Label </Label>
              <div title="Add Dynamic Label">
                <Button
                  size="mini"
                  color={this.state.keepEntitySelected ? "blue" : "grey"}
                  onClick={() => this.showModalForClassification()}
                  className="icon btn_addlabel"
                  style={{
                    display: displaySettingsByRole(getUidToken().roleId),
                  }}
                >
                  <Icon id="d_icon" name="add circle" />
                </Button>
              </div>
            </div>
            <div style={{ boxShadow: "4px 4px 4px rgba(85,85,85,0.1)" }}>
              <Input
                value={this.state.searchQuery}
                className="w-100 search_input m-b-10"
                onChange={(event) =>
                  this.setState({ searchQuery: event.target.value })
                }
                placeholder="Search..."
              />
            </div>
            <div
              className="option_scroll"
              disabled={
                parseInt(getUidToken().roleId) === ROLES_TYPES.GUEST ||
                parseInt(getUidToken().roleId) === ROLES_TYPES.QA
                  ? true
                  : false
              }
            >
              {arrs}
            </div>
            <div
              className="bundle-set"
              disabled={
                parseInt(getUidToken().roleId) === ROLES_TYPES.GUEST ||
                parseInt(getUidToken().roleId) === ROLES_TYPES.QA
                  ? true
                  : false
              }
            >
              {renderArrs}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div style={styles.arsStyle} className="render_height">
        {renderArrs}
      </div>
    );
  }

  deleteItem(e) {
    this.deleteItemAfterUpdateFromS3(e);
    if (
      this.props.projectDetails.task_type === IMAGE_MASKING ||
      this.props.projectDetails.task_type === IMAGE_SEGMENTATION
    ) {
      this.mergingGroupLabelButtonSwitch(false);
      this.setState({ buttonDisabled: true });
      this.deleteAnnotationsFromS3(this, "Deleted");
    }
  }

  deleteItemAfterUpdateFromS3 = (e) => {
    if (undefined !== e) e.preventDefault();
    logEvent("buttons", "delete");
    logEvent("Mark As", "Delete");
    this.setState({ updateAnnotatorInfo: true });
    if (this.state.confirmModalVisible) {
      this.removeLastAnnotation();
    }
    let result = "";
    const {
      currentHit,
      changesInSession,
      projectDetails,
      currentTags,
      currentHitNiri,
    } = this.state;
    if (changesInSession > 0) {
      result = this.getCurrentResult();
    } else if (currentHit.result && currentHit.result.length > 0) {
      result = currentHit.result;
    } else if (currentHit.hitResults && currentHit.hitResults.length > 0) {
      console.log("check currentHit 6610", currentHit);
      result = currentHit.hitResults[0].result;
    }
    console.log("check moveToDone only 6600");
    this.setState({ loading: true, action: "moveToDone" });
    console.log("check hitstatus 6585");
    updateHitStatus(
      currentHit.id,
      this.props.currentProject,
      HIT_STATE_DELETED,
      result,
      this.moveToDoneCallback.bind(this)
    );
    currentHitNiri !== undefined && updateHitStatus(
        currentHitNiri.id,
        this.props.currentProject,
        HIT_STATE_DELETED,
        result,
        this.moveToDoneCallbackNiri.bind(this)
      );
    this.setState({ confirmModalVisible: false });
    this.state.currentHit.status = "re-Deleted";
  };

  removeLastAnnotation(e) {
    //try to remove last annotation , delete the annotation
    if (undefined !== e) e.preventDefault();
    const { currentHit, projectDetails } = this.state;
    if (
      this.state.confirmModalVisible &&
      projectDetails.task_type === IMAGE_CLASSIFICATION
    ) {
      this.setState({ currentTags: new Set() });
      this.state.currentTags = new Set();
    } else if (
      this.state.confirmModalVisible &&
      (projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX ||
        projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX_V2 ||
        projectDetails.task_type === IMAGE_SEGMENTATION ||
        projectDetails.task_type === IMAGE_MASKING ||
        projectDetails.task_type === IMAGE_BOUNDING_BOX)
    ) {
      this.state.boundingBoxMap = [];
    }
    if (currentHit.result) {
      currentHit.result = null;
    }
    if (currentHit.hitResults) {
      currentHit.hitResults = null;
    }
  }

  retagHit(e) {
    this.requeueAfterUpdateFromS3(e);
    if (
      this.props.projectDetails.task_type === IMAGE_MASKING ||
      this.props.projectDetails.task_type === IMAGE_SEGMENTATION
    ) {
      this.mergingGroupLabelButtonSwitch(false);
      this.setState({ buttonDisabled: true });
      this.deleteAnnotationsFromS3(this, "Requeue");
    }
  }

  requeueAfterUpdateFromS3 = (e) => {
    if (undefined !== e) e.preventDefault();
    logEvent("buttons", "Retag");
    logEvent("Mark As", "Retag");
    const {
      currentHit,
      changesInSession,
      currentTags,
      projectDetails,
      currentHitNiri
    } = this.state;
    console.log("check moveToDone only 6645");
    this.setState({ loading: true, action: "moveToDone" });
    if (this.state.confirmModalVisible) {
      this.removeLastAnnotation();
    }
    let result = "";
    if (changesInSession > 0) {
      result = this.getCurrentResult();
    } else if (
      undefined !== currentHit &&
      currentHit.result &&
      currentHit.result.length > 0
    ) {
      result = currentHit.result;
    } else if (
      undefined !== currentHit &&
      currentHit.hitResults &&
      currentHit.hitResults.length > 0
    ) {
      result = currentHit.hitResults[0].result;
    }

    this.state.currentHit.result = result;
    console.log("check hitstatus 6639 ");
    updateHitStatus(
      currentHit.id,
      this.props.currentProject,
      HIT_STATE_REQUEUED,
      result,
      this.moveToDoneCallback.bind(this)
    );
    if(currentHitNiri !== undefined)
    {
      updateHitStatus(
        currentHitNiri.id,
        this.props.currentProject,
        HIT_STATE_REQUEUED,
        result,
        this.moveToDoneCallbackNiri.bind(this)
      )
    }
    this.setState({ confirmModalVisible: false });
    this.state.currentHit.status = "re-Queued";
  };

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  handleLabelChange = (value) => {
    let result = "";
    let elementList = [];
    const { currentHit, changesInSession } = this.state;
    console.log("check handleLabelChange", currentHit);
    result = currentHit.hitResults[0].result;
    let jsonObj = JSON.parse(result);
    this.setState({ entityColorMap: [] });
    jsonObj.annotationResult.forEach((element) => {
      element.label.map((result) => {
        if (!value instanceof Array && value === "clearAll") {
          elementList.push(element);
        } else if (value instanceof Array && value.length > 0) {
          // in case of multiple selection start
          value.map((taggedLabel) => {
            let taggedLabelstr = taggedLabel.split(":");

            if (taggedLabelstr[0] === result.value) {
              element.points.map((res) => {
                res.color = [];
                res.color.push(taggedLabelstr[1]);
              });
              elementList.push(element);
            }
          });
          // end
        } else if (value instanceof Array && value.length === 0) {
          elementList.push(element);
        }
      });
    });

    this.setState({ labelBoundingBoxDoc: elementList });

    if (value instanceof Array && value.length > 0) {
      this.setState({ selectedLabel: value });
      value.map((colors) => {
        let entityColorMap = [];
        let colorslab = colors.split(":");
        let color = { Label: colorslab[0], Color: colorslab[1] };
        entityColorMap.push(color);
        this.setState({ entityColorMap });
      });
    } else {
      this.setState({ selectedLabel: "" });
    }

    this.showDocumentAnnotation();
  };

  drawVideo = (obj, callback, param) => {
    return new Promise((resolve, reject) => {
      const boundingBoxMap = [];
      let attributes = undefined;
      let changesInSession = 0;
      for (const key of Object.keys(obj.rects)) {
        const timeMap = obj.rectTimeMap[key];
        let note = '';
        let shape = "polygon";
        if (obj.rectShapeMap) {
          shape = obj.rectShapeMap[key];
        }
        const positions = [];
        for (let jindex = 0; jindex < timeMap.length; jindex++) {
          if (timeMap[jindex] !== undefined) {
            positions.push({ points: obj.rects[key][jindex], time: timeMap[jindex] });
          }
        }
        if (obj.attributesOption && key in obj.attributesOption) {
          attributes = Object.keys(obj.attributesOption[key]).length > 0 ? obj.attributesOption[key] : undefined;
        }
        if (obj.notes && key in obj.notes) {
          note = obj.notes[key];
        }
        boundingBoxMap.push({
          startTime: timeMap[0],
          endTime: obj.endTimeMap[key],
          label: obj.rectCatMap[key],
          shape,
          positions,
          videoWidth: obj.player.videoWidth,
          videoHeight: obj.player.videoHeight,
          attributes: attributes,
          color: obj.rectColorMap ? obj.rectColorMap[key] : obj.currentColor,
          notes: note,
          label: obj.rectCatMap[key],
          boundingSlideValues: obj.slidevalues
        });
        changesInSession = changesInSession + 1;
      }
      if (
        this.state.boundingBoxMap &&
        this.state.boundingBoxMap.length > 0 &&
        Object.keys(obj.rects).length === 0
      ) {
        changesInSession = changesInSession + 1;
      }
      this.setState({ boundingValues : obj.slidevalues})
      this.setState({ changesInSession, boundingBoxMap }, () => {
        if (callback) callback(param);
      });
      resolve();
    });
  };

  sortEntities = (a, b) => {
    a = a.toLowerCase();
    b = b.toLowerCase();
    if (a > b) {
      return 1;
    } else if (a < b) {
      return -1;
    } else if (a === b) {
      return 0;
    }
  };

  showActionButtons() {
    if (
      parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN ||
      parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR
    ) {
      return (
        <div
          className="action_btns"
          style={{ display: displaySettingsByRole(getUidToken().roleId) }}
        >
          {(this.state.type === HIT_STATE_SKIPPED ||
            this.state.type === HIT_STATE_DONE ||
            this.state.type === HIT_STATE_PRE_TAGGED ||
            this.state.type === HIT_STATE_REQUEUED ||
            this.state.type === HIT_EVALUATION_CORRECT ||
            this.state.type === HIT_EVALUATION_INCORRECT ||
            this.state.type === HIT_STATE_ALL) && (
            <a
              className={`status_link ${this.btnDisable() ? "a_disabled" : ""}`}
              href="#"
              onClick={this.moveToDone.bind(this, "moveToDone")}
            >
              {" "}
              <Icon name="check" /> Move to Done
            </a>
          )}
          {(this.state.type === HIT_STATE_DONE ||
            this.state.type === HIT_STATE_PRE_TAGGED ||
            this.state.type === HIT_STATE_REQUEUED ||
            this.state.type === HIT_STATE_DELETED ||
            this.state.type === HIT_EVALUATION_CORRECT ||
            this.state.type === HIT_EVALUATION_INCORRECT ||
            this.state.type === HIT_STATE_ALL) && (
            <a
              className={`status_link ${this.btnDisable() ? "a_disabled" : ""}`}
              href="#"
              onClick={this.moveToSkip.bind(this)}
            >
              {" "}
              <Icon name="share" /> Mark as Skipped
            </a>
          )}

          {(this.state.type === HIT_STATE_SKIPPED ||
            this.state.type === HIT_STATE_DONE ||
            this.state.type === HIT_STATE_PRE_TAGGED ||
            this.state.type === HIT_STATE_REQUEUED ||
            this.state.type === HIT_EVALUATION_CORRECT ||
            this.state.type === HIT_EVALUATION_INCORRECT ||
            this.state.type === HIT_STATE_ALL) && (
            <a
              className={`status_link color_red ${
                this.btnDisable() ? "a_disabled" : ""
              }`}
              href="#"
              onClick={this.deleteItem.bind(this)}
            >
              {" "}
              <Icon name="trash alternate icon" style={styles.icnStyle} />{" "}
              Delete
            </a>
          )}
          {(this.state.type === HIT_STATE_DONE ||
            this.state.type === HIT_STATE_SKIPPED ||
            this.state.type === HIT_STATE_PRE_TAGGED ||
            this.state.type === HIT_STATE_DELETED ||
            this.state.type === HIT_EVALUATION_CORRECT ||
            this.state.type === HIT_EVALUATION_INCORRECT ||
            this.state.type === HIT_STATE_ALL) && (
            <a
              className={`status_link ${this.btnDisable() ? "a_disabled" : ""}`}
              href="#"
              onClick={this.retagHit.bind(this)}
            >
              <Icon name="redo" /> Requeue
            </a>
          )}

          {this.state.confirmModalVisible && (
            <AntModal
              className="hit_model"
              style={{ display: displaySettingsByRole(getUidToken().roleId) }}
              title=""
              visible={this.state.confirmModalVisible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              footer={[
                <Button
                  className="btn_cancel"
                  key="cancel"
                  onClick={this.handleCancel}
                >
                  Cancel
                </Button>,
              ]}
            >
              {this.actionModalForSkipped() &&
                (parseInt(getUidToken().roleId) ===
                  parseInt(ROLES_TYPES.ADMIN) ||
                  parseInt(getUidToken().roleId) ===
                    parseInt(ROLES_TYPES.ANNOTATOR))}
            </AntModal>
          )}
        </div>
      );
    }
  }

  evaluateHit(value, e) {
    if (undefined !== e) e.preventDefault();
    logEvent("evaluation", value);
    const { currentHit, currentHitNiri } = this.state;
    this.setState({ loding: true });
    addHitEvaluation(
      currentHit.id,
      this.props.currentProject,
      value,
      this.evaluationCallback.bind(this, value)
    );
    currentHitNiri !== undefined &&
       addHitEvaluation(
          currentHitNiri.id,
          this.props.currentProject,
          value,
          this.evaluationCallback.bind(this, value)
        );
  }

  showEvaluationButtons() {
    this.state.searchHitUrl = null;
    return (
      <div className="eva_btn">
        <a
          href="#"
          className={`btn_base btn_incorrect custom_btn_complete ${
            this.btnDisable() ? "a_disabled" : ""
          }`}
          onClick={
            this.state.hitEvaluation === "INCORRECT"
              ? this.evaluateHit.bind(this, "none")
              : this.evaluateHit.bind(this, "INCORRECT")
          }
        >
          {" "}
          <AntIcon className="m-r-5" type="close" /> INCORRECT
        </a>
        <a
          href="#"
          className={`btn_base btn_correct m-l-10 custom_btn_complete ${
            this.btnDisable() ? "a_disabled" : ""
          }`}
          onClick={
            this.state.hitEvaluation === "CORRECT"
              ? this.evaluateHit.bind(this, "none")
              : this.evaluateHit.bind(this, "CORRECT")
          }
        >
          {" "}
          <AntIcon className="m-r-5" type="check" /> CORRECT
        </a>
      </div>
    );
  }

  nextRow(action, e) {
    const {
      totalHitsVal,
      currentIndexValue,
      type,
      currentIndex,
      changesInSession,
      currentHit,
      currentHitNiri,
      label,
      dateFrom,
      dateTo,
      evaluationType,
      totalFilteredHits,
      hits,
      hitUrl,
    } = this.state;
    const isLastPage = currentIndex === 9 || currentIndex === currentIndexValue;
    let autoFlag = false;
    const result = this.checkResults(changesInSession, currentHit);
    if (!label && !dateFrom && !dateTo && !evaluationType && !hitUrl) {
      if (
        action === "next" &&
        (type === "done" || type === "all") &&
        isLastPage &&
        !currentHitNiri &&
        hits.length !== totalFilteredHits
      ) {
        const nextHitsVal = totalHitsVal - 10;
        this.setState({ imageLoad: true });
        this.loadProjectDetails(
          this.props.currentProject,
          nextHitsVal,
          totalHitsVal
        );
        this.setState({
          totalHitsVal: nextHitsVal,
          currentIndexValue: currentIndexValue + 10,
        });
      }
      let indexval = currentIndexValue - currentIndex;
      const isLastPage1 = currentIndex === 4 || indexval % 5 === 0;
      const prevNext = indexval - currentIndex;
      if (
        action === "next" &&
        (type === "done" || type === "all") &&
        currentHitNiri &&
        isLastPage1 &&
        (prevNext === 1 || (prevNext !== 11 && prevNext % 10 !== 1)) &&
        hits.length !== totalFilteredHits / 2
      ) {
        const nextHitsVal1 = totalHitsVal - 10;
        this.setState({ imageLoad: true });
        this.loadProjectDetails(
          this.props.currentProject,
          nextHitsVal1,
          totalHitsVal
        );
        this.setState({
          totalHitsVal: nextHitsVal1,
          currentIndexValue: currentIndexValue + 10,
        });
      }
    }
    if (undefined !== e) e.preventDefault();
    logEvent("buttons", "Next Row");
    logEvent("navigation", "Next");
    this.setState({ updateAnnotatorInfo: true });
    if (
      this.state.changesInSession > 0 &&
      this.state.type != HIT_STATE_DONE &&
      this.state.type != HIT_STATE_ALL &&
      parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN
    ) {
      const confirm = AntModal.confirm;
      const that = this;
      confirm({
        title: "Confirmation",
        content:
          "Your unsaved changes will be lost. Are you sure you want to leave this page?",
        okText: "OK",
        cancelText: "CANCEL",
        onOk() {
          that.setState({ changesInSession: 0 });
          that.hitAddCallback(undefined, "Ok", action);
        },
        onCancel() {},
      });
    } else {
      this.action = undefined;
      if (action === "next") {
        this.callsaveTagAndNextRow();
      } else {
        this.callgetBackTopreviousRow();
      }
      // handle video project next and previous button api call
      // this.state.changesInSession > 0
      //   && this.drawImageAutoSaveAction(autoFlag, result)
    }
    return false;
  }

  btnDisable = () =>
    this.state.selectedLabel === "" &&
    this.state.selectedLabel.length === 0 &&
    this.state.buttonDisabled;

  showButtonsMini() {
    const {
      totalFilteredHits,
      currentIndex,
      type,
      hits,
      currentHitNiri,
      hitScrollCompleted,
      totalHitsCompleted,
    } = this.state;
    let totalHitsData = totalFilteredHits;
    if (this.state.type === "done" && !currentHitNiri) {
      totalHitsData = this.props.location.query.totalHitsDoneData;
    }
    const { styles } = helper;
    let nextButtonDisabled =
      currentIndex < 0 ||
      (hitScrollCompleted && currentIndex > hits.length - 2) ||
      (totalHitsCompleted && currentIndex > hits.length - 2) ||
      currentIndex === totalFilteredHits - 1;

    if ("shortcuts" in this.state) {
      const shortcuts = this.state.shortcuts;
      if ("next" in shortcuts) {
        this.mergingGroupLabelButtonSwitch(false);
        const combo = convertKeyToString(shortcuts.next);
        // nextButton = 'Next (' + combo + ')';
        if (!nextButtonDisabled) {
          Mousetrap.bind(combo, this.nextRow.bind(this, "next"));
        } else {
          Mousetrap.unbind(combo);
        }
      }
      if ("previous" in shortcuts) {
        this.mergingGroupLabelButtonSwitch(false);
        const combo = convertKeyToString(shortcuts.previous);
        // prevButton = 'Previous (' + combo + ')';
        if (this.state.currentIndex > 0) {
          Mousetrap.bind(combo, this.nextRow.bind(this, "previous"));
        } else {
          Mousetrap.unbind(combo);
        }
      }
      if (
        "moveToDone" in shortcuts &&
        (parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN ||
          parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR)
      ) {
        const combo = convertKeyToString(shortcuts.moveToDone);
        // prevButton = 'Previous (' + combo + ')';
        if (
          this.state.type === HIT_STATE_SKIPPED ||
          this.state.type === HIT_STATE_PRE_TAGGED ||
          this.state.type === HIT_STATE_REQUEUED
        ) {
          this.mergingGroupLabelButtonSwitch(false);
          Mousetrap.bind(combo, this.moveToDone.bind(this));
        } else {
          Mousetrap.unbind(combo);
        }
      }
    }
    let nextButtonValue;
    if (
      totalFilteredHits - 1 == currentIndex ||
      (type !== "all" && type !== "done") ||
      hits.length === 1 ||
      hits.length === 0
    ) {
      nextButtonValue = false;
    } else {
      nextButtonValue = true;
    }
    if (currentHitNiri !== undefined) {
      let totalLastIndexval = totalHitsData / 2;
      if (
        (type === "done" && currentIndex === totalLastIndexval - 1) ||
        this.state.hits.length === 1
      ) {
        nextButtonDisabled = true;
        nextButtonValue = false;
      } else if (
        (type === "all" && currentIndex === totalLastIndexval - 1) ||
        this.state.hits.length === 1
      ) {
        nextButtonDisabled = true;
        nextButtonValue = false;
      }
    }

    return (
      <div className="crt_set">
        {this.state.currentIndex <= 0 && (
          <span className="ctrl_btn btn_default">
            <img alt="left" src={LEFT} />
          </span>
        )}

        {this.state.currentIndex > 0 && (
          <a
            href="#"
            className={`ctrl_btn btn_default ${
              this.btnDisable() ? "a_disabled" : "btn_active"
            }`}
            title="Previous"
            onClick={this.nextRow.bind(this, "previous")}
          >
            <img alt="left" src={LEFT} />
          </a>
        )}

        {this.state.type === "notDone" &&
          this.state.projectDetails.task_type !== POS_TAGGING && (
            <a
              href="#"
              className={
                "ctrl_btn btn_active m-l-5 " +
                (this.state.currentIndex < 0 ? "eventnone" : "")
              }
              title="Mail"
              loading={this.state.loading}
              onClick={this.skipRow}
              disabled={this.state.currentIndex < 0}
            >
              <img
              alt="mail"
                src={MAIL}
                style={{ display: displaySettingsByRole(getUidToken().roleId) }}
              />
            </a>
          )}

        {!nextButtonDisabled && (
          <a
            href="#"
            className={`ctrl_btn m-l-5 btn_default ${
              this.btnDisable() ? "a_disabled" : "btn_active"
            }`}
            title="Next"
            onClick={this.nextRow.bind(this, "next")}
          >
            {" "}
            <img alt="right" src={RIGHT} />
          </a>
        )}

        {nextButtonDisabled && (
          <span
            className={`ctrl_btn btn_default m-l-5 ${
              nextButtonValue == false ? "a_disabled" : "btn_active"
            }`}
            title="Next"
            onClick={this.nextRow.bind(this, "next")}
          >
            {" "}
            <img alt="right" src={RIGHT} />
          </span>
        )}
      </div>
    );
  }


  getUsersFromTags = (text) => {
    let displayText = _clone(text);
    const tags = text.match(/@\{\{[^\}]+\}\}/gi) || [];
    const allUserIds = tags.map((myTag) => {
      const tagData = myTag.slice(3, -2);
      const tagDataArray = tagData.split("||");
      return { _id: tagDataArray[1], name: tagDataArray[2] };
    });
    return _uniqBy(allUserIds, (myUser) => myUser._id);
  };

  swapTags = (text) => {
    let displayText = _clone(text);
    const tags = text.match(/@\{\{[^\}]+\}\}/gi) || [];
    tags.map((myTag) => {
      const tagData = myTag.slice(3, -2);
      const tagDataArray = tagData.split("||");
      let tagDisplayValue = tagDataArray[2];
      tagDisplayValue =
        "<span style= 'color: #29abe2'>" + tagDisplayValue + "</span>";
      displayText = displayText.replace(
        new RegExp(_escapeRegExp(myTag), "gi"),
        tagDisplayValue
      );
    });
    return displayText;
  };

  handleChange = (event, newValue, newPlainTextValue, mentions) => {
    console.log(newValue, newPlainTextValue, mentions);
    this.setState({
      comments: newValue,
      mentionData: { newValue, newPlainTextValue, mentions },
    });
  };

  getCommentDetails = () => {
    const { currentHit } = this.state;
    getCommentsDetails(currentHit.id, this.getCommentDetailsCallBack.bind(this));
  };
  getCommentDetailsCallBack = (err, res) => {
    console.log(res);
    if (!err) {
      let arrayItems = [];
      if (res.body && res.body.length > 0) {
        let response = res.body;

        response.map((comment) => {
          arrayItems.push(
            <div>
              <span>{comment.commentText}</span>
              {comment.handleAction && <span>DELETE</span>}
            </div>
          );
        });
        return <div>{arrayItems}</div>;
      }
    }
  };

  saveComments = () => {
    const { currentHit } = this.state;
    if (!this.state.comments || this.state.comments.length === 0) {
      showAlert("Please enter the comments", "error");
      return false;
    }
    let data = {};
    data.comments = this.state.comments;
    data.displayText = this.swapTags(this.state.comments);
    const usersList = this.getUsersFromTags(this.state.comments);
    data.mentionedUsers = JSON.stringify(usersList);
    this.props.setloaderMenu(true);
    saveComments(
      this.props.currentProject,
      currentHit.id,
      data,
      this.saveCommentCallBack.bind(this)
    );
  };

  editComments = () => {
    this.setState({ editCommentsButtonEnable: false });
  };

  handleCommentsChange = (event) => {
    this.setState({ comments: event.target.value });
  };

  applyFilters=()=> {
    const { hitUrl, currentHitNiri } = this.state;
    const { totalHits } = this.props.projectDetails;
    let searchHitUrl = { searchHitUrl: hitUrl || null };
    this.state.searchHitUrl = searchHitUrl;
    this.state.currentIndex = -1;
    if (currentHitNiri) {
      this.state.currentCount = totalHits / 2;
    } else {
      this.state.currentCount = totalHits;
    }
    searchHitUrl.searchHitUrl !== null
      ? (this.state.imagesLoad =
          true && this.loadProjectDetails(this.props.currentProject))
      : this.setState({ imgName: true });
  }

  searchOnEnterPressed = (event) => {
    const keyCode = event.keyCode || event.which;
    if (keyCode === 13) {
      this.applyFilters(event);
    }
  };

  showClearAll() {
    document.getElementById("textfield").value = "";
    this.setState({ hitUrl: null });
  }

  handleAllFilterChanges = (event) => {
    console.log("handleAllFilterChanges",event.target.value);
    this.setState({ hitUrl: event.target.value || null });
    event.target.value !== null
      ? this.setState({ imgName: false })
      : this.setState({ imgName: true });
  };

  saveCommentCallBack(err, res) {
    this.props.setloaderMenu(false);
    if (!err) {
      if (res.body && res.body.length > 0) {
        let hitsList = [];
        this.state.hits.map((hit) => {
          if (hit.id === this.state.currentHit.id) {
            hit.comments = res.body;
          }
          hitsList.push(hit);
        });
        this.state.currentHit.comments = res.body;
        this.setState({
          editCommentsButtonEnable: true,
          hits: hitsList,
          comments: "",
        });
      }
      showAlert(COMMENTS_SAVED_SUCCESS, SUCCESS);
    }
  }

  showButtons(type) {
    let nextButton = "Next";
    let prevButton = "Previous";
    let skipButton = "Skip";
    let moveToDoneButton = "Move to Done";
    let saveButton = "Save";
    const { styles } = helper;
    const nextButtonDisabled =
      this.state.currentIndex < 0 ||

      (this.state.hitScrollCompleted &&
        this.state.currentIndex >= this.state.hits.length - 1)
      || (this.state.totalHitsCompleted &&
        this.state.currentIndex >= this.state.hits.length - 1) ||
      this.state.currentIndex + 1 ===
        this.state.projectDetails.totalHits -
          this.state.projectDetails.totalHitsDone +
          (this.state.projectDetails.totalHitsSkipped +
          this.state.projectDetails.totalHitsDeleted +
          (this.state.projectDetails.totalHitsReQueued === undefined)
            ? 0
            : this.state.projectDetails.totalHitsReQueued +
              (this.state.projectDetails.totalHitsPreTagged === undefined)
            ? 0
            : this.state.projectDetails.totalHitsPreTagged);
    const nextButtonDisabledForPos =
      this.state.currentIndex < 0 ||
      this.state.notDoneHits - 1 <= this.state.currentIndex ||
      this.state.currentIndex + 1 ===
        this.state.projectDetails.totalHits -
          this.state.projectDetails.totalHitsDone +
          (this.state.projectDetails.totalHitsSkipped +
          this.state.projectDetails.totalHitsDeleted +
          (this.state.projectDetails.totalHitsReQueued === undefined)
            ? 0
            : this.state.projectDetails.totalHitsReQueued +
              (this.state.projectDetails.totalHitsPreTagged === undefined)
            ? 0
            : this.state.projectDetails.totalHitsPreTagged);
    if ("shortcuts" in this.state) {
      const shortcuts = this.state.shortcuts;
      if ("next" in shortcuts) {
        const combo = convertKeyToString(shortcuts.next);
        nextButton = "Next (" + combo + ")";
        if (!nextButtonDisabled) {
          Mousetrap.bind(combo, this.saveTagAndNextRow);
        } else {
          Mousetrap.unbind(combo);
        }
      }
      if ("previous" in shortcuts) {
        const combo = convertKeyToString(shortcuts.previous);
        prevButton = "Previous (" + combo + ")";
        if (this.state.currentIndex > 0) {
          Mousetrap.bind(combo, this.getBackTopreviousRow);
        } else {
          Mousetrap.unbind(combo);
        }
      }
      if (
        ("skip" in shortcuts &&
          parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN) ||
        parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR
      ) {
        const combo = convertKeyToString(shortcuts.skip);
        skipButton = "Skip (" + combo + ")";
        if (this.state.currentIndex >= 0) {
          Mousetrap.bind(combo, this.skipRow);
        } else {
          Mousetrap.unbind(combo);
        }
      }
      if (
        ("moveToDone" in shortcuts &&
          parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN) ||
        parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR
      ) {
        const combo = convertKeyToString(shortcuts.moveToDone);
        moveToDoneButton = "Move to Done (" + combo + ")";
        if (this.state.currentIndex >= 0) {
          Mousetrap.bind(combo, this.moveToDone.bind(this, "saveToDone"));
        } else {
          Mousetrap.unbind(combo);
        }
      }
    }
    // let flexDirection = "row";
    if (type && type === "vertical") {
      return (
        <div className="clasify_btn txt-classify" style={styles.clasifyStyle}>
          <div style={styles.flexEven}>
            <div
              title={prevButton}
              className={
                this.state.currentIndex <= 0 || this.state.buttonDisabled
                  ? "eventnone"
                  : ""
              }
            >
              <Button
                size="mini"
                icon
                onClick={this.getBackTopreviousRow}
                disabled={
                  this.state.currentIndex <= 0 || this.state.buttonDisabled
                }
              >
                <Icon name="left arrow" />
              </Button>
            </div>
            <div
              title={nextButton}
              className={
                nextButtonDisabled || this.state.buttonDisabled
                  ? "eventnone"
                  : ""
              }
            >
              <Button
                size="mini"
                icon
                onClick={this.saveTagAndNextRow}
                disabled={nextButtonDisabled || this.state.buttonDisabled}
              >
                <Icon name="right arrow" />
              </Button>
            </div>
          </div>
          {this.state.projectDetails.task_type === TEXT_CLASSIFICATION && (
            <div
              title={saveButton}
              className="btn_show_classify"
              style={{ display: displaySettingsByRole(getUidToken().roleId) }}
            >
              <Button
                size="mini"
                color="blue"
                icon
                labelPosition="left"
                disabled={this.state.changesInSession <= 0}
                onClick={this.moveToDone.bind(this, "saveToDone")}
              >
                <Icon name="save" />
              </Button>
            </div>
          )}

          {/* Updates Abinesh Rajapandiyan @332capestart */}
          <div
            title={moveToDoneButton}
            className={this.state.type === HIT_STATE_DONE ? "eventnone" : ""}
            style={{ display: displaySettingsByRole(getUidToken().roleId) }}
          >
            <Button
              size="mini"
              icon
              onClick={this.moveToDone.bind(this, "saveToDone")}
              disabled={this.state.type === HIT_STATE_DONE}
            >
              <Icon name="check icon" />
            </Button>
          </div>
          <div
            title={skipButton}
            className={
              this.state.currentIndex < 0 ||
              this.state.type === HIT_STATE_SKIPPED
                ? "eventnone"
                : ""
            }
            style={{ display: displaySettingsByRole(getUidToken().roleId) }}
          >
            <Button
              size="mini"
              icon
              onClick={this.skipRow}
              disabled={
                this.state.currentIndex < 0 ||
                this.state.type === HIT_STATE_SKIPPED
              }
            >
              <Icon name="mail forward" />
            </Button>
          </div>
        </div>
      );
    }
    return (
      <div id="s2" style={styles.divBase}>
        <div title={prevButton} className="btn_show_classify">
          <Button
            size="mini"
            color="grey"
            icon
            labelPosition="left"
            onClick={this.getBackTopreviousRow}
            disabled={this.state.currentIndex <= 0}
          >
            <Icon name="left arrow" />
          </Button>
        </div>
        <br />
        <div
          title={skipButton}
          className="btn_show_classify"
          style={{ display: displaySettingsByRole(getUidToken().roleId) }}
        >
          <Button
            size="mini"
            color="grey"
            icon
            labelPosition="left"
            onClick={this.skipRow}
            disabled={
              this.state.currentIndex < 0 ||
              this.state.type === HIT_STATE_SKIPPED
            }
          >
            <Icon name="mail forward" />
          </Button>
        </div>
        <br />
        {/* Updated by Abinesh Rajapandiyan @332capestart
         ** adds disable state based on hitrusult availability
         */}
        <div
          title={moveToDoneButton}
          className="btn_show_classify"
          style={{ display: displaySettingsByRole(getUidToken().roleId) }}
        >
          <Button
            size="mini"
            color="blue"
            icon
            labelPosition="left"
            disabled={this.state.type === HIT_STATE_DONE}
            onClick={this.moveToDone.bind(this, "saveToDone")}
          >
            <Icon name="check" />
          </Button>
        </div>
        <br />
        <div title={nextButton} className="btn_show_classify">
          <Button
            size="mini"
            color="blue"
            icon
            labelPosition="right"
            onClick={this.saveTagAndNextRow}
            disabled={nextButtonDisabledForPos}
          >
            <Icon name="right arrow" />
          </Button>
        </div>
      </div>
    );
  }

  showFullScreenActionButtons = () => {

    let nextButton = "Next";
    let prevButton = "Previous";
    let skipButton = "Move To Skip";
    let moveButton = "Move To Done";
    let reQueue = "Move To Requeue";
    let m_delete = "Move To Delete";

    const nextButtonDisabled =
      this.state.currentIndex < 0 ||
      (this.state.hitScrollCompleted &&
        this.state.currentIndex > this.state.hits.length - 2) ||
      (this.state.totalHitsCompleted &&
        this.state.currentIndex > this.state.hits.length - 2) ||
      this.state.currentIndex === this.state.totalFilteredHits - 1 ||
      this.state.currentIndex + 1 ===
        this.state.projectDetails.totalHits -
          this.state.projectDetails.totalHitsDone +
          (this.state.projectDetails.totalHitsSkipped +
          this.state.projectDetails.totalHitsDeleted +
          (this.state.projectDetails.totalHitsReQueued === undefined)
            ? 0
            : this.state.projectDetails.totalHitsReQueued +
              (this.state.projectDetails.totalHitsPreTagged === undefined)
            ? 0
            : this.state.projectDetails.totalHitsPreTagged);

    if ("shortcuts" in this.state) {
      const shortcuts = this.state.shortcuts;
      if ("next" in shortcuts) {
        const combo = convertKeyToString(shortcuts.next);
        if (!nextButtonDisabled) {
          Mousetrap.bind(combo, this.nextRow.bind(this, "next"));
        } else {
          Mousetrap.unbind(combo);
        }
      }
      if ("previous" in shortcuts) {
        const combo = convertKeyToString(shortcuts.previous);
        // prevButton = 'Previous (' + combo + ')';
        if (this.state.currentIndex > 0) {
          Mousetrap.bind(combo, this.nextRow.bind(this, "previous"));
        } else {
          Mousetrap.unbind(combo);
        }
      }
      if (
        ("moveToDone" in shortcuts &&
          parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN) ||
        parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR
      ) {
        const combo = convertKeyToString(shortcuts.moveToDone);
        if (this.state.currentIndex >= 0) {
          Mousetrap.bind(combo, this.moveToDone.bind(this, "saveToDone"));
        } else {
          Mousetrap.unbind(combo);
        }
      }
    }

    return (
      <div className="e_buttons">
        <div
          title={prevButton}
          className={
            this.state.currentIndex <= 0 || this.state.buttonDisabled
              ? "eventnone"
              : ""
          }
        >
          <Button
            icon
            size="mini"
            color="grey"
            icon
            onClick={this.nextRow.bind(this, "previous")}
            disabled={this.state.currentIndex <= 0 || this.state.buttonDisabled}
          >
            <Icon name="left arrow" />
          </Button>
        </div>
        <div
          title={nextButton}
          className={nextButtonDisabled ? "eventnone" : ""}
        >
          <Button
            icon
            size="mini"
            color="blue"
            icon
            onClick={this.nextRow.bind(this, "next")}
            disabled={nextButtonDisabled}
          >
            <Icon name="right arrow" />
          </Button>
        </div>
        {(this.state.type === HIT_STATE_SKIPPED ||
          this.state.type === HIT_STATE_PRE_TAGGED ||
          this.state.type === HIT_STATE_REQUEUED) && (
          <div
            title={moveButton}
            className={
              "text-center " + (this.state.buttonDisabled ? "eventnone" : "")
            }
          >
            <Button
              icon
              size="mini"
              color="grey"
              icon
              onClick={this.moveToDone.bind(this, "saveToDone")}
              disabled={this.state.buttonDisabled}
            >
              <Icon name="check" />
            </Button>
          </div>
        )}
        {(this.state.type === HIT_STATE_DONE ||
          this.state.type === HIT_STATE_PRE_TAGGED ||
          this.state.type === HIT_STATE_REQUEUED ||
          this.state.type === HIT_STATE_DELETED) && (
          <div
            title={skipButton}
            className={
              "text-center " + (this.state.buttonDisabled ? "eventnone" : "")
            }
          >
            <Button
              icon
              size="mini"
              color="grey"
              icon
              onClick={this.moveToSkip.bind(this)}
              disabled={this.state.buttonDisabled}
            >
              <Icon name="share" />
            </Button>
          </div>
        )}

        {(this.state.type === HIT_STATE_SKIPPED ||
          this.state.type === HIT_STATE_DONE ||
          this.state.type === HIT_STATE_PRE_TAGGED ||
          this.state.type === HIT_STATE_REQUEUED) && (
          <div
            title={m_delete}
            className={
              "text-center " + (this.state.buttonDisabled ? "eventnone" : "")
            }
          >
            <Button
              icon
              size="mini"
              color="grey"
              icon
              onClick={this.deleteItem.bind(this)}
              disabled={this.state.buttonDisabled}
            >
              <Icon name="trash alternate icon" />
            </Button>
          </div>
        )}
        {(this.state.type === HIT_STATE_DONE ||
          this.state.type === HIT_STATE_SKIPPED ||
          this.state.type === HIT_STATE_PRE_TAGGED ||
          this.state.type === HIT_STATE_DELETED) && (
          <div
            title={reQueue}
            className={
              "text-center " + (this.state.buttonDisabled ? "eventnone" : "")
            }
          >
            <Button
              icon
              size="mini"
              color="grey"
              icon
              onClick={this.retagHit.bind(this)}
              disabled={this.state.buttonDisabled}
            >
              <Icon name="redo" />
            </Button>
          </div>
        )}
      </div>
    );
  };

  toggleAutoLabel = () => {
    if (this.state.autoLabel) {
      this.setState({ autoLabel: false });
    } else {
      this.setState({ autoLabel: true });
    }
  };

  toggleAutoClose = () => {
    logEvent("buttons", "Auto Close", !this.state.autoClose);
    if (this.state.autoClose) {
      this.setState({ autoClose: false });
    } else {
      this.setState({ autoClose: true });
    }
  };

  toggleNotes = () => {
    logEvent("buttons", "Notes Toggle", !this.state.notes);
    if (this.state.notes) {
      this.setState({ notes: false });
    } else {
      this.setState({ notes: true });
    }
  };

  toggleHideLabels = () => {
    logEvent("buttons", "Hide Labels Toggle", !this.state.hideLabels);
    if (this.state.hideLabels) {
      this.setState({ hideLabels: false });
    } else {
      this.setState({ hideLabels: true });
    }
  };

  toggleEntitySelected = () => {
    logEvent("buttons", "Hide Labels Toggle", !this.state.keepEntitySelected);
    if (this.state.keepEntitySelected) {
      this.setState({ keepEntitySelected: false });
    } else {
      this.setState({ keepEntitySelected: true });
    }
  };

  handleClick = (event, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  handleNoteChange = (event) => {
    if (
      parseInt(getUidToken().roleId) === parseInt(ROLES_TYPES.ADMIN) ||
      parseInt(getUidToken().roleId) === parseInt(ROLES_TYPES.ANNOTATOR)
    ) {
      this.stopAutoSave = true;
      this.setState({
        currentNote: event.target.value,
        changesInSession: this.state.changesInSession + 1,
      });
    }
  };

  showExtra = (extra) => {
    const arrs = [];
    for (const k1 of Object.keys(extra)) {
      arrs.push(
        <Table.Row>
          <Table.Cell textAlign="left" className="h5 bold">
            {k1}
          </Table.Cell>
          <Table.Cell className="h6">{extra[k1]}</Table.Cell>
        </Table.Row>
      );
    }

    return (
      <Table
        celled
        color="blue"
        key="blue"
        size="small"
        compact="very"
        collapsing
      >
        <Table.Body>{arrs}</Table.Body>
      </Table>
    );
  };

  taggingInfoModelOpen = (e) => {
    e.preventDefault();
    this.setState({ taggingInfoModelVisible: true });
  };

  cancelTaggingInfoModal = () => {
    this.setState({ taggingInfoModelVisible: false });
  };

  renderTaggingInfoModal = () => {
    const { taggingInfoModelVisible, rules } = this.state;
    return (
      <div>
        <AntModal
          title="Project Guidelines"
          bodyStyle={{ top: 20, overflowY: "auto", height: "500px" }}
          visible={taggingInfoModelVisible}
          footer={null}
          onCancel={this.cancelTaggingInfoModal}
        >
          <p>{rules.instructions}</p>
        </AntModal>
      </div>
    );
  };

  showTaggingInstructions() {
    const { activeIndex, rules } = this.state;
    return (
      <div className="project_info">
        <label>Project Guidelines</label>
        <p
          title={rules.instructions}
          className={rules.instructions.length > 15 ? "info-project-guide" : ""}
        >
          {rules.instructions}
        </p>
        {rules.instructions.length > 40 ? (
          <a
            href="#"
            className="more"
            onClick={(e) => this.taggingInfoModelOpen(e)}
          >
            more...
          </a>
        ) : (
          ""
        )}
      </div>
    );
  }

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.handleDialogClickEvent(e);
    }
  };

  // Dynamic Label Apply
  handleDialogClickEvent = () => {
    if (this.state.labelValue === "" || this.state.labelValue === undefined) {
      this.setState({ isEmptyLabel: true });
    } else {
      this.setState({ isEmptyLabel: false });
      this.setState({ showLabelError: false });

      let entityColorMap = this.state.entityColorMap;

      if (!entityColorMap[this.state.value]) {
        this.setState({ newLabelUpdate: true });
      }

      this.polyAnnotator.updateNewLabel();
      this.setState({ open: false });
      return false;
    }
  };

  handleDialogClose = () => {
    this.setState({ newLabelUpdate: false });
    this.setState({ open: false });
  };

  showLabelPopup = () => {
    this.setState({ value: "" });
    this.setState({ open: true });
    this.setState({ isEmptyLabel: false });
    this.setState({ showLabelError: false });
  };

  checkNewLabel = () => {
    this.setState({ newLabelUpdate: false });
  };

  setLabelValue = (data) => {
    this.setState({
      labelValue: data.toString().trim(),
      value: data,
    });
    if (data !== undefined && data.trim() !== "" && data.length > 0) {
      this.setState({ isEmptyLabel: false });
    }
  };

  deleteLabel = (label, callback) => {
    const { currentHit, entities } = this.state;
    let labelvalue = { labelName: label };
    // delete lable save API call
    updateNewLabelName(
      currentHit.id,
      this.props.currentProject,
      labelvalue,
      NEW_LABEL_DELETE,
      this.labelUpdatedCallback.bind(this, callback)
    );
    entities.map((rect, index) => {
      if (rect === label) {
        entities.splice(index, 1);
      }
    });
    const entityColorMap = createDocEntityColorMap(entities, ENTITY_COLORS);
    this.setState({ entityColorMap: entityColorMap });
  };

  redirectCompletedStatusClick(orgName, projectName, task_type) {
    this.setState({ fromLeaderBoard: false });
    let url =
      "/projects/" +
      orgName +
      "/" +
      projectName +
      "/" +
      this.props.params.projectId +
      "/space?type=done";
    if (task_type === POS_TAGGING) {
      url =
        "/projects/" +
        orgName +
        "/" +
        projectName +
        "/" +
        this.props.params.projectId +
        "/space?type=done";
    }

    if (task_type === VIDEO_BOUNDING_BOX || task_type === VIDEO_CLASSIFICATION)
      this.setState({ currentHit: undefined });
    this.props.pushState(url);
  }

  handleDownloadOk = () => {
    this.setState({
      confirmDownloadModalVisible: false,
    });
    this.downloadFilterResult();
  };

  handleDownloadCancel = () => {
    this.setState({
      confirmDownloadModalVisible: false,
    });
  };
  summaryContent() {
    let fromDate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(this.state.dateFrom);
    let toDate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(this.state.dateTo);
    const { downloadFormat } = this.state;
    return (
      <div>
        <Form.Group
          inline
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Form.Radio
            label="All"
            value="ALL"
            checked={this.state.downloadOption === "ALL"}
            onChange={this.downloadModeChange}
          />
          &nbsp; &nbsp; &nbsp; &nbsp;
          <Form.Radio
            label="Current"
            value="CURRENT"
            checked={this.state.downloadOption === "CURRENT"}
            onChange={this.downloadModeChange}
          />
        </Form.Group>
        {this.state.downloadOption !== "CURRENT" && (
          <ul className="m_summary">
            {this.state.type && this.state.type !== null && (
              <li>
                <label>{"Hits Status"}</label>
                <span>{this.state.type}</span>
              </li>
            )}
            {this.state.evaluationType && this.state.evaluationType !== null && (
              <li>
                <label>{"Evaluation Status"}</label>
                <span>{this.state.evaluationType}</span>
              </li>
            )}
            {this.state.commentFilter && this.state.commentFilter !== null && (
              <li>
                <label>{"Comments"}</label>
                <span>{this.state.commentFilter}</span>
              </li>
            )}
            {this.state.contributorId && this.state.contributorId !== null && (
              <li>
                <label>{"Contributor"}</label>
                <span>{this.state.contributorName}</span>
              </li>
            )}
            {this.state.label && this.state.label !== null && (
              <li>
                <label>{"Entity"}</label>
                <span>{this.state.label}</span>
              </li>
            )}
            {this.state.dateFrom &&
              this.state.dateFrom !== null &&
              this.state.dateTo &&
              this.state.dateTo !== null && (
                <li>
                  <label>{"Date"}</label>
                  <span>{fromDate + " to " + toDate}</span>
                </li>
              )}
            {this.state.classificationLabel &&
              this.state.classificationLabel !== null && (
                <li>
                  <label>{"Classification Label"}</label>
                  <span>{this.state.classificationLabel}</span>
                </li>
              )}
            {this.state.hitsNotAnnotatedCount !== null && (
              <li>
                <label>{"Pages without annotation"}</label>
                <span>{this.state.hitsNotAnnotatedCount}</span>
              </li>
            )}
            {this.state.totalFilteredHits !== null && (
              <li>
                <label>Number of Files</label>
                <span>{this.state.totalFilteredHits}</span>
              </li>
            )}
          </ul>
        )}
        <Form.Radio
          label="Normal json"
          value="json"
          checked={downloadFormat === "json"}
          onChange={this.handleJsonChange}
        />
        <Form.Radio
          label="Stanford NER Format"
          value="stanford"
          checked={downloadFormat === "stanford"}
          onChange={this.handleJsonChange}
        />
      </div>
    );
  }
  showSummaryPage() {
    return (
      <div className="text-center">
        {this.state.confirmDownloadModalVisible && (
          <LocaleProvider locale={en_US}>
            <AntModal
              className="s_model"
              title="Summary"
              visible={this.state.confirmDownloadModalVisible}
              onOk={this.handleDownloadOk}
              onCancel={this.handleDownloadCancel}
              footer={[
                <div className="txt-center">
                  <Button
                    className="b-r-20"
                    key="submit"
                    type="primary"
                    onClick={this.handleDownloadOk}
                  >
                    {" "}
                    <AntIcon type="download" className="m-r-5" />
                    Download
                  </Button>
                </div>,
              ]}
            >
              {this.summaryContent()}
            </AntModal>
          </LocaleProvider>
        )}
      </div>
    );
  }
  downloadCallback = (error, response) => {
    if (!error) {
      this.setState({ loading: false });
      const blob = response.xhr.response;
      if (this.props.projectDetails) {
        const taskType = this.props.projectDetails.task_type;
        if (taskType === TEXT_MODERATION || taskType === TEXT_SUMMARIZATION) {
          FileSaver.saveAs(blob, this.props.projectDetails.name + ".json");
        } else {
          if (this.state.downloadFormat === "json") {
            FileSaver.saveAs(blob, this.props.projectDetails.name + ".json");
          } else if (this.state.downloadFormat === "stanford") {
            FileSaver.saveAs(blob, this.props.projectDetails.name + ".tsv");
          } else if (this.state.downloadFormat === "csv") {
            FileSaver.saveAs(blob, this.props.projectDetails.name + ".csv");
          } else {
            FileSaver.saveAs(blob, this.props.projectDetails.name + ".txt");
          }
        }
      } else {
        FileSaver.saveAs(blob, this.props.projectDetails.name);
      }
    } else {
      alert(response.body.message);
    }
  };

  downloadCsvCallback = (error, response) => {
    if (!error) {
      let fileDownload = require("react-file-download");
      let jsonData = JSON.parse(response.text);
      fileDownload(jsonData.data, jsonData.fileName + ".csv");
      this.props.setloaderMenu(false);
      this.setState({ loading: false });
    } else {
      this.props.setloaderMenu(false);
      this.setState({ loading: false });
      showAlert(response.body.message, "error");
    }
  };

  handleJsonChange = (event, { value }) => {
    this.setState({ downloadFormat: value });
  };

  downloadFilterResult() {
    let param = null;
    if (
      this.state.type === HIT_STATE_ALL &&
      this.state.downloadOption === "ALL"
    ) {
      param = "&filterDownload=" + false;
    } else {
      param = "&filterDownload=" + true;
    }
    if (this.state.downloadFormat === "json") {
      this.setState({ downloadFormat: "json" });
    } else if (this.state.downloadFormat === "csv") {
      this.setState({ downloadFormat: "csv" });
    } else this.setState({ downloadFormat: "stanford" });
    if (
      this.state.totalFilteredHits === 0 &&
      this.state.downloadOption === "CURRENT"
    )
      this.setState({ itemOption: "ALL", loading: true });
    else
      this.setState({ itemOption: this.state.downloadOption, loading: true });

    if (this.state.type && this.state.type !== null)
      param += "&type=" + this.state.type;
    if (this.state.evaluationType && this.state.evaluationType !== null)
      param += "&evaluationType=" + this.state.evaluationType;
    if (this.state.commentFilter && this.state.commentFilter !== null)
      param += "&commentFilter =" + this.state.commentFilter;
    if (this.state.contributorId && this.state.contributorId !== null)
      param += "&userId=" + this.state.contributorId;
    if (this.state.label && this.state.label !== null)
      param += "&label=" + this.state.label;
    if (
      this.state.classificationLabel &&
      this.state.classificationLabel !== null
    )
      param += "&classificationLabel=" + this.state.classificationLabel;
    if (this.state.dateFrom && this.state.dateFrom !== null)
      param += "&fromDate=" + this.state.dateFrom;
    if (this.state.dateTo && this.state.dateTo !== null)
      param += "&toDate=" + this.state.dateTo;

    if (
      this.state.downloadOption &&
      this.state.downloadOption == "CURRENT" &&
      this.state.currentHit &&
      this.state.totalFilteredHits > 0
    )
      param += "&currentHitId=" + this.state.currentHit.id;
    downloadfile(
      this.props.currentProject,
      this.state.itemOption,
      this.downloadCallback,
      this.state.downloadFormat,
      param
    );
  }

  clearPosLabels = () => {
    let predictType = this.state.clearOptionValue;
    let predLabels = this.state.posTaggingPredChangeLabels;
    for (let index = 0; index < predLabels.length; index++) {
      if (predictType === "0" && predLabels[index] === "M") {
        this.removeTag(index);
      } else if (predictType === "1" && predLabels[index] === "H") {
        this.removeTag(index);
      } else if (predictType === "2") {
        this.removeTag(index);
      }
    }
    if (predictType === "0") {
      this.clearMachinePredictionConfirm();
    }
    this.handleClearCancel();
  };

  clearPosLabelsNew = () => {
    const { currentHit, changesInSession, clickedColor } = this.state;
    if (currentHit.hitResults && currentHit.hitResults.length > 0) {
      currentHit.hitResults[0].result = currentHit.data;
      currentHit.result = currentHit.data;
      let entity = this.state.taggedEntity;
      this.setState({
        currentHit,
        taggedEntity: [],
        changesInSession: changesInSession + 1,
        clickedColor: "",
      });
    }
  };

  handleClearOk = () => {
    const that = this;
    const confirm = AntModal.confirm;
    confirm({
      title: "Confirmation",
      content:
        "Are your sure you want to clear all the annotations in the current page?",
      okText: "OK",
      cancelText: "CANCEL",
      onOk() {
        that.clearPosLabels();
      },
      onCancel() {
        that.setState({ confirmModalVisible: false });
      },
    });
  };

  handleClearBtn = () => {
    const that = this;
    const confirm = AntModal.confirm;
    confirm({
      title: "Confirmation",
      content:
        "Are your sure you want to clear all the annotations in the current page?",
      okText: "OK",
      cancelText: "CANCEL",
      onOk() {
        that.clearPosLabelsNew();
      },
      onCancel() {
        that.setState({ confirmModalVisible: false });
      },
    });
  };
  handleClearCancel = (e) => {
    this.setState({
      clearModalVisible: false,
    });
  };

  clearOptionChange = (event, { value }) => {
    this.setState({ clearOptionValue: value });
  };
  openSnapshot() {
    const { currentHit } = this.state;
    downloadImageUrl((url, uid, token) => {
      if (
        currentHit &&
        currentHit.data &&
        currentHit.data.includes("hits/images") &&
        !currentHit.data.includes("http") &&
        !currentHit.data.includes("https")
      ) {
        currentHit.data = `${url}${currentHit.data}?token=${token}&uid=${uid}`;
      }
    });
    window.open(currentHit.data);
  }
  showClearPoS() {
    this.setState({
      clearModalVisible: true,
    });
  }
  clearPoSAnnotations() {
    return (
      <div className="text-center">
        {this.state.clearModalVisible && (
          <LocaleProvider locale={en_US}>
            <AntModal
              className="s_model"
              title="Clear Annotations"
              visible={this.state.clearModalVisible}
              onOk={this.handleClearOk}
              onCancel={this.handleClearCancel}
              footer={[
                <div className="txt-center">
                  <Button
                    className="b-r-20"
                    key="submit"
                    type="primary"
                    onClick={this.handleClearOk}
                  >
                    Clear
                  </Button>
                </div>,
              ]}
            >
              <Form.Group
                inline
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Form.Radio
                  label="Machine"
                  value="0"
                  checked={this.state.clearOptionValue === "0"}
                  onChange={this.clearOptionChange}
                />
                &nbsp; &nbsp; &nbsp; &nbsp;
                <Form.Radio
                  label="Human"
                  value="1"
                  checked={this.state.clearOptionValue === "1"}
                  onChange={this.clearOptionChange}
                />
                &nbsp; &nbsp; &nbsp; &nbsp;
                <Form.Radio
                  label="Both"
                  value="2"
                  checked={this.state.clearOptionValue === "2"}
                  onChange={this.clearOptionChange}
                />
              </Form.Group>
            </AntModal>
          </LocaleProvider>
        )}
      </div>
    );
  }

  deleteComments = (id) => {
    const { currentHit } = this.state;
    this.props.setloaderMenu(true);
    deleteComments(currentHit.id, id, this.deleteCommentCallBack.bind(this));
  };

  updateComments = (id, text) => {
    const { currentHit } = this.state;
    let data = {};
    data.comments = text;
    data.displayText = this.swapTags(text);
    const usersList = this.getUsersFromTags(text);
    data.mentionedUsers = JSON.stringify(usersList);
    this.props.setloaderMenu(true);
    updateComments(
      currentHit.id,
      id,
      data,
      this.updateCommentCallBack.bind(this)
    );
  };

  commentAction = (res, type) => {
    let hitsList = [];
    this.state.hits.map((hit) => {
      if (hit.id === this.state.currentHit.id) {
        hit.comments = res.body;
      }
      hitsList.push(hit);
    });
    this.state.currentHit.comments = res.body;
    this.setState({ hits: hitsList, comments: "" });
    if (type === "update") showAlert(COMMENTS_UPDATED_SUCCESS, SUCCESS);
    else showAlert(COMMENTS_DELETED_SUCCESS, SUCCESS);
    this.props.setloaderMenu(false);
  };

  deleteCommentCallBack = (err, res) => {
    if (!err) {
      this.commentAction(res, "delete");
    }
  };

  updateCommentCallBack = (err, res) => {
    if (!err) {
      this.commentAction(res, "update");
    }
  };

  closeComments = () => {
    this.setState({ comments: "" });
  };
  showClearButton = (flag) => {
    this.setState({ clearButton: flag });
  };
  showUndoButton = (flag) => {
    this.setState({ undoButton: flag });
  };
  showRedoButton = (flag) => {
    this.setState({ redoButton: flag });
  };
  render() {
    const { styles, svgPath } = helper;
    const { M6, M9, M11, M16, M27 } = svgPath;
    const {
      shortcuts,
      projectDetails,
      rules,
      currentHit,
      currentNote,
      fromLeaderBoard,
      hitsCompleted,
      hits,
      visible,
      startValue,
      endValue,
      fullScreen,
      loading,
      contributorDetails,
      classification,
      entities,
      isFullscreenEnabled,
      changesInSession,
      notes,
      hideLabels,
      autoClose,
      keepEntitySelected,
      continuousTagging,
      autocloseDisable,
      autoLabel,
      undoButton,
      buttonDisabled,
      clearButton,
      redoButton,
    } = this.state;
    let { currentIndex, totalFilteredHits, type } = this.state;
    if (Array.isArray(type)) {
      type = type[0];
    }
    let popoverTop = undefined;
    let keyobardPopover = undefined;
    let prevWord = undefined;
    let nextWord = undefined;
    let pageTitle = this.props.params.projectName;
    let popupContent = "Select word and then entity to tag.";
    let pageDetails = "";
    if (totalFilteredHits)
      pageDetails = pageDetails.concat(
        "( ",
        currentIndex + 1,
        " of ",
        this.state.hits.length,
        " )"
      );
    if (this.state.projectDetails) {
      keyobardPopover = (
        <Popover id="popover-positioned-top" style={styles.wordPre}>
          {this.getKeyboardShortcuts(shortcuts)}
        </Popover>
      );
      if (shortcuts) {
        if ("left" in shortcuts) {
          const prevK = convertKeyToString(shortcuts.left);
          if (prevK) {
            prevWord = prevK;
          }
        }
        if ("right" in shortcuts) {
          const nextK = convertKeyToString(shortcuts.right);
          if (nextK) {
            nextWord = nextK;
          }
        }
      }
    }
    const fullScreenPopover = (
      <Popover
        className="full_screenpop"
        id="popover-positioned-top"
        title="Full Screen"
      />
    );
    if (rules && projectDetails) {
      popupContent = popupContent + " " + rules.instructions;
      if (projectDetails.task_type === POS_TAGGING) {
        popoverTop = `
        How to annotate ?
         Click to select a word and then select an entity to annotate.`
      } else if (projectDetails.task_type === TEXT_CLASSIFICATION) {
        popoverTop = `
        How to classify ? 
         Click to select a class to classify the sentence.`
      } else if (projectDetails.task_type === IMAGE_BOUNDING_BOX) {
        popoverTop = `
        How to Bound ?
         Click on the image and mouse-drag to draw a rectangle and pick a
         category from drop-down menu. By Default first category in the list
         will be shown selected.
        `
      } else if (
        projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX ||
        projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX_V2 ||
        projectDetails.task_type === IMAGE_SEGMENTATION ||
        projectDetails.task_type === IMAGE_MASKING
      ) {
        popoverTop = `
        How to Bound ?
         Click on the image to mark each point of the polygon.
         When you close the "Polygon" button, the last point is
         auto-connected to the first point to complete the polygon.
        `
      }
    }
    
    console.log("popoverTop Data",popoverTop)
    let extra = "";
    if (currentHit) {
      extra = JSON.parse(currentHit.extras);
    }

    const showCommentsDetails = () => {
      const { currentHit } = this.state;
      let arrayItems = [];
      currentHit.comments &&
        currentHit.comments.map((comment) => {
          arrayItems.push(
              <CommentComponent
                comment={comment}
                deleteComments={this.deleteComments}
                updateComments={this.updateComments}
                closeComments={this.closeComments}
                contributionDetails={this.state.contributorDetails}
              />
          );
        });
      return (
        <div>
          {arrayItems && arrayItems.length > 0 && (
            <div style={{ marginTop: this.state.projectDetails.task_type === VIDEO_BOUNDING_BOX ? "150px" :
             (this.state.projectDetails.task_type === IMAGE_SEGMENTATION && this.state.classification !== undefined) ? "20%" :  
             this.state.projectDetails.task_type === IMAGE_CLASSIFICATION ? "0px" :
             "100px" }}>
              <h2>Comments</h2>
              <div>{arrayItems}</div>
            </div>
          )}
        </div>
      );
    };

    const showNoteLable = () => {
      return (
        <div>
          <div className="bottomBarLayout note_txt m-t-5">
            {(projectDetails.task_type === TEXT_CLASSIFICATION ||
              projectDetails.task_type === IMAGE_CLASSIFICATION ||
              projectDetails.task_type === POS_TAGGING ||
              projectDetails.task_type === POS_TAGGING_GENERIC) && (
              <ul className="legendInfo">
                <li>
                  <span className="legendTag M"></span>Machine
                </li>
                <li>
                  <span className="legendTag H"></span>Human
                </li>
              </ul>
            )}

          </div>
        </div>
      );
    };
    const elem = document.getElementById("polygonBound");
    if (elem && elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    }

    if (hits && fromLeaderBoard === true && hitsCompleted)
      return (
        <div>
          {type === "notDone" && (
            <Segment
              color="blue"
              className="marginTopExtra"
              style={styles.baseMargin}
            >
              {type && type === "skipped" && (
                <h4 className="m-b-20">
                  {" "}
                  All skipped rows are tagged, You can check the project stats.{" "}
                </h4>
              )}
              {type && type !== "skipped" && (
                <h4 className="m-b-20">
                  {" "}
                  All rows are tagged, You can check the project stats.{" "}
                </h4>
              )}
              {!type && (
                <h4 className="m-b-20">
                  {" "}
                  All items are tagged, You can check the project stats.{" "}
                </h4>
              )}
              <div className="redirect_btn">
                <Button
                  onClick={() =>
                    this.props.pushState(
                      "/projects/" +
                        this.props.params.orgName +
                        "/" +
                        this.props.params.projectName +
                        "/" +
                        this.props.params.projectId
                    )
                  }
                >
                  Show Project Stats
                </Button>
                <Button
                  onClick={() =>
                    this.redirectCompletedStatusClick(
                      this.props.params.orgName,
                      this.props.params.projectName,
                      this.props.projectDetails.task_type
                    )
                  }
                >
                  Show Completed HITs
                </Button>
                <Button
                  onClick={() =>
                    this.props.pushState(
                      "/projects/" +
                        this.props.params.orgName +
                        "/" +
                        this.props.params.projectName +
                        "/" +
                        this.props.params.projectId +
                        "/space?type=skipped"
                    )
                  }
                >
                  Show Skipped HITs
                </Button>
              </div>
            </Segment>
          )}
        </div>
      );
    return (
      <div
        className={`${
          this.state.isFullscreenEnabled ? "full_view_dynamic" : ""
        }`}
      >
        {this.props.projectDetails == null ||
        this.props.annotatedLabelCount === 0 ? (
          <div className={buttonDisabled ? "overlay_dom" : ""}>
            <div className="cssload-wraper">
              <div className="cssload-dots"></div>
            </div>
          </div>
        ) : null}
        {this.props.projectDetails && (
          <div className="pr_doc_annotate m-t-30">
            {type !== HIT_STATE_NOT_DONE &&
              this.props.projectDetails.task_type !== POS_TAGGING && (
                <div style={styles.margint}>
                  <div className="project_sec">
                    <div className="project_title f-column">
                      <div className="f-center">
                        <a href="#" onClick={this.redirectBackImage.bind(this)}>
                          <span className="btn_back">
                            <img alt="backicon" src={BACKICON} />
                          </span>
                        </a>
                      </div>
                      <div className="breadcrum-sec">
                        <div className="sec_holder">
                          <a
                            className={
                              "tilte_display fw-400 " +
                              (this.props.projectDetails &&
                              this.props.projectDetails.name.length > 15
                                ? "pro_width"
                                : "")
                            }
                            href="#"
                            onClick={this.redirectBackImage.bind(this)}
                            title={
                              this.props.projectDetails
                                ? this.props.projectDetails.name
                                : "Dashboard"
                            }
                          >
                            {this.props.projectDetails
                              ? this.props.projectDetails.name
                              : "Dashboard"}
                          </a>
                          {breadcrumbSeperate()}
                          {hitsBreadCrumbTitle(type)}
                          &nbsp; {pageDetails}
                        </div>
                      </div>
                    </div>

                    {(type === HIT_STATE_DONE ||
                      type === HIT_STATE_DELETED ||
                      type === HIT_STATE_SKIPPED ||
                      type === HIT_STATE_ALL) &&
                      (this.props.projectDetails.task_type ===
                        DOCUMENT_ANNOTATION ||
                        this.props.projectDetails.task_type ===
                          POS_TAGGING_GENERIC) &&
                      !hitsCompleted && (
                        <div
                          style={styles.fCenter}
                          className={
                            this.props.projectDetails.task_type ===
                              DOCUMENT_ANNOTATION ||
                            this.props.projectDetails.task_type ===
                              POS_TAGGING_GENERIC
                              ? "align_autoLabel"
                              : ""
                          }
                        >
                          {autocloseDisable}
                          {autocloseDisable !== true}
                          <Checkbox
                            className={
                              "m-r-5 margin-t-b-0 " +
                              (autocloseDisable !== true
                                ? "block_enable"
                                : "block_disable")
                            }
                            style={{
                              display: displaySettingsByRole(
                                getUidToken().roleId
                              ),
                            }}
                            checked={autoClose}
                            onClick={this.toggleAutoClose.bind(this)}
                            label="AutoClose on Selection"
                          />
                          <Checkbox
                            className="m-t-10 m-r-5 margin-t-b-0"
                            checked={autoLabel}
                            onClick={this.toggleAutoLabel.bind(this)}
                            label="Autolabel Same Text in Document"
                          />
                          <Button
                            className="m-r-5 btn_green"
                            disabled={undoButton}
                            size="mini"
                            icon
                            onClick={() => this.docAnnotator.undo()}
                          >
                            <Icon name="undo" className="m-r-5" id="m-r-5" />
                            Undo
                          </Button>
                          <Button
                            className="m-r-5 btn_green"
                            disabled={redoButton}
                            size="mini"
                            icon
                            onClick={() => this.docAnnotator.redo()}
                          >
                            <Icon name="redo" className="m-r-5" id="m-r-5" />
                            Redo 1
                          </Button>
                        </div>
                      )}
                    {(type === HIT_STATE_DONE ||
                      type === HIT_STATE_DELETED ||
                      type === HIT_STATE_SKIPPED ||
                      type === HIT_STATE_ALL) &&
                      (this.props.projectDetails.task_type ===
                        IMAGE_POLYGON_BOUNDING_BOX_V2 ||
                        this.props.projectDetails.task_type ===
                          IMAGE_SEGMENTATION ||
                        this.props.projectDetails.task_type ===
                          IMAGE_MASKING) &&
                      !hitsCompleted && (
                        <div
                          className="text-center m-r-15"
                          style={{
                            display: displaySettingsByRole(
                              getUidToken().roleId
                            ),
                          }}
                        >
                          <Checkbox
                            className="m-r-5"
                            size="mini"
                            checked={continuousTagging}
                            onClick={() => this.continuousTaggingSelected()}
                            label="Keep Entity Selected"
                          />
                        </div>
                      )}

                    {this.state.type === "done" || this.state.type === "all" ? (
                      <div className="project_set">
                        <ul className="tools">
                          <li>
                            <div className="searchBox">
                              {!this.state.imgName ? (
                                <input
                                  type="text"
                                  id="textfield"
                                  style={{
                                    fontSize: "0.9em",
                                    border: "1px red solid !important",
                                  }}
                                  value={this.state.hitUrl}
                                  name="search"
                                  onChange={this.handleAllFilterChanges}
                                  onKeyDown={this.searchOnEnterPressed.bind(
                                    this)}
                                  placeholder="Search"
                                />
                              ) : (
                                <input
                                  type="empty-text"
                                  id="textfield"
                                  style={{ fontSize: "0.9em" }}
                                  value={this.state.hitUrl}
                                  name="search"
                                  onChange={
                                    this.handleAllFilterChanges
                                  }
                                  onKeyDown={this.searchOnEnterPressed.bind(
                                    this
                                  )}
                                  placeholder="Search"
                                />
                              )}
                              <img
                                src={SEARCHSMALL}
                                alt="search"
                                onClick={this.applyFilters}
                                style={{
                                  position: "absolute",
                                  left: "85%",
                                  top: "5px",
                                  cursor: "pointer",
                                }}
                              />
                              {this.state.imgName ? (
                                <p
                                  style={{
                                    fontSize: "9.5px",
                                    color: "red",
                                    marginLeft: "15px",
                                    marginTop:"5px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Please Enter Image Name
                                </p>
                              ) : null}
                            </div>
                          </li>
                          <li style={{ display: "flex", gap: 1 }}>
                            <div>
                            <Button icon size="mini" className="btn_danger" secondary  onClick={() => this.showClearAll()} disabled={!this.state.hitUrl ? true : false}>
                                <Icon name="remove" />
                              </Button>

                            </div>
                            <div>
                              {
                                <div style={styles.fCenter}>
                                  {fullScreenPopover &&
                                    this.props.projectDetails.task_type !==
                                      VIDEO_BOUNDING_BOX &&
                                      this.props.projectDetails.task_type !==
                                        VIDEO_CLASSIFICATION && (
                                      <div>
                                        <Tooltip title={"Full Screen"}>
                                          <Button
                                            className="btn_green"
                                            compact
                                            size="mini"
                                            icon
                                            onClick={() => {
                                              if (!fullScreen)
                                                this.setState({
                                                  fullScreen: true,
                                                });
                                              else
                                                this.setState({
                                                  fullScreen: false,
                                                });
                                            }}
                                          >
                                            {!this.state.fullScreen && (
                                              <Icon name="expand" />
                                            )}
                                            {this.state.fullScreen && (
                                              <Icon name="compress" />
                                            )}
                                          </Button>
                                        </Tooltip>
                                      </div>
                                    )}
                                </div>
                              }
                            </div>
                          </li>
                        </ul>
                      </div>
                    ) : null}
                    <div className="project_set">
                      {this.showSummaryPage()}
                      {(type !== HIT_STATE_ALL || type === HIT_STATE_ALL) && (
                        <ul className="tools">
                          {this.props.projectDetails.task_type ===
                            IMAGE_MASKING &&
                            currentHit &&
                            currentHit.data && (
                              <li>
                                <button
                                  className="downloadIcon"
                                  onClick={() => this.openSnapshot()}
                                  title="Open As Image"
                                >
                                  <img
                                    alt="download"
                                    style={styles.icnWidth}
                                    src={IMGDOWNLOAD}
                                  />
                                </button>
                              </li>
                            )}
                          {hits && hits.length > 0 && (
                            <li>
                              <button
                                className="downloadIcon project_set text-center"
                                onClick={() => {
                                  this.setState({
                                    confirmDownloadModalVisible: true,
                                  });
                                }}
                                title="Download"
                              >
                                <AntIcon type="download" />
                              </button>
                            </li>
                          )}
                          <li>
                            <button
                              className="filterIcon"
                              onClick={this.toggleMenu}
                              title="Filter"
                            >
                              <img alt="filter" src={FUNEL} />
                            </button>
                          </li>
                          {this.props.projectDetails.task_type !==
                            IMAGE_CLASSIFICATION &&
                            this.props.projectDetails.task_type !==
                              IMAGE_MASKING &&
                            this.props.projectDetails.task_type !==
                              IMAGE_POLYGON_BOUNDING_BOX_V2 &&
                            this.props.projectDetails.task_type !==
                              IMAGE_SEGMENTATION &&
                            type !== HIT_STATE_ALL && (
                              <li id="clr_btn">
                                <button
                                  className="clr_icon"
                                  onClick={() =>
                                    this.docAnnotator
                                      ? this.docAnnotator.showClearAll()
                                      : this.clearAllImageAnnotatios()
                                  }
                                  title="Clear All"
                                >
                                  <svg
                                    width="28"
                                    height="28"
                                    viewBox="0 0 28 28"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d={M9} fill={styles.svg.fillcolor} />
                                    <path d={M11} fill={styles.svg.fillcolor} />
                                    <path d={M16} fill={styles.svg.fillcolor} />
                                    <path d={M27} fill={styles.svg.fillcolor} />
                                    <path d={M6} fill={styles.svg.fillcolor} />
                                  </svg>
                                </button>
                              </li>
                            )}
                        </ul>
                      )}
                      
                      {visible && (
                        <div className="hits_filter">
                          <div className="dropBox_set">
                            <div className="f_between">
                              <h3 className="sec_tilte">
                                <span>Filter By</span>
                              </h3>
                              <AntIcon
                                className="f_close"
                                type="close"
                                onClick={this.toggleMenu}
                              />
                            </div>
                            {type !== HIT_STATE_NOT_DONE &&
                              this.props.projectDetails.task_type !==
                                POS_TAGGING && (
                                <div className="custom_radius">
                                  <div className="hold_sec">
                                    <label>HITs STATUS</label>
                                    {this.getHitStates()}
                                  </div>
                                  <div className="hold_sec">
                                    {(type === HIT_STATE_DONE ||
                                      type === HIT_STATE_ALL ||
                                      type === HIT_STATE_SKIPPED ||
                                      type === HIT_STATE_DELETED) &&
                                      this.getEvaluations()}
                                  </div>
                                  <div className="hold_sec">
                                    {(type === HIT_STATE_DONE ||
                                      type === HIT_STATE_ALL ||
                                      type === HIT_STATE_SKIPPED ||
                                      type === HIT_STATE_DELETED) &&
                                      this.getContributors(
                                        contributorDetails,
                                        this.props.selectedHitsDetails
                                      )}
                                  </div>
                                  <div className="hold_sec">
                                    {(type === HIT_STATE_DONE ||
                                      type === HIT_STATE_ALL ||
                                      type === HIT_STATE_SKIPPED ||
                                      type === HIT_STATE_DELETED) &&
                                      entities &&
                                      entities.length > 0 &&
                                      this.getEntities(entities)}
                                  </div>
                                  <div className="hold_sec">
                                    {(type === HIT_STATE_DONE ||
                                      type === HIT_STATE_ALL ||
                                      type === HIT_STATE_SKIPPED ||
                                      type === HIT_STATE_DELETED) &&
                                      this.props.projectDetails.task_type ===
                                        DOCUMENT_ANNOTATION &&
                                      this.getClassificationLabels(
                                        classification
                                      )}
                                  </div>
                                </div>
                              )}
                            <div className="hold_sec">
                              {(type === HIT_STATE_DONE ||
                                type === HIT_STATE_ALL ||
                                type === HIT_STATE_SKIPPED ||
                                type === HIT_STATE_DELETED) &&
                                this.props.projectDetails.task_type !==
                                  POS_TAGGING && (
                                  <div className="input-uniform">
                                    <label>DATE</label>
                                    <div className="f_datesec">
                                      <LocaleProvider locale={en_US}>
                                        <DatePicker
                                          disabledDate={this.disabledStartDate}
                                          showTime
                                          format="YYYY-MM-DD HH:mm:ss"
                                          value={startValue}
                                          placeholder="Start Date"
                                          onChange={this.onStartChange}
                                          onOk={this.handleStartOK}
                                        />
                                      </LocaleProvider>
                                      <LocaleProvider locale={en_US}>
                                        <DatePicker
                                          disabledDate={this.disabledEndDate}
                                          showTime
                                          format="YYYY-MM-DD HH:mm:ss"
                                          value={endValue}
                                          placeholder="End Date"
                                          onChange={this.onEndChange}
                                          onOk={this.handleEndOK}
                                        />
                                      </LocaleProvider>
                                    </div>
                                  </div>
                                )}
                            </div>
                            <div className="custom_radius hold_sec">
                              {(type === HIT_STATE_DONE ||
                                type === HIT_STATE_ALL ||
                                type === HIT_STATE_SKIPPED ||
                                type === HIT_STATE_DELETED) &&
                                this.getCommentsByFilter()}
                            </div>
                          </div>
                        </div>
                      )}



                    </div>
                  </div>
                  <div className="project_view">
                    <div className="w-80 box_shodow box_scrol">
                      <div className="main_box flex-colum">
                        <div className="box_parent">
                          {this.state.imageLoad && (
                            <div className="overlay_doms">
                              <div className="cssload-wraper">
                                <div className="cssload-dots"></div>
                              </div>
                            </div>
                          )}
                          {/* {loading && <Segment loading={loading} basic vertical />}*/}
                          {/* {!loading && this.props.projectDetails && (
                            <div className="txt-center">
                              {!this.state.loadFilterImages &&
                                (hits.length === 0 ||
                                  (hits && hits[0] === undefined) ||
                                  this.state.lastHitsDetails === true ||
                                  (fromLeaderBoard === false &&
                                    hitsCompleted)) && (
                                  <h2> No items to display here </h2>
                                )
                              //<Segment loading={true} basic vertical />
                              }
                            </div>
                          )} */}

                        {!loading && this.props.projectDetails && (
                          <div className="txt-center">{(!this.state.loadFilterImages) && (hits.length === 0 || (hits && hits[0] === undefined) || (this.state.lastHitsDetails === true) ||
                            (fromLeaderBoard === false && hitsCompleted))
                            && 
                            <h2> No items to display here </h2>
                            //<Segment loading={true} basic vertical />
                            }</div>
                        )}
                        
                          {!(
                            hits.length === 0 ||
                            (hits && hits[0] === undefined)
                          ) &&
                            !(fromLeaderBoard === false && hitsCompleted) &&
                            currentHit && (
                              <div
                                id="targetdiv"
                                className="w-100 videoClassifytag setCustomHeight"
                                style={styles.targetStyle1}
                                loading={loading}
                              >
                                {this.props.projectDetails.task_type ===
                                  POS_TAGGING && (
                                  <Fullscreen
                                    enabled={fullScreen}
                                    onChange={(isFullscreenEnabled) =>
                                      this.setState({
                                        isFullscreenEnabled,
                                        fullScreen: isFullscreenEnabled,
                                      })
                                    }
                                  >
                                    <div className="marginTopExtra">
                                      {prevWord && (
                                        <div className="text-left">
                                          <p>
                                            {" "}
                                            Previous Word Shortcut:{" "}
                                            <b>{prevWord}</b>
                                          </p>
                                        </div>
                                      )}
                                      {nextWord && (
                                        <div className="text-left">
                                          <p>
                                            {" "}
                                            Next Word Shortcut:{" "}
                                            <b> {nextWord} </b>{" "}
                                          </p>
                                        </div>
                                      )}
                                      {extra && (
                                        <div>{this.showExtra(extra)}</div>
                                      )}
                                      {this.showTagLine()}
                                      <br />
                                      <h3>Entities</h3>
                                      <br />
                                      <div className="forCursorPointer ">
                                        {this.showTags(entities)}
                                      </div>
                                      <div
                                        style={styles.lHeight}
                                        disabled={this.state.loading}
                                      />
                                      {this.state.type === "notDone" &&
                                        this.showButtons()}
                                    </div>
                                  </Fullscreen>
                                )}
                                {projectDetails.task_type ===
                                  TEXT_SUMMARIZATION && (
                                  <Fullscreen
                                    enabled={fullScreen}
                                    onChange={(isFullscreenEnabled) =>
                                      this.setState({
                                        isFullscreenEnabled,
                                        fullScreen: isFullscreenEnabled,
                                      })
                                    }
                                  >
                                    <div className="marginTopExtra">
                                      {extra && (
                                        <div>{this.showExtra(extra)}</div>
                                      )}
                                      {this.showText()}
                                      <br />
                                      {this.showWriteText(TEXT_SUMMARIZATION)}
                                      <div
                                        style={styles.lHeight}
                                        disabled={loading}
                                      />
                                      {this.state.type === "notDone" &&
                                        this.showButtons()}
                                    </div>
                                  </Fullscreen>
                                )}

                                {this.state.projectDetails.task_type ===
                                  TEXT_CLASSIFICATION && (
                                  <Fullscreen
                                    enabled={fullScreen}
                                    onChange={(isFullscreenEnabled) =>
                                      this.setState({
                                        isFullscreenEnabled,
                                        fullScreen: isFullscreenEnabled,
                                      })
                                    }
                                  >
                                    <div
                                      className="marginTopExtra"
                                      style={styles.flexB}
                                    >
                                      <div className="w-77">
                                        {extra && (
                                          <div>{this.showExtra(extra)}</div>
                                        )}
                                        {this.showText()}
                                        <br />
                                        {currentHit &&
                                          currentIndex >= 0 &&
                                          showNoteLable()}
                                      </div>
                                      <div className="w-20">
                                        {this.showClassifyTags(
                                          true,
                                          "vertical"
                                        )}
                                        <br />
                                        {type === "notDone" &&
                                          this.showButtons("vertical")}
                                      </div>
                                    </div>
                                  </Fullscreen>
                                )}
                                {projectDetails.task_type ===
                                  TEXT_MODERATION && (
                                  <Fullscreen
                                    enabled={fullScreen}
                                    onChange={(isFullscreenEnabled) =>
                                      this.setState({
                                        isFullscreenEnabled,
                                        fullScreen: isFullscreenEnabled,
                                      })
                                    }
                                  >
                                    <div className="marginTopExtra">
                                      {extra && (
                                        <div>{this.showExtra(extra)}</div>
                                      )}
                                      {this.showText()}
                                      <br />
                                      {this.showWriteText(TEXT_MODERATION)}
                                      <div
                                        style={styles.lHeight}
                                        disabled={loading}
                                      />
                                      {type === "notDone" && this.showButtons()}
                                    </div>
                                  </Fullscreen>
                                )}

                                {this.props.projectDetails.task_type ===
                                  VIDEO_CLASSIFICATION && (
                                  <div className="videoclassification_fullscreen">
                                    <Fullscreen enabled={false}>
                                      <div style={styles.flexB}>
                                        <div className="w-80">
                                          {extra && (
                                            <div>{this.showExtra(extra)}</div>
                                          )}
                                          {this.showImages()}
                                          <br />
                                          {currentHit &&
                                            currentIndex >= 0 &&
                                            showNoteLable()}
                                        </div>
                                        <div className="w-20">
                                          {this.showClassifyTags(
                                            true,
                                            "vertical"
                                          )}
                                          <br />
                                          {type === "notDone" &&
                                            this.showButtons("vertical")}
                                        </div>
                                      </div>
                                    </Fullscreen>
                                  </div>
                                )}

                                {this.props.projectDetails.task_type ===
                                  IMAGE_CLASSIFICATION && (
                                  <div className="imageClassification_fullscreen">
                                    <Fullscreen
                                      enabled={fullScreen}
                                      onChange={(isFullscreenEnabled) =>
                                        this.setState({
                                          isFullscreenEnabled,
                                          fullScreen: isFullscreenEnabled,
                                        })
                                      }
                                    >
                                      <div className="img_classify w-100">
                                        <div className="w-80 work_sec">
                                          {extra && (
                                            <div>{this.showExtra(extra)}</div>
                                          )}
                                          {this.showImages()}
                                          {currentHit &&
                                            currentIndex >= 0 &&
                                            showNoteLable()}
                                        </div>
                                        <div className="w-20 r_box">
                                          {this.showClassifyTags(
                                            true,
                                            "vertical"
                                          )}
                                          {this.state.type === "notDone" &&
                                            this.showButtons("vertical")}
                                          {isFullscreenEnabled &&
                                            this.state.type !== "notDone" && (
                                              <div className="l_icon_section m-b-5 fullscreen_icons">
                                                {this.showFullScreenActionButtons()}
                                              </div>
                                            )}
                                        </div>
                                        {this.addClassificationDynamicLabels()}
                                      </div>
                                    </Fullscreen>
                                  </div>
                                )}
                                {this.props.projectDetails.task_type ===
                                  IMAGE_BOUNDING_BOX && (
                                  <div>
                                    {extra && (
                                      <div>{this.showExtra(extra)}</div>
                                    )}
                                    <Fullscreen
                                      enabled={fullScreen}
                                      onChange={(isFullscreenEnabled) =>
                                        this.setState({
                                          isFullscreenEnabled,
                                          fullScreen: isFullscreenEnabled,
                                        })
                                      }
                                    >
                                      {
                                        <div className="col-md-12">
                                          {this.showBoundingImages()}
                                        </div>
                                      }
                                    </Fullscreen>
                                  </div>
                                )}

                                {this.props.projectDetails.task_type ===
                                  IMAGE_POLYGON_BOUNDING_BOX && (
                                  <div>
                                    {extra && (
                                      <div>{this.showExtra(extra)}</div>
                                    )}
                                    <Fullscreen
                                      enabled={fullScreen}
                                      onChange={(isFullscreenEnabled) =>
                                        this.setState({
                                          isFullscreenEnabled,
                                          fullScreen: isFullscreenEnabled,
                                        })
                                      }
                                    >
                                      {this.showPolygonBoundingImages()}
                                    </Fullscreen>
                                  </div>
                                )}

                                {(this.props.projectDetails.task_type ===
                                  IMAGE_POLYGON_BOUNDING_BOX_V2 ||
                                  this.props.projectDetails.task_type ===
                                    IMAGE_SEGMENTATION ||
                                  this.props.projectDetails.task_type ===
                                    IMAGE_MASKING) && (
                                  <div>
                                    {extra && (
                                      <div>{this.showExtra(extra)}</div>
                                    )}
                                    <Fullscreen
                                      enabled={fullScreen}
                                      onChange={(isFullscreenEnabled) =>
                                        this.setState({
                                          isFullscreenEnabled,
                                          fullScreen: isFullscreenEnabled,
                                        })
                                      }
                                    >
                                      {/* saleen start*/}
                                      {this.state.classification && (
                                        <div className="w-100">
                                          {this.state.classification && (
                                            <div>
                                              <Segment
                                                raised
                                                basic
                                                vertical
                                                style={styles.sPadding}
                                              >
                                                {this.getClassificationQuestions()}
                                              </Segment>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                      {/* saleen end*/}
                                      {this.showPolygonV2BoundingImages()}
                                      {this.state.isFullscreenEnabled && (
                                        <div>
                                          <div className="right_box_entity">
                                            {(type === HIT_STATE_DONE ||
                                              type === HIT_STATE_DELETED ||
                                              type === HIT_STATE_SKIPPED ||
                                              type === HIT_EVALUATION_CORRECT ||
                                              type ===
                                                HIT_EVALUATION_INCORRECT ||
                                              type === HIT_STATE_ALL) &&
                                              !hitsCompleted && (
                                                <div
                                                  className="text-center m-r-15"
                                                  style={{
                                                    display: displaySettingsByRole(
                                                      getUidToken().roleId
                                                    ),
                                                  }}
                                                >
                                                  <Checkbox
                                                    className="m-r-5"
                                                    size="mini"
                                                    checked={continuousTagging}
                                                    onClick={() =>
                                                      this.continuousTaggingSelected()
                                                    }
                                                    label="Keep Entity Selected"
                                                  />
                                                </div>
                                              )}
                                          </div>

                                          <div className="right_box w-20 m-l-20 box_shodow fullscreen_tagging">
                                            <div className="sec_top">
                                              {currentHit.status === "done" &&
                                                currentHit.evaluation &&
                                                this.state.hitStatus &&
                                                this.state.hitEvaluation &&
                                                this.state.hitEvaluation.toLowerCase() ===
                                                  "correct" && (
                                                  <span
                                                    className="status_btn m-b-5 w-100"
                                                    style={{
                                                      backgroundColor:
                                                        "#12c4fc",
                                                      color: "white",
                                                      textAlign: "center",
                                                      borderRadius: "20px",
                                                    }}
                                                  >
                                                    {this.state.hitEvaluation}
                                                  </span>
                                                )}
                                              {currentHit.status === "done" &&
                                                currentHit.evaluation &&
                                                this.state.hitStatus &&
                                                this.state.hitEvaluation &&
                                                this.state.hitEvaluation.toLowerCase() ===
                                                  "incorrect" && (
                                                  <span
                                                    className="status_btn m-b-5 w-100"
                                                    style={{
                                                      backgroundColor:
                                                        "#e0595d",
                                                      color: "white",
                                                      textAlign: "center",
                                                      borderRadius: "20px",
                                                    }}
                                                  >
                                                    {this.state.hitEvaluation}
                                                  </span>
                                                )}
                                              {currentHit &&
                                                (parseInt(
                                                  getUidToken().roleId
                                                ) === ROLES_TYPES.ADMIN ||
                                                  parseInt(
                                                    getUidToken().roleId
                                                  ) === ROLES_TYPES.GUEST ||
                                                  parseInt(
                                                    getUidToken().roleId
                                                  ) === ROLES_TYPES.QA) && (
                                                  <div className="sec_bottom  m-t-0 m-b-10">
                                                    <h4 className="m-b-10 txt-center">
                                                      Evaluate Tagging
                                                    </h4>
                                                    {this.showEvaluationButtons()}
                                                  </div>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Fullscreen>
                                  </div>
                                )}

                                {this.props.projectDetails.task_type ===
                                  VIDEO_BOUNDING_BOX && (
                                  <div>
                                    {extra && 
                                      <div>{this.showExtra(extra)}</div>}
                                    {this.state.classification && (
                                              <div>
                                                <Segment
                                                  raised
                                                  basic
                                                  vertical
                                                  style={styles.sPadding}
                                                >
                                                  {this.getClassificationQuestions()}
                                                </Segment>
                                              </div>
                                            )}
                                    {this.showVideoAnnotation()}
                                  </div>
                                )}

                                {(this.props.projectDetails.task_type ===
                                  DOCUMENT_ANNOTATION ||
                                  this.props.projectDetails.task_type ===
                                    POS_TAGGING_GENERIC) && (
                                  <Fullscreen
                                    enabled={fullScreen}
                                    onChange={(isFullscreenEnabled) =>
                                      this.setState({
                                        isFullscreenEnabled,
                                        fullScreen: isFullscreenEnabled,
                                      })
                                    }
                                  >
                                    <div>
                                      {extra && (
                                        <div>{this.showExtra(extra)}</div>
                                      )}
                                      <br />
                                      <div
                                        className="bg_white"
                                        style={styles.flexColumn}
                                      >
                                        {this.state.classification && (
                                          <div className="w-100">
                                            {this.state.classification && (
                                              <div>
                                                <h3> Choose all that apply </h3>
                                                <Segment
                                                  raised
                                                  basic
                                                  vertical
                                                  style={styles.sPadding}
                                                >
                                                  {this.getClassificationQuestions()}
                                                </Segment>
                                              </div>
                                            )}
                                          </div>
                                        )}
                                        <div className="w-100">
                                          {this.showDocumentAnnotation()}
                                        </div>
                                      </div>
                                      <div
                                        style={styles.lHeight}
                                        disabled={this.state.loading}
                                      />
                                    </div>
                                  </Fullscreen>
                                )}
                                <br />
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <HitsOverviewBox 
                      currentHit={currentHit}
                      hits={hits}
                      type={type}
                      changesInSession={changesInSession}
                      stateType = {this.state.type}
                      contributorDetails={this.state.contributorDetails}
                      projectDetails={this.props.projectDetails}
                      showButtonsMini={this.showButtonsMini()}
                      showEvaluationButtons={this.showEvaluationButtons()}
                      showActionButtons={this.showActionButtons()}
                      handleChange={this.handleChange}
                      propsState={this.state}
                      propsData= {this.props}
                      saveComments={this.saveComments}
                      displaySettingsByRole={displaySettingsByRole}
                      handleLabelChange={this.handleLabelChange}
                      annotateModalOpen={this.state.annotateModalOpen}
                      selectedLabel={this.state.selectedLabel}
                      onlyUnique={this.onlyUnique}
                      sortEntities={this.sortEntities}
                    />
                      </div>
                    </div>
              )}
            {
              <div style={styles.pSpace}>
                <div style={styles.rSpacebetween} className="project_sec">
                  {type === HIT_STATE_NOT_DONE && (
                    <div style={styles.fStart} className="vAlignFix">
                      <Button
                        icon
                        title="Back"
                        className="pull-left btn-normal"
                        onClick={this.redirectBackImage.bind(this)}
                        compact
                      >
                        <Icon name="arrow left" />
                      </Button>
                      <Button
                        icon
                        title="Home"
                        className="pull-left btn-normal"
                        onClick={() => this.routeToHome()}
                        compact
                      >
                        <Icon size="large" name="home" />
                      </Button>
                      {rules.instructions &&
                        (type === HIT_STATE_NOT_DONE ||
                          this.props.projectDetails.task_type ===
                            POS_TAGGING) && (
                          <div style={styles.dFlex}>
                            {this.showTaggingInstructions()}
                          </div>
                        )}
                      <div style={styles.dFlex}>
                        {this.getHitInfo(this.state.currentHit)}{" "}
                      </div>
                    </div>
                  )}

                  {this.props.projectDetails && (
                    <div className="m-t-30">
                      {type !== HIT_STATE_NOT_DONE &&
                        this.props.projectDetails.task_type === POS_TAGGING && (
                          <div style={styles.margint}>
                            <div className="project_sec">
                              <div className="project_title f-column">
                                <div className="f-center">
                                  <a
                                    href="#"
                                    onClick={this.redirectBackImage.bind(this)}
                                  >
                                    <span className="btn_back">
                                      <img alt="backicon" src={BACKICON} />
                                    </span>
                                  </a>
                                </div>
                                <div className="breadcrum-sec">
                                  <div className="sec_holder">
                                    <a
                                      className={
                                        "tilte_display fw-400 " +
                                        (this.props.projectDetails &&
                                        this.props.projectDetails.name.length >
                                          15
                                          ? "pro_width"
                                          : "")
                                      }
                                      href="#"
                                      onClick={this.redirectBackImage.bind(
                                        this
                                      )}
                                      title={
                                        this.props.projectDetails
                                          ? this.props.projectDetails.name
                                          : "Dashboard"
                                      }
                                    >
                                      {this.props.projectDetails
                                        ? this.props.projectDetails.name
                                        : "Dashboard"}
                                    </a>
                                    {breadcrumbSeperate()}
                                    {hitsBreadCrumbTitle(type)}
                                    &nbsp; {pageDetails}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                  )}

                  <div style={styles.fCenter}>
                    {type === HIT_STATE_NOT_DONE &&
                      this.props.projectDetails.task_type ===
                        IMAGE_BOUNDING_BOX &&
                      !hitsCompleted && (
                        <div className="text-center">
                          <Checkbox
                            className="m-r-5"
                            size="mini"
                            checked={notes}
                            onClick={this.toggleNotes.bind(this)}
                            label="Show Notes"
                          />

                          <Checkbox
                            className="m-r-5"
                            size="mini"
                            checked={hideLabels}
                            onClick={this.toggleHideLabels.bind(this)}
                            label="Hide Label"
                          />

                          <Checkbox
                            className="m-r-5"
                            size="mini"
                            checked={autoClose}
                            onClick={this.toggleAutoClose.bind(this)}
                            label="AutoClose"
                          />
                        </div>
                      )}

                    {type === HIT_STATE_NOT_DONE &&
                      (this.props.projectDetails.task_type ===
                        IMAGE_POLYGON_BOUNDING_BOX_V2 ||
                        this.props.projectDetails.task_type ===
                          IMAGE_SEGMENTATION ||
                        this.props.projectDetails.task_type ===
                          IMAGE_MASKING) &&
                      !hitsCompleted && (
                        <div
                          className="text-center m-r-15"
                          style={{
                            display: displaySettingsByRole(
                              getUidToken().roleId
                            ),
                          }}
                        >
                          <Checkbox
                            className="m-r-5"
                            size="mini"
                            checked={continuousTagging}
                            onClick={() => this.continuousTaggingSelected()}
                            label="Keep Entity Selected"
                          />
                        </div>
                      )}

                    {type === HIT_STATE_NOT_DONE &&
                      (parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN ||
                        parseInt(getUidToken().roleId) ===
                          ROLES_TYPES.ANNOTATOR) &&
                      (this.props.projectDetails.task_type ===
                        DOCUMENT_ANNOTATION ||
                        this.props.projectDetails.task_type ===
                          POS_TAGGING_GENERIC) &&
                      !hitsCompleted && (
                        <div
                          style={styles.fCenter}
                          className={
                            this.props.projectDetails.task_type ===
                              DOCUMENT_ANNOTATION ||
                            this.props.projectDetails.task_type ===
                              POS_TAGGING_GENERIC
                              ? "align_autoLabel"
                              : ""
                          }
                        >
                          {autocloseDisable}
                          {autocloseDisable !== true}
                          <Checkbox
                            className={
                              "m-r-5 margin-t-b-0 " +
                              (autocloseDisable !== true
                                ? "block_enable"
                                : "block_disable")
                            }
                            checked={autoClose}
                            onClick={this.toggleAutoClose.bind(this)}
                            label="AutoClose on Selection"
                          />
                          <Checkbox
                            className="m-t-10 m-r-5 margin-t-b-0"
                            checked={autoLabel}
                            onClick={this.toggleAutoLabel.bind(this)}
                            label="Autolabel Same Text in Document"
                          />
                          {this.props.projectDetails.task_type ===
                            POS_TAGGING_GENERIC && (
                            <div>
                              <Button
                                className="m-r-5 btn_green"
                                disabled={undoButton}
                                size="mini"
                                icon
                                onClick={() => this.clearMachinePrediction()}
                              >
                                {" "}
                                Clear_pred{" "}
                              </Button>
                            </div>
                          )}
                          <Button
                            className="m-r-5 btn_green"
                            disabled={undoButton}
                            size="mini"
                            icon
                            onClick={() => this.docAnnotator.undo()}
                          >
                            <Icon name="undo" className="m-r-5" id="m-r-5" />
                            Undo
                          </Button>
                          <Button
                            className="m-r-5 btn_green"
                            disabled={redoButton}
                            size="mini"
                            icon
                            onClick={() => this.docAnnotator.redo()}
                          >
                            <Icon name="redo" className="m-r-5" id="m-r-5" />
                            Redo 2
                          </Button>
                          <Button
                            className="m-r-5 btn_danger"
                            disabled={clearButton}
                            size="mini"
                            icon
                            onClick={() => this.docAnnotator.showClearAll()}
                          >
                            <AntIcon type="close" className="m-r-5" />
                            Clear All
                          </Button>
                        </div>
                      )}
                    {type === HIT_STATE_NOT_DONE &&
                      this.props.projectDetails.task_type === POS_TAGGING &&
                      (parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN ||
                        parseInt(getUidToken().roleId) ===
                          ROLES_TYPES.ANNOTATOR) && (
                        <div style={styles.fCenter} className="btn_fit">
                          {popoverTop && (
                            <div style={styles.helpStyle}>
                              <Button
                                className="m-r-5 btn_green"
                                size="mini"
                                icon
                                onClick={() => this.showClearPoS()}
                              >
                                {" "}
                                Clear
                              </Button>
                              &nbsp;&nbsp;&nbsp;
                            </div>
                          )}
                          {this.clearPoSAnnotations()}
                        </div>
                      )}
                    {(type === HIT_STATE_NOT_DONE ||
                      this.props.projectDetails.task_type === POS_TAGGING) && (
                      <div
                        style={styles.fCenter}
                        className="btn_fit"
                        style={{
                          display: displaySettingsByRole(getUidToken().roleId),
                        }}
                      >
                        {popoverTop && (
                          <div style={styles.helpStyle}>
                           <Tooltip title={popoverTop}>
                              <Icon
                                className="helpIcon"
                                size="large"
                                name="help circle"
                              />
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    )}

                    {this.state.type === "skipped" ||
                    this.state.type === "deleted" ||
                    this.state.type === "notDone" ? (
                      <div
                        style={styles.fCenter}
                        className={
                          "btn_fit" +
                          (type === HIT_STATE_NOT_DONE ||
                          this.props.projectDetails.task_type === POS_TAGGING
                            ? ""
                            : " fullscreenInOverview")
                        }
                      >
                        {fullScreenPopover &&
                          this.props.projectDetails.task_type !==
                            VIDEO_BOUNDING_BOX &&
                            this.props.projectDetails.task_type !==
                              VIDEO_CLASSIFICATION && (
                            <div>
                              <Tooltip title={"Full Screen"}>
                                <Button
                                  className="btn_green"
                                  compact
                                  size="mini"
                                  icon
                                  onClick={() => {
                                    if (!fullScreen)
                                      this.setState({ fullScreen: true });
                                    else this.setState({ fullScreen: false });
                                  }}
                                >
                                  {!this.state.fullScreen && (
                                    <Icon name="expand" />
                                  )}
                                  {this.state.fullScreen && (
                                    <Icon name="compress" />
                                  )}
                                </Button>
                              </Tooltip>
                            </div>
                          )}
                      </div>
                    ) : null}
                  </div>

                  {type !== HIT_STATE_NOT_DONE &&
                    this.props.projectDetails.task_type === POS_TAGGING && (
                      <div className="project_set">
                        {this.showSummaryPage()}
                        {(type !== HIT_STATE_ALL || type === HIT_STATE_ALL) && (
                          <ul className="tools">
                            {hits && hits.length > 0 && (
                              <li>
                                <button
                                  className="downloadIcon project_set text-center"
                                  onClick={() => {
                                    this.setState({
                                      confirmDownloadModalVisible: true,
                                    });
                                  }}
                                  title="Download"
                                >
                                  <AntIcon type="download" />
                                </button>
                              </li>
                            )}
                            <li>
                              <button
                                className="filterIcon"
                                onClick={this.toggleMenu}
                                title="Filter"
                              >
                                <img alt="filter" src={FUNEL} />
                              </button>
                            </li>
                            {type !== HIT_STATE_ALL && (
                              <li id="clr_btn">
                                <button
                                  className="clr_icon"
                                  onClick={() => this.handleClearBtn()}
                                  title="Clear All"
                                >
                                  <svg
                                    width="28"
                                    height="28"
                                    viewBox="0 0 28 28"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d={M9} fill={styles.svg.fillcolor} />
                                    <path d={M11} fill={styles.svg.fillcolor} />
                                    <path d={M16} fill={styles.svg.fillcolor} />
                                    <path d={M27} fill={styles.svg.fillcolor} />
                                    <path d={M6} fill={styles.svg.fillcolor} />
                                  </svg>
                                </button>
                              </li>
                            )}
                          </ul>
                        )}
                        {visible && (
                          <div className="hits_filter">
                            <div className="dropBox_set">
                              <div className="f_between">
                                <h3 className="sec_tilte">
                                  <span>Filter By</span>
                                </h3>
                                <AntIcon
                                  className="f_close"
                                  type="close"
                                  onClick={this.toggleMenu}
                                />
                              </div>
                              {(type === HIT_STATE_DONE ||
                                type !== HIT_STATE_DONE) &&
                                this.props.projectDetails.task_type ===
                                  POS_TAGGING && (
                                  <div className="custom_radius">
                                    <div className="hold_sec">
                                      <label>HITs STATUS</label>
                                      {this.getHitStates()}
                                    </div>
                                    <div className="hold_sec">
                                      {(type === HIT_STATE_DONE ||
                                        type === HIT_STATE_ALL ||
                                        type === HIT_STATE_SKIPPED ||
                                        type === HIT_STATE_DELETED) &&
                                        this.getEvaluations()}
                                    </div>
                                    <div className="hold_sec">
                                      {(type === HIT_STATE_DONE ||
                                        type === HIT_STATE_ALL ||
                                        type === HIT_STATE_SKIPPED ||
                                        type === HIT_STATE_DELETED) &&
                                        this.getContributors(
                                          contributorDetails,
                                          this.props.selectedHitsDetails
                                        )}
                                    </div>
                                    <div className="hold_sec">
                                      {(type === HIT_STATE_DONE ||
                                        type === HIT_STATE_ALL ||
                                        type === HIT_STATE_SKIPPED ||
                                        type === HIT_STATE_DELETED) &&
                                        entities &&
                                        entities.length > 0 &&
                                        this.getEntities(entities)}
                                    </div>
                                    <div className="hold_sec">
                                      {(type === HIT_STATE_DONE ||
                                        type === HIT_STATE_ALL ||
                                        type === HIT_STATE_SKIPPED ||
                                        type === HIT_STATE_DELETED) &&
                                        this.props.projectDetails.task_type ===
                                          DOCUMENT_ANNOTATION &&
                                        this.getClassificationLabels(
                                          classification
                                        )}
                                    </div>
                                  </div>
                                )}
                              <div className="hold_sec">
                                {(type === HIT_STATE_DONE ||
                                  type === HIT_STATE_ALL ||
                                  type === HIT_STATE_SKIPPED ||
                                  type === HIT_STATE_DELETED) &&
                                  this.props.projectDetails.task_type ===
                                    POS_TAGGING && (
                                    <div className="input-uniform">
                                      <label>DATE</label>
                                      <div className="f_datesec">
                                        <LocaleProvider locale={en_US}>
                                          <DatePicker
                                            disabledDate={
                                              this.disabledStartDate
                                            }
                                            showTime
                                            format="YYYY-MM-DD HH:mm:ss"
                                            value={startValue}
                                            placeholder="Start Date"
                                            onChange={this.onStartChange}
                                            onOk={this.handleStartOK}
                                          />
                                        </LocaleProvider>
                                        <LocaleProvider locale={en_US}>
                                          <DatePicker
                                            disabledDate={this.disabledEndDate}
                                            showTime
                                            format="YYYY-MM-DD HH:mm:ss"
                                            value={endValue}
                                            placeholder="End Date"
                                            onChange={this.onEndChange}
                                            onOk={this.handleEndOK}
                                          />
                                        </LocaleProvider>
                                      </div>
                                    </div>
                                  )}
                              </div>
                              <div className="custom_radius hold_sec">
                                {(type === HIT_STATE_DONE ||
                                  type === HIT_STATE_ALL ||
                                  type === HIT_STATE_SKIPPED ||
                                  type === HIT_STATE_DELETED) &&
                                  this.getCommentsByFilter()}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                </div>
                {type === "notDone" &&
                  (this.props.projectDetails.task_type ===
                    DOCUMENT_ANNOTATION ||
                    this.props.projectDetails.task_type ===
                      POS_TAGGING_GENERIC) && (
                    <div
                      style={{
                        display: displaySettingsByRole(getUidToken().roleId),
                      }}
                    >
                      <p style={styles.posTag}>
                        <li>
                          Click on the document and then drag to select text and
                          select a label.
                        </li>
                      </p>
                    </div>
                  )}
                {/*{loading && <Segment loading={loading} basic vertical />}*/}

                {hits && currentHit && (
                  <div
                    id="targetdiv"
                    className="project_view"
                    style={styles.targetStyle}
                    loading={loading}
                  >
                    {this.props.projectDetails.task_type === POS_TAGGING && (
                      <Fullscreen
                        enabled={fullScreen}
                        onChange={(isFullscreenEnabled) =>
                          this.setState({
                            isFullscreenEnabled,
                            fullScreen: isFullscreenEnabled,
                          })
                        }
                      >
                        <div className="marginTopExtra flex_box_design m-5">
                          <div className="w-100 ">
                            <div className="pr_img_annotate w-100 noBg">
                              <div className="w-80 work_sec posTagingcon box_shodow box_scrol">
                                <div className="shortcutInfo w_header">
                                  Shortcut for Words: &nbsp;
                                  {prevWord && (
                                    <span>
                                      Previous Word "<b>{prevWord}</b>",
                                    </span>
                                  )}
                                  {nextWord && (
                                    <span>
                                      {" "}
                                      Next Word "<b>{nextWord}</b>"{" "}
                                    </span>
                                  )}
                                </div>

                                {extra && <div>{this.showExtra(extra)}</div>}
                                <div className="words addBgGrey">
                                  {this.showTagLine()}
                                </div>
                                {currentHit &&
                                  currentIndex >= 0 &&
                                  showNoteLable()}
                              </div>
                              <div className="w-20 label_sec m-l-20 box_shodow box_scrol">
                                <div className="label_holder">
                                  <div className="l_entity m-t-10">
                                    <h3>Entities</h3>
                                    <div
                                      className="forCursorPointer tag_btn_sec m-t-10"
                                      disabled={
                                        parseInt(getUidToken().roleId) ===
                                          ROLES_TYPES.GUEST ||
                                        parseInt(getUidToken().roleId) ===
                                          ROLES_TYPES.QA
                                          ? true
                                          : false
                                      }
                                    >
                                      {this.showTags(this.state.entities)}
                                    </div>
                                    <div
                                      style={styles.lHeight}
                                      disabled={loading}
                                    />
                                    {this.state.type === "notDone" &&
                                      this.showButtons()}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {this.state.hits.length > 0 &&
                            this.state.type !== HIT_STATE_NOT_DONE &&
                            this.props.projectDetails.task_type ===
                              POS_TAGGING && (
                                <HitsOverviewBox
                                currentHit={currentHit}
                                hits={hits}
                                type={type}
                                changesInSession={changesInSession}
                                stateType = {this.state.type}
                                contributorDetails={this.state.contributorDetails}
                                projectDetails={this.props.projectDetails}
                                showButtonsMini={this.showButtonsMini()}
                                showEvaluationButtons={this.showEvaluationButtons()}
                                showActionButtons={this.showActionButtons()}
                                handleChange={this.handleChange}
                                propsState={this.state}
                                propsData= {this.props}
                                saveComments={this.saveComments}
                                />
                            )}
                        </div>
                      </Fullscreen>
                    )}
                    {type === HIT_STATE_NOT_DONE &&
                      this.props.projectDetails.task_type ===
                        IMAGE_CLASSIFICATION && (
                        <Fullscreen
                          enabled={fullScreen}
                          onChange={(isFullscreenEnabled) =>
                            this.setState({
                              isFullscreenEnabled,
                              fullScreen: isFullscreenEnabled,
                            })
                          }
                        >
                          <div className="img_classify">
                            <div className="w-80 work_sec">
                              {extra && <div>{this.showExtra(extra)}</div>}
                              {this.showImages()}
                              {currentHit &&
                                currentIndex >= 0 &&
                                showNoteLable()}
                            </div>
                            <div className="w-20 r_box">
                              {this.showClassifyTags(true, "vertical")}
                              {type === HIT_STATE_NOT_DONE &&
                                this.showButtons("vertical")}
                            </div>
                            {this.addClassificationDynamicLabels()}
                          </div>
                        </Fullscreen>
                      )}
                    {type === HIT_STATE_NOT_DONE &&
                      projectDetails.task_type === TEXT_SUMMARIZATION && (
                        <Fullscreen
                          enabled={fullScreen}
                          onChange={(isFullscreenEnabled) =>
                            this.setState({
                              isFullscreenEnabled,
                              fullScreen: isFullscreenEnabled,
                            })
                          }
                        >
                          <div className="marginTopExtra">
                            {extra && <div>{this.showExtra(extra)}</div>}
                            {this.showText()}
                            <br />
                            {this.showWriteText(TEXT_SUMMARIZATION)}
                            {currentHit && currentIndex >= 0 && showNoteLable()}
                            <div style={styles.lHeight} disabled={loading} />
                            {type === "notDone" && this.showButtons()}
                          </div>
                        </Fullscreen>
                      )}{" "}
                    {type === HIT_STATE_NOT_DONE &&
                      projectDetails.task_type === TEXT_CLASSIFICATION && (
                        <Fullscreen
                          enabled={fullScreen}
                          onChange={(isFullscreenEnabled) =>
                            this.setState({
                              isFullscreenEnabled,
                              fullScreen: isFullscreenEnabled,
                            })
                          }
                        >
                          <div className="marginTopExtra" style={styles.flexB}>
                            <div className="w-80">
                              {extra && (
                                <div className="pan-zoom-element">
                                  {this.showExtra(extra)}
                                </div>
                              )}
                              {this.showText()}
                              <br />
                              {currentHit &&
                                currentIndex >= 0 &&
                                showNoteLable()}
                            </div>
                            <div className="w-20 tbx-wrap cover">
                              <div className="txt-labels">
                                {this.showClassifyTags(true, "vertical")}
                                <br />
                                {type === "notDone" &&
                                  this.showButtons("vertical")}
                              </div>
                            </div>
                          </div>
                        </Fullscreen>
                      )}
                    {type === HIT_STATE_NOT_DONE &&
                      projectDetails.task_type === TEXT_MODERATION && (
                        <Fullscreen
                          enabled={fullScreen}
                          onChange={(isFullscreenEnabled) =>
                            this.setState({
                              isFullscreenEnabled,
                              fullScreen: isFullscreenEnabled,
                            })
                          }
                        >
                          <div className="marginTopExtra">
                            {extra && <div>{this.showExtra(extra)}</div>}
                            {this.showText()}
                            <br />
                            {this.showWriteText(TEXT_MODERATION)}
                            {currentHit && currentIndex >= 0 && showNoteLable()}
                            <div style={styles.lHeight} disabled={loading} />
                            {type === "notDone" && this.showButtons()}
                          </div>
                        </Fullscreen>
                      )}
                    {type === HIT_STATE_NOT_DONE &&
                      this.props.projectDetails.task_type ===
                        VIDEO_CLASSIFICATION && (
                        <Fullscreen enabled={false}>
                          <div className="img_classify" style={styles.flexB}>
                            <div className="w-80 work_sec">
                              <div className="pan-zoom-element">
                                {extra && <div>{this.showExtra(extra)}</div>}
                                {this.showImages()}
                              </div>
                              <br />
                              {currentHit &&
                                currentIndex >= 0 &&
                                showNoteLable()}
                            </div>
                            <div className="w-20 r_box">
                              {this.showClassifyTags(true, "vertical")}
                              <br />
                              {type === "notDone" &&
                                this.showButtons("vertical")}
                            </div>
                          </div>
                        </Fullscreen>
                      )}
                    {type === HIT_STATE_NOT_DONE &&
                      this.props.projectDetails.task_type ===
                        IMAGE_BOUNDING_BOX && (
                        <div>
                          {extra && <div>{this.showExtra(extra)}</div>}
                          <Fullscreen
                            enabled={fullScreen}
                            onChange={(isFullscreenEnabled) =>
                              this.setState({
                                isFullscreenEnabled,
                                fullScreen: isFullscreenEnabled,
                              })
                            }
                          >
                            {
                              <div className="col-md-12">
                                {this.showBoundingImages()}
                              </div>
                            }
                            {currentHit && currentIndex >= 0 && showNoteLable()}
                          </Fullscreen>
                        </div>
                      )}
                    {type === HIT_STATE_NOT_DONE &&
                      this.props.projectDetails.task_type ===
                        IMAGE_POLYGON_BOUNDING_BOX && (
                        <div>
                          {extra && <div>{this.showExtra(extra)}</div>}
                          <Fullscreen
                            enabled={fullScreen}
                            onChange={(isFullscreenEnabled) =>
                              this.setState({
                                isFullscreenEnabled,
                                fullScreen: isFullscreenEnabled,
                              })
                            }
                          >
                            {this.showPolygonBoundingImages()}
                            {currentHit && currentIndex >= 0 && showNoteLable()}
                          </Fullscreen>
                        </div>
                      )}
                    {this.state.type === HIT_STATE_NOT_DONE &&
                      (this.props.projectDetails.task_type ===
                        IMAGE_POLYGON_BOUNDING_BOX_V2 ||
                        this.props.projectDetails.task_type ===
                          IMAGE_SEGMENTATION ||
                        this.props.projectDetails.task_type ===
                          IMAGE_MASKING) && (
                        <div>
                          {extra && <div>{this.showExtra(extra)}</div>}
                          <Fullscreen
                            enabled={fullScreen}
                            onChange={(isFullscreenEnabled) =>
                              this.setState({
                                isFullscreenEnabled,
                                fullScreen: isFullscreenEnabled,
                              })
                            }
                          >
                            {/* saleen start */}
                            {this.state.classification && (
                              <div style={{ backgroundColor : "white"}}>
                                <Segment
                                  raised
                                  basic
                                  vertical
                                  style={styles.sPadding}
                                >
                                  {this.getClassificationQuestions()}
                                </Segment>
                              </div>
                            )}
                            {/* saleen end */}

                            {this.showPolygonV2BoundingImages()}
                            {this.state.isFullscreenEnabled && (
                              <div className="right_box_entity">
                                {type === HIT_STATE_NOT_DONE && !hitsCompleted && (
                                  <div
                                    className="text-center m-r-15"
                                    style={{
                                      display: displaySettingsByRole(
                                        getUidToken().roleId
                                      ),
                                    }}
                                  >
                                    <Checkbox
                                      className="m-r-5"
                                      size="mini"
                                      checked={continuousTagging}
                                      onClick={() =>
                                        this.continuousTaggingSelected()
                                      }
                                      label="Keep Entity Selected"
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                            {currentHit && currentIndex >= 0 && showNoteLable()}
                          </Fullscreen>
                        </div>
                      )}
                    {type === HIT_STATE_NOT_DONE &&
                      this.props.projectDetails.task_type ===
                        VIDEO_BOUNDING_BOX && (
                        <div>
                          {extra && <div>{this.showExtra(extra)}</div>}
                          {this.state.classification && (
                                          <div>
                                            <Segment
                                              raised
                                              basic
                                              vertical
                                              style={styles.sPadding}
                                            >
                                              {this.getClassificationQuestions()}
                                            </Segment>
                                          </div>
                                        )}
                            {this.showVideoAnnotation()}
                            {currentHit && currentIndex >= 0 && showNoteLable()}
                          </div>
                        )}
                    {type === HIT_STATE_NOT_DONE &&
                      (this.props.projectDetails.task_type ===
                        DOCUMENT_ANNOTATION ||
                        this.props.projectDetails.task_type ===
                          POS_TAGGING_GENERIC) && (
                        <Fullscreen
                          enabled={fullScreen}
                          onChange={(isFullscreenEnabled) =>
                            this.setState({
                              isFullscreenEnabled,
                              fullScreen: isFullscreenEnabled,
                            })
                          }
                        >
                          <div>
                            {extra && <div>{this.showExtra(extra)}</div>}
                            <br />

                            <div className="bg_white" style={styles.flexColumn}>
                              {(classification || currentHit.emojiEnabled) && (
                                  <div className="w-100">
                                    {this.state.classification && (
                                      <div>
                                        <h3> Choose all that apply </h3>
                                        <Segment
                                          raised
                                          basic
                                          vertical
                                          style={styles.sPadding}>
                                          {this.getClassificationQuestions()}
                                        </Segment>
                                      </div>
                                    )}
                                    {this.state.currentHit.emojiEnabled && (
                                      <div className="m-t-10">
                                        <a
                                          disabled={!currentHit.emojiEnabled}
                                          size="mini"
                                          icon
                                          onClick={() => this.docAnnotator.showModal()}
                                        >
                                          View Original Content
                                          </a>
                                      </div>
                                    )}
                                  </div>
                                )}
                              <div className="w-100">
                                {this.showDocumentAnnotation()}
                              </div>
                              {currentHit &&
                                currentIndex >= 0 &&
                                showNoteLable()}
                            </div>
                            <div style={styles.lHeight} disabled={loading} />
                          </div>
                        </Fullscreen>
                      )}
                    <br />
                  </div>
                )}
              </div>
            }
            {currentHit && currentIndex >= 0 && showCommentsDetails()}
            {!fullScreen && this.addClassificationDynamicLabels()}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log("mapStateToProps taggerpage", state);
  return {
    currentHit: state.projectReducer.currentHit,
    menuHidden: state.projectReducer.menuHidden,
    currentProject: state.projectReducer.currentProject,
    projectDetails: state.projectReducer.projectDetails,
    currentHitsUrl: state.projectReducer.currentHitsData,
    selectedHitsDetails: state.projectReducer.selectedHitsDetails,
    prevPageDetails: state.projectReducer.prevPageDetails,
    user: state.loginReducer.user,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setloaderMenu,
      getProjectDetails,
      goBack,
      downloadfile,
      downloadfilecsv,
      setCurrentProject,
      setCurrentHit,
      updateProjectDetails,
      updatePrevPageDeatails,
      pushState: push,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TaggerSpace);