export const APP_CONSTANTS = {
    PROFILE_IMAGE_MIN_SIZE: 5120,
    PROFILE_IMAGE_MAX_SIZE: 256000,
    SUBSCRIPTION_TYPE_NEW: 'new',
    SUBSCRIPTION_TYPE_UPDATE: 'update'
};

export const pageSize = 10;


// export const BASE_URL = "http://192.168.52.34:9090/"
// export const BASE_URL_WITHOUT_SUFFIX_SLASH = (config.servingEnv === 'online' ? config.apiURL : window.location.protocol + "//" + window.location.hostname + ':9090/pronotate');

// export const BASE_URL_WITHOUT_SUFFIX_SLASH = "http://192.168.52.34:9090/pronotate"
// export const BASE_URL = "http://192.168.52.34:9090/pronotate/"

export const BASE_URL = process.env.REACT_APP_API_BASE_URL
export const BASE_URL_WITHOUT_SUFFIX_SLASH = process.env.REACT_APP_BASE_URL_WITHOUT_SUFFIX_SLASH


export const VIDEO_ANNOTATOR_CONSTANTS = {
    MOVE_TO_DONE_ANNOTATION_CONFIRM_MESSAGE: 'Unfortunately, there are no annotations. Do you want this video to Move to Done?',
    MOVE_TO_DONE_CLASSIFICATION_CONFIRM_MESSAGE: 'Unfortunately, there are no classifications selected. Do you want this video to Move to Done?'
}

export const POLY_ANNOTATOR_CONSTANTS = {
    TAGGED_ITEM_MESSAGE: 'Are you sure you wish to clear all tagged items?',
};
export const SUCCESS = "success";
export const ERROR = "error";
export const COMMENTS_SAVED_SUCCESS = "Comment saved successfully";
export const COMMENTS_UPDATED_SUCCESS = "Comment updated successfully";
export const COMMENTS_DELETED_SUCCESS = "Comment deleted successfully";
export const USER_UPDATED_SUCCESS = "User updated successfully";
export const SHARE = "Are you sure you want to Share the project to the Contributor";
export const UNSHARE = "Are you sure you want to unshare the project from the Contributor";
export const DRAW_TOOLS = {
    POLYGON: {
        NAME: 'polygon',
        TYPE: 'draw'
    },
    LINE: {
        NAME: 'line',
        TYPE: 'draw'
    },
    POINT: {
        NAME: 'point',
        TYPE: 'draw'
    },
    POLYLINE: {
        NAME: 'polyline',
        TYPE: 'draw'
    },
    LINEPOLY: {
      NAME: 'linepoly',
      TYPE: 'draw'
  },
    RECTANGLE: {
        NAME: 'rectangle',
        TYPE: 'draw'
    },
    CIRCLE: {
        NAME: 'circle',
        TYPE: 'draw'
    },
    FREEHAND: {
        NAME: 'freehand',
        TYPE: 'draw'
    },
    ERASERPOLYGON: {
        NAME: 'eraserpolygon',
        TYPE: 'draw'
    },
    MAGICTOOL: {
      NAME: 'polygonml',
      TYPE: 'draw'
   }
}

export const SYMBOLS = {
    HASH: "#"
};

export const ACTIONS = {
    UPDATE: "update",
    ADD: "add",
    DELETE: "delete",
    CLICK: "click",
    IDLE: "idle"
};

export const ALERT_TYPES = {
    ERROR: "error",
    SUCCESS: "success",
    WARNING: "warnning"
};

export const BOOLEAN_TYPES = {
     TRUE: "true",
     FALSE: "false"
};

export const TAGS = {
    M_TAG: "M",
    H_TAG: "H",
    MACHINE: "machine",
    LEGEND_TAG: "legendTag "
};

export const CONFIRMATION_BOX = {
    CONFIRMATION_TITLE: "Confirmation",
    CONFIRMATION_MODAL_OK: "OK",
    CONFIRMATION_MODAL_CANCEL: "CANCEL"
};

export const LINKS = {
    HOME: 'home',
    SHARED: 'shared'
};

export const TOOLTIP = {
    SHOW_LABEL: "Show Label",
    HIDE_LABEL: "Hide Label",
    LOCK_ANNOTATIONS: "Lock All Annotations",
    UNLOCK_ANNOTATIONS: "Unlock All Annotations",
    HIDE_LABEL_SECTIONS: "Hide Label Sections",
    SHOW_LABEL_SECTIONS: "Show Label Sections",
    SELECT_COLOR: "Please select a color",
    HIDE_REFERENCE_IMG: 'Hide Reference Image',
    SHOW_REFERENCE_IMG: 'Show Reference Image'
};

export const LABELS = {
    ADD_DYNAMIC_LABEL: "Add Dynamic Label",
    ENTER_LABEL: "Enter your label",
    CONTRIBUTOR_DETAILS: "Contributor Details"
};

export const SESSION_VARIABLES = {
    TOKEN_EXPIRY: "tokenExpiry",
    LAST_ACCESS_TIME_API: "lastTimeAccessAPI",
    TIME_EXPIRY_POPUP: "timeExpiryPopup"
};

export const MESSAGES = {
    TOKEN_EXPIRY_MESSAGE: "Your session has expired. Please log in again.",
    HIT_LABEL_EMPTY_MESSAGE: "Label name should not empty.",
    LABEL_NOT_EMPTY: "Label should not empty.",
    LABEL_CREATE_MESSAGE: "Dynamic label created successfully.",
    DELETE_USER_CONFIRM_MESSAGE: ["Are you sure you want to delete the user  ", "?"],
	RECOVER_USER_CONFIRM_MESSAGE:["Are you sure you want to recover the user  ", "?"],
    DELETE_COMMENT_MESSAGE: "Are you sure you want to delete this comment?",
    NO_TAG_FOUND: "No Tag Found, moving to next",
    PASSWORD_REQUIRED: "Password is required",
    RE_PASSWORD_REQUIRED: "Re-Enter Password is required",
    PASSWORD_MISMATCH: "Password is mismatched",
    EXPIRY_ALERT_MESSAGE: "Your session is about to expire. Do you want to continue?",
    PASSWORD_COMPLEXITY_MESSAGE: "Password between 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character.",
    CONTRIBUTOR_ALREADY_ADDED: "Selected Contributors are already added to the project.",
    CONTRIBUTOR_ADDED_SUCCESS: "Contributors are successfully added to the project."
};

export const NUMBERS = {
    NUM_3: "3"
};

export const ROLES = {
    OWNER: 'OWNER',
    ADMIN_CONTRIBUTOR: 'ADMIN_CONTRIBUTOR',
    CONTRIBUTOR: 'CONTRIBUTOR'
};

export const COLORS = {
    RED: "red",
};

export const ICONS = {
    REMOVE: "remove"
};

export const CONTRIBUTE_MODE = {
    ACTIVE: "Active",
    IN_ACTIVE: "InActive",
    SHARE: "share",
    UNSHARE: "Unshare"
};

export const ACTION_BUTTONS = {
    NEXT: 'next'
}

export const COPYRIGHT = {
    YEAR: "Copyright © 2024"
}

export const ITEM_OPTIONS = {
    TAGGED: 'TAGGED',
    NON_TAGGED: 'NONTAGGED',
    MASKED: 'MASKED',
    ANNOTATED: 'ANNOTATED'
}



export const projectData = [
    {
      prtitle: "Image Annotations",
      data: [
        {
          title: "Image Classification",
          describe:
            "Upload file with image urls and get your team to classify image.",
        value:"IMAGE_CLASSIFICATION"
        },
        {
          title: "Image Bounding Box",
          describe:
            "Create rectangular bounding boxes around objects in Images.",
            value:"IMAGE_BOUNDING_BOX_V2"
        },
        {
          title: "Image Segmentation",
          shortdesc: "Bounding Box, Polygons etc",
          describe: "Create free-form polygons, points, lines in images.",
          value:"IMAGE_POLYGON_BOUNDING_BOX_V2"
        },
        {
          title: "Image Masking",
          shortdesc: "Masking, Polygons etc",
          describe: "Mask using free hand polygon, rectangle or circle",
          value:"IMAGE_MASKING"
        },
      ],
    },

    {
      prtitle: "VIDEO ANNOTATIONS",
      data: [
        {
          title: "VIDEO CLASSIFICATION",
          describe:
            "Upload file with video urls and get your team to classify videos",
            value:"VIDEO_CLASSIFICATION"
        },
        {
          title: "VIDEO ANNOTATION",
          describe:
            "Object Tracking: Create rectangular bounding boxes around objects in video.",
          value:"VIDEO_BOUNDING_BOX"
        },
      ],
    },

    {
      prtitle: "TEXT ANNOTATIONS",
      data: [
        {
          title: "DOCUMENT ANNOTATION",
          shortdesc: "Document Processing, NLP",
          describe:
            "Full-length document NER annotation. Annotate individual files (PDF, DOC, Text) one-by-one",
            value:"DOCUMENT_ANNOTATION"
        },
        {
          title: "NER TAGGING",
          shortdesc: "NLP, Text Processing, Large Sentences",
          describe:
            "Line-by-line NER annotation. Upload a CSV file and do NER on each row.",
            value:"POS_TAGGING_GENERIC"
        },
        {
          title: "POS TAGGING FOR SMALL SENTENCES",
          shortdesc: "NLP, Text Processing, Search Queries and Logs",
          describe:
            "Optimized for small sentences. Annotate words in a sentence.",
            value:"POS_TAGGING"
        },
        {
          title: "TEXT SUMMARIZATION",
          shortdesc: "NLP, Text Processing",
          describe:
            "Upload file with text and invite people to write summaries",
            value:"TEXT_SUMMARIZATION"
        },
        {
          title: "TEXT CLASSIFICATION",
          shortdesc: "NLP, Text Processing, IR",
          describe:
            "Create project with entities and text, get your team to classify them",
            value:"TEXT_CLASSIFICATION"
        },
        {
          title: "TEXT MODERATION",
          shortdesc: "Text Processing, User Generated Content",
          describe: "Get your team to moderate the user generated text",
          value:"TEXT_MODERATION"
        },
      ],
    },

    {
      prtitle: "MEDICAL IMAGE ANNOTATIONS",
      data: [
        {
          title: "MEDICAL IMAGE SEGMENTATION",
          shortdesc: "Text Processing, User Generated Content",
          describe: "Create annotations in Dicom Images",
          value:"DICOM_SEGMENTATION"
        },
      ],
    },

    {
      prtitle: "CUSTOM ANNOTATIONS",
      data: [
        {
          title: "EMAIL US FOR CUSTOM ANNOTATIONS",
          shortdesc: "",
          describe:
            "If there is any annotation use-case which is not covered in the list, please drop us an email at",
            value:"contact@pronotate.com"
        },
      ],
    },
  ];

  export const DOC_ANNOTATOR_CONSTANTS = {
    CLEAR_ALL_TAGGED_ITEM: 1,
    CLEAR_ALL_YOUR_TAGGING: 2,
    TAGGED_ITEM_MESSAGE: 'Are you sure you wish to clear all tagged items?',
    TAGGING_MESSAGE: 'Are you sure you wish to clear your taggings?'
};

// Dicom Constants
export const PASTE_PIXEL = 50;
export const UPLOAD_URL = "http://172.25.0.214:8080/api/v1/image/upload/";
export const UPLOAD_IMAGE_SUCCESS = "Image uploaded successfully";