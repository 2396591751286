import React, { Component, PropTypes } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { bindActionCreators } from "redux";
import {
  Checkbox,
  Button,
  Accordion,
  Form,
  Icon
} from "semantic-ui-react";
import { push } from "react-router-redux";
import ImageAttributeForm from "../ImportProjects/TaggerImport/ImageAttributeForm";
import { uploadFileDT,manualUpload,uploadFileDTCompare,getNERTagsList } from "../../Services/UploadFileApis";
import { editProject,changeExistingColorAPI } from "../../Services/TaggerApis";
import { getHomeData,getAllWorkspaceList } from "../../Services/WorkspaceApis";
import { getUidToken,showAlert } from "../../helpers/commonFunctions";


import {
  posSample,
  imageBoundingSample,
  imagePolyBoundingSample,
  textClassificationJsonSample,
  breadcrumbSeperate,
  DOCUMENT_ANNOTATION_TYPES,
  DOC_ANNO_CHILDNODEALERT,
  DOC_ANNO_ALERT_TITLE,
  DOC_ANNO_OK_TEXT,
  createEntitiesJson,
  createDocEntityColorMap,
  ENTITY_COLORS,
  IMAGE_SEGMENTATION,
  checkFileSize,
} from "../../helpers/Utils";

import { updateFileUploadStats,updateHomeData,setCurrentProject,getProjectDetails,getUserHomeData,
  updateUserDefinedColorStats } from "../../redux/reducer/projectReducer";

import ImportProjectHelpers from "../ImportProjects/ImportProjectHelpers";
import {
  IMAGE_POLYGON_BOUNDING_BOX,
  VIDEO_BOUNDING_BOX,
  VIDEO_CLASSIFICATION,
  IMAGE_POLYGON_BOUNDING_BOX_V2,
  IMAGE_MASKING,
  POS_TAGGING_GENERIC,
  DOCUMENT_ANNOTATION,
  IMAGE_BOUNDING_BOX,
  TEXT_SUMMARIZATION,
  POS_TAGGING,
  TEXT_CLASSIFICATION,
  IMAGE_CLASSIFICATION,
  TEXT_MODERATION,
  CHILD_NODE_LIMIT,
  DICOM_SEGMENTATION,
  DICOM_CLASSIFICATION,
  projectTypePath,
  validEnitity,
} from "../../helpers/Utils";


import AdvanceEntityForm from "../ImportProjects/TaggerImport/AdvanceEntitiesForm";
import EntityOptions from "../ImportProjects/TaggerImport/EntityOptions";
import BasicEntityForm from "../ImportProjects/TaggerImport/BasicEntityForm";

import {
  Modal as AntModal,
  Button as AntButton,
  Upload as AntUpload,
  Modal,
  Icon as AntIcon,
  message as AntMessage,
  Select,
  LocaleProvider,
  Collapse,
} from "antd";
import en_US from "antd/lib/locale-provider/en_US";
import _ from "lodash";

import { setloaderMenu } from "../../redux/reducer/projectReducer";
import UploadModel from "./UploadModel";
import BACK from "../../images/Arrow_Simple_left1.svg";
import ToggleSwitch from "../ImportProjects/TaggerImport/ToggleSwitch";
import ColorLabelForm from "../ImportProjects/TaggerImport/ColorLabelForm";
import NERTagsModal from "../../Components/NERTagsModal/NERTagsModal";


const bytes = require("bytes");
const uuidv1 = require("uuid/v1");
const { Panel } = Collapse;


class TaggerEdit extends Component {
  static propTypes = {
    user: PropTypes.object,
    login: PropTypes.func,
    logout: PropTypes.func,
    pushState: PropTypes.func,
    projects: PropTypes.array,
    currentProject: PropTypes.string,
    updateFileUploadStats: PropTypes.func,
    projectDetails: PropTypes.object,
    location: PropTypes.object,
    query: PropTypes.object,
    type: PropTypes.string,
    updateHomeData: PropTypes.func,
    params: PropTypes.object,
    orgName: PropTypes.string,
    projectName: PropTypes.string,
    setCurrentProject: PropTypes.func,
    getProjectDetails: PropTypes.func,
    getUserHomeData: PropTypes.func,
    currentPathProject: PropTypes.string,
    currentPathOrg: PropTypes.string,
    showUserDefinedColor: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    console.log("edit props are ", props);
    const type = props.location.query.type;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fileUploaded = this.fileUploaded.bind(this);
    this.handleUploadFile = this.handleUploadFile.bind(this);
    this.handleAntUploadFile = this.handleAntUploadFile.bind(this);
    this.handleAntUploadFileCompare = this.handleAntUploadFileCompare.bind(
      this
    );
    this.openScreen = this.openScreen.bind(this);
    this.projectEditedCallback = this.projectEditedCallback.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.projectDetailsFetched = this.projectDetailsFetched.bind(this);
    this.fileUploadProgressCallback = this.fileUploadProgressCallback.bind(
      this
    );
    this.docOptionTypeChange = this.docOptionTypeChange.bind(this);
    this.addAdvanceEntityForm = this.addAdvanceEntityForm.bind(this);
    this.addParent = this.addParent.bind(this);
    this.addAdvanceFormEntity = this.addAdvanceFormEntity.bind(this);
    this.removeAdvanceFormEntity = this.removeAdvanceFormEntity.bind(this);
    this.updateAvanceFormInput = this.updateAvanceFormInput.bind(this);
    this.updateBasicFormInput = this.updateBasicFormInput.bind(this);
    this.removeAdvanceFormLabel = this.removeAdvanceFormLabel.bind(this);
    this.removeBasicFormEntity = this.removeBasicFormEntity.bind(this);
    this.removeTagSpaces = this.removeTagSpaces.bind(this);
    const fields = {};
    let basicEntitiesOld = [];
    let basicEntities = [];
    let advanceEntities = [];
    let autoClose = false;
    let allowDynamiclabelling = false;
    let hideLabels = true;
    let notes = false;
    let defaultShape = "polygon";
    let shortcuts = undefined;
    let classification_name = "";
    let classification_disp_name = "";
    let classification_classes = "";
    fields.classification_name = "";
    fields.classification_disp_name = "";
    fields.classification_classes = "";
    fields.attributes = "";
    fields.classification = []
    fields.editClassAttribute = []
    let coloredTags = [];
    let selectedWorkspace = "";
    let workspaceName = "";
    let createUserDefinedColor = false;

    if (this.props.projectDetails) {
      const { name, taskRules } = this.props.projectDetails;
      const rulesJson = JSON.parse(taskRules);
      console.log("Constructor rule",this.props.projectDetails)
      console.log("Rules",rulesJson)
      const tags = rulesJson.tags;
      const instructions = rulesJson.instructions;
      allowDynamiclabelling = this.props.projectDetails.allowDynamiclabelling;
      createUserDefinedColor = this.props.projectDetails.createUserDefinedColor;
      selectedWorkspace = this.props.projectDetails.workspaceId.toString();
      workspaceName = this.props.projectDetails.workspaceName;
      if ("autoClose" in rulesJson) {
        autoClose = rulesJson.autoClose;
      }
      if ("notes" in rulesJson) {
        notes = rulesJson.notes;
      }
      if ("hideLabels" in rulesJson) {
        hideLabels = rulesJson.hideLabels;
      }
      if ("defaultShape" in rulesJson) {
        defaultShape = rulesJson.defaultShape;
      }
      if ("shortcuts" in rulesJson) {
        shortcuts = rulesJson.shortcuts;
      }
      if ("classification" in rulesJson) {
        let classification = rulesJson.classification;
        if(classification.length > 0) {
          console.log("Classification Name",classification[0])
          classification_name = classification[0].name;
          classification_disp_name = classification[0].displayName;
          classification_classes = classification[0].classes;
          fields.classification_name = classification_name;
          fields.classification_disp_name = classification_disp_name;
          fields.classification_classes = classification_classes.join();
          console.log("Rules Json classification",rulesJson.classification)
          fields.classification = rulesJson.classification
        }
      }
      if ("coloredTags" in rulesJson) {
        coloredTags = rulesJson.coloredTags;
      }
      if("attributes" in rulesJson)
        {
          fields.attributes = rulesJson.attributes
          console.log("Fieldsattributes",fields.attributes)
        }
        console.log("editClassAttribute",rulesJson.editClassAttribute)
        if("editClassAttribute" in rulesJson)
          {
            fields.editClassAttribute = rulesJson.editClassAttribute
            console.log("EditClassAttribute",rulesJson.editClassAttribute)
          }

      fields.projectName = name;
      fields.tags = tags;

      if (this.props.projectDetails.task_type === IMAGE_MASKING) {
        fields.coloredTags = coloredTags;
      }
      fields.shortcuts = shortcuts;
      fields.instructions = instructions;
      // adds to identify basic and advance enitites list from available tags
      if (Array.isArray(tags)) {
        basicEntitiesOld = tags.filter((it) => !("entities" in it.entities[0]));
        advanceEntities = tags.filter((it) => "entities" in it.entities[0]);
        tags
          .filter((it) => !("entities" in it.entities[0]))
          .forEach((it) => {
            if (it.entities) {
              const newTags = [];
              it.entities.forEach((tag) => {
                newTags.push(tag.name);
              });
              it.entities = newTags.join();
              basicEntities.push(it);
            }
          });
      }
    }
    this.props.updateUserDefinedColorStats(createUserDefinedColor);
    this.state = {
      loading: false,
      manualUploadCallbackres: false,
      manualHit: 0,
      manualHitIgnored: 0,
      manualHitSize: 0,
      imageAttributes: [],
      dropDownAttributes: [],
      objectAttribute:'',
      editClassAttribute:[],
      file: undefined,
      fileCompare: undefined,
      errors: undefined,
      fileUploaded: false,
      uploadType: undefined,
      fileUploadProgress: 0,
      preColor: [],
      autoClose,
      defaultShape,
      notes,
      hideLabels,
      isChangeExistingColor: false,
      keepEntitySelected: false,
      activeIndex: 1,
      fields,
      type,
      newEntity: "",
      preColor_Ref: [],
      tagTypes: ["tags"],
      newEntityForm: [],
      addNewEntityForm: false,
      isLoagin: false,
      docCategoryType: {
        Basic: false,
        Advance: false,
      },
      basicEntitiesOld,
      basicEntities,
      advanceEntities,
      fileName: "",
      fileNameCompare: undefined,
      allowDynamiclabelling,
      isShowAdvanceLabelTop: false,
      workspaceList: [],
      selectedWorkspace,
      workspaceName,
      showCompletedModel: false,
      createUserDefinedColor,
      modelEntity: false,
      nermodelVisible: false,
      NERTags: "",
      editNERTag: {},
      compareImg: false,
    };
  }

  state = {
    loading: false,
    file: undefined,
    fileCompare: undefined,
    errors: undefined,
    fileUploaded: false,
  };

  emptyCheck = false;
  colorObj = {};

  componentDidMount() {
    console.log("Did mount TaggerEdit ", this.props);
    if (
      this.props.params.orgName &&
      this.props.params.projectName &&
      this.props.currentPathOrg !== this.props.params.orgName &&
        this.props.currentPathProject !== this.props.params.projectName
    ) {
      // this.props.currentProject = this.props.params.projectId;
      // this.props.setCurrentProject(this.props.params.projectId)
      // this.props.setCurrentProject({orgName: this.props.params.orgName, projectName: this.props.params.projectName}, getUidToken());

    }

    this.getWorkspaceDetails();
    if (this.props.projectDetails.task_type === POS_TAGGING_GENERIC) {
      this.props.projectDetails.nerTags === null
        ? getNERTagsList(this.props.user.orgId, this.getNERTagsListCallBack)
        : this.setState({ NERTags: this.props.projectDetails.nerTags });
      this.setState({ modelEntity: this.props.projectDetails.enableAutoTag });
    }
    // if (this.props.params.orgName && this.props.params.projectName) {
    //   this.props.setCurrentProject({orgName: this.props.params.orgName, projectName: this.props.params.projectName}, getUidToken());
    // }
    const editor = document.getElementById("instruction");
    if (editor !== null) {
      editor.setAttribute("data-gramm", "false");
    }
  }

  toggleInput = (e) => {
    //e.stopPropagation();
    console.log("this.state.loading : ", this.state.loading);
    this.setState({ isLoagin: true });
  };

  componentWillReceiveProps(nextProps) {
    console.log("next props in TaggerOveriew", this.props, nextProps);
    if (
      this.props.currentProject !== nextProps.currentProject ||
      !this.props.projectDetails
    ) {
      this.props.getProjectDetails(nextProps.currentProject, getUidToken());
      this.props.setloaderMenu(true);
      this.setState({ loading: true });
    }
    if (!this.props.projectDetails && nextProps.projectDetails) {
      const { name, taskRules } = nextProps.projectDetails;
      const rulesJson = JSON.parse(taskRules);
      console.log("Will receive props",rulesJson)
      const tags = rulesJson.tags;
      const instructions = rulesJson.instructions;
      let shortcuts = undefined;
      let autoClose = false;
      let notes = false;
      let defaultShape = "polygon";
      let classification_name = "";
      let classification_disp_name = "";
      let classification_classes = [];
      const fields = {};
      if ("shortcuts" in rulesJson) {
        shortcuts = rulesJson.shortcuts;
      }
      if ("autoClose" in rulesJson) {
        autoClose = rulesJson.autoClose;
      }
      if ("notes" in rulesJson) {
        notes = rulesJson.notes;
      }
      if ("defaultShape" in rulesJson) {
        defaultShape = rulesJson.defaultShape;
      }
      if ("classification" in rulesJson) {
        let classification = rulesJson.classification;
        classification_name = classification[0].name;
        classification_disp_name = classification[0].displayName;
        classification_classes = classification[0].classes.join();
        fields.classification_name = classification_name;
        fields.classification_disp_name = classification_disp_name;
        fields.classification_classes = classification_classes;
      }
      fields.projectName = name;
      fields.tags = tags;
      fields.instructions = instructions;
      fields.shortcuts = shortcuts;
      this.props.setloaderMenu(false);
      this.setState({
        loading: false,
        fields,
        notes,
        autoClose,
        defaultShape,
      });
    }
  }

  // showClassificationGroups() {
  //   return (<p>Classification Groups</p>);
  // }

  resetClassification() {
    let fields = this.state.fields;
    fields.classification_name = "";
    fields.classification_disp_name = "";
    fields.classification_classes = "";
    this.setState({ fields });
  }

  fileUploaded(error, response) {
    console.log(" file was uploaded ", error, response);
    this.props.setloaderMenu(false);
    if (response && response.statusCode && response.statusCode === 200) {
      console.log(" file upload successful ", response.body);
      this.setState({
        loading: false,
        fileUploaded: true,
        fileUploadStats: response.body,
      });
      this.props.updateFileUploadStats(response.body);
      this.props.getProjectDetails(this.props.currentProject, getUidToken());
      getHomeData(this.projectDetailsFetched);
    } else {
      const errors = response ? response.body.message : error;
      this.setState({ loading: false, fileUploaded: false, errors });
    }
  }

  openScreen = (screen) => {
    console.log("opening screen ", screen);
    this.props.pushState("/projects/" + screen);
  };

  handleChange(field, element) {
    console.log(" handle change ", field, element, this.state);
    const fields = this.state.fields;
    fields[field] = element.target.value;
    this.setState({ fields });
  }

  handleColorLabelChange(field, element, index) {
    if (element.target.value.length > 0)
      this.setState({
        disableSubmitButton: false,
      });
    const fields = this.state.fields;
    let coloredTag = fields.coloredTags[index];
    coloredTag.label = element.target.value;
    this.setState({
      fields,
    });
  }
  colorConfirm = (index) => {
    const that = this;
    const confirm = Modal.confirm;
    confirm({
      title: "Confirmation",
      content:
        "Do you want to change the color of the already annotated label?",
      okText: "OK",
      cancelText: "CANCEL",
      onOk() {
        that.applyExisitingColor(index);
      },
      onCancel() {
        return false;
      },
    });
  };
  applyExisitingColor = (index) => {
    this.colorObj[
      this.state.fields.coloredTags[index].label
    ] = this.state.fields.coloredTags[index].color;
    this.setState({ isChangeExistingColor: true });
  };
  handleColorChange = (color, index) => {
    const fields = this.state.fields;
    let coloredTag = fields.coloredTags[index];
    coloredTag.color = color.hex;
    this.setState({ fields });
    this.applyExisitingColor(index);
  };
  colorChangeResponse(error, response) {
    this.colorObj = {};
  }

  addColorTag = () => {
    const fields = this.state.fields;
    fields.coloredTags.push({ color: ImportProjectHelpers.getDefaultColor, label: "" });
    this.setState({ fields });
  };
  changeExistingColor = (index) => {
    this.colorConfirm(index);
  };
  removeColorTag = (index) => {
    const fields = this.state.fields;
    fields.coloredTags.splice(index, 1);
    this.setState({ fields });
  };

  handleUploadFile(event) {
    console.log("handle upload file", event.target.files[0]);
    const file = event.target.files[0];
    this.setState({ file });
  }

  projectDetailsFetched(error, response) {
    console.log("home details fetched ", error, response);
    if (!error) {
      this.props.updateHomeData(
        response.body.userDetails,
        response.body.projects,
        response.body.planName,
        response.body.labelsAllowed,
        response.body.labelsDone,
        response.body.subscriptionExpiryTimestamp
      );
    }
  }

  fileUploadProgressCallback(event) {
    console.log("file upload progress", event.percent);
    this.setState({ fileUploadProgress: event.percent });
  }

  projectEditedCallback(error, response) {
    console.log(" project edit callback ", error, response);
    this.props.setloaderMenu(false);
    if (!error) {
      this.props.getUserHomeData(getUidToken());
      this.props.getProjectDetails(this.props.currentProject, getUidToken());
      this.setState({ loading: false, projectEdited: true });
    } else {
      const errors = response.body.message;
      this.setState({ loading: false, projectEdited: false, errors });
    }
  }

  getWorkspaceDetails = () => {
    getAllWorkspaceList(
      this.props.user.orgId,
      this.getWorkspaceDetailsCallBack
    );
  };

  getWorkspaceDetailsCallBack = (error, response) => {
    if (!error) {
      this.setState({ workspaceList: response.body });
    } else {
      console.log("Error to get workspace", error);
    }
  };

  handleWorkspaceChange = (value) => {
    const filterList = this.state.workspaceList.filter(
      (w) => w.name === value
    )[0];
    this.setState({ selectedWorkspace: filterList.id });
  };

  renderWorksapce = () => {
    let roleStatus = this.props.projectDetails.contributorDetails.filter(
      (element) => element.userDetails.uid === this.props.user.uid
    )[0].role;
    const options = this.state.workspaceList.map((workspace) => (
      <option value={workspace.name.toString()}>{workspace.name}</option>
    ));
    return (
      <div className="workspace_select">
        <LocaleProvider locale={en_US}>
          <Select
            disabled={roleStatus === "OWNER" ? false : true}
            defaultValue={this.state.workspaceName}
            className="select_width"
            showSearch
            placeholder="Select a workspace"
            optionFilterProp="children"
            onChange={this.handleWorkspaceChange}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {options}
          </Select>
        </LocaleProvider>
      </div>
    );
  };

  // handleObjectAttributeChange( element) {
  //   console.log("handle object att",...fields.attributes, element.target.value)
	// 	this.setState({objectAttribute: element.target.value})
  //   console.log("FIelds",this.state.fields)
	// }

  handleObjectAttributeChange(element) {
    const newValue = element.target.value;
    console.log("handle object att", ...this.state.fields.attributes.split(','), newValue);
    // this.setState({objectAttribute: element.target.value})

    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        attributes: newValue,
      },
    }), () => {
      console.log("Fields after update", this.state.fields);
    });
  }


  handleEditAttributeChange(field, element, index) {
    if (element.target.value.length > 0) {
      this.setState({ disableSubmitButton: false });
    }
  
    // Copy the current editClassAttribute from the state
    const editClassAttribute = [...this.state.fields.editClassAttribute];
  
    // Update the specific attribute
    editClassAttribute[index] = {
      ...editClassAttribute[index],
      [field]: element.target.value,
    };
  
    // Set the updated editClassAttribute back to the state
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        editClassAttribute,
      },
    }));
  }


  addAttributeList = () => {
    const newAttribute = { name: '', displayName: '', classes: [] };
    
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        editClassAttribute: [...prevState.fields.editClassAttribute, newAttribute],
      },
    }));
  }

  removeAttributeList = (index) => {
    this.setState((prevState) => {
      const editClassAttribute = [...prevState.fields.editClassAttribute];
      editClassAttribute.splice(index, 1);
  
      return {
        fields: {
          ...prevState.fields,
          editClassAttribute,
        },
      };
    });
  }



  handleAttributeChange(field, element, index) {
    console.log("handleAttributeChange", field, element, index);
    console.log("Field before ", this.state.fields.classification);
  
    if (element.target.value.length > 0) {
      this.setState({
        disableSubmitButton: false,
      });
    }
  
    // Copy the current classification from the state
    const imageAttributes = [...this.state.fields.classification];
  
    // Update the specific attribute
    imageAttributes[index] = {
      ...imageAttributes[index],
      [field]: field === "classes" ? element.target.value.split(',') : element.target.value,
    };
  
    // If the updated field is displayName, update the name field as well
    if (field === "displayName") {
      imageAttributes[index].name = element.target.value;
    }
  
    console.log("Updation of ", imageAttributes);
  
    // Set the updated classification back to the state
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        classification: imageAttributes,
      },
    }), () => {
      // Callback function to log the state after it has been updated
      console.log("FIELDS after setState callback", this.state.fields.classification);
    });
  }


    addAttribute() {
      const newAttribute = { name: '', displayName: '', classes: [] };
      this.setState((prevState) => ({
        fields: {
          ...prevState.fields,
          classification: [...prevState.fields.classification, newAttribute],
        },
      }));
    }

    removeAttribute(index) {
      this.setState((prevState) => {
        const imageAttributes = [...prevState.fields.classification];
        imageAttributes.splice(index, 1);
    
        return {
          fields: {
            ...prevState.fields,
            classification: imageAttributes,
          },
        };
      });
    }

    // removeAttribute = (index) => {
		// 	// const imageAttributes = this.state.imageAttributes;
    //   const imageAttributes = this.fields.classification
		// 	console.log(imageAttributes)
		// 	imageAttributes.splice(index, 1);
		// 	console.log(imageAttributes)
		// 	this.setState({ imageAttributes });
		// }


    getClassificationClass = (classsification) => {
      let splitArr = [];
      if (classsification.indexOf(",") > 0) {
        console.log("Entered in the getclass if")
        splitArr = classsification.split(",");
        splitArr = splitArr.filter(entry => entry.trim() !== '');
        splitArr = splitArr.map(item => item = item.trim())
      }
      console.log("splitArr",splitArr)
      return splitArr;
    }

  handleSubmit = (response, type, value) => {
    console.log("handleResponse", response, type, value);
    if (this.state.fields.tags && !validEnitity(this.state.fields.tags)) {
      this.displayErrorNotification(
        "error",
        "Entities should not end with comma"
      );
      return false;
    }
    if (this.props.projectDetails.task_type === POS_TAGGING) {
      let tagList = this.state.fields.tags.split(", ");
      let tagWords = false;
      tagList.forEach((t) => {
        if (t.split(" ").length > 1) {
          tagWords = true;
        }
      });
      if (tagWords) {
        this.displayErrorNotification(
          "error",
          "Tag shoud be single word for PoS Tagging project"
        );
        return true;
      }
    }
    if (this.props.currentProject && this.state.file) {
      if (this.state.uploadType === "Pre-Annotated") {
        if (
          this.props.projectDetails.task_type === TEXT_SUMMARIZATION ||
          this.props.projectDetails.task_type === TEXT_MODERATION ||
          this.props.projectDetails.task_type === VIDEO_CLASSIFICATION
        ) {
          uploadFileDT(
            this.state.file,
            this.props.currentProject,
            this.fileUploaded,
            this.fileUploadProgressCallback,
            "PRE_TAGGED_TSV"
          );
        } else if (
          this.props.projectDetails.task_type === TEXT_CLASSIFICATION
        ) {
          let format = "PRE_TAGGED_TSV";
          if (
            this.state.selectedFormat &&
            this.state.selectedFormat === "json"
          ) {
            format = "PRE_TAGGED_JSON";
          }
          uploadFileDT(
            this.state.file,
            this.props.currentProject,
            this.fileUploaded,
            this.fileUploadProgressCallback,
            format
          );
        } else {
          uploadFileDT(
            this.state.file,
            this.props.currentProject,
            this.fileUploaded,
            this.fileUploadProgressCallback,
            "PRE_TAGGED_JSON"
          );
        }
      } else {
        if (
          this.props.projectDetails.task_type === DOCUMENT_ANNOTATION ||
          this.props.projectDetails.task_type === POS_TAGGING_GENERIC
        ) {
          if (this.state.isURLs) {
            uploadFileDT(
              this.state.file,
              this.props.currentProject,
              this.fileUploaded,
              this.fileUploadProgressCallback,
              "URL_FILE"
            );
          } else {
            uploadFileDT(
              this.state.file,
              this.props.currentProject,
              this.fileUploaded,
              this.fileUploadProgressCallback
            );
          }
        } else {
          if (
            this.state.fileName !== null &&
            this.state.fileNameCompare !== undefined
          ) {
            uploadFileDT(
              this.state.file,
              this.props.currentProject,
              this.fileUploaded,
              this.fileUploadProgressCallback
            );
            uploadFileDTCompare(
              this.state.fileCompare,
              this.props.currentProject,
              this.fileUploaded,
              this.fileUploadProgressCallback
            );
          } else {
            uploadFileDT(
              this.state.file,
              this.props.currentProject,
              this.fileUploaded,
              this.fileUploadProgressCallback
            );
          }
        }
      }
      this.props.setloaderMenu(true);
      this.setState({ loading: true });
    } else if (this.props.currentProject && this.state.type === "label") {
      // this.displayErrorNotification('error', this.props.projectDetails.task_type);

      /**
       * adds to update the basic entity structure to required JSON
       */
      const { taskRules } = this.props.projectDetails;
      const rulesJson = JSON.parse(taskRules);
      const tags = rulesJson.tags;
      const isArray = Array.isArray(tags);
      const basicEntities = isArray
        ? tags.filter((it) => !("entities" in it.entities[0]))
        : [];
      if (this.state.basicEntities && this.state.basicEntities.length > 0) {
        this.state.basicEntities.forEach((it, index) => {
          if (typeof it.entities === "string") {
            this.state.basicEntities[index].entities = it.entities
              .split(",")
              .map(Function.prototype.call, String.prototype.trim)
              .map((tag) => {
                const taggedEntity = basicEntities[index]
                  ? basicEntities[index].entities.find(
                      (entity) => entity.name === tag
                    )
                  : undefined;
                const tagId = taggedEntity ? taggedEntity.id : uuidv1();
                return {
                  id: tagId,
                  name: tag,
                };
              });
          }
        });
      }
      let rules = "";
      if (this.state.fields.tags) {
        if (this.state.fields.tags instanceof Array) {
          for (var i = 0; i < this.state.fields.tags.length; i++) {
            this.removeTagSpaces(this.state.fields.tags[i].entities);
          }
        } else this.removeTagSpaces(this.state.fields.tags);
        rules = {
          tags:
            this.props.projectDetails.task_type === DOCUMENT_ANNOTATION
              ? [...this.state.basicEntities, ...this.state.advanceEntities]
              : this.state.fields.tags,
          instructions: this.state.fields.instructions,
        };
      } else {
        rules = { instructions: this.state.fields.instructions };
      }
      if (this.state.fields.shortcuts) {
        rules.shortcuts = this.state.fields.shortcuts;
      }
      if (
        this.props.projectDetails.task_type === IMAGE_MASKING &&
        this.state.createUserDefinedColor
      ) {
        rules.coloredTags = this.state.fields.coloredTags;
        let tagsArray = [];
        if (
          this.state.fields.tags.length > 0 &&
          this.state.fields.tags.indexOf(",") >= -1
        ) {
          this.state.fields.tags.split(",").map((tags) => {
            if (!tagsArray.includes(tags.trim())) tagsArray.push(tags.trim());
          });
        }

        rules.coloredTags.map((coloredTag) => {
          if (!tagsArray.includes(coloredTag.label))
            tagsArray.push(coloredTag.label);
        });
        rules.tags = tagsArray.join(",");
      }
      if (
        this.props.projectDetails &&
        (this.props.projectDetails.task_type === DOCUMENT_ANNOTATION ||
          this.props.projectDetails.task_type === POS_TAGGING_GENERIC ||
          this.props.projectDetails.task_type === IMAGE_BOUNDING_BOX)
      ) {
        rules.autoClose = this.state.autoClose;
      }
      if (
        this.state.fields &&
        this.state.fields.classification_name &&
        this.state.fields.classification_disp_name &&
        this.state.fields.classification_classes
      ) {
        rules.classification = [
          {
            name: this.state.fields.classification_name,
            displayName: this.state.fields.classification_disp_name,
            classes: this.getClassificationClass(
              this.state.fields.classification_classes
            ),
          },
        ];
      }
      if (
        this.props.projectDetails &&
        this.props.projectDetails.task_type === IMAGE_BOUNDING_BOX
      ) {
        rules.hideLabels = this.state.hideLabels;
        rules.notes = this.state.notes;
      }
      if (
        (this.props.projectDetails &&
          this.props.projectDetails.task_type ===
            IMAGE_POLYGON_BOUNDING_BOX_V2) ||
        this.props.projectDetails.task_type === IMAGE_SEGMENTATION ||
        this.props.projectDetails.task_type === IMAGE_MASKING ||
        this.props.projectDetails.task_type === VIDEO_BOUNDING_BOX
      ) {
        rules.defaultShape = this.state.defaultShape;
        rules.keepEntitySelected = this.state.keepEntitySelected;
        if( this.props.projectDetails.task_type === IMAGE_SEGMENTATION ||
          this.props.projectDetails.task_type === IMAGE_MASKING || 
          this.props.projectDetails.task_type === VIDEO_BOUNDING_BOX
        )
          {
            // if (this.state.objectAttribute && this.state.objectAttribute.length > 0)
              // rules.attributes = this.state.objectAttribute
            rules.attributes = this.state.fields.attributes
            let imageAttribute = [];
		        let dropDownAttribute = [];
		        let checkClasses = false;
            console.log("this.state.fields.classification",this.state.fields.classification)
            this.state.fields.classification.map(value => {
              if (value.classes.length === 0) {
                checkClasses = true;
              } else if (Array.isArray(value.classes)) { 
                imageAttribute.push(value);
              } else {
                value.classes = this.getClassificationClass(value.classes)
                imageAttribute.push(value);
              }
            })
            // rules.classification = this.state.fields.classification
            console.log("handle submit image att",imageAttribute)
            rules.classification = imageAttribute
          //   rules.classification  = [
          //     {
          //         "name": "Attribute name 1",
          //         "displayName": "Attribute name 1",
          //         "classes": [
          //             "ss1",
          //             "ss2",
          //             "ss3"
          //         ]
          //     },
          //     {
          //         "name": "Attribute name 2",
          //         "displayName": "Attribute name 2",
          //         "classes": [
          //             "t1",
          //             "t2",
          //             "t4"
          //         ]
          //     },
          //     {
          //         "name": "Attribute name 3",
          //         "displayName": "Attribute name 3",
          //         "classes": [
          //           "w1",
          //           "w2",
          //           "w4"
          //       ]
          //     }
          // ]
            // rules.editClassAttribute = dropDownAttribute;
            rules.editClassAttribute = this.state.fields.editClassAttribute
          }
      }
      console.log(
        "response",
        response,
        this.state.fields,
        JSON.stringify(rules)
      );
      let input = {
        name: this.state.fields.projectName,
        taskType: this.props.projectDetails.task_type,
        accessType: "RESTRICTED",
        rules: JSON.stringify(rules),
      };
      if (this.props.projectDetails.task_type === DOCUMENT_ANNOTATION)
        input.allowDynamiclabelling = this.state.allowDynamiclabelling;
      if (this.props.projectDetails.task_type === IMAGE_MASKING) {
        input.createUserDefinedColor = this.state.createUserDefinedColor;
      }

      input.workspaceId = this.state.selectedWorkspace;
      if (
        this.props.projectDetails.task_type === POS_TAGGING_GENERIC &&
        this.state.modelEntity
      ) {
        input.nerTags = this.state.NERTags;
      }
      input.enableAutoTag = this.state.modelEntity;
      console.log("INput",input)
      editProject(this.props.currentProject, input, this.projectEditedCallback);
      this.props.setloaderMenu(true);
      this.setState({ loading: true });
    }
    if (
      this.props.projectDetails.task_type === IMAGE_MASKING &&
      this.state.isChangeExistingColor
    ) {
      changeExistingColorAPI(
        this.props.currentProject,
        this.colorObj,
        this.colorChangeResponse
      );
    }
  };

  getClassificationClass = (classsification) => {
    let splitArr = [];
    if (classsification.indexOf(",") > 0) {
      splitArr = classsification.split(",");
      splitArr = splitArr.filter((entry) => entry.trim() !== "");
      splitArr = splitArr.map((item) => (item = item.trim()));
    }
    return splitArr;
  };

  dynamicLabelOptionChange = () => {
    console.log(
      "buttons",
      "Allow Dynamic Labelling",
      !this.state.allowDynamiclabelling
    );
    if (this.state.allowDynamiclabelling) {
      this.setState({ allowDynamiclabelling: false });
    } else {
      this.setState({ allowDynamiclabelling: true });
    }
  };

  manualUploadCallback = (data) => {
    this.setState({
      manualUploadCallbackres: true,
      manualHit: data.body["hit_count"],
      manualHitIgnored: data.body["hit_ignored"],
      manualHitSize: data.body["size"],
    });
    this.props.setloaderMenu(false);
  };

  isUserDefinedColorSwitchChange = (checked) => {
    let fields = this.state.fields;
    let { coloredTags } = this.state.fields;
    let projectDetails = this.props.projectDetails;
    let entityColorMap = [];
    const entitiesObject = createEntitiesJson(projectDetails.taskRules);
    let entities = entitiesObject.entities;
    if (entities.length > 0) {
      if (isNaN(entities[0])) {
        entities = entities.sort();
      } else {
        entities = entities.sort((first, last) => {
          return first - last;
        });
      }
    }
    entityColorMap = createDocEntityColorMap(entities, ENTITY_COLORS);
    let data = this.state.fields.tags.split(",");
    if (isNaN(data[0])) {
      data = data.sort();
    } else {
      data = data.sort((first, last) => {
        return first - last;
      });
    }
    this.state.fields.tags = data.join(",");

    if (fields.coloredTags.length === 0) {
      if (fields.tags.length > 0 && fields.tags.indexOf(",") >= -1) {
        let colorMapTags = [];
        this.state.fields.tags.split(",").map((tags) => {
          let jsonObj = {};
          jsonObj.color = entityColorMap[tags.trim()];
          jsonObj.label = tags.trim();
          colorMapTags.push(jsonObj);
        });
        coloredTags = colorMapTags;
      } else {
        coloredTags = [{ color: ImportProjectHelpers.getDefaultColor, label: "" }];
      }
      fields.coloredTags = coloredTags;

      // fields.tags = '';
    }
    this.setState({ fields, createUserDefinedColor: checked });
    this.props.updateUserDefinedColorStats(checked);
  };

  advancedOptionChange = (e1, e2, e3) => {
    console.log("advancedOptionChange", e1, e2, e3);
    if (e1 === "autoClose") {
      this.setState({ autoClose: e3.checked });
    } else if (e1 === "hideLabels") {
      this.setState({ hideLabels: e3.checked });
    } else if (e1 === "keepEntitySelected") {
      this.setState({ keepEntitySelected: e3.checked });
    } else if (e1 === "notes") {
      this.setState({ notes: e3.checked });
    } else if (e1 === "defaultShape") {
      if (e3.checked) {
        this.setState({ defaultShape: e3.value });
      }
    } else if (e1 === "selectedFormat") {
      if (e3.checked) {
        this.setState({ selectedFormat: e3.value });
      }
    } else if (e1 === "isURLs") {
      this.setState({ isURLs: e3.checked });
    }
  };

  addAdvanceEntityForm() {
    const newList = this.state.advanceEntities;
    const masterId = uuidv1();
    const formId = uuidv1();
    let count = 0;
    if (0 != newList.length) {
      count = newList[newList.length - 1].count + 1;
    } else {
      count = newList.length + 1;
    }
    newList.push({
      id: formId,
      name: "",
      entities: [
        {
          id: masterId,
          text: "",
          labelName: "",
          parentId: null,
          level: 0,
          entities: [
            {
              id: uuidv1(),
              text: "",
              parentId: masterId,
              level: 1,
              entities: [],
              name: "",
            },
          ],
        },
      ],
      count: count,
    });
    this.state.isShowAdvanceLabelTop = true;
    return newList;
  }

  // @params input (formId,entityId,event,data, action), output (data)
  editAdvanceForm(formId, entityId, event, data, key, action) {
    const dataset = this.state.advanceEntities;
    const formIndex = dataset.findIndex((it) => it.id === formId);
    if (action === "delete") {
      if (!entityId) {
        dataset.splice(formIndex, 1);
        let count = 0;
        dataset.forEach((entity) => {
          count++;
          entity.count = count;
        });
      } else {
        data.forEach((entity) => {
          if (entity.entities.length > 0) {
            this.editAdvanceForm(
              formId,
              entityId,
              null,
              entity.entities,
              null,
              "delete"
            );
          }
          if (entityId === entity.id) {
            const deleteIndex = data.findIndex((it) => it.id === entityId);
            data.splice(deleteIndex, 1);
          }
        });
      }
    } else if (action === "add") {
      data.forEach((entity) => {
        if (entity.entities.length > 0) {
          this.editAdvanceForm(
            formId,
            entityId,
            null,
            entity.entities,
            null,
            "add"
          );
        }
        if (entityId === entity.id) {
          if (entity.level > CHILD_NODE_LIMIT) {
            const confirm = AntModal.warning;
            confirm({
              title: DOC_ANNO_ALERT_TITLE,
              content: DOC_ANNO_CHILDNODEALERT,
              okText: DOC_ANNO_OK_TEXT,
              onOk() {},
            });
            return false;
          }
          entity.entities.push({
            id: uuidv1(),
            text: "",
            parentId: entityId,
            level: entity.level + 1,
            entities: [],
            name: "",
          });
        }
      });
    } else if (action === "update") {
      if (key === "label") {
        dataset[formIndex].name = event.target.value;
        dataset[formIndex].count = dataset.length;
      } else {
        data.forEach((entity) => {
          if (entityId === entity.id) {
            if (event) {
              entity.name = event.target.value;
              entity.text = event.target.value;
              entity.count = data.length;
            }
          } else if (entity.entities.length > 0) {
            this.editAdvanceForm(
              formId,
              entityId,
              event,
              entity.entities,
              key,
              "update"
            );
          }
        });
      }
    }
    if (entityId) {
      dataset[formIndex].entities = data;
    }

    return dataset;
  }

  editBasicForm(event, id, key, type) {
    let list = this.state.basicEntities;
    const editIndex = list.findIndex((it) => it.id === id);
    if (type === "add") {
      this.state.isShowAdvanceLabelTop = false;
      list.push({
        id: uuidv1(),
        label: "",
        entities: "",
        isEntityDuplicate: false,
        isLabelDuplicate: false,
      });
    } else if (type === "update") {
      const val = event.target.value;
      list[editIndex][key] = val;
    } else {
      if (id === "all") {
        list = [];
      } else {
        list.splice(editIndex, 1);
      }
    }
    return list;
  }

  addParent(formId) {
    const newList = this.state.advanceEntities;
    const formIndex = newList.findIndex((it) => it.id === formId);
    const parentId = uuidv1();
    newList[formIndex].entities.push({
      id: parentId,
      text: "",
      parentId: null,
      level: 0,
      entities: [],
      name: "",
      count: newList.length,
    });
    this.setState({ advanceEntities: newList });
  }

  addAdvanceFormEntity(formId, entityId, data) {
    const updatedList = this.editAdvanceForm(
      formId,
      entityId,
      null,
      data,
      null,
      "add"
    ); //formId, entityId,event,list,action
    this.setState({ advanceEntities: updatedList });
  }

  removeAdvanceFormEntity(formId, entityId, data) {
    const updatedList = this.editAdvanceForm(
      formId,
      entityId,
      null,
      data,
      null,
      "delete"
    );
    this.setState({ advanceEntities: updatedList });
  }

  updateAvanceFormInput(formId, event, entityId, key, data) {
    const updatedList = this.editAdvanceForm(
      formId,
      entityId,
      event,
      data,
      key,
      "update"
    );
    this.setState({ advanceEntities: updatedList });
  }

  updateBasicFormInput(event, id, key) {
    const updatedList = this.editBasicForm(event, id, key, "update");
    this.setState({ basicEntities: updatedList });
  }

  // formId, entityId,event,list,action
  removeAdvanceFormLabel(formId, data) {
    const updatedList = this.editAdvanceForm(
      formId,
      null,
      null,
      data,
      null,
      "delete"
    );
    this.setState({ advanceEntities: updatedList });
  }

  removeBasicFormEntity(id) {
    const updatedList = this.editBasicForm(null, id, null, "delete");
    this.setState({ basicEntities: updatedList });
  }

  // adds basic/ advance entity form inputs
  docOptionTypeChange(value) {
    const newList = this.state.advanceEntities;
    if (value === DOCUMENT_ANNOTATION_TYPES.ADVANCE) {
      const updatedList = this.addAdvanceEntityForm();
      this.setState({ advanceEntities: updatedList });
    } else {
      const updatedList = this.editBasicForm(null, null, null, "add");
      this.setState({ basicEntities: updatedList });
    }
  }

  // render advance label form
  renderAdvanceEntities() {
    return this.state.advanceEntities.map((label) => {
      return (
        <AdvanceEntityForm
          formId={label.id}
          label={label.name}
          list={label.entities}
          serNum={label.count}
          addParent={(id) => this.addParent(id)}
          addChild={(entityId) =>
            this.addAdvanceFormEntity(label.id, entityId, label.entities)
          } // formId, entityId, data
          updateInput={(e, entityId, key) =>
            this.updateAvanceFormInput(
              label.id,
              e,
              entityId,
              key,
              label.entities
            )
          }
          removeLabel={(id) =>
            this.removeAdvanceFormLabel(label.id, label.entities)
          }
          removeChild={(entityId) =>
            this.removeAdvanceFormEntity(label.id, entityId, label.entities)
          }
        />
      );
    });
  }

  handleAntUploadFile(info) {
    const file = info.file.originFileObj;
    const regex = /^[a-zA-Z0-9_,]+\.[a-zA-Z0-9]+$/;
    
    if(regex.test(file.name))
      {
        this.setState({ file });
        this.setState({ fileName: file.name });
      }
    else{
        showAlert("The file name should not contains any special characters","error")
      }
  }

  handleAntUploadFileCompare(info) {
    const fileCompare = info.file.originFileObj;
    const regex = /^[a-zA-Z0-9_,]+\.[a-zA-Z0-9]+$/;
    
    if(regex.test(fileCompare.name))
      {
        this.setState({ fileCompare });
        this.setState({ fileNameCompare: fileCompare.name });
      }
    else{
        showAlert("The file name should not contains any special characters","error")
      }
  }

  getEmptyInput = (enitity) => {
    if (enitity.name === "") {
      this.emptyCheck = true;
      return true;
    } else if (enitity.entities.length === 1) {
      this.getEmptyInput(enitity.entities[0]);
      if (
        enitity.entities[0].parentId === null &&
        enitity.entities[0].entities.length === 0
      ) {
        this.emptyCheck = true;
        return true;
      }
    } else if (enitity.entities.length > 0) {
      enitity.entities.forEach((enitity) => {
        this.getEmptyInput(enitity);
      });
    }
  };
  tagsToArray = (tags) => {
    let tagsArr = [];
    if (tags instanceof Array) {
      tags.forEach((element) => {
        element.name = element.name.trim();
        tagsArr.push(element);
      });
    } else {
      if (tags.includes(",")) {
        let splitArr = tags.split(",");
        splitArr.forEach((arr) => {
          tagsArr.push(arr.trim());
        });
      } else {
        tagsArr.push(tags);
      }
    }
    return tagsArr;
  };

  removeTagSpaces = (tags) => {
    if (tags instanceof Array) {
      let tagsArr = this.tagsToArray(tags);
      this.state.fields.tags = tagsArr;
    } else {
      if (tags !== undefined) {
        let splitArr = tags.split(",");
        splitArr.map((element) => {
          element = element.trim();
        });
        this.state.fields.tags = splitArr.join();
      }
    }
  };

  checkIfArrayIsUnique = (tags) => {
    let tagsArr = this.tagsToArray(tags);
    return tagsArr.length === new Set(tagsArr).size;
  };

  checkUnique = (tagsArr) => {
    return tagsArr.length === new Set(tagsArr).size;
  };

  checkBascicEntityUnique = (tags) => {
    let labelList = [];
    let disable = false;
    for (var i = 0; i < tags.length; i++) {
      labelList.push(tags[i].label.trim());
      let unique = this.checkIfArrayIsUnique(tags[i].entities);
      if (!unique) {
        disable = true;
        tags[i].isEntityDuplicate = true;
        //break;
      } else {
        tags[i].isEntityDuplicate = false;
      }
    }

    const labelIdList = _.uniqBy(tags, "label");
    if (labelIdList.length > 0) {
      if (tags.length > 1) {
        if (tags.length !== labelIdList.length) {
          for (var i = 0; i < tags.length; i++) {
            for (var j = 0; j < labelIdList.length; j++) {
              if (tags[i].id !== labelIdList[j].id) {
                tags[i].isLabelDuplicate = true;
              } else {
                tags[i].isLabelDuplicate = false;
                break;
              }
            }
          }
        } else {
          for (var i = 0; i < tags.length; i++) {
            tags[i].isLabelDuplicate = false;
          }
        }
      }
    }

    if (disable || (!disable && !this.checkUnique(labelList))) {
      this.displayErrorNotification(
        "error",
        "Duplicate labels or Entities not Allowed."
      );
      return true;
    }

    return false;
  };

  displayErrorNotification(type, message) {
    let isNotification = document.getElementsByClassName(
      "ant-notification-notice"
    );
    if (isNotification.length == 0) {
      showAlert(message, type);
    }
  }

  checkOcuurenceUnique = (entities) => {
    let nameList = [];
    for (var i = 0; i < entities.length; i++) {
      nameList.push(entities[i].name.trim());
    }
    if (nameList.length > 0 && !this.checkUnique(nameList)) {
      this.emptyCheck = true;
      this.displayErrorNotification(
        "error",
        "Duplicate labels or Entities not Allowed."
      );
      return true;
    } else {
      entities.forEach((element) => {
        this.checkOcuurenceUnique(element.entities);
      });
    }
  };

  checkAdvancedEntityUnique = (tags) => {
    tags.forEach((element) => {
      this.checkOcuurenceUnique(element.entities);
    });
  };

  goBackToOverview = () => {
    this.props.pushState(
      projectTypePath(this.props.projectDetails) +
        this.props.params.orgName +
        "/" +
        this.props.params.projectName +
        "/" +
        this.props.params.projectId
    );
  };

  showCompletedModel = () => {
    this.setState({ showCompletedModel: true, type: "" });
  };

  modelLoading = (type) => {
    this.props.setloaderMenu(type);
    this.setState({ loading: type });
  };

  NEREntityOptionChange = (e1, e2, e3) => {
    if (e1 === "modelEntity") {
      this.setState({ modelEntity: e3.checked });
      this.props.projectDetails.nerTags === null
        ? getNERTagsList(this.props.user.orgId, this.getNERTagsListCallBack)
        : this.setState({ NERTags: this.props.projectDetails.nerTags });
    }
  };

  getNERTagsListCallBack = (error, response) => {
    if (!error) {
      this.setState({ NERTags: JSON.stringify(response.body) });
    } else {
      console.log("Error to get NER Tags", error);
    }
  };

  updateValue = (tags) => {
    let renderNERTags = [];
    tags.map((tag, index) => {
      let data = {};
      data.key = index.toString();
      data.desc = tag.desc;
      data.slabel = tag.slabel;
      renderNERTags.push(data);
    });
    this.setState({
      NERTags: JSON.stringify(renderNERTags),
      nermodelVisible: false,
    });
    showAlert("Changes saved successfully.", "success");
  };



  render() {
    console.log("taggder add state is ", this.state, this.props);
    const {
      fields,
      file,
      loading,
      type,
      selectedWorkspace,
      basicEntities,
    } = this.state;
    const { projectDetails, showUserDefinedColor } = this.props;
    const { coloredTags } = this.state.fields;
    let submitDisabled = true;
    if (projectDetails) {
      if (fields && (Object.keys(fields).length >= 2 || file) && !loading) {
        submitDisabled = false;
      }
      if (
        !submitDisabled &&
        type === "label" &&
        fields &&
        Object.keys(fields).length >= 2 &&
        (("projectName" in fields && fields.projectName.length === 0) ||
          ("instructions" in fields &&
            fields.instructions !== undefined &&
            fields.instructions.length === 0) ||
          //if ner tagging autotagged then tag is optional
          (((projectDetails.task_type !== POS_TAGGING_GENERIC &&
            projectDetails.task_type !== TEXT_MODERATION &&
              projectDetails.task_type !== TEXT_SUMMARIZATION) ||
            (projectDetails.task_type === POS_TAGGING_GENERIC &&
              !this.state.modelEntity)) &&
            "tags" in fields &&
            !showUserDefinedColor &&
            fields.tags !== undefined &&
            fields.tags.length === 0))
      ) {
        submitDisabled = true;
      } else if (
        !submitDisabled &&
        projectDetails.task_type !== TEXT_MODERATION &&
          projectDetails.task_type !== TEXT_SUMMARIZATION &&
        type === "label" &&
        fields &&
        Object.keys(fields).length === 3 &&
        fields.tags.length === 0
      ) {
        submitDisabled = true;
      } else if (
        !submitDisabled &&
        type === "label" &&
        selectedWorkspace === "" &&
        selectedWorkspace.length === 0
      ) {
        submitDisabled = true;
      } else if (
        !submitDisabled &&
        type === "label" &&
        showUserDefinedColor &&
        fields.coloredTags.length > 0
      ) {
        fields.coloredTags.map((coloredTag) => {
          if (coloredTag.label.length === 0) {
            submitDisabled = true;
            return true;
          }
        });
      }
    }

    // if (this.props.location.query.type === IMAGE_SEGMENTATION || this.props.location.query.type === IMAGE_MASKING || this.props.location.query.type === VIDEO_BOUNDING_BOX) {
    //   if (this.state.imageAttributes.length === 0) {
    //    const imageAttributes = this.state.imageAttributes;
    //    imageAttributes.push({name: '', displayName: '', classes: [] });
    //    this.setState({ imageAttributes })
    //   }
    //   if (this.state.dropDownAttributes.length === 0) {
    //    const dropDownAttributes = this.state.dropDownAttributes;
    //    dropDownAttributes.push({name: '', displayName: '', classes: [] });
    //    this.setState({ dropDownAttributes })
    //   }
    // }
    // console.log("this.props.location.query.type",this.props.location.query.type)
    // if (this.props.location.query.type === IMAGE_SEGMENTATION || this.props.location.query.type === IMAGE_MASKING || this.props.location.query.type === VIDEO_BOUNDING_BOX) {
      if (this.props.projectDetails.task_type === IMAGE_SEGMENTATION || this.props.projectDetails.task_type === IMAGE_MASKING || this.props.projectDetails.task_type === VIDEO_BOUNDING_BOX) {
      console.log("Inside the query data",fields.classification)
      // if (this.state.imageAttributes.length === 0) {
      //  const imageAttributes = this.state.imageAttributes;
      if (fields.classification.length === 0) {
      const imageAttributes = fields.classification
       imageAttributes.push({name: '', displayName: '', classes: [] });
       this.setState({ imageAttributes })
      }

      // if (this.state.dropDownAttributes.length === 0) {
      //  const dropDownAttributes = this.state.dropDownAttributes;
      //  dropDownAttributes.push({name: '', displayName: '', classes: [] });
      //  this.setState({ dropDownAttributes })
      // }

      if(fields.editClassAttribute.length === 0)
        {
          const editClassAttribute = fields.editClassAttribute
          editClassAttribute.push({name: '', displayName: '', classes: [] })
          this.setState({editClassAttribute})
        }



    }
    if ((fields.classification_name.length > 0 && (fields.classification_classes.length === 0 || fields.classification_disp_name.length === 0)) ||
    ((fields.classification_name.length === 0 || fields.classification_disp_name.length === 0) && fields.classification_classes.length > 0) ||
    ((fields.classification_name.length === 0 || fields.classification_classes.length === 0) && fields.classification_disp_name.length > 0)) {
     submitDisabled = true;
}

    if (
      (projectDetails.task_type === POS_TAGGING ||
        projectDetails.task_type === POS_TAGGING_GENERIC ||
        projectDetails.task_type === TEXT_CLASSIFICATION ||
        projectDetails.task_type === IMAGE_CLASSIFICATION ||
        projectDetails.task_type === IMAGE_BOUNDING_BOX ||
        projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX ||
        projectDetails.task_type === VIDEO_CLASSIFICATION ||
        projectDetails.task_type === VIDEO_BOUNDING_BOX ||
        projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX_V2 ||
        (projectDetails.task_type === IMAGE_MASKING && !showUserDefinedColor) ||
        projectDetails.task_type === DICOM_CLASSIFICATION ||
        projectDetails.task_type === DICOM_SEGMENTATION ||
        projectDetails.task_type === IMAGE_SEGMENTATION) &&
      fields.tags &&
      !this.checkIfArrayIsUnique(fields.tags)
    ) {
      submitDisabled = true;
      this.displayErrorNotification(
        "error",
        "Duplicate labels or Entities not Allowed."
      );
    }

    if (
      projectDetails.task_type === IMAGE_MASKING &&
      showUserDefinedColor &&
      !ImportProjectHelpers.checkIfColoredArrayIsUnique(fields.coloredTags)
    ) {
      submitDisabled = true;
      this.displayErrorNotification(
        "error",
        "Duplicate labels or Entities not Allowed."
      );
    }
    if (
      projectDetails.task_type === IMAGE_MASKING &&
      showUserDefinedColor &&
      ImportProjectHelpers.checkForCommaSeparator(fields.coloredTags)
    ) {
      submitDisabled = true;
      this.displayErrorNotification("error", "Comma separators not Allowed.");
    }

    if (projectDetails) {
      //Validation for empty label in document annotation
      if (projectDetails.task_type === DOCUMENT_ANNOTATION && !submitDisabled) {
        //n
        this.emptyCheck = false;
        if (
          this.state.advanceEntities.length === 0 &&
          basicEntities.length === 0
        ) {
          this.emptyCheck = true;
        } else {
          let validateBasic = ImportProjectHelpers.validateBasicEntity(basicEntities);
          this.state.advanceEntities.forEach((enitity) => {
            if (enitity.entities && enitity.entities.length > 0) {
              this.getEmptyInput(enitity);
            } else {
              this.emptyCheck = true;
            }
          });

          if (!this.emptyCheck)
            this.checkAdvancedEntityUnique(this.state.advanceEntities);

          if (validateBasic) {
            this.emptyCheck = true;

            const labelIdList = _.uniqBy(basicEntities, "label");
            if (labelIdList.length > 0) {
              if (basicEntities.length > 1) {
                if (basicEntities.length !== labelIdList.length) {
                  for (var i = 0; i < basicEntities.length; i++) {
                    for (var j = 0; j < labelIdList.length; j++) {
                      if (basicEntities[i].id !== labelIdList[j].id) {
                        basicEntities[i].isLabelDuplicate = true;
                      } else {
                        basicEntities[i].isLabelDuplicate = false;
                        break;
                      }
                    }
                  }
                } else {
                  for (var i = 0; i < basicEntities.length; i++) {
                    basicEntities[i].isLabelDuplicate = false;
                  }
                }
              }
            }
            for (var i = 0; i < basicEntities.length; i++) {
              let isCommaIndex = ImportProjectHelpers.validateFirstLastIndex(
                basicEntities[i]
              );
              let unique = this.checkIfArrayIsUnique(basicEntities[i].entities);
              if (isCommaIndex || !unique) {
                basicEntities[i].isEntityDuplicate = true;
              } else {
                basicEntities[i].isEntityDuplicate = false;
              }
            }
          } else if (
            !this.emptyCheck &&
            basicEntities.length > 0 &&
            !validateBasic
          ) {
            this.emptyCheck = this.checkBascicEntityUnique(basicEntities);
          }
        }
        submitDisabled = this.emptyCheck;
      }
    }
    //
    // const styles = require("./TaggerEdit.scss");
    const inputWidth = { width: "50%" };
    const { projectName, tags, instructions } = this.state.fields;
    let ignoreClass = "hidden";
    const docOptions = (
      <Form.Checkbox
        label="Single Label per Entity"
        name="autoClose"
        checked={this.state.autoClose}
        value="Single Label"
        onChange={this.advancedOptionChange.bind(this, "autoClose")}
      />
    );

    const imgPolyV2Options = (
      <div>
        <Form.Field>
          <Checkbox
            radio
            label="Polygon"
            name="checkboxRadioGroup"
            value="polygon"
            checked={this.state.defaultShape === "polygon"}
            onChange={this.advancedOptionChange.bind(this, "defaultShape")}
          />
        </Form.Field>
        <Form.Field>
          <Checkbox
            radio
            label="Rectangle"
            name="checkboxRadioGroup"
            value="rectangle"
            checked={this.state.defaultShape === "rectangle"}
            onChange={this.advancedOptionChange.bind(this, "defaultShape")}
          />
        </Form.Field>
        <Form.Checkbox
          label="Keep Entity Selected"
          name="keepEntitySelected"
          checked={this.state.keepEntitySelected}
          value="Keep Entity Selected"
          onChange={this.advancedOptionChange.bind(this, "keepEntitySelected")}
        />
      </div>
    );
    const imgBoundingOptions = (
      <div>
        <Form.Checkbox
          label="Show Notes"
          name="notes"
          checked={this.state.notes}
          value="Show Notes"
          onChange={this.advancedOptionChange.bind(this, "notes")}
        />
        <Form.Checkbox
          label="Single Label per Entity"
          name="autoClose"
          checked={this.state.autoClose}
          value="Single Label"
          onChange={this.advancedOptionChange.bind(this, "autoClose")}
        />
        <Form.Checkbox
          label="Hide Annotation After Labeling"
          name="hideLabels"
          checked={this.state.hideLabels}
          value="Single Label"
          onChange={this.advancedOptionChange.bind(this, "hideLabels")}
        />
      </div>
    );

    if (
      this.state.fileUploaded &&
      this.state.type === "file" &&
      this.state.fileUploadStats.numHitsIgnored > 0
    ) {
      ignoreClass = "";
    }

    const advPanels = [
      {
        key: "details",
        title: "Advanced Details",
        content: {
          as: Form.Checkbox,
          onClick: this.advancedOptionChange.bind(this, "isURLs"),
          value: this.state.isURLs,
          label: "URLs ?",
        },
      },
    ];

    const uploadprops = {
      name: "file",
      action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
      headers: {
        authorization: "authorization-text",
      },
      showUploadList: false,
    };
    console.log("this.state.projectEdited", this.state.projectEdited);
    let tagPlaceHolder = "List of Classes comma seperated : Car, Cat, Tree";
    return (
      <div style={{ margin: "0 6%" }}>
        <Helmet title="Edit Project" />
        {this.props.currentProject && (
          <div className="pr_labelset">
            <div className="project_sec m-t-20">
              <div className="project_title f-column singletxt">
                <div className="f-center">
                  <a
                    onClick={() =>
                      this.props.pushState(
                        projectTypePath(projectDetails) +
                          this.props.params.orgName +
                          "/" +
                          this.props.params.projectName +
                          "/" +
                          this.props.params.projectId
                      )
                    }
                  >
                    <span className="btn_back m-r-5">
                      <img alt="back" src={BACK} />
                    </span>
                  </a>
                </div>
                <div className="breadcrum-sec">
                  <div className="sec_holder">
                    <a
                      className={
                        "tilte_display fw-400 " +
                        (this.props.params.orgName.length > 15
                          ? "pro_width"
                          : "")
                      }
                      title={this.props.params.orgName}
                    >
                      {this.props.params.orgName}
                    </a>
                    {breadcrumbSeperate()}
                    <a
                      className={
                        "tilte_display fw-400 " +
                        (this.props.params.projectName.length > 15
                          ? "pro_width"
                          : "")
                      }
                      onClick={() =>
                        this.props.pushState(
                          "/projects/" +
                            this.props.params.orgName +
                            "/" +
                            this.props.params.projectName +
                            "/" +
                            this.props.params.projectId
                        )
                      }
                      title={this.props.params.projectName}
                    >
                      {this.props.params.projectName}
                    </a>
                  </div>
                </div>
              </div>
              <div className="project_set">
                {!this.state.projectEdited &&
                  this.state.type === "label" &&
                  projectDetails &&
                  projectDetails.task_type === DOCUMENT_ANNOTATION && (
                    <EntityOptions
                      value={this.state.docCategoryType}
                      onInputChange={this.docOptionTypeChange}
                    />
                  )}
                {!this.state.projectEdited &&
                  this.state.type === "label" &&
                  projectDetails &&
                  projectDetails.task_type === IMAGE_MASKING && (
                    <div>
                      <ToggleSwitch
                        createUserDefinedColor={
                          this.state.createUserDefinedColor
                        }
                        isUserDefinedColorSwitchChange={this.isUserDefinedColorSwitchChange.bind(
                          this
                        )}
                      />
                      <span className="m-l-10">Create User Defined Color </span>
                    </div>
                  )}
              </div>
            </div>
            {!this.state.projectEdited && this.state.type === "label" && (
              <div className="project_view project_new advance">
                <div className="w-100 box_shodow m-h-content box_area">
                  <div className="main_box flex-colum m-t-40">
                    <div className="base_root w-100">
                      {// detail-1
                      !this.state.projectEdited &&
                        this.state.type === "label" &&
                        projectDetails &&
                        projectDetails.task_type === DOCUMENT_ANNOTATION && (
                          <div className="m-b-8 w-50 p-r-10 p-l-10">
                            {!this.state.projectEdited && projectDetails && (
                              <Form key="import1" compact>
                                <Form.Input
                                  id="projectName"
                                  onChange={this.handleChange.bind(
                                    this,
                                    "projectName"
                                  )}
                                  label="Project Name"
                                  control="input"
                                  type="text"
                                  value={projectName}
                                />
                              </Form>
                            )}
                          </div>
                        )}

                      {// detail-2
                      this.state.type === "label" &&
                        projectDetails &&
                        (projectDetails.task_type === TEXT_MODERATION ||
                          projectDetails.task_type === TEXT_SUMMARIZATION ||
                          projectDetails.task_type === IMAGE_CLASSIFICATION ||
                          projectDetails.task_type === VIDEO_CLASSIFICATION ||
                          projectDetails.task_type === POS_TAGGING ||
                          projectDetails.task_type === POS_TAGGING_GENERIC ||
                          projectDetails.task_type === TEXT_CLASSIFICATION ||
                          projectDetails.task_type === IMAGE_BOUNDING_BOX ||
                          projectDetails.task_type ===
                            IMAGE_POLYGON_BOUNDING_BOX ||
                          projectDetails.task_type ===
                            IMAGE_POLYGON_BOUNDING_BOX_V2 ||
                          projectDetails.task_type === IMAGE_MASKING ||
                          projectDetails.task_type === VIDEO_BOUNDING_BOX ||
                          projectDetails.task_type === IMAGE_SEGMENTATION ||
                          projectDetails.task_type === DICOM_CLASSIFICATION ||
                          projectDetails.task_type === DICOM_SEGMENTATION) && (
                          <div className="m-b-8 w-50 p-r-10 p-l-10">
                            {!this.state.projectEdited && (
                              <Form key="import1" compact>
                                <Form.Input
                                  id="projectName"
                                  onChange={this.handleChange.bind(
                                    this,
                                    "projectName"
                                  )}
                                  label="Project Name"
                                  control="input"
                                  type="text"
                                  value={projectName}
                                />
                              </Form>
                            )}
                          </div>
                        )}

                      {!this.state.projectEdited && (
                        <div className="m-b-8 w-50 p-r-10 p-l-10">
                          <div>
                            <label style={{ color: "rgba(0,0,0,.87)" }}>
                              Workspace Name
                            </label>
                            {this.renderWorksapce()}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="base_root w-100">
                      {this.state.type === "label" &&
                        projectDetails &&
                        (projectDetails.task_type === TEXT_MODERATION ||
                          projectDetails.task_type === TEXT_SUMMARIZATION ||
                          projectDetails.task_type === IMAGE_CLASSIFICATION ||
                          projectDetails.task_type === VIDEO_CLASSIFICATION ||
                          projectDetails.task_type === POS_TAGGING ||
                          projectDetails.task_type === POS_TAGGING_GENERIC ||
                          projectDetails.task_type === TEXT_CLASSIFICATION ||
                          projectDetails.task_type === IMAGE_BOUNDING_BOX ||
                          projectDetails.task_type ===
                            IMAGE_POLYGON_BOUNDING_BOX ||
                          projectDetails.task_type ===
                            IMAGE_POLYGON_BOUNDING_BOX_V2 ||
                          projectDetails.task_type === IMAGE_MASKING ||
                          projectDetails.task_type === IMAGE_SEGMENTATION ||
                          projectDetails.task_type === VIDEO_BOUNDING_BOX ||
                          projectDetails.task_type === DICOM_CLASSIFICATION ||
                          projectDetails.task_type === DICOM_SEGMENTATION) && (
                          <div className="m-b-8 w-100 p-r-10 p-l-10">
                            {!this.state.projectEdited && (
                              <Form key="import1" compact>
                                {typeof tags === "string" &&
                                  (projectDetails.task_type ===
                                    IMAGE_POLYGON_BOUNDING_BOX ||
                                    projectDetails.task_type ===
                                      VIDEO_BOUNDING_BOX ||
                                    projectDetails.task_type ===
                                      IMAGE_POLYGON_BOUNDING_BOX_V2 ||
                                    projectDetails.task_type ===
                                      IMAGE_BOUNDING_BOX ||
                                    projectDetails.task_type ===
                                      IMAGE_SEGMENTATION ||
                                    (projectDetails.task_type ===
                                      IMAGE_MASKING &&
                                      !showUserDefinedColor)) && (
                                    <Form.Input
                                      id="tags"
                                      onChange={this.handleChange.bind(
                                        this,
                                        "tags"
                                      )}
                                      label="List of classes comma seperated"
                                      control="input"
                                      type="text"
                                      value={tags}
                                    />
                                  )}
                                {projectDetails.task_type === POS_TAGGING && (
                                  <div className="checkOption">
                                    <Form.Checkbox
                                      label="Enable Auto Tagging"
                                      disabled={true}
                                      name="autoTag"
                                      checked={projectDetails.enableAutoTag}
                                      value="Enable Auto Tagging"
                                    />
                                  </div>
                                )}

                                {projectDetails.task_type ===
                                  IMAGE_CLASSIFICATION && (
                                  <div className="checkOption">
                                    <Form.Checkbox
                                      label="Upload Model"
                                      disabled={true}
                                      name="autoTag"
                                      checked={projectDetails.enableAutoTag}
                                      value="Upload"
                                    />
                                  </div>
                                )}

                                {projectDetails.task_type ===
                                  POS_TAGGING_GENERIC && (
                                  <div className="checkOption">
                                    <Form.Checkbox
                                      label="Enable Auto Tagging"
                                      name="autoTag"
                                      checked={projectDetails.enableAutoTag}
                                      value="Enable Auto Tagging"
                                    />
                                  </div>
                                )}

                                {(projectDetails.task_type ===
                                  IMAGE_SEGMENTATION || projectDetails.task_type === IMAGE_MASKING ||
                                  projectDetails.task_type === VIDEO_BOUNDING_BOX
                                )
                                  && (
                                    <div className="w-100">
                                    <div className="w-100 m-b-20">
                                    <Collapse className="c_collapse" bordered={true} expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}>
                                        <Panel header="IMAGE ATTRIBUTES">
                                          <div className="view_holder custom_textarea w-100">
                                          <div className="w-100">
                                          {
                                            // this.state.imageAttributes.map((value, index) => {
                                              fields.classification.map((value,index) => {
                                              console.log("imageAttributes data",value)
                                            return (
                                            <ImageAttributeForm
                                              handleAttributeChange={this.handleAttributeChange.bind(this)}
                                              addAttribute={this.addAttribute.bind(this)}
                                              removeAttribute={this.removeAttribute.bind(this)}
                                              value={value}
                                              index={index}
                                              // total={this.state.imageAttributes.length}
                                              total={fields.classification.length}
                                              />
                                            )
                                            })
                                          }
                                          </div>
                                        </div>
          
                                          </Panel>
                                    </Collapse>
                                  </div>
                                  </div>
                                )}

                                {(projectDetails.task_type ===
                                  IMAGE_SEGMENTATION ||
                                  projectDetails.task_type ===
                                    IMAGE_MASKING ||
                                    projectDetails.task_type === VIDEO_BOUNDING_BOX
                                  ) && (
                                  <div className="w-100">
                                    <div className="w-100 m-b-10">
                                      <Collapse
                                        className="c_collapse"
                                        bordered={true}
                                        expandIcon={({ isActive }) => (
                                          <Icon
                                            type="caret-right"
                                            rotate={isActive ? 90 : 0}
                                          />
                                        )}
                                      >
                                        <Panel header="OBJECT/Annotation ATTRIBUTES">
                                          <div className="view_holder custom_textarea w-100">
                                            <div className="w-100">
                                              {
                                                <Form className="div-classify">
                                                  <Form.Input
                                                    className="w-33 p-r-5"
                                                    onChange={this.handleObjectAttributeChange.bind(
                                                      this
                                                    )}
                                                    id="objectAttribute"
                                                    compact
                                                    // value={
                                                    //   this.state.objectAttribute
                                                    // }
                                                    value={fields.attributes}
                                                    placeholder="List of comma separated object attribute names"
                                                  />
                                                </Form>
                                              }
                                            </div>
                                          </div>
                                        </Panel>
                                      </Collapse>
                                    </div>
                                  </div>
                                )}


                      { (projectDetails.task_type === VIDEO_BOUNDING_BOX || 
                         projectDetails.task_type === IMAGE_MASKING || 
                         projectDetails.task_type === IMAGE_SEGMENTATION ) &&
												<div className="w-100">
													<div className="w-100 m-b-20">
													<Collapse className="c_collapse" bordered={true} expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}>
															<Panel header="ATTRIBUTES VALUES">
																<div className="view_holder custom_textarea w-100">
																<div className="w-100">
																{
                                    fields.editClassAttribute.map((value,index)=>{
																	return (
                                  <ImageAttributeForm
																		handleAttributeChange={this.handleEditAttributeChange.bind(this)}
																		addAttribute={this.addAttributeList.bind(this)}
																		removeAttribute={this.removeAttributeList.bind(this)}
																		value={value}
																		index={index}
                                    role={"objectAttribute"}
                                    total={fields.editClassAttribute.length}
                                    />)
																	})
																}
																</div>
															</div>
																</Panel>
													</Collapse>
												</div>
												</div>
												}

                                {(projectDetails.task_type ===
                                  IMAGE_CLASSIFICATION ||
                                  projectDetails.task_type ===
                                    VIDEO_CLASSIFICATION ||
                                  projectDetails.task_type === POS_TAGGING ||
                                  projectDetails.task_type ===
                                    POS_TAGGING_GENERIC ||
                                  projectDetails.task_type ===
                                    TEXT_CLASSIFICATION ||
                                  projectDetails.task_type ===
                                    DICOM_CLASSIFICATION ||
                                  projectDetails.task_type ===
                                    DICOM_SEGMENTATION) && (
                                  <div>
                                    <Form.Input
                                      id="tags"
                                      onChange={this.handleChange.bind(
                                        this,
                                        "tags"
                                      )}
                                      label="List of classes comma seperated"
                                      control="input"
                                      type="text"
                                      value={tags}
                                    />
                                  </div>
                                )}
                                <div className="tagSet">
                                  {this.state.modelEntity &&
                                    ImportProjectHelpers.listEntities(this)}
                                  <div className="tagSetEdit">
                                    {this.state.modelEntity && (
                                      <AntIcon
                                        type="edit"
                                        title="Edit"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          this.setState({
                                            nermodelVisible: true,
                                          })
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                              </Form>
                            )}
                          </div>
                        )}

                      {/* { this.state.type === 'label'
                       && projectDetails && !this.state.projectEdited
                       && (
                         projectDetails.task_type === IMAGE_BOUNDING_BOX ||
                         projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX_V2 ||
                         projectDetails.task_type === DOCUMENT_ANNOTATION ||
                         projectDetails.task_type === IMAGE_MASKING ) &&
													<div className="m-b-8 w-50 p-l-10 p-r-10">

											<Collapse  className="c_collapse" >

												<Panel header="Advanced options" key="1">
												<div>
                          { this.state.type === 'label' && projectDetails && (projectDetails.task_type === IMAGE_BOUNDING_BOX) &&
                            <Form>
                              <Form.Checkbox label="Show Notes" name="notes" checked={this.state.notes} value="Show Notes" onChange={this.advancedOptionChange.bind(this, 'notes')} />
                              <Form.Checkbox label="Single Label per Entity" name="autoClose" checked={this.state.autoClose} value="Single Label" onChange={this.advancedOptionChange.bind(this, 'autoClose')} />
                              <Form.Checkbox label="Hide Annotation After Labeling" name="hideLabels" checked={this.state.hideLabels} value="Single Label" onChange={this.advancedOptionChange.bind(this, 'hideLabels')} />
                            </Form>
                          }
                          { this.state.type === 'label'
                          && projectDetails
                          && (
                            projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX_V2
                            ) &&
                            <Form>
                              <Form.Field>
                              <Checkbox  radio  label="Polygon"  name="checkboxRadioGroup"  value="polygon"
                                checked={this.state.defaultShape === 'polygon'}
                                onChange={this.advancedOptionChange.bind(this, 'defaultShape')} />
                              </Form.Field>
                              <Form.Field>
                                <Checkbox radio  label="Rectangle"  name="checkboxRadioGroup"  value="rectangle"
                                checked={this.state.defaultShape === 'rectangle'}
                                  onChange={this.advancedOptionChange.bind(this, 'defaultShape')} />
                              </Form.Field>
                            </Form>
                          }
                           { this.state.type === 'label' && projectDetails && (projectDetails.task_type === DOCUMENT_ANNOTATION || projectDetails.task_type === POS_TAGGING_GENERIC) &&
                            <Form>
                              <Form.Checkbox label="Single Label per Entity" name="autoClose" checked={this.state.autoClose} value="Single Label" onChange={this.advancedOptionChange.bind(this, 'autoClose')} />
                            </Form>
                          // <Form.Checkbox label="Single label per entity" name="notes" value="Single label per entity" />
                          }
                          </div>
                            </Panel>
                          </Collapse>
                      </div>
                      } */}

                      {projectDetails.task_type === DOCUMENT_ANNOTATION &&
                        !this.state.projectEdited && (
                          <div className="m-b-8 w-50 p-l-10 p-r-10">
                            <Collapse
                              className="c_collapse"
                              defaultActiveKey={["1"]}
                            >
                              <Panel header="Dynamic Label" key="1">
                                {/* <Collapse className="c_collapse" bordered={true} expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}>
																<Panel header="Dynamic Label"> */}
                                <div>
                                  <Checkbox
                                    size="mini"
                                    checked={this.state.allowDynamiclabelling}
                                    onClick={this.dynamicLabelOptionChange.bind(
                                      this
                                    )}
                                    label="Allow Dynamic Labelling"
                                  />
                                </div>
                              </Panel>
                            </Collapse>
                          </div>
                        )}
                    </div>
                    {/* Color picker display section for image masking  <ColorLabelForm/>*/}
                    {projectDetails.task_type === IMAGE_MASKING &&
                      showUserDefinedColor &&
                      coloredTags.length > 0 && (
                        <div className="w-100">
                          <label className="cval_label">
                            List of Entities/ Categories
                          </label>
                          <div className="column-reverse">
                            {coloredTags.map((coloredTag, index) => {
                              return (
                                <ColorLabelForm
                                  coloredTag={coloredTag}
                                  handleColorLabelChange={this.handleColorLabelChange.bind(
                                    this
                                  )}
                                  handleColorChange={this.handleColorChange.bind(
                                    this
                                  )}
                                  addColorTag={this.addColorTag.bind(this)}
                                  changeExistingColor={this.changeExistingColor.bind(
                                    this
                                  )}
                                  removeColorTag={this.removeColorTag.bind(
                                    this
                                  )}
                                  index={index}
                                  task_type={
                                    this.props.projectDetails.task_type
                                  }
                                />
                              );
                            })}
                          </div>
                        </div>
                      )}
                    {!this.state.projectEdited &&
                      this.state.type === "label" &&
                      projectDetails &&
                      projectDetails.task_type === DOCUMENT_ANNOTATION && (
                        <div className="base_root w-100">
                          <div className="m-b-8 w-100 p-l-10 p-r-10">
                            <h5 className="c_label">List of Labels</h5>
                            {!this.state.projectEdited && projectDetails && (
                              <Form
                                key="import1"
                                className="m-b-10 w-100"
                                compact
                              >
                                {projectDetails.task_type ===
                                  DOCUMENT_ANNOTATION && (
                                  <div
                                    className={
                                      this.state.isShowAdvanceLabelTop
                                        ? "column-reverse"
                                        : ""
                                    }
                                  >
                                    {!this.state.advancedTags &&
                                      this.state.basicEntities.length > 0 && (
                                        <div className="column-reverse">
                                          {this.state.basicEntities.map(
                                            (basiclabel) => {
                                              return (
                                                <BasicEntityForm
                                                  list={basiclabel}
                                                  updateInput={(e, id, key) =>
                                                    this.updateBasicFormInput(
                                                      e,
                                                      id,
                                                      key
                                                    )
                                                  }
                                                  removeLabel={(id) =>
                                                    this.removeBasicFormEntity(
                                                      id
                                                    )
                                                  }
                                                />
                                              );
                                            }
                                          )}
                                        </div>
                                      )}
                                    {// adds advance entity form for document annotation
                                    !this.state.advancedTags &&
                                      this.state.advanceEntities.length > 0 && (
                                        <div className="column-reverse">
                                          {this.renderAdvanceEntities()}
                                        </div>
                                      )}
                                  </div>
                                )}
                              </Form>
                            )}
                          </div>
                        </div>
                      )}

                    {this.state.type === 'label' && projectDetails && (projectDetails.task_type === DOCUMENT_ANNOTATION || projectDetails.task_type === POS_TAGGING_GENERIC) && !this.state.projectEdited &&
												<div className="base_root w-100">
													<div className="p-l-10 p-r-10 m-b-5 w-100">
													<Collapse className="c_collapse" defaultActiveKey={['1']}>
															<Panel header="Classifications" key="1">
																<div className="view_holder custom_textarea w-100">
																<div className="w-100">
																<div className="remove_btn">
                                { (this.state.fields.classification_name && this.state.fields.classification_classes && this.state.fields.classification_disp_name && (this.state.fields.classification_name.length > 0 && this.state.fields.classification_disp_name.length > 0 && this.state.fields.classification_classes.length > 0)) &&
                                <a className="btn_clear" href="javascript:void(0);" onClick={() => { this.setState({ addClassificationGroup: false }); this.resetClassification();}}>Remove</a>
                                }
																</div>
																{
                                  <Form className="div-classify">
                                    <Form.Input className="w-33 p-r-5" onChange={this.handleChange.bind(this, 'classification_name')} id="classification_name" compact label="Name" value={this.state.fields.classification_name} placeholder="Theme" />
                                    <Form.Input className="w-33 p-r-5" onChange={this.handleChange.bind(this, 'classification_disp_name')} id="classification_disp_name" compact label="Display Name" value={this.state.fields.classification_disp_name} placeholder="Choose the theme for given aritcle." />
                                    <Form.Input className="w-33 p-r-5" onChange={this.handleChange.bind(this, 'classification_classes')} id="classification_classes" compact label="Classes" value={this.state.fields.classification_classes } placeholder="News,Sports,Business" />
                                  </Form>
                                }
																</div>
															</div>

																</Panel>
													</Collapse>
													</div>

												</div>
												}

                    {!this.state.projectEdited && (
                      <div className="base_root w-100">
                        <div className="p-l-10 p-r-10 w-100">
                          <Form className="w-100">
                            <div className="view_holder custom_textarea">
                              <Form.Input
                                id="instruction"
                                type="textarea"
                                onChange={this.handleChange.bind(
                                  this,
                                  "instructions"
                                )}
                                label="Tagging Instruction"
                                control="TextArea"
                                value={instructions}
                              />
                            </div>
                          </Form>
                        </div>
                      </div>
                    )}
                    {!this.state.projectEdited && (
                      <div className="base_root w-100">
                        <div className="txt-center m-t-40 p-l-10 p-r-10 w-100">
                          <Button
                            className="r_submit"
                            type="submit"
                            disabled={false}
                            onClick={this.handleSubmit}
                          >
                            Submit
                          </Button>
                          <p
                            className="error"
                            disabled={!this.state.errors}
                          >
                            {this.state.errors}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="success_msg"
            >
              {(this.state.projectEdited || this.state.showCompletedModel) && (
                <div className="suceess_holder">
                  {this.state.showCompletedModel && (
                    <h2 className="m-t-15 success_title">
                      {" "}
                      Model Update successful
                    </h2>
                  )}
                  {this.state.projectEdited && (
                    <h2 className="m-t-15 success_title">
                      {" "}
                      Project updated successfully
                    </h2>
                  )}
                  <Button
                    className="success_submit m-b-15"
                    onClick={() =>
                      this.props.pushState(
                        projectTypePath(projectDetails) +
                          this.props.params.orgName +
                          "/" +
                          this.props.params.projectName +
                          "/" +
                          this.props.params.projectId
                      )
                    }
                  >
                    Back to Projects
                  </Button>
                </div>
              )}
              {this.state.type === "file" &&
                !this.state.uploadType &&
                !this.state.fileUploaded &&
                projectDetails &&
                !this.state.manualUploadCallbackres && (
                  <div className="pr_uploadsec">
                    <h2 className="m-b-20 m-t-5">Select Upload Type</h2>
                    <div className="upload_holder">
                      <div className="raw_data">
                        <div>
                          <h4 className="sec_tilte">Raw Data</h4>
                        </div>
                        <div className="txt-center">
                          <AntButton
                            className="pr_upload m-t-10"
                            icon="upload"
                            onClick={() => {
                              this.setState({ uploadType: "Raw" });
                            }}
                          >
                            {" "}
                            Upload{" "}
                          </AntButton>
                        </div>
                      </div>
                      <div className="upload_pre">
                        <div>
                          <h4 className="sec_tilte">Pre-Annotated Data</h4>
                          <p>
                            If you have some data which is already pre-annotated
                            and want to go through annotations and correct them.
                          </p>
                        </div>
                        <div className="txt-center">
                          <AntButton
                            className="pr_upload"
                            icon="upload"
                            onClick={() => {
                              this.setState({ uploadType: "Pre-Annotated" });
                            }}
                          >
                            {" "}
                            Upload{" "}
                          </AntButton>
                        </div>
                      </div>
                      <div className="upload_pre">
                        <div>
                          <h4 className="sec_tilte">Manual Data</h4>
                          <p>
                            If you have data that is of huge size, upload the
                            images directly to S3 browser under the respective
                            path eg:(uploads/projectid) and click proceed after
                            the upload is completed.
                          </p>
                        </div>
                        <div className="txt-center">
                          <AntButton
                            className="pr_upload m-t-10"
                            icon="right-circle-o"
                            onClick={() => {
                              this.props.setloaderMenu(true);
                              manualUpload(
                                this.props.params.projectId,
                                this.props.projectDetails.task_type,
                                this.manualUploadCallback
                              );
                            }}
                          >
                            Proceed{" "}
                          </AntButton>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
            </div>
            <div className="text-center marginTop suceess_pageview">
              {this.state.fileUploaded && this.state.type === "file" && (
                <div className="suceess_holder">
                  <h2 className="m-t-15"> Project update successful</h2>
                  <div className="success_view">
                    <div className="viewbox">
                      <h3>{this.state.fileUploadStats.numHitsCreated}</h3>
                      <h4>Number of HITs Created</h4>
                    </div>
                    <div className="viewbox ignoreClass">
                      <h3>{this.state.fileUploadStats.numHitsIgnored}</h3>
                      <h4>Number of HITs Ignored</h4>
                    </div>
                    <div className="viewbox">
                      <h3>
                        {bytes(
                          this.state.fileUploadStats.totalUploadSizeInBytes
                        )}
                      </h3>
                      <h4>File Size</h4>
                    </div>
                  </div>
                  <div>
                    <Button
                      color="teal"
                      onClick={() =>
                        this.props.pushState(
                          projectTypePath(projectDetails) +
                            this.props.params.orgName +
                            "/" +
                            this.props.params.projectName +
                            "/" +
                            this.props.params.projectId
                        )
                      }
                    >
                      Back to Project
                    </Button>
                  </div>
                </div>
              )}
              {this.state.manualUploadCallbackres && (
                <div className="suceess_holder">
                  <h2 className="m-t-15"> Project update successful</h2>
                  <div className="success_view">
                    <div className="viewbox">
                      <h3>{this.state.manualHit}</h3>
                      <h4>Number of HITs Created</h4>
                    </div>
                    <div className="viewbox ignoreClass">
                      <h3>{this.state.manualHitIgnored}</h3>
                      <h4>Number of HITs Ignored</h4>
                    </div>
                    <div className="viewbox">
                      <h3>{bytes(this.state.manualHitSize)}</h3>
                      <h4>File Size</h4>
                    </div>
                  </div>
                  <div>
                    <Button
                      color="teal"
                      onClick={() => {
                        this.setState({ manualUploadCallbackres: false });
                        this.props.pushState(
                          projectTypePath(projectDetails) +
                            this.props.params.orgName +
                            "/" +
                            this.props.params.projectName +
                            "/" +
                            this.props.params.projectId
                        );
                      }}
                    >
                      Back to Project
                    </Button>
                  </div>
                </div>
              )}
              {!this.state.fileUploaded &&
                this.state.type === "file" &&
                this.state.uploadType === "Pre-Annotated" &&
                projectDetails && (
                  <div className="pr_selectfile">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <h3 className="sec_tilte" style={{ marginTop: "0" }}>
                          Import Data
                        </h3>
                        <h3>Select file with Pre-Annotated data </h3>
                      </div>
                      <Button
                        className="text-left default_btn"
                        onClick={() => {
                          this.setState({
                            uploadType: undefined,
                            file: undefined,
                            fileCompare: undefined,
                            fileName: "",
                            fileNameCompare: "",
                            errors: undefined,
                          });
                        }}
                      >
                        Change Import Type
                      </Button>
                    </div>

                    <div className="select_holder m-b-15">
                      <div className="b_l">
                        {(projectDetails.task_type === POS_TAGGING ||
                          projectDetails.task_type === POS_TAGGING_GENERIC ||
                          projectDetails.task_type === DOCUMENT_ANNOTATION) && (
                          <div>
                            <p>
                              Please upload a text file with each line in file
                              having input sentence in following json format.
                              Format is similar to the annotated and downloaded
                              json file from ProNotate. Max size 10MB
                            </p>
                            <div className="codeArea">
                              <code style={{color:"black"}}>
                              {JSON.stringify(posSample)}
                              </code>
                            </div>
                            <p>
                              <b>Content</b> contONains input text,{" "}
                              <b>annotation</b> has the labeled content,{" "}
                              <b>extras</b> is for some extra columns that you
                              want to show with each row.
                            </p>
                          </div>
                        )}

                        {(projectDetails.task_type === TEXT_SUMMARIZATION ||
                          projectDetails.task_type === TEXT_MODERATION) && (
                          <div>
                            <p>
                              Please upload a text file with each line in file
                              having input sentence in following tab seperated
                              format. Max size 10MB
                            </p>
                            <pre>
                              Text Line Result Text Extra KeyValue
                              Pair1(optional) Extra KeyValue Pair1(optional)
                              <br />
                              <br />
                              AFP - India's Tata Iron and Steel Company Ltd.
                              Tata Iron and Steel Company. id=1 content=games
                              <br />
                              British Foreign Minister UK Foreign Minister id=2
                              content=UK site=34
                              <br />
                              Japan carmaker Toyota Japanese carmaker Toyota
                              id=100
                            </pre>
                          </div>
                        )}
                        {projectDetails.task_type === TEXT_CLASSIFICATION && (
                          <Form>
                            <Form.Field>
                              Selected value: <b>{this.state.value}</b>
                            </Form.Field>
                            <Form.Field>
                              <Checkbox
                                radio
                                label="TSV/CSV"
                                name="checkboxRadioGroup"
                                value="tsv"
                                checked={this.state.selectedFormat === "tsv"}
                                onChange={this.advancedOptionChange.bind(
                                  this,
                                  "selectedFormat"
                                )}
                              />
                            </Form.Field>
                            <Form.Field>
                              <Checkbox
                                radio
                                label="JSON"
                                name="checkboxRadioGroup"
                                value="json"
                                checked={this.state.selectedFormat === "json"}
                                onChange={this.advancedOptionChange.bind(
                                  this,
                                  "selectedFormat"
                                )}
                              />
                            </Form.Field>
                          </Form>
                        )}
                        {projectDetails.task_type === TEXT_CLASSIFICATION &&
                          this.state.selectedFormat === "json" && (
                            <div>
                              <p>
                                Please upload a text file with each line in file
                                having input sentence in json format. This is
                                same as download format from ProNotate Max size
                                10MB
                              </p>
                              <pre>{textClassificationJsonSample}</pre>
                            </div>
                          )}
                        {projectDetails.task_type === TEXT_CLASSIFICATION &&
                          this.state.selectedFormat === "tsv" && (
                            <div>
                              <p>
                                Please upload a text file with each line in file
                                having input sentence in following tab seperated
                                format. Max size 10MB
                              </p>
                              <pre>
                                Text Line Comma Separated Labels Extra KeyValue
                                Pair1(optional) Extra KeyValue Pair1(optional)
                                <br />
                                <br />
                                <br />
                                <br />
                                AFP - India's Tata Iron and Steel Company Ltd.
                                Class1, Class2 id=1 content=games
                                <br />
                                British Foreign Minister Class4,Class5 id=2
                                content=UK site=34
                                <br />
                                Japan carmaker Toyota Class1 id=100
                              </pre>
                            </div>
                          )}
                        {projectDetails.task_type === IMAGE_CLASSIFICATION && (
                          <div>
                            <p>
                              Please upload a text file with each line in file
                              having input sentence in following tab seperated
                              format. Max size 10MB{" "}
                            </p>
                            <pre>
                              Image_URL Comma Separated Labels Extra KeyValue
                              Pair1(optional) Extra KeyValue Pair1(optional)
                              <br />
                              <br />
                              <br />
                              <br />
                              http://amazonaws.com/grande.jpg Class1, Class2,
                              Class3 id=1 context=image1
                              <br />
                              http://amazonaws.com/Carraway.jpg Class1 id=2
                              context=image2 site=34
                              <br />
                              http://.amazonaws.com/slices.jpg Class1, Class2
                              id=32
                            </pre>
                          </div>
                        )}
                        {projectDetails.task_type === DICOM_SEGMENTATION && (
                          <div>
                            <p>
                              Please upload a text file with each line in file
                              having input sentence in following json format.
                              Format is similar to the annotated and downloaded
                              json file from ProNotate. Max size 10MB
                            </p>
                            <div className="codeArea">
                              <code style={{color:"black"}}>
                                {JSON.stringify(imagePolyBoundingSample)}
                              </code>
                            </div>
                            <p>
                              <b>Content</b> contains image URL,{" "}
                              <b>annotation</b> has the co-ordinate of polygon
                              [first and last value of array is of same
                              co-ordinate], <b>extras</b> is for some extra
                              columns that you want to show with each row.
                            </p>
                          </div>
                        )}
                        {projectDetails.task_type === VIDEO_CLASSIFICATION && (
                          <div>
                            <p>
                              Please upload a text file with each line in file
                              having input sentence in following tab seperated
                              format. Max size 10MB
                            </p>
                            <pre>
                              Video_URL Comma Separated Labels Extra KeyValue
                              Pair1(optional) Extra KeyValue Pair1(optional)
                              <br />
                              <br />
                              <br />
                              <br />
                              http://amazonaws.com/grande.mp4 Class1, Class2,
                              Class3 id=1 context=image1
                              <br />
                              http://amazonaws.com/Carraway.mp4 Class1 id=2
                              context=image2 site=34
                              <br />
                              http://.amazonaws.com/slices.mp4 Class1, Class2
                              id=32
                            </pre>
                          </div>
                        )}
                        {projectDetails.task_type === IMAGE_BOUNDING_BOX && (
                          <div>
                            <p>
                              Please upload a text file with each line in file
                              having input sentence in following json format.
                              Format is similar to the annotated and downloaded
                              json file from ProNotate. Max size 10MB
                            </p>
                            <div className="codeArea">
                              <code style={{color:"black"}}>
                                {JSON.stringify(imageBoundingSample)}
                                </code>
                            </div>
                            <p>
                              <b>Content</b> contains input image URL,{" "}
                              <b>annotation</b> has the left-top and
                              right-bottom co-ordinates, <b>extras</b> is for
                              some extra columns that you want to show with each
                              row.
                            </p>
                          </div>
                        )}
                        {(projectDetails.task_type ===
                          IMAGE_POLYGON_BOUNDING_BOX ||
                          projectDetails.task_type ===
                            IMAGE_POLYGON_BOUNDING_BOX_V2 ||
                          projectDetails.task_type === IMAGE_SEGMENTATION ||
                          projectDetails.task_type === IMAGE_MASKING) && (
                          <div>
                            <p>
                              Please upload a text file with each line in file
                              having input sentence in following json format.
                              Format is similar to the annotated and downloaded
                              json file from ProNotate. Max size 10MB
                            </p>
                            <div className="codeArea">
                              <code style={{color:"black"}}>
                                {JSON.stringify(imagePolyBoundingSample)}
                              </code>
                            </div>
                            <p>
                              <b>Content</b> contains image url,{" "}
                              <b>annotation</b> has the co-ordinate of polygon
                              [first and last value of array is of same
                              co-ordinate], <b>extras</b> is for some extra
                              columns that you want to show with each row.
                            </p>
                          </div>
                        )}
                        {projectDetails.task_type === VIDEO_BOUNDING_BOX && (
                          <div>
                            <p>
                              Please upload a text file with each line in file
                              having input sentence in following json format.
                              Format is similar to the annotated and downloaded
                              json file from ProNotate. Max size 10MB
                            </p>
                            <div className="codeArea">
                              <code style={{color:"black"}}>
                                {JSON.stringify(imagePolyBoundingSample)}
                                </code>
                            </div>
                            <p>
                              <b>Content</b> contains video url,{" "}
                              <b>annotation</b> has the co-ordinate of polygon
                              [first and last value of array is of same
                              co-ordinate], <b>extras</b> is for some extra
                              columns that you want to show with each row.
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="b_r">
                        <form
                          encType="multipart/form-data"
                          action=""
                          key="importFile"
                          className="text-center"
                        >
                          {/* <input className="h4 text-primary" disabled={this.state.loading} type="file" name="fileName" onChange={this.handleUploadFile}>
                    </input> */}
                          <AntUpload
                            {...uploadprops}
                            disabled={this.state.loading}
                            onChange={this.handleAntUploadFile}
                            className="upload_filebtn"
                          >
                            <AntButton>
                              <AntIcon type="upload" /> Click to Upload
                            </AntButton>
                          </AntUpload>
                          <div className="upload_info">
                            <h4>{this.state.fileName}</h4>
                            {/*this.state.file && this.state.file.size > 10000000 && <p style={{ color: 'red', fontWeight: 'bold' }}> File size is big: {bytes(this.state.file.size)} </p>*/}
                            {this.state.file && (
                              <p> File Size: {bytes(this.state.file.size)} </p>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="txt-center msg_txt">
                      <p className="error" disabled={!this.state.errors}>
                        {this.state.errors}
                      </p>
                      <Button
                        className="base_btnsubmit m-b-15"
                        type="submit"
                        disabled={
                          this.state.file === undefined ||
                          checkFileSize(
                            bytes(this.state.file.size).toString(),
                            this.state.uploadType
                          )
                            ? true
                            : false
                        }
                        onClick={this.handleSubmit.bind(
                          this,
                          "PRE_TAGGED_JSON"
                        )}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                )}

              {!this.state.fileUploaded &&
                this.state.type === "file" &&
                this.state.uploadType === "Raw" &&
                projectDetails && (
                  <div className="pr_selectfile">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <h3 className="sec_tilte" style={{ marginTop: "0" }}>
                          Import Data
                        </h3>
                        <h3>Select file with input data</h3>
                      </div>
                      <Button
                        className="text-left default_btn"
                        onClick={() => {
                          this.setState({
                            uploadType: undefined,
                            file: undefined,
                            fileCompare: undefined,
                            fileName: "",
                            fileNameCompare: "",
                            errors: undefined,
                          });
                        }}
                      >
                        Change Import Type
                      </Button>
                    </div>

                    <div className="select_holder m-b-15">
                      <div className="b_l">
                        {projectDetails.task_type === POS_TAGGING && (
                          <p>Please upload a text/doc/pdf file. </p>
                        )}
                        {projectDetails.task_type === POS_TAGGING_GENERIC && (
                          <div className="w-100">
                            <p>
                              Upload a Text/CSV file where each line has one
                              data-item to be tagged.
                            </p>
                            <Accordion
                              as={Form.Field}
                              panels={advPanels}
                              style={{ fontSize: "14px" }}
                            />
                          </div>
                        )}
                        {projectDetails.task_type === TEXT_SUMMARIZATION && (
                          <div className="w-100">
                            <p>
                              Please upload a text file with each line in file
                              having sentence to be summarized.
                            </p>
                            <p>
                              <strong> OR </strong>{" "}
                            </p>
                            <p>
                              {" "}
                              A zip file of all the text documents to be
                              summarized. Max file size is 100 MB for free plans
                            </p>
                          </div>
                        )}
                        {projectDetails.task_type === TEXT_MODERATION && (
                          <div className="w-100">
                            <p>
                              Please upload a text file with each line in file
                              having sentence to be moderated.
                            </p>
                            <p>
                              <strong> OR </strong>{" "}
                            </p>
                            <p>
                              {" "}
                              A zip file of all the text documents to be
                              moderated. Max file size is 100 MB for free plans
                            </p>
                          </div>
                        )}
                        {projectDetails.task_type === TEXT_CLASSIFICATION && (
                          <div className="w-100">
                            <p>
                              Please upload a text file with each line in file
                              having sentence to be classified.
                            </p>
                            <p>
                              <strong> OR </strong>{" "}
                            </p>
                            <p>
                              {" "}
                              A zip file of all the text documents to be
                              classified. Max file size is 100 MB for free plans
                            </p>
                          </div>
                        )}
                        {projectDetails.task_type === DOCUMENT_ANNOTATION && (
                          <div className="w-100">
                            <p>Please upload a valid text/doc/pdf file.</p>
                            <p>
                              <strong> OR </strong>{" "}
                            </p>
                            <p>
                              A zip file of all the text/pdf/doc documents. Max
                              file size is 100 MB for free plans
                            </p>
                            <Accordion
                              as={Form.Field}
                              panels={advPanels}
                              style={{ fontSize: "14px" }}
                            />
                          </div>
                        )}
                        {projectDetails.task_type === IMAGE_CLASSIFICATION && (
                          <div className="w-100">
                            <p>Upload a text file containing URLs of images.</p>
                            <p>
                              <strong> OR </strong>{" "}
                            </p>
                            <p>
                              A zip file of all the images. Max file size is 100
                              MB for free plans
                            </p>
                          </div>
                        )}
                        {projectDetails.task_type === DICOM_SEGMENTATION && (
                          <div className="w-100">
                            <p>
                              Upload a text file containing URLs of images.{" "}
                            </p>
                            <strong> OR </strong>
                            <p>
                              A zip file of all the images. Max file size is 100
                              MB for free plans
                            </p>
                          </div>
                        )}
                        {projectDetails.task_type === VIDEO_CLASSIFICATION && (
                          <p>Upload a text file containing URLs of videos.</p>
                        )}
                        {(projectDetails.task_type === IMAGE_BOUNDING_BOX ||
                          projectDetails.task_type ===
                            IMAGE_POLYGON_BOUNDING_BOX ||
                          projectDetails.task_type ===
                            IMAGE_POLYGON_BOUNDING_BOX_V2 ||
                          projectDetails.task_type === IMAGE_MASKING ||
                          projectDetails.task_type === IMAGE_SEGMENTATION) && (
                          <div className="w-100">
                            <p>Upload a text file containing URLs of images.</p>
                            <p>
                              <strong> OR </strong>{" "}
                            </p>
                            <p>
                              A zip file of all the images. Max file size is 100
                              MB for free plans
                            </p>
                            {(projectDetails.task_type === IMAGE_MASKING ||
                              projectDetails.task_type ===
                                IMAGE_SEGMENTATION) && (
                              <div className="b_r">
                                <form
                                  encType="multipart/form-data"
                                  action=""
                                  key="importFile"
                                  className="text-center"
                                >
                                  <AntUpload
                                    {...uploadprops}
                                    disabled={this.state.loading}
                                    onChange={this.handleAntUploadFile}
                                    className="upload_filebtndefault"
                                  >
                                    <AntButton>
                                      <AntIcon type="upload" /> Click to Upload
                                    </AntButton>
                                  </AntUpload>
                                  <div className="upload_infoCompare" style={{ marginTop : "-80px"}}>
                                    <h4>{this.state.fileName}</h4>
                                    {this.state.file && (
                                      <h5 style={{ marginTop : "-20px"}}>
                                        {" "}
                                        File Size: {bytes(
                                          this.state.file.size
                                        )}{" "}
                                      </h5>
                                    )}
                                  </div>
                                </form>
                              </div>
                            )}
                          </div>
                        )}
                        {projectDetails.task_type === VIDEO_BOUNDING_BOX && (
                          <p>Upload a text file containing URLs of videos.</p>
                        )}
                      </div>
                      {(projectDetails.task_type === IMAGE_MASKING ||
                        projectDetails.task_type === IMAGE_SEGMENTATION) && (
                        <div className="b_r">
                          <h3 className="h3tag">
                            Upload dataset to compare with reference images
                            while annotating
                          </h3>
                          <form
                            encType="multipart/form-data"
                            action=""
                            key="importFile"
                            className="text-center"
                          >
                            <AntUpload
                              {...uploadprops}
                              disabled={this.state.loading}
                              onChange={this.handleAntUploadFileCompare}
                              className="upload_filebtn"
                            >
                              <AntButton>
                                <AntIcon type="upload" /> Click to Upload
                              </AntButton>
                            </AntUpload>
                            <div className="upload_info">
                              <h4>{this.state.fileNameCompare}</h4>
                              {this.state.fileCompare && (
                                <p>
                                  {" "}
                                  File Size:{" "}
                                  {bytes(this.state.fileCompare.size)}{" "}
                                </p>
                              )}
                            </div>
                          </form>
                        </div>
                      )}
                      {projectDetails.task_type !== IMAGE_MASKING &&
                        projectDetails.task_type !== IMAGE_SEGMENTATION && (
                          <div className="b_r">
                            <form
                              encType="multipart/form-data"
                              action=""
                              key="importFile"
                              className="text-center"
                            >
                              <AntUpload
                                {...uploadprops}
                                disabled={this.state.loading}
                                onChange={this.handleAntUploadFile}
                                className="upload_filebtn"
                              >
                                <AntButton>
                                  <AntIcon type="upload" /> Click to Upload
                                </AntButton>
                              </AntUpload>
                              <div className="upload_info">
                                <h4>{this.state.fileName}</h4>
                                {this.state.file && (
                                  <p>
                                    {" "}
                                    File Size4: {bytes(
                                      this.state.file.size
                                    )}{" "}
                                  </p>
                                )}
                              </div>
                            </form>
                          </div>
                        )}
                    </div>
                    <div></div>
                    <div className="txt-center msg_txt">
                      <p className="error" disabled={!this.state.errors}>
                        {this.state.errors}
                      </p>
                      <Button
                        className="base_btnsubmit m-b-15"
                        type="submit"
                        disabled={
                          (this.state.file === undefined &&
                            this.state.fileCompare === undefined) ||
                          checkFileSize(
                            bytes(this.state.file.size).toString(),
                            this.state.uploadType
                          )
                            ? true
                            : false
                        }
                        onClick={this.handleSubmit}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                )}

              {/* Edit page for add model start*/}
              {/* {!this.state.fileUploaded && this.state.type === 'model' && this.state.uploadType === 'Raw' && projectDetails && */}
              {this.state.type === "model" && (
                <UploadModel
                  currentProject={this.props.currentProject}
                  goBackToOverview={this.goBackToOverview}
                  showCompletedModel={this.showCompletedModel}
                  projectType={this.props.projectDetails.task_type}
                  modelLoading={this.modelLoading}
                ></UploadModel>
              )}
              {/* Edit page for add model end */}
              <div>
                {this.state.nermodelVisible && (
                  <NERTagsModal
                    nermodelVisible={this.state.nermodelVisible}
                    editNERTag={this.state.editNERTag}
                    closeModel={() => this.setState({ nermodelVisible: false })}
                    NERTags={this.state.NERTags}
                    updateValue={this.updateValue}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  console.log("mapStateToProps Import", state);
  return {
    user: state.loginReducer.user,
    currentPathOrg: state.projectReducer.currentPathOrg,
    currentPathProject: state.projectReducer.currentPathProject,
    currentProject: state.projectReducer.currentProject,
    projectDetails: state.projectReducer.projectDetails,
    projects: state.projectReducer.projects,
    showUserDefinedColor: state.projectReducer.showUserDefinedColor,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pushState: push,
      setloaderMenu,
      getUserHomeData,
      getProjectDetails,
      updateFileUploadStats,
      updateHomeData,
      setCurrentProject,
      updateUserDefinedColorStats,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TaggerEdit);
