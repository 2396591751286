import React from 'react';

class CustomMultiSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  handleSelect = (optionValue) => {
    const { value = [], onChange } = this.props;
    const newValue = value.includes(optionValue)
      ? value.filter(v => v !== optionValue)
      : [...value, optionValue];
    onChange(newValue);
  };

  toggleDropdown = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  };

  render() {
    const { options, value = [], placeholder = "Select an Entity" } = this.props;
    const { isOpen } = this.state;

    // Styles
    const containerStyle = {
      // position: 'relative',
      width: '100%',
      minWidth: '200px',
    };

    const inputStyle = {
      width: '100%',
      padding: '8px',
      border: '1px solid #d9d9d9',
      borderRadius: '4px',
      minHeight: '32px',
      display: 'flex',
      flexWrap: 'wrap',
      gap: '4px',
      alignItems: 'center',
      cursor: 'pointer',
      backgroundColor: '#fff',
    };

    const tagStyle = {
      backgroundColor: '#f0f0f0',
      padding: '2px 6px',
      borderRadius: '2px',
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
    };

    const removeTagStyle = {
      cursor: 'pointer',
      color: '#999',
      fontWeight: 'bold',
    };

    const dropdownStyle = {
      // position: 'absolute',
      top: '100%',
      left: 0,
      right: 0,
      border: '1px solid #d9d9d9',
      borderRadius: '4px',
      backgroundColor: '#fff',
      maxHeight: '200px',
      overflowY: 'auto',
      zIndex: 1000,
      marginTop: '2px',
    };

    const optionStyle = {
      display:"flex",
      gap:"10px",
      alignItems:"center",
      padding: '8px',
      cursor: 'pointer',
    };

    const hoveredOptionStyle = {
      ...optionStyle,
      backgroundColor: '#f5f5f5',
    };

    return (
      <div style={containerStyle}>
        {/* Selected Items Display */}
        <div 
          style={inputStyle}
          onClick={this.toggleDropdown}
        >
          {value.length === 0 && (
            <span style={{ color: '#999' }}>{placeholder}</span>
          )}
          {value.map((val) => (
            <span key={val} style={tagStyle}>
              {val}
              <span 
                style={removeTagStyle}
                onClick={(e) => {
                  e.stopPropagation();
                  this.handleSelect(val);
                }}
              >
                ×
              </span>
            </span>
          ))}
        </div>

        {/* Dropdown Options */}
        {isOpen && (
          <div style={dropdownStyle}>
            {options.map((option) => (
              <div
                key={option}
                style={value.includes(option) ? optionStyle : hoveredOptionStyle}
                onClick={() => this.handleSelect(option)}
                // onMouseOver={(e) => e.target.style.backgroundColor = '#f5f5f5'}
                // onMouseOut={(e) => e.target.style.backgroundColor = value.includes(option) ? '#fff' : '#f5f5f5'}
              >
                <div>{option}</div>
                <div style={{height:"10px",width:"10px",borderRadius:"50%",backgroundColor: value.includes(option) ? "#12C4FC" : ""}}></div>
              </div>
            ))}
            {options.length === 0 && (
              <div style={optionStyle}>No options available</div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default CustomMultiSelect

